const roles = [
  { role: "All Source-Collection Manager (CO-CLO-001)", comp: [ 0, 1, 3, 4, 6, 7, 10, 12, 14, 20, 21, 22, 23, 24, 25, 26, 29, 31, 32, 36, 37, 40, 41, 44, 45, 46, 47, 48, 49 ] },
  { role: "All Source-Collection Requirements Manager (CO-CLO-002)", comp: [ 0, 1, 3, 6, 7, 10, 12, 14, 20, 21, 22, 23, 24, 25, 26, 29, 31, 32, 36, 37, 40, 41, 44, 45, 46, 47, 48, 49 ] },
  { role: "All-Source Analyst (AN-ASA-001)", comp: [ 4, 6, 7, 9, 10, 14, 20, 21, 22, 23, 24, 26, 27, 29, 31, 32, 34, 35, 37, 40, 41, 43, 44, 45, 47, 48, 49] },
  { role: "Authorizing Official (SP-RSK-001)", comp: [ 8, 11, 12, 14, 15, 16, 19, 21, 22, 23, 26, 31, 32, 33, 37, 42, 44, 48] },
  { role: "Communications Security (COMSEC) Manager (OV-MGT-002)", comp: [ 1, 8, 11, 12, 14, 15, 18, 19, 20, 21, 22, 23, 26, 37, 41, 48 ] },
  { role: "Cyber Crime Investigator (IN-INV-001)", comp: [ 4, 6, 9, 18, 21, 23, 24, 26, 37, 46, 47, 48, 49 ] },
  { role: "Cyber Defense Analyst (PR-CDA-001)", comp: [ 5, 6, 9, 10, 11, 13, 14, 17, 18, 19, 21, 22, 23, 26, 27, 29, 30, 37, 40, 42, 44, 45, 47, 48 ] },
  { role: "Cyber Defense Forensics Analyst (IN-FOR-002)", comp: [ 1, 4, 6, 7, 14, 15, 18, 21, 23, 26, 30, 37, 38, 39, 40, 41, 47, 48, 49 ] },
  { role: "Cyber Defense Incident Responder (PR-CIR-001)", comp: [ 1, 4, 6, 18, 20, 21, 23, 26, 29, 37, 40, 47, 48 ] },
  { role: "Cyber Defense Infrastructure Support Specialist (PR-INF-001)", comp: [ 1, 6, 14, 18, 19, 21, 23, 26, 29, 35, 37, 40, 45, 48, 49 ] },
  { role: "Cyber Instructional Curriculum Developer (OV-TEA-001)", comp: [ 9, 19, 20, 21, 22, 23, 26, 29, 30, 32, 35, 37, 40, 44, 48] },
  { role: "Cyber Instructor (OV-TEA-002)", comp: [ 2, 4, 5, 6, 9, 10, 16, 17, 19, 20, 21, 22, 23, 25, 26, 29, 30, 32, 37, 40, 43, 44, 47, 48] },
  { role: "Cyber Intel Planner (CO-OPL-001)", comp: [ 1, 2, 6, 7, 9, 10, 14, 16, 18, 20, 21, 22, 23, 24, 26, 31, 32, 33, 34, 35, 36, 37, 40, 41, 43, 44, 45, 47, 48, 49] },
  { role: "Cyber Legal Advisor (OV-LGA-001)", comp: [ 4, 6, 11, 21, 23, 24, 26, 31, 37, 44, 47, 48 ] },
  { role: "Cyber Operator (CO-OPS-001)", comp: [ 1, 3, 4, 5, 6, 7, 9, 10, 12, 14, 20, 21, 22, 23, 25, 26, 29, 30, 36, 37, 40, 43, 45, 47, 48] },
  { role: "Cyber Ops Planner (CO-OPL-002)", comp: [ 1, 6, 7, 9, 10, 14, 16, 18, 20, 21, 22, 23, 24, 26, 31, 32, 33, 34, 35, 36, 37, 40, 41, 44, 45, 47, 48, 49] },
  { role: "Cyber Policy and Strategy Planner (OV-SPP-002)", comp: [ 6, 16, 21, 23, 26, 32, 33, 37, 44, 48] },
  { role: "Cyber Workforce Developer and Manager (OV-SPP-001)", comp: [ 6, 15, 16, 21, 22, 23, 26, 32, 33, 37, 44, 48] },
  { role: "Data Analyst (OM-DTA-002)", comp: [ 5, 6, 9, 10, 12, 13, 14, 15, 17, 21, 23, 26, 27, 28, 30, 34, 37, 39, 40, 47, 48 ] },
  { role: "Database Administrator (OM-DTA-001)", comp: [ 1, 10, 11, 12, 13, 14, 15, 17, 20, 21, 23, 26, 30, 37, 48 ] },
  { role: "Enterprise Architect (SP-ARC-001)", comp: [ 5, 7, 9, 11, 13, 15, 17, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 35, 37, 38, 40, 41, 42, 44, 45, 48 ] },
  { role: "Executive Cyber Leadership (OV-EXL-001)", comp: [ 0, 20, 21, 23, 26, 32, 33, 37, 44, 47, 48] },
  { role: "Exploitation Analyst (AN-EXP-001)", comp: [ 1, 3, 4, 5, 6, 7, 9, 10, 13, 20, 21, 22, 23, 24, 26, 29, 30, 31, 32, 34, 37, 38, 40, 43, 44, 45, 47, 48, 49] },
  { role: "Forensics Analyst (IN-FOR-001)", comp: [ 1, 4, 6, 7, 14, 18, 21, 23, 26, 30, 37, 38, 40, 47, 48, 49 ] },
  { role: "Information Systems Security Developer (SP-SYS-001)", comp: [ 1, 5, 6, 7, 8, 11, 13, 14, 15, 17, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 32, 33, 34, 37, 38, 40, 41, 42, 45, 47, 48] },
  { role: "Information Systems Security Manager (OV-MGT-001)", comp: [ 1, 6, 8, 9, 11, 12, 14, 15, 18, 19, 20, 21, 22, 23, 26, 29, 30, 33, 36, 37, 40, 41, 44, 46, 47, 48] },
  { role: "Information Technology (IT) Project Manager (OV-PMA-002)", comp: [ 8, 9, 15, 20, 21, 22, 23, 25, 26, 32, 35, 36, 37, 41, 44, 46, 48] },
  { role: "IT Investment/Portfolio Manager (OV-PMA-004)", comp: [ 8, 20, 21, 23, 26, 32, 37, 46, 48] },
  { role: "IT Program Auditor (OV-PMA-005)", comp: [ 8, 9, 15, 20, 21, 22, 23, 26, 35, 37, 41, 46, 48] },
  { role: "Knowledge Manager (OM-KMG-001)", comp: [ 10, 11, 12, 20, 21, 23, 25, 26, 32, 37, 44, 48 ] },
  { role: "Mission Assessment Specialist (AN-ASA-002)", comp: [ 4, 6, 7, 9, 10, 13, 14, 20, 21, 22, 23, 24, 26, 27, 29, 31, 32, 34, 35, 37, 40, 41, 43, 44, 45, 47, 48, 49] },
  { role: "Multi-Disciplined Language Analyst (AN-LNG-001)", comp: [ 2, 4, 6, 9, 10, 20, 21, 22, 23, 24, 26, 29, 31, 32, 36, 37, 38, 43, 44, 45, 46, 47, 48, 49] },
  { role: "Network Operations Specialist (OM-NET-001)", comp: [ 1, 6, 11, 12, 14, 15, 19, 20, 21, 22, 23, 26, 29, 30, 37, 40, 41, 44, 45, 48, 49] },
  { role: "Partner Integration Planner (CO-OPL-003)", comp: [ 1, 6, 7, 9, 10, 14, 16, 20, 21, 22, 23, 26, 31, 32, 33, 34, 35, 37, 44, 45, 47, 48, 49] },
  { role: "Privacy Officer/Privacy Compliance Manager (OV-LGA-002)", comp: [ 11, 18, 20, 21, 23, 26, 31, 32, 33, 36, 37, 44, 45, 46, 47, 48] },
  { role: "Product Support Manager (OV-PMA-003)", comp: [ 8, 9, 15, 18, 20, 21, 22, 23, 25, 26, 35, 37, 41, 44, 46, 48] },
  { role: "Program Manager (OV-PMA-001)", comp: [ 8, 15, 20, 21, 22, 23, 25, 26, 32, 35, 37, 41, 46, 48] },
  { role: "Research and Development Specialist (SP-TRD-001)", comp: [ 4, 6, 8, 9, 14, 15, 16, 21, 22, 23, 24, 26, 30, 31, 37, 38, 41, 44, 45, 47, 48, 49] },
  { role: "Secure Software Assessor (SP-DEV-002)", comp: [ 5, 6, 9, 11, 14, 15, 17, 18, 19, 21, 22, 23, 26, 30, 37, 38, 39, 40, 41, 42, 47, 48, 49] },
  { role: "Security Architect (SP-ARC-002)", comp: [ 1, 2, 6, 7, 9, 11, 13, 14, 15, 17, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 32, 35, 36, 37, 38, 40, 41, 42, 44, 45, 47, 48] },
  { role: "Security Control Assessor (SP-RSK-002)", comp: [ 0, 1, 2, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 29, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 48] },
  { role: "Software Developer (SP-DEV-001)", comp: [ 5, 6, 9, 11, 14, 15, 17, 18, 19, 21, 22, 23, 26, 30, 37, 38, 39, 40, 41, 42, 47, 48, 49 ] },
  { role: "System Administrator (OM-ADM-001)", comp: [ 11, 14, 15, 17, 18, 19, 21, 22, 23, 26, 29, 30, 33, 37, 38, 40, 41, 42, 45, 48] },
  { role: "System Test & Evaluation Specialist (SP-TST-001)", comp: [ 5, 8, 11, 15, 19, 20, 21, 23, 26, 34, 37, 40, 41, 42, 44, 48] },
  { role: "Systems Developer (SP-SYS-002)", comp: [ 1, 5, 6, 7, 8, 11, 13, 14, 15, 17, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 33, 37, 38, 40, 41, 42, 44, 45, 47, 48 ] },
  { role: "Systems Requirements Planner (SP-SRP-001)", comp: [ 1, 7, 8, 9, 11, 12, 14, 15, 17, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 36, 37, 40, 41, 42, 44, 45, 46, 48 ] },
  { role: "Systems Security Analyst (OM-ANA-001)", comp: [ 5, 6, 11, 13, 14, 15, 17, 19, 20, 21, 22, 23, 26, 27, 29, 30, 31, 37, 38, 40, 41, 42, 45, 46, 47, 48 ] },
  { role: "Target Developer (AN-TGT-001)", comp: [ 4, 6, 7, 9, 10, 13, 14, 18, 20, 21, 22, 23, 24, 26, 27, 29, 31, 32, 34, 35, 37, 41, 43, 44, 45, 47, 48, 49 ] },
  { role: "Target Network Analyst (AN-TGT-002)", comp: [ 2, 3, 4, 6, 7, 9, 10, 13, 14, 20, 21, 22, 23, 24, 26, 27, 29, 31, 32, 34, 37, 38, 43, 44, 45, 47, 48, 49] },
  { role: "Technical Support Specialist (OM-STS-001)", comp: [ 7, 11, 15, 18, 19, 20, 21, 22, 23, 25, 26, 30, 33, 34, 37, 40, 42, 48 ] },
  { role: "Threat/Warning Analyst (AN-TWA-001)", comp: [ 4, 6, 7, 9, 10, 14, 20, 21, 23, 24, 26, 27, 29, 31, 32, 34, 35, 37, 40, 41, 43, 44, 45, 47, 48, 49 ] },
  { role: "Vulnerability Assessment Analyst (PR-VAM-001)", comp: [ 1, 4, 5, 6, 14, 17, 19, 20, 21, 23, 26, 37, 40, 42, 47, 48 ] }
]

export default roles
