import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import { Button as AntButton, Form, InputNumber, Result, Select } from 'antd'
import styled from 'styled-components'
import dropImg from '../assets/drop-here-courses_2.png'

import Course from './Course'

const CoursesList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
  text-align: center;
`

class TimeTable extends React.Component {
  constructor() {
    super()
    this.state = {
      defaultFormValues: {
        semesters: 2,
        credits: 120,
        profiles: [],
        course_sem_limit: 6,
        credit_tolerance: 5
      }
    }
  }

  render(){
    const { analysis } = this.props

    const printDroppable = (column) => {
      let draggedCourse = this.props.draggedCourse ? this.props.courses[this.props.draggedCourse] : null
      let isDropDisabled =  draggedCourse === null ? false :
        column.semester === draggedCourse.semester ? false : true

      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot) => (
            <CoursesList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.courseIds.map((course, index) => {
                return <Course key={this.props.courses[course].id} course={this.props.courses[course]} index={index} removeCourse={(course)=>this.props.removeCourses([course])} openAddModal={this.props.openAddModal}
                columnId={column.id} rsSkillsGroup={this.props.rsSkillsGroup}/>
              })}
              {provided.placeholder}
              {column.courseIds.length === 0 ? <img src={dropImg} className="dropImg" alt='dropHere'/> : ''}
            </CoursesList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="time-table">
        <h3 className="section-title">{analysis === "settings" ? "Profile Coverage Analysis" : "Suggested Curricula"}</h3>
        <div className="content">
          <div className="content-scroll">
            {analysis === "error" ? (
              <>
                <div className="buttons-container">
                  <Button variant="outline-secondary" onClick={this.props.goBackAnalysis}>{"Go Back"}</Button>
                </div>
                <div className="vertCenter">
                  <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                  />
                </div>
              </>
            ) : analysis === 'new-curricula' ? (
              <div className="new-curricula">
                <div className="buttons-container">
                  <Button variant="outline-secondary" onClick={this.props.goBackAnalysis}>{"Go Back"}</Button>
                </div>

                {this.props.semestersToShow >= 1 ? (
                    <>
                      <h5 className="main-title">1st year</h5>
                      <Container>
                        <Row>
                          <Col>
                            <h6 className="semester-title">winter semester</h6>
                            {printDroppable(this.props.columns['1-w'])}
                          </Col>

                          {this.props.semestersToShow >= 2 ? (
                            <Col>
                              <h6 className="semester-title">summer semester</h6>
                              {printDroppable(this.props.columns['1-s'])}
                            </Col>
                          ) : null}
                        </Row>
                      </Container>
                    </>
                ) : null}

                {this.props.semestersToShow >= 3 ? (
                  <>
                    <h5 className="main-title second">2nd year</h5>
                    <Container>
                      <Row>
                        <Col>
                          <h6 className="semester-title">winter semester</h6>
                          {printDroppable(this.props.columns['2-w'])}
                        </Col>

                        {this.props.semestersToShow >= 4 ? (
                          <Col>
                            <h6 className="semester-title">summer semester</h6>
                            {printDroppable(this.props.columns['2-s'])}
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </>
                ) : null}

                {this.props.semestersToShow >= 5 ? (
                  <>
                    <h5 className="main-title second">3rd year</h5>
                    <Container>
                      <Row>
                        <Col>
                          <h6 className="semester-title">winter semester</h6>
                          {printDroppable(this.props.columns['3-w'])}
                        </Col>

                        {this.props.semestersToShow >= 6 ? (
                          <Col>
                            <h6 className="semester-title">summer semester</h6>
                            {printDroppable(this.props.columns['3-s'])}
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </>
                ) : null}

                {this.props.semestersToShow >= 7 ? (
                  <>
                    <h5 className="main-title second">4th year</h5>
                    <Container>
                      <Row>
                        <Col>
                          <h6 className="semester-title">winter semester</h6>
                          {printDroppable(this.props.columns['4-w'])}
                        </Col>

                        {this.props.semestersToShow >= 8 ? (
                          <Col>
                            <h6 className="semester-title">summer semester</h6>
                            {printDroppable(this.props.columns['4-s'])}
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </>
                ) : null}

                {this.props.semestersToShow >= 9 ? (
                  <>
                    <h5 className="main-title second">5th year</h5>
                    <Container>
                      <Row>
                        <Col>
                          <h6 className="semester-title">winter semester</h6>
                          {printDroppable(this.props.columns['5-w'])}
                        </Col>

                        {this.props.semestersToShow >= 10 ? (
                          <Col>
                            <h6 className="semester-title">summer semester</h6>
                            {printDroppable(this.props.columns['5-s'])}
                          </Col>
                        ) : null}
                      </Row>
                    </Container>
                  </>
                ) : null}

              </div>
            ) : (
              <div className="analysis-settings">
                <h5 className="main-title">Profile Analysis Settings</h5>
                <Form
                  name="profileAnalysis"
                  labelWrap
                  colon={false}
                  labelCol={{span: 8}}
                  wrapperCol={{span: 15}}
                  initialValues={this.state.defaultFormValues} // to do semesters to show
                  onFinish={(values) => {
                    this.setState({defaultFormValues: values})
                    this.props.analyzeCurricula(JSON.parse(JSON.stringify(values)))
                  }}
                  autoComplete="off"
                >

                  <Form.Item
                    label="Years"
                    name="semesters"
                    rules={[{required: true, message: 'Please fill in the number of years!'}]}
                  >
                    <InputNumber min={1} max={5} step={1} />
                  </Form.Item>

                  <Form.Item
                    label="Max. courses per semester"
                    name="course_sem_limit"
                    rules={[{required: true, message: 'Please fill in the maximum courses per semester!'}]}
                  >
                    <InputNumber min={0} step={1}/>
                  </Form.Item>
{/*}
                  <Form.Item
                    label="ECTS Credits"
                    name="credits"
                    rules={[{required: true, message: 'Please fill in the total number of credits!'}]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
*/}
                  <Form.Item
                    label="ECTS Credits"
                    required
                    style={{marginBottom: 0}}
                  >
                    <Form.Item
                      rules={[{required: true, message: 'Please fill in the total number of credits!'}]}
                      name="credits"
                      style={{
                        display: 'inline-block'
                      }}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    
                    <Form.Item
                      name="credit_tolerance"
                      rules={[{required: true, message: 'Please fill in the credit tolerance!'}]}
                      style={{
                        display: 'inline-block'
                      }}
                    >
                      <InputNumber prefix="±" min={0} />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label="Profile to cover"
                    name="profiles"
                    rules={[{required: true, message: 'Please fill in at least one profile!'}]}
                  >
                    <Select 
                      mode="multiple" 
                      placeholder="Please select" 
                      allowClear 
                      options={this.props.rsProfiles.map(profile => ({label: profile.name, value: profile.id}))}
                    />
                  </Form.Item>

                  <div className="buttons-container">
                    <AntButton loading={analysis === "loading"} disabled={Object.keys(this.props.courses).length < 1} type="primary" htmlType="submit">
                      Analyze
                    </AntButton>
                  </div>
                </Form>
              </div>
            )}



          </div>
        </div>

      </Col>
    )
  }
}

export default TimeTable
