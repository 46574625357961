import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Form, Input, Modal, Select } from 'antd'

const EditProfileModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles, usersData }) => {
  const [modalLoading, setModalLoading] = React.useState(false)
  // const [profileData, setProfileData] = React.useState(null)
  const [form] = Form.useForm()


  // set default form values
  React.useEffect(() => {
    // rsProfiles.find(e => e.id === editRecord)
    // if (visible !== openAd && visible) {
    //   let initialData = JSON.parse(JSON.stringify(visible))
    //   delete initialData.key

      //initialData.type = initialData.type ? initialData.type.split(",").map(s => s.trim()) : []
      // form.setFieldsValue(initialData)
      // setOpenAd(rsProfiles.find(e => e.id === editRecord))

    if ((typeof editRecord === "object" && editRecord !== null) && form.getFieldValue("id") !== editRecord.id) {
      console.log("updatuju form")
      let initialData = JSON.parse(JSON.stringify(editRecord))
      delete initialData.key
      form.setFieldsValue(initialData)
    }

  }, [editRecord, form])

  function getUserName (id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers]
    let user = allUsers.find(u => u.id === id)
    let output = user.admin_level > 1 ? "ADMIN " : ""
    output += user.name + " (ID " + user.id + ")"
    return output
  }

  return (
    <Modal
      getContainer={false}
      centered
      open={typeof editRecord === "object" && editRecord !== null}
      title="Edit the profile"
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={()=>{
        form.resetFields()
        // setOpenAd(false)
        onCancel()
      }}
      className="edit-job-modal"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setModalLoading(true)
            delete values.created
            delete values.created_by
            delete values.updated
            delete values.updated_by

            Axios.post( Config.server.editProfile, {...authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                setModalLoading(false)
                if (response && response.data.error === false){
                  form.resetFields()
                  showMessage({type: 'success', content: "Updated successfully."})
                  refresh()
                } else {
                  console.log("error: " + response.data.message)
                  showMessage({type: 'error', content: "Something went wrong!"})
                }
              })
              .catch((error) => {
                setModalLoading(false)
                console.log(error)
                showMessage({type: 'error', content: "Something went wrong!"})
              })
          })
      }}
    >
      <Form form={form} layout="horizontal" name="edit_job_modal" labelCol={{ span: 7 }} wrapperCol={{ span: 15}} labelWrap colon={false} className="content-scroll" >

        <Form.Item name="name" label="Name" rules={[
          {required: true, message: 'Please fill in the name!'},
          {type: 'string', min: 2, max: 1023 },
        ]}>
          <Input />
        </Form.Item>

        <Form.Item name="skills_group" label="Skills Group" rules={[{type: 'array', max: 255 }]}>
          <Select showSearch showArrow allowClear mode="multiple" optionFilterProp={"label"} options={
            rsSkillsGroup
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            .map(skill => ({value: skill.id, label: skill.name}))
          }>
          </Select>
        </Form.Item>

        {typeof editRecord === "object" && editRecord !== null ? <>
          <Form.Item label="Updated">
            {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
          </Form.Item>

          <Form.Item label="Created">
            {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
          </Form.Item>
        </> : null }

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>

      </Form>

    </Modal>
  )
}

export default EditProfileModalForm
