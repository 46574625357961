import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Modal, Form, Input } from 'antd'
import {createHash} from 'crypto'

const UserEditModal = ({ setUser, visible, onModalConfirm, onCancel, showMessage, authData, token }) => {
  const [userEditModalLoading, setUserEditModalLoading] = React.useState(false)
  const [openUser, setOpenUser] = React.useState(false)
  const [form] = Form.useForm()

  // set default form values
  if (visible !== openUser && visible) {
    let initialData = JSON.parse(JSON.stringify(visible))
    initialData.password = ""
    form.setFieldsValue(initialData)
    setOpenUser(visible)
  }

  return (
    <Modal
      centered
      open={visible}
      title="Edit your profile"
      // width={690}
      okText="Save"
      confirmLoading={userEditModalLoading}
      cancelText="Cancel"
      onCancel={onCancel}
      className="user-edit-modal icon-position-fix"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.password = values.password ? createHash('sha256').update(values.password).digest('hex') : ""
            values.id = visible.id
            setUserEditModalLoading(true)

            Axios.post( Config.server.editUser, {...authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                setUserEditModalLoading(false)
                if (response && response.data.error === false){
                  form.resetFields()
                  showMessage({type: 'success', content: "Updated successfully."})
                  // setUser(response.data.user)
                  if (response.data.user.token === "") response.data.user.token = token
                  setUser({...response.data.user, password: values.password ? values.password : visible.password})
                  onModalConfirm()
                } else {
                  console.log("error: " + response.data.message)
                  showMessage({type: 'error', content: "Something went wrong!"})
                }
              })
              .catch((error) => {
                setUserEditModalLoading(false)
                console.log(error)
                showMessage({type: 'error', content: "Something went wrong!"})
              })

          })
      }}
    >
      <Form form={form} layout="horizontal" name="edit_user_modal" labelCol={{ span: 4 }} wrapperCol={{ span: 19}}>
        <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please fill in your name!'}, {type: 'string', max: 255}]}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="Institution" name="company" rules={[{type: 'string', max: 255}]}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="E-mail" name="email" rules={[{type: 'email', required: true, message: 'Please fill in your e-mail address!'},{type: 'string', max: 255}]}>
          <Input disabled autoComplete="off" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{min: 8, message: 'The password must be at least 8 characters long.' }, {type: 'string', max: 255}]} extra="Leave empty if you don't want to change the password!">
          <Input.Password autoComplete="off" type="password" placeholder="Your current password"/>
        </Form.Item>
      </Form>

    </Modal>
  )
}

export default UserEditModal
