import React from 'react'
import {Alert, Badge, Button, Col, Container, Form, Modal, Row, Spinner, Tab, Tabs, Table, ListGroup} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { DragDropContext } from 'react-beautiful-dnd'
import FilterList from '@material-ui/icons/FilterList'
import ListAlt from '@material-ui/icons/ListAlt'
import Launch from '@material-ui/icons/Launch'
import Close from '@material-ui/icons/Close'

import Filter from './Filter'
import TimeTable from './TimeTable.js'
import ListOfTrainings from './ListOfTrainings.js'
import Statistics from './Statistics.js'
import dndStructure from './initStructure/dndStructure'
import sampleTrainings from './initStructure/SampleTrainings'
import TrainingForm from './TrainingForm'

import { checkColumnStructure, checkTrainingsStructure } from "./utils"

class Trainings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trainings: {},
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedTraining: null,
      sampleTrainingsLoaded: false,
      showAddModal: false,
      showBrowseModal: false,
      addModalValidated: false,
      addModalData: null,
      modalTrainingId: null,
      dataToShow: props.dbTrainings,
      checkedList: []
    }
  }

  /* Close modal data dialog */
  closeAddModal = () => this.setState({
    showAddModal: false,
    addModalValidated: false
  })

  /* Open modal data dialog */
  openAddModal = (trainingId) => {
    let modalData = null
    if (trainingId !== null) {
      modalData = this.state.trainings[trainingId]
    }
    this.setState({showAddModal: true, modalTrainingId: trainingId, addModalData: modalData})
  }

  /* Open modal for available trainings */
  openBrowseModal = () => this.setState({
    showBrowseModal: true
  })

  /* Close modal */
  closeBrowseModal = () => this.setState({
    showBrowseModal: false
  })

  /* Manage list of checked trainings */
  onCheckBoxChange = (e,id) => {
    let resultArray = this.state.checkedList
    if (!this.state.checkedList.includes(id)) {
      resultArray.push(id)
    } else {
      resultArray = this.state.checkedList.filter(checkedId => checkedId !== id)
    }
    this.setState({
      checkedList: resultArray
    })
  }

  createTrainingDnDId = (name, prefix, pool) => {
    // Create base part of item ID in DnD structure
    let newIdBase = prefix + "_" + name.replace(/ /g,"_").toLowerCase()
    var counter = 0
    while (pool.includes(newIdBase+"_"+counter)) {
      counter++
    }
    return newIdBase+"_"+counter
    /*
    // martin nohava method
    // Escape special characters in newIdBase and create regexp
    let regexp = newIdBase.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const matcher = new RegExp(`^${regexp}`, 'g');

    let duplicateIds = pool.filter(i => i.match(matcher));

    // If duplicate IDs are not found
    if (duplicateIds.length === 0) {
      // Return new ID (e.g. prefix_name_of_training_in_lower_case_0)
      return newIdBase + "_0"
    } else {
      // If duplicate IDs are found
      duplicateIds.sort()
      // Get the greatest ID
      let latestId = duplicateIds.pop().split("_").slice(-1)
      // Increment the greates ID by 1
      latestId = Number(latestId) + 1
      // Return new unique ID (e.g. prefix_name_of_training_in_lower_case_1)
      return newIdBase + "_" + latestId
    }
    */
  }

  /* Write all selected trainings to the state */
  submitBrowseModal = () => {
    /* Prepare new empty state variables */
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newTrainings = JSON.parse(JSON.stringify(this.state.trainings))

    /* Add selected trainings */
    this.state.checkedList.forEach((id) => {
      /* Create new training object with data from selected training */
      let newTraining = JSON.parse(JSON.stringify(this.state.dataToShow.find(i => i.id === id)))
      /* Create new unique ID of training for use in DnD structure */
      newTraining.id = this.createTrainingDnDId(newTraining.name, "db", Object.keys(newTrainings))
      /* Add training ID to DnD structure */
      newColumns['available'].trainingIds.push(newTraining.id)
      /* Push object containing training data to trainings array */
      newTrainings = {
        ...newTrainings,
        [newTraining.id]: newTraining
      }
    })

    this.setState({columns: newColumns, trainings: newTrainings, checkedList: []})
    this.closeBrowseModal()
  }

  submitModal = () =>{
    if (this.state.modalTrainingId === null) {
      this.addCustomTraining(this.state.addModalData)
    } else {
      this.updateTraining(this.state.addModalData)
    }
    this.closeAddModal()
  }

  /* Write new training data to the state */
  addCustomTraining = (input) => {
    let newTraining = JSON.parse(JSON.stringify(input))
    let newState = {
      trainings: JSON.parse(JSON.stringify(this.state.trainings)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    // generate local training id
    newTraining.id = this.createTrainingDnDId(newTraining.name, "user", Object.keys(newState.trainings))
    // add to available columns
    newState.columns['available'].trainingIds.push(newTraining.id)
    newState.trainings[newTraining.id] = newTraining

    this.setState(newState)
  }

  updateTraining = (input) => {
    let newTraining = JSON.parse(JSON.stringify(input))
    let oldId = this.state.modalTrainingId
    let newState = {
      trainings: JSON.parse(JSON.stringify(this.state.trainings)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    newTraining.id = this.createTrainingDnDId(newTraining.name, "user", Object.keys(newState.trainings))
    let oldPosition = Object.keys(this.state.columns).find(columnId => this.state.columns[columnId].trainingIds.includes(oldId))

    // update courses
    delete newState.trainings[oldId]
    newState.trainings[newTraining.id] = newTraining

    // update columns
    let trainingIndex = newState.columns[oldPosition].trainingIds.indexOf(oldId)
    newState.columns[oldPosition].trainingIds[trainingIndex] = newTraining.id

    this.setState(newState)
  }
/*
  duplicateTraining = () => {
    let newTraining = JSON.parse(JSON.stringify(this.state.trainings[this.state.modalTrainingId]))
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newPosition = null
    Object.values(this.state.columns).some(item => {
      if(item.trainingIds.includes(newTraining.id)){
        newPosition = item.id
        return true
      } else {
        return null
      }
    })
    newTraining.name += " (copy)"
    newTraining.id = this.createTrainingDnDId(newTraining.name, "user", Object.keys(this.state.trainings))
    newColumns[newPosition].trainingIds.push(newTraining.id)

     this.setState({
       trainings: {...this.state.trainings, [newTraining.id]: newTraining},
       columns: newColumns
     })
    this.closeAddModal()
  }
*/
  removeForeverTraining = (training) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[training.position].trainingIds.splice( newState.columns[training.position].trainingIds.indexOf(training.id), 1 )
    delete newState.trainings[training.id]
    this.setState({columns: newState.columns, trainings: newState.trainings})
  }

  removeTraining = (training) => {
    let newPosition = 'available'
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    newColumns[training.position].trainingIds.splice( newColumns[training.position].trainingIds.indexOf(training.id), 1 )
    newColumns[newPosition].trainingIds.push(training.id)
    this.setState({columns: newColumns})
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)
      if (!newData.hasOwnProperty("trainings")) {
        this.setState({error:{message: "Wrong input file format!"}})
        return
      }
      // check valid position and item structure
      const columnTest = checkColumnStructure(newData.trainings, dndStructure.columns)
      const trainingTest = checkTrainingsStructure(newData.trainings)
      if (columnTest && trainingTest) {

        // prepare of writing to the state
        let newState = {
          trainings: JSON.parse(JSON.stringify(this.state.trainings)),
          columns: JSON.parse(JSON.stringify(this.state.columns))
        }

        // testing id duplicity and generating new id
        newData.trainings.forEach(newTraining => {

          /* Create new unique ID of training for use in DnD structure */
          newTraining.id = this.createTrainingDnDId(newTraining.name, "import", Object.keys(newState.trainings))

          // console.log(
          //   newTraining.id,
          //   this.state.columns.available.trainingIds.includes(newTraining.id),
          //   Object.keys(newState.trainings).includes(newTraining.id),
          // )

          // saving to the new state object
          newState.columns[newTraining.position].trainingIds.push(newTraining.id)
          delete newTraining.position
          newState.trainings[newTraining.id] = newTraining
        })

        // write to the state
        this.setState(newState)
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch(e) {
      console.log(e)
      this.setState({error:{message: "Sorry, something went wrong!"}})
    }
  }

  loadSamples = () => {
  // slouzi pro upload sample trainings
    if (typeof(sampleTrainings) !== "undefined" && sampleTrainings !== null && sampleTrainings.length !== 0){
      let sortedSampleTrainings = sampleTrainings.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      /* Prepare new empty state variables */
      let newColumns = JSON.parse(JSON.stringify(this.state.columns))
      let newTrainings = JSON.parse(JSON.stringify(this.state.trainings))

      /* Add selected trainings */
      sortedSampleTrainings.forEach((newTraining) => {
        /* Create new unique ID of training for use in DnD structure */
        newTraining.id = this.createTrainingDnDId(newTraining.name, "sample", Object.keys(newTrainings))
        /* Add training ID to DnD structure */
        newColumns['available'].trainingIds.push(newTraining.id)
        /* Push object containing training data to trainings array */
        newTrainings[newTraining.id] = newTraining
      })

      this.setState({columns: newColumns, trainings: newTrainings, sampleTrainingsLoaded: true})
    } else {
      this.setState({error:{message: "There are no sample trainings available!"}})
    }
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedTraining = this.state.trainings[start.draggableId].id
    this.setState({
      homeIndex,
      draggedTraining,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedTraining: null
      })
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedTraining: null
      })
      return
    }
    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]
    if (start === finish) {
      const newTrainingIds = Array.from(start.trainingIds)
      newTrainingIds.splice(source.index, 1)
      newTrainingIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        trainingIds: newTrainingIds
      }
      const newState = {
        ...this.state,
        homeIndex: null,
        draggedTraining: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }
      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startTrainingIds = Array.from(start.trainingIds)
    startTrainingIds.splice(source.index, 1)
    const newStart = {
      ...start,
      trainingIds: startTrainingIds
    }

    const finishTrainingIds = Array.from(finish.trainingIds)
    finishTrainingIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      trainingIds: finishTrainingIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedTraining: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  handleFilterChange = (newData) => {
    this.setState({dataToShow: newData})
  }

  resetFilter = () => {
    /* Show all trainings */
    this.setState({dataToShow: this.props.dbTrainings})
    /* Unselect all filter options */
    let items = document.getElementsByName('filter-checkbox')
    for (let i=0; i<items.length; i++) {
      if(items[i].type === 'checkbox') {
        items[i].checked = false
      }
    }
  }

  render(){
    const { columns, error, trainings, addModalData, draggedTraining, showAddModal, showBrowseModal, addModalValidated, modalTrainingId, sampleTrainingsLoaded, dataToShow, checkedList } = this.state
    const { dbTrainings, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles } = this.props

    // helper for display selected skills/knowledge by each skill group
    const selectedSkillsList = (formData, category, groupId) => {
      let output = []
      if (category === "skills") {
      rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
          if (formData.specific_skills.includes(skillId)) {
          output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
          }
      })
      } else {
      rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
          if (formData.specific_knowledge.includes(knowledgeId)) {
          output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
          }
      })
      }
      return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
    }

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <div><Button variant="link" onClick={()=>this.setState({error:null})}>Go back</Button></div>
          </div>
        ) : (
          <Container fluid>
            <Row>
              <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                <ListOfTrainings columns={columns} trainings={trainings}
                  draggedTraining={draggedTraining}
                  sampleTrainingsLoaded={sampleTrainingsLoaded}
                  addCustomTraining={this.addCustomTraining}
                  removeTraining={this.removeForeverTraining}
                  loadSamples={this.loadSamples}
                  openAddModal={this.openAddModal}
                  openBrowseModal={this.openBrowseModal}/>
                <TimeTable columns={columns} trainings={trainings}
                  draggedTraining={draggedTraining}
                  removeTraining={this.removeTraining}
                  importData={this.importData}
                  openAddModal={this.openAddModal}/>
                <Statistics rsSkillsGroup={rsSkillsGroup}
                  rsProfiles={rsProfiles}
                  trainings={ columns['selected'].trainingIds.map(trainingId => trainings[trainingId])}/>
              </DragDropContext>
            </Row>
          </Container>
        )}

        <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

          {/* <Form noValidate validated={addModalValidated} onSubmit={this.submitModal}> */}

            <Modal.Header closeButton className={modalTrainingId === null ? "" : " edit"}>
              <Modal.Title>{modalTrainingId === null ? "Add new training" : "Edit training"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <TrainingForm saveLocally={(newData)=>{this.setState({addModalData: newData}, () => {this.submitModal()})}} formData={addModalData} userDataMerged={null} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} authData={null} hideForm={()=>this.closeAddModal()} showMessage={null} getPublicData={null}></TrainingForm>

            </Modal.Body>
            {/* <Modal.Footer>
              {modalTrainingId === null ? "" : <Button variant="outline-dark" className="left" onClick={this.duplicateTraining}>Duplicate</Button>}
              <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form> */}
        </Modal>

        <Modal show={showBrowseModal} onHide={this.closeBrowseModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >
          <Form noValidate validated={addModalValidated} onSubmit={this.submitBrowseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Browse available trainings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container defaultActiveKey="#item0">
              <Row>
                <Col className="no-padding-right" sm={3}>
                  <div className="col-header">
                    <h5 className="text"><FilterList/>Filter by</h5>
                    <Button variant="outline-secondary" className="browse-header-button" size="sm" onClick={this.resetFilter}><div className="material-icon-wrapper"><Close fontSize='small'/>Reset</div></Button>
                  </div>
                  <div className="content-scroll">
                    <Filter data={dbTrainings} skillsGroup={rsSkillsGroup} toShow={this.handleFilterChange}/>
                  </div>
                </Col>
                <Col sm={3} className="min-padding-left">
                  <div className="col-header">
                    <h5 className='text'><ListAlt/>Trainings</h5>
                    <Button variant="link" className="browse-header-button" disabled size="sm">{dataToShow.length}/{dbTrainings.length}</Button>
                  </div>
                  <ListGroup className=" content-scroll">
                    {!dataToShow.length ? (
                      <div className="placeholder">
                        <Spinner animation="grow" /><br/>No training matches the selected filter!
                      </div>
                    ) : (
                      dataToShow.map((item) => (
                        <ListGroup.Item action href={"#item" + item.id} key={item.id} className={checkedList.includes(item.id) ? "selected" : ""} >
                          <Form.Check
                            inline
                            label={item.name}
                            name="training-checkbox"
                            type="checkbox"
                            checked={checkedList.includes(item.id) ? true : false}
                            id={item.id}
                            onChange={(e)=>this.onCheckBoxChange(e, item.id)}
                          />
                        </ListGroup.Item>
                      ))
                    )}
                  </ListGroup>
                </Col>
                <Col sm={6}>
                  <h5 className="col-header">&nbsp;</h5>
                  <Tab.Content className="content-scroll">
                    <Tab.Pane eventKey="#item0">
                      <div className="placeholder" style={{paddingTop: "40px"}}>
                        Nothing selected
                      </div>
                    </Tab.Pane>
                    {dataToShow.map((item) => (
                      <Tab.Pane eventKey={"#item" + item.id} key={item.id}>
                        <h1>{item.name}</h1>
                        <Tabs
                          defaultActiveKey="overview"
                          id="uncontrolled-tab-trainings"
                          className="mb-3"
                        >
                          <Tab eventKey="overview" title="Overview">
                            <h5 variant="secondary">Description</h5>
                            <p>{item.description}</p>
                            <Button variant="info" href={item.link} target="_blank"><div className="material-icon-wrapper"><Launch fontSize="small"/>Website</div></Button>
                            <p>&nbsp;</p>
                            <h5>Rewire skill groups</h5>
                              <div className="skills-list">
                              {item.skills_group.sort((a, b) => rsSkillsGroup.find(i => i.id === a).name.localeCompare(rsSkillsGroup.find(i => i.id === b).name)).map((groupId) => {
                              let color = rsSkillsGroup.find(i => i.id === groupId).color
                              return (
                                  <span key={groupId}>
                                  <Badge data-tip data-for={"Tooltip-skillgroup-" + groupId} style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}>
                                      {rsSkillsGroup.find(i => i.id === groupId).name}
                                  </Badge>
                                  <ReactTooltip className="tooltip" id={"Tooltip-skillgroup-" + groupId} place="top" effect="solid">
                                      <h5>{rsSkillsGroup.find(i => i.id === groupId).name}</h5>
                                      <h6>Included skills:</h6>
                                      {selectedSkillsList(item, "skills", groupId)}
                                      <h6>Included knowledge:</h6>
                                      {selectedSkillsList(item, "knowledge", groupId)}
                                  </ReactTooltip>
                                  </span>
                              )
                              })}
                              </div>
                          </Tab>
                          <Tab eventKey="details" title="Details">
                            <Table striped bordered hover className="details">
                              <tbody>
                                <tr>
                                  <td>Organizer</td>
                                  <td>{item.organizer}</td>
                                </tr>
                                <tr>
                                  <td>Country</td>
                                  <td>{item.country}</td>
                                </tr>
                                <tr>
                                  <td>Language</td>
                                  <td>
                                    {item.language.length > 1 ? (
                                      <ul>
                                      {item.language.map((item, index) => (
                                        <li key={index}>
                                          {item}
                                        </li>
                                      ))}
                                      </ul>
                                    ) : (item.language.toString())}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Dates</td>
                                  <td>
                                    {item.dates.length > 1 ? (
                                      <ul>
                                      {item.dates.map((item, index) => (
                                        <li key={index}>
                                          {item}
                                        </li>
                                      ))}
                                      </ul>
                                    ) : (item.dates.toString())}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Duration</td>
                                  <td>{item.duration === '' ? '' : item.duration + ' hours'}</td>
                                </tr>
                                <tr>
                                  <td>Can lead to certification</td>
                                  <td>{item.can_lead_to_certification}</td>
                                </tr>
                                <tr>
                                  <td>Includes exams for certification</td>
                                  <td>{item.includes_exams_for_certification}</td>
                                </tr>
                                <tr>
                                  <td>Content type</td>
                                  <td>{item.content_type}</td>
                                </tr>
                                <tr>
                                  <td>Timing</td>
                                  <td>{item.timing}</td>
                                </tr>
                                <tr>
                                  <td>Type/Format</td>
                                  <td>{item.type_format}</td>
                                </tr>
                                <tr>
                                  <td>Price</td>
                                  <td>{item.price === '' ? '' : item.price + ' €'}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            {checkedList.length} trainings selected &nbsp;&nbsp;
            <Button variant="secondary" onClick={this.closeBrowseModal}>Close</Button>
            <Button variant="primary" type="submit" disabled={!checkedList.length}>Load</Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </div>
    )
  }

}

export default Trainings
