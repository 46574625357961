import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Button, Modal, Form, Input } from 'antd'
import {createHash} from 'crypto'

const RegisterModal = ({ visible, switchLogin, onModalConfirm, onCancel, showMessage }) => {
  const [registerModalLoading, setRegisterModalLoading] = React.useState(false)
  const [form] = Form.useForm()

  function register(){
    form
      .validateFields()
      .then((values) => {
        values.password = createHash('sha256').update(values.password).digest('hex')
        setRegisterModalLoading(true)

        Axios.post( Config.server.register, values, {headers: { 'Content-Type': 'application/json' }})
          .then((response) => {
            setRegisterModalLoading(false)
            if (response && response.data.error === false){
              form.resetFields()
              showMessage({type: 'success', content: "Registration successful. Now you have to wait for the confirmation by Admin.", duration: 5})
              switchLogin("toLog")
            } else {
              showMessage({type: 'error', content: response.data.message})
            }
          })
          .catch((error) => {
            setRegisterModalLoading(false)
            console.log(error)
            showMessage({type: 'error', content: "Registration failed!"})
          })
      })
      .catch((info) => {
        console.log(info)
      })
  }


  return (
    <Modal
      centered
      open={visible}
      title="Registration"
      width={500}
      footer={null}
      onCancel={onCancel}
      className="register-modal"
    >
      <Form form={form} layout="horizontal" name="register_modal" labelCol={{ span: 5 }}
      wrapperCol={{ span: 18}} className="loginForm" onFinish={()=>register()}>
        <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please fill in your name!'}, {type: 'string', max: 255}]}>
          <Input />
        </Form.Item>
        <Form.Item label="Institution" name="company" rules={[{type: 'string', max: 255}]}>
          <Input />
        </Form.Item>
        <Form.Item label="E-mail" name="email" rules={[{type: 'email', required: true, message: 'Please fill in your e-mail address!'}, {type: 'string', max: 255}]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[
          {required: true, message: 'Please fill in your password!'},
          {min: 8, message: 'The password must be at least 8 characters long.' },
          {type: 'string', max: 255}
        ]}>
          <Input.Password type="password"/>
        </Form.Item>
        <Form.Item>
        </Form.Item>
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24}}>
          <Button type="primary" htmlType="submit" className="button icon-position-fix" loading={registerModalLoading}>
            Register
          </Button>
          Or <Button type="link" className="buttonLink" onClick={() => switchLogin("toLog")}>log in now!</Button>
        </Form.Item>

      </Form>

    </Modal>
  )
}

export default RegisterModal
