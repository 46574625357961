import React from 'react'
import { Typography  } from 'antd'

import './BlankSection.scss'

const { Title } = Typography


const BlankSection = () => {

  // use primarly Ant 4.x graphic library for your compoments : https://4x.ant.design/components/overview/

  return (
    <section id="blank-section" className={"section-content"}>
      <span><Title className="pageTitle">This is a blank demo section.</Title></span>
      <p> You can use it as a starting point for your code.</p>




    </section>
  )
}

export default BlankSection
