import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Button, Modal, Space, Typography } from 'antd'

const { Title } = Typography

const FeedbackMain= ({ showMessage, visitorId, askToFeedback, setAskToFeedback }) => {
  const feedbackType = "feedback_main"

  const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false)
  const [feedbackModalTimer, setFeedbackModalTimer] = React.useState(null)

  React.useEffect(() => {
    if (visitorId && askToFeedback === feedbackType) {
      visitorFeedback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorId, askToFeedback])

  React.useEffect(() => {
    return () => {
      clearTimeout(feedbackModalTimer)
    }
  }, [feedbackModalTimer])  

  function visitorFeedback () {
    Axios.post( Config.server.visitorFeedback, {visitor_id: visitorId, feedback_type: feedbackType}, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      if (!response.data.error) {
        if (response.data.ask_feedback === false) {
          // console.log("Feedback: User already answered. Move to next question.")
          setFeedbackModalVisible(false)
          setAskToFeedback("feedback_section")
        } else {
          // console.log("Feedback: Ask to user!")
          setFeedbackModalTimer(
            setTimeout(() => {
              setFeedbackModalVisible(true)
            }, 120000) // 2 minutes in milliseconds
            // }, 2000) // 2 seconds in milliseconds
          )
        }
      } else {
        console.log("Feedback error: " + response.data.message)
        setFeedbackModalVisible(false)
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  function sendFeedback(answer){
    showMessage({type: 'success', content: "Thank you for your feedback."})
    setFeedbackModalVisible(false)
    Axios.post( Config.server.visitorFeedback, {visitor_id: visitorId, feedback_type: feedbackType, answer: answer}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        if (response && response.data.error === false) {
          // console.log("Feedback: User answered. Move to next question.")
          setAskToFeedback("feedback_section")
        } else {
          console.log(response.data.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  return (
    <Modal
      centered
      open={feedbackModalVisible}
      title="Please provide us with feedback"
      width={400}
      footer={null}
      onCancel={()=>setFeedbackModalVisible(false)}
      className="feedback-modal"
    >
      <Title level={4}>Are you satisfied with the CyberABILITY platform?</Title>
      <Space align="center" size="large">
        <Button type="primary" onClick={()=>sendFeedback(true)}>YES</Button>
        <Button type="primary" danger onClick={()=>sendFeedback(false)}>NO</Button>       
      </Space>
    </Modal>
  )
}

export default FeedbackMain
