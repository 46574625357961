const dndStructure = {
  columns: {
    'custom-w': {id: 'custom-w', semester: "winter", year: "0", title: 'Custom', courseIds: []},
    'custom-s': {id: 'custom-s', semester: "summer", year: "0", title: 'Custom', courseIds: []},
    'mandatory-w': {id: 'mandatory-w', semester: "winter", year: "0", title: 'Mandatory', courseIds: []},
    'mandatory-s': {id: 'mandatory-s', semester: "summer", year: "0", title: 'Mandatory', courseIds: []},
    'voluntary-w': {id: 'voluntary-w', semester: "winter", year: "0", title: 'Voluntary', courseIds: []},
    'voluntary-s': {id: 'voluntary-s', semester: "summer", year: "0", title: 'Voluntary', courseIds: []},
    '1-w': {id: '1-w', semester: "winter", year: "1", title: '1 year winter semester', courseIds: []},
    '1-s': {id: '1-s', semester: "summer", year: "1", title: '1 year summer semester', courseIds: []},
    '2-w': {id: '2-w', semester: 'winter', year: "2", title: '2 year winter semester', courseIds: []},
    '2-s': {id: '2-s', semester: 'summer', year: "2", title: '2 year summer semester', courseIds: []},
    '3-w': {id: '3-w', semester: 'winter', year: "3", title: '3 year winter semester', courseIds: []},
    '3-s': {id: '3-s', semester: 'summer', year: "3", title: '3 year summer semester', courseIds: []},
    '4-w': {id: '4-w', semester: 'winter', year: "4", title: '4 year winter semester', courseIds: []},
    '4-s': {id: '4-s', semester: 'summer', year: "4", title: '4 year summer semester', courseIds: []},
    '5-w': {id: '5-w', semester: 'winter', year: "5", title: '5 year winter semester', courseIds: []},
    '5-s': {id: '5-s', semester: 'summer', year: "5", title: '5 year summer semester', courseIds: []}
  }
}

export default dndStructure
