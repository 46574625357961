import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Button, Modal, Form, Input } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {createHash} from 'crypto'

const LoginModal = ({ visible, switchLogin, setUser, onCancel, showMessage }) => {
  const [loginModalLoading, setLoginModalLoading] = React.useState(false)
  const [form] = Form.useForm()

  function login(){
    form
      .validateFields()
      .then((values) => {
        setLoginModalLoading(true)
        values.password = createHash('sha256').update(values.password).digest('hex')
        
        Axios.post( Config.server.login, values, {headers: { 'Content-Type': 'application/json' }})
          .then((response) => {
            setLoginModalLoading(false)
            if (response && response.data.error === false){
              form.resetFields()
              showMessage({type: 'success', content: "Now you are logged in."})
              setUser({...response.data.user, ...values})
            } else {
              if (response.data.message) {
                showMessage({type: 'error', content:
                  response.data.message.includes("Incorrect e-mail") ? "Incorrect e-mail or password!" :
                  response.data.message.includes("Unconfirmed") ? "We are sorry, your account is still unconfirmed. Please wait for the confirmation by Admin." :
                  response.data.message.includes("Blocked") ? "Your account is blocked by Admin." : "Something went wrong!",
                duration: 5})
              } else {
                showMessage({type: 'error', content: "Something went wrong!"})
              }
            }
          })
          .catch((error) => {
            setLoginModalLoading(false)
            console.log(error)
            showMessage({type: 'error', content: "Login failed!"})
          })
      })
      .catch((info) => {
        console.log(info)
      })
  }


  return (
    <Modal
      centered
      open={visible}
      title="Log in"
      width={400}
      footer={null}
      onCancel={onCancel}
      className="login-modal"
    >
      <Form form={form} className="loginForm" name="login_modal" onFinish={()=>login()}>
        <Form.Item name="email" rules={[{type: 'email', required: true, message: 'Please fill in your e-mail address!'}]}>
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail address" />
        </Form.Item>
        <Form.Item name="password" rules={[{required: true, message: 'Please fill in your password!'}]}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password"/>
        </Form.Item>
        <Form.Item>
          <Button type="link" className="buttonLink" onClick={()=>switchLogin("toForgot")}>
            Forgot password
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="button icon-position-fix" loading={loginModalLoading}>
            Log in
          </Button>
          Or <Button type="link" className="buttonLink" onClick={()=>switchLogin("toReg")}>register now!</Button>
        </Form.Item>

      </Form>

    </Modal>
  )
}

export default LoginModal
