import { ServerURL } from '../../config.js'

let subfolder = "csprofiler"

export const Config = {
	server	: {
		"getData"										: ServerURL+subfolder+"/getData.php",
		"analyzeCourseDescription"	: ServerURL+subfolder+"/analyzeCourseDescription.php",
		"analyzeCurricula"					: ServerURL+subfolder+"/analyzeCurricula.php",
	}
}
