import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'
import FormWizard from 'react-form-wizard-component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Axios from "axios";
import {Config} from './config_section.js'
import './CareerPath.scss';
import 'react-form-wizard-component/dist/style.css';

import CISO from '../roles/role-icons/CISO.png'
import incident_responder from '../roles/role-icons/incident_responder.png'
import legal_policy_compliance from '../roles/role-icons/legal_policy_compliance.png'
import cyber_threat_intelligence from '../roles/role-icons/cyber_threat_intelligence.png'
import architect from '../roles/role-icons/architect.png'
import auditor from '../roles/role-icons/auditor.png'
import educator from '../roles/role-icons/educator.png'
import implementer from '../roles/role-icons/implementer.png'
import researcher from '../roles/role-icons/researcher.png'
import risk_manager from '../roles/role-icons/risk_manager.png'
import forensics from '../roles/role-icons/forensics.png'
import pentester from '../roles/role-icons/pentester.png'
import logoImage from '../../assets/logo_dark.jpg'

const imagemapper = {
    "Chief Information Security Officer (Ciso)": CISO,
    "Cyber Incident Responder": incident_responder,
    "Cyber Legal, Policy & Compliance Officer": legal_policy_compliance,
    "Cyber Threat Intelligence Specialist": cyber_threat_intelligence,
    "Cybersecurity Architect": architect,
    "Cybersecurity Auditor": auditor,
    "Cybersecurity Educator": educator,
    "Cybersecurity Implementer": implementer,
    "Cybersecurity Researcher": researcher,
    "Cybersecurity Risk Manager": risk_manager,
    "Digital Forensics Investigator": forensics,
    "Penetration Tester": pentester
};


const CareerPath = () => {

    const [currentRoles, setCurrentRoles] = useState([]);
    const [additionalSkills, setAdditionalSkills] = useState([]);
    const [additionalKnowledge, setAdditionalKnowledge] = useState([]);
    const [fetchedData, setFetchedData] = useState(null);
    const [checkedSkills, setCheckedSkills] = useState([]);
    const [checkedKnowledge, setCheckedKnowledge] = useState([]);
    const [missingSkills, setMissingSkills] = useState([]);
    const [missingKnowledge, setMissingKnowledge] = useState([]);
    const contentRef = useRef(null);
    const missingRequirementsRef = useRef(null);
    const fetchedDataRef = useRef(fetchedData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(Config.server.getData);
                setFetchedData(response.data);
            } catch (error) {
                console.log(error);
                setFetchedData(0)
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        fetchedDataRef.current = fetchedData;
    }, [fetchedData]);

    const handleCurrentRoleSelection = (roles) => {
        setCurrentRoles(roles);

        if (fetchedDataRef.current) {
            const skillsVerbose = roles.flatMap(role => getSkillsVerbose(role, fetchedDataRef.current));
            const allSkills = [];
            const allKnowledge = [];
            skillsVerbose.forEach(skillObject => {
                allSkills.push(...Object.values(skillObject).flatMap(skill => skill.specific_skills));
                allKnowledge.push(...Object.values(skillObject).flatMap(skill => skill.specific_knowledge));
            });
            setAdditionalSkills(allSkills);
            setAdditionalKnowledge(allKnowledge);
        } else {
            setAdditionalSkills([]);
            setAdditionalKnowledge([]);
        }
    };

    const handleSkillCheck = (checkedValues) => {
        setCheckedSkills(checkedValues);
        updateMissingData(checkedValues, checkedKnowledge);
    };

    const handleKnowledgeCheck = (checkedValues) => {
        setCheckedKnowledge(checkedValues);
        updateMissingData(checkedSkills, checkedValues);
    };

    const updateMissingData = (checkedSkills, checkedKnowledge) => {
        // console.log("mphka")
        // console.log(checkedSkills)
        // console.log(checkedKnowledge)
        const missingSkills = additionalSkills.filter(skill => !checkedSkills.includes(skill));
        const missingKnowledge = additionalKnowledge.filter(knowledge => !checkedKnowledge.includes(knowledge));
        setMissingSkills(missingSkills);
        setMissingKnowledge(missingKnowledge);
    };

    function getSkillsVerbose(profileName, all_data) {
        const skills = all_data.data["rs_skill_group"];
        const profile = all_data.data["rs_profiles"].find(profile => profile.name === profileName);
        const rewire_groups = profile.skills_group.map(skillId => skills.find(skill => skill.id === skillId));
        const specific_skills = all_data.data["rs_specific_skills"];
        const specific_knowledge = all_data.data["rs_specific_knowledge"];
        let skills_verbose = {};
        rewire_groups.forEach(rewire_group => {
            let specific_skills_verbose = [];
            let specific_knowledge_verbose = [];
            if (rewire_group.specific_skills.length > 0) {
                rewire_group.specific_skills.forEach(specific_skill => {
                    specific_skills_verbose.push(specific_skills.find(s => s.id === specific_skill).name);
                });
            }
            if (rewire_group.specific_knowledge.length > 0) {
                rewire_group.specific_knowledge.forEach(specific_knowledge_item => {
                    specific_knowledge_verbose.push(specific_knowledge.find(k => k.id === specific_knowledge_item).name);
                });
            }
            skills_verbose[rewire_group.name] = {
                specific_skills: specific_skills_verbose,
                specific_knowledge: specific_knowledge_verbose
            };
        });
        return skills_verbose;
    }
    const handleExportHTML = () => {
        if (!missingRequirementsRef.current) return;

        html2canvas(missingRequirementsRef.current, { scale: 2 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const contentWidth = pageWidth;
            const contentHeight = (canvas.height * contentWidth) / canvas.width;

            // Add content to the first page
            pdf.addImage(imgData, 'PNG', 0, 0, contentWidth, contentHeight);

            // Add logo image on top of the content
            const logoWidth = 28; // Adjust width of the logo as needed
            const logoHeight = 10; // Adjust height of the logo as needed
            const logoX = 10; // Adjust x-coordinate of the logo as needed
            const logoY = 10; // Adjust y-coordinate of the logo as needed
            pdf.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

            // Check if content exceeds page height
            if (contentHeight > pageHeight) {
                // Calculate the number of pages required
                const numPages = Math.ceil(contentHeight / pageHeight);

                // Split the content into multiple pages
                for (let i = 1; i < numPages; i++) {
                    pdf.addPage();
                    const startY = -(pageHeight * i);
                    pdf.addImage(imgData, 'PNG', 0, startY, contentWidth, contentHeight, null, 'FAST');
                }
            }

            pdf.save('pathway.pdf');
        });
    };


    if (fetchedData === null) {
        return (
            <section id="career-path" className={"section-content"}>
            <div className="section-header" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div className="main_loading">
                    <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />} />
                    <br/>
                </div>
            </div>
            </section>
        );
    }
    else if (fetchedData === 0) {
      return (
        <div className="vertCenter">
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        </div>
      )
    }
    else {
      return (
        <section id="career-path" className={"section-content"}>
            <div ref={contentRef}>
                <FormWizard
                    shape="circle"
                    color="#3e9dd1"
                    finishButtonTemplate={(handleComplete) => (
                        <div className="wizard-footer-right" style={{
                            backgroundColor: 'rgb(62, 157, 209)',
                            borderColor: 'rgb(62, 157, 209)',
                            borderRadius: '4px'
                        }}>
                            {/* Render export button */}
                            <button className="wizard-btn" onClick={handleExportHTML}>
                                Export
                            </button>
                        </div>
                    )}
                >

                <FormWizard.TabContent title="Desired Role" icon="ti-user">
                    <h1>Role Selection</h1>
                    <p>Select the cybersecurity-related role you are aiming to achieve.</p>
                    <table className="checkbox-group">
                        <tbody>
                            {fetchedData.data["rs_profiles"].map(profile => (
                                <tr key={profile.id}>
                                    <td>

                                        <Checkbox
                                            value={profile.name}
                                            onChange={(e) => handleCurrentRoleSelection(e.target.checked ? [profile.name] : [])}
                                            checked={currentRoles.includes(profile.name)}
                                        >
                                            {currentRoles.includes(profile.name)}
                                            <img src={imagemapper[profile.name]} alt={profile.name}
                                                 className="role-image" height="25px"/>
                                            {profile.name}
                                            </Checkbox>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Acquired Skills" icon="ti-view-list-alt">
                    <div ref={contentRef}>
                        <h1 className="current-role">{`${currentRoles.join(', ')}`}</h1>

                        <h1>Skills Selection</h1>

                        <p>Choose the skills you already own.</p>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Checkbox.Group onChange={handleSkillCheck} value={checkedSkills}>
                                <table className="checkbox-group">
                                    <tbody>
                                    {additionalSkills.map((skill, index) => (
                                        <tr key={index}>
                                            <td className={checkedSkills.includes(skill) ? 'selected-item' : ''}>
                                                <Checkbox value={skill}>{skill}</Checkbox>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Checkbox.Group>
                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Acquired Knowledge" icon="ti-book">
                    <h1 className="current-role">{`${currentRoles.join(', ')}`}</h1>

                    <h1>Knowledge Selection</h1>
                    <p>Choose the knowledge subjects you already possess.</p>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Checkbox.Group onChange={handleKnowledgeCheck} value={checkedKnowledge}>
                            <table className="checkbox-group">
                                <tbody>
                                {additionalKnowledge.map((knowledge, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Checkbox value={knowledge}>{knowledge}</Checkbox>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Checkbox.Group>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Missing Requirements" icon="ti-check">
                    <div ref={missingRequirementsRef}>
                        <h1 className="current-role">{`${currentRoles.join(', ')}`}</h1>

                        <h1>Cybersecurity Career Pathway</h1>
                        <p style={{fontSize: '1.2em'}}>Good job so far! This is what remains to be acquired to reach
                            your desired role. Check out the REWIRE <a target='_blank' rel="noreferrer"
                                                                       href='https://rewireproject.eu/cyber-range/'>Cyber
                                Range</a>, <a target='_blank' rel="noreferrer" href='https://vle.rewireproject.eu/'>Online
                                Courses</a>, and <a target='_blank' rel="noreferrer"
                                                    href='https://rewireproject.eu/certification/'>Certification</a> to
                            help you achieve your goal.</p>

                        <div className="requirements-container">
                            <table className="missing-requirements-table">
                                <thead>
                                <tr>
                                    <th className="requirements-label highlight">Missing Skills</th>
                                </tr>
                                </thead>
                                <tbody>
                                {missingSkills.length === 0 ? (
                                    additionalSkills.map((skill, index) => (
                                        <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'white' : '#f2f2f2'}}>
                                            <td>{skill}</td>
                                        </tr>
                                    ))
                                ) : (
                                    missingSkills.map((skill, index) => (
                                        <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'white' : '#f2f2f2'}}>
                                            <td>{skill}</td>
                                        </tr>
                                    ))
                                )}

                                </tbody>
                            </table>
                            <table className="missing-requirements-table">
                                <thead>
                                <tr>
                                    <th className="requirements-label highlight">Missing Knowledge</th>
                                </tr>
                                </thead>
                                <tbody>
                                {missingKnowledge.length === 0 ? (
                                    additionalKnowledge.map((knowledge, index) => (
                                        <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'white' : '#f2f2f2'}}>
                                            <td>{knowledge}</td>
                                        </tr>
                                    ))
                                ) : (
                                    missingKnowledge.map((knowledge, index) => (
                                        <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'white' : '#f2f2f2'}}>
                                            <td>{knowledge}</td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </FormWizard.TabContent>
                </FormWizard>
            </div>
        </section>
      );
    }
};

export default CareerPath;
