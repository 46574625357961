const title = 'Cybersecurity Auditor';

// const alternativeTitles = [
//     'Information Security Auditor (IT or Legal Auditor)',
//     'Governance Risk Compliance (GRC) Auditor',
//     'Cybersecurity Audit Manager',
//     'Cybersecurity Procedures and Processes Auditor',
//     'Information Security Risk and Compliance Auditor',
//     'Data Protection Assessment Analyst'
// ];

const alternativeTitles = [
    {key:1, title:'Information Security Auditor (IT or Legal Auditor)'},
    {key:2, title:'Governance Risk Compliance (GRC) Auditor'},
    {key:3, title:'Cybersecurity Audit Manager'},
    {key:4, title:'Cybersecurity Procedures and Processes Auditor'},
    {key:5, title:'Information Security Risk and Compliance Auditor'},
    {key:6, title:'Data Protection Assessment Analyst'}
];

// const summaryStatement = [
//     'Perform cybersecurity audits on the organisation’s ecosystem. Ensuring compliance with statutory, regulatory, policy information, security requirements, industry standards and best practices.'
// ];

const summaryStatement = [{key:1, statement:'Perform cybersecurity audits on the organisation’s ecosystem. Ensuring compliance with statutory, regulatory, policy information, security requirements, industry standards and best practices.'}];

// const mission =[
//     'Conducts independent reviews to assess the effectiveness of processes and controls and the overall compliance with the organisation\'s legal and regulatory frameworks policies.',
//     'Evaluates, tests and verifies cybersecurity-related products (systems, hardware, software and services), functions and policies ensuring, compliance with guidelines, standards and regulations.'
// ];

const mission =[
    {key:1, mission:'Conducts independent reviews to assess the effectiveness of processes and controls and the overall compliance with the organisation\'s legal and regulatory frameworks policies.'},
    {key:2, mission:'Evaluates, tests and verifies cybersecurity-related products (systems, hardware, software and services), functions and policies ensuring, compliance with guidelines, standards and regulations.'}
];

// const deliverables = [
//     'Cybersecurity Audit Plan',
//     'Cybersecurity Audit Report'
// ];

const deliverables = [
    {key:1, deliverable:'Cybersecurity Audit Plan', description: 'A plan that presents the overall strategy and the procedures an auditor will follow to conduct a cybersecurity audit.'},
    {key:2, deliverable:'Cybersecurity Audit Report', description: 'A report providing a thorough understanding of the level of security of a system, assessing its cybersecurity strengths and weaknesses. It may also provide remediation actions to improve the overall cybersecurity of the system.'}
];

const mainTasks = [
    {
        key: 1,
        task: 'Develop the organisation\'s auditing policy, procedures, standards and guidelines'
    },
    {
        key: 2,
        task: 'Establish the methodologies and practices used for systems auditing'
    },
    {
        key: 3,
        task: 'Establish the target environment and manage auditing activities'
    },
    {
        key: 4,
        task: 'Define audit scope, objectives and criteria to audit against'
    },
    {
        key: 5,
        task: 'Develop an audit plan describing the frameworks, standards, methodology, procedures and auditing tests'
    },
    {
        key: 6,
        task: 'Review target of evaluation, security objectives and requirements based on the risk profile'
    },
    {
        key: 7,
        task: 'Audit compliance with cybersecurity-related applicable laws and regulations'
    },
    {
        key: 8,
        task: 'Audit conformity with cybersecurity-related applicable standards'
    },
    {
        key: 9,
        task: 'Execute the audit plan and collect evidence and measurements'
    },
    {
        key: 10,
        task: 'Maintain and protect the integrity of audit records'
    },
    {
        key: 11,
        task: 'Develop and communicate conformity assessment, assurance, audit, certification and maintenance reports'
    },
    {
        key: 12,
        task: 'Monitor risk remediation activities'
    }
];

// • Organise and work in a systematic and deterministic way based on evidence
// • Follow and practice auditing frameworks, standards and methodologies
// • Apply auditing tools and techniques
// • Analyse business processes, assess and review software or hardware security, as well as technical and organisational controls
// • Decompose and analyse systems to identify weaknesses and ineffective controls • Communicate, explain and adapt legal and regulatory requirements and business needs
// • Collect, evaluate, maintain and protect auditing information
// • Audit with integrity, being impartial and independent

const keySkills = [
    {
        key: 1,
        skill: 'Organise and work in a systematic and deterministic way based on evidence'
    },
    {
        key: 2,
        skill: 'Follow and practice auditing frameworks, standards and methodologies'
    },
    {
        key: 3,
        skill: 'Apply auditing tools and techniques'
    },
    {
        key: 4,
        skill: 'Analyse business processes, assess and review software or hardware security, as well as technical and organisational controls'
    },
    {
        key: 5,
        skill: 'Decompose and analyse systems to identify weaknesses and ineffective controls'
    },
    {
        key: 6,
        skill: 'Communicate, explain and adapt legal and regulatory requirements and business needs'
    },
    {
        key: 7,
        skill: 'Collect, evaluate, maintain and protect auditing information'
    },
    {
        key: 8,
        skill: 'Audit with integrity, being impartial and independent'
    }
];

// • Cybersecurity controls and solutions
// • Legal, regulatory and legislative compliance requirements, recommendations and best practices
// • Monitoring, testing and evaluating cybersecurity controls' effectiveness
// • Conformity assessment standards, methodologies and frameworks
// • Auditing standards, methodologies and frameworks
// • Cybersecurity standards, methodologies and frameworks
// • Auditing-related certification
// • Cybersecurity-related certifications

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Cybersecurity controls and solutions'
    },
    {
        key: 2,
        knowledge: 'Legal, regulatory and legislative compliance requirements, recommendations and best practices'
    },
    {
        key: 3,
        knowledge: 'Monitoring, testing and evaluating cybersecurity controls\' effectiveness'
    },
    {
        key: 4,
        knowledge: 'Conformity assessment standards, methodologies and frameworks'
    },
    {
        key: 5,
        knowledge: 'Auditing standards, methodologies and frameworks'
    },
    {
        key: 6,
        knowledge: 'Cybersecurity standards, methodologies and frameworks'
    },
    {
        key: 7,
        knowledge: 'Auditing-related certification'
    },
    {
        key: 8,
        knowledge: 'Cybersecurity-related certifications'
    }
];

// B.3. Testing
// B.5. Documentation Production E.3. Risk Management
// E.6 ICT Quality Management
// Level 4 Level 3 Level 4 Level 4

const eCompetences = [
    {
        key: 1,
        competence: 'B.3. Testing',
        level: ['Level 4']
    },
    {
        key: 2,
        competence: 'B.5. Documentation Production',
        level: ['Level 3']
    },
    {
        key: 3,
        competence: 'E.3. Risk Management',
        level: ['Level 4']
    },
    {
        key: 4,
        competence: 'E.6. ICT Quality Management',
        level: ['Level 4']
    },
];


export const AUDData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}