import React from 'react'
import { Col, Button, Form, Row, Spinner } from 'react-bootstrap'
import { Config } from '../config_section'
import Axios from 'axios'
import moment from 'moment'
import SkillSelectionModal from './Cur-SkillSelectionModal'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import areas from './initStructure/SpartaAreas'
import topics from './initStructure/SpartaTopics'

class CourseForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: this.initForm(props.formData),
      isLoading: false,
      formValidated: false,
      deleteModal: null,
      showSkillsModal: false,
      descAnalysisLoading: false
    }
  }

  // if no data passed by parent init empty form
  initForm = (formData) => {
    const emptyForm = {
      name: '',
      credits: '',
      training: '',
      topics: [],
      currentTopic: '',
      currentTopicPer: '',
      semester: '',
      description: '',
      skills_group: [],
      specific_skills: [],
      specific_knowledge: [],
      type: ''
    }

    if (formData === null) {
      return emptyForm
    } else {
      return formData
    }
  }

  // save form data of new or edited table row
  saveForm = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({formValidated: true})

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, formValidated: false, })

    if (typeof this.props.saveLocally === 'function') {
      // only return data locally to parent component
      const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss')
      const newFormData = {
        ...this.state.formData,
        created: this.state.formData.hasOwnProperty('id') ? this.state.formData.created : currentTimestamp,
        created_by: this.state.formData.hasOwnProperty('id') ? this.state.formData.created_by : 0,
        updated: currentTimestamp,
        updated_by: 0
      }
      this.props.saveLocally(newFormData)
      this.props.hideForm()
    } else {
      // prepare data to send to db
      let values = JSON.parse(JSON.stringify(this.state.formData))
      delete values.created
      delete values.created_by
      delete values.updated
      delete values.updated_by
      values.topics = JSON.stringify(values.dates)
      values.skills_group = JSON.stringify(values.skills_group)
      values.specific_skills = JSON.stringify(values.specific_skills)
      values.specific_knowledge = JSON.stringify(values.specific_knowledge)

      Axios.post( this.state.formData.hasOwnProperty('id') ? Config.server.editCourse : Config.server.addCourse, {...this.props.authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
        //this.setState({isLoading: false, formData: null})
        this.props.showMessage({type: 'success', content: "Data updated successfully."})
        this.props.getPublicData()
        this.props.hideForm()
        } else {
        this.setState({isLoading: false})
        this.props.showMessage({type: 'error', content: "Something went wrong!"})
        console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({isLoading: false})
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
    }
  }

  // add new item to array field of the form
  addItemTo = (variable) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[variable].push("")
    this.setState({formData: newData})
  }

  // delete item from array field of the form
  deleteItemFrom = (variable, id) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[variable].splice(id,1)
    this.setState({formData: newData})
  }

  addTopic = () => {
    if (this.state.formData.currentTopic){
      let newData = JSON.parse(JSON.stringify(this.state.formData))
      newData.topics.push({
        topicId: this.state.formData.currentTopic - 1,
        ectsPer: this.state.formData.currentTopicPer ? this.state.formData.currentTopicPer / 100 : 1
      })
      newData.currentTopicPer = ''
      newData.currentTopic = ''
      this.setState({formData: newData})
    }
  }

  editTopic = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData.currentTopic = newData.topics[id].topicId + 1
    newData.currentTopicPer = parseFloat(newData.topics[id].ectsPer)*100
    newData.topics.splice(id, 1)
    this.setState({formData: newData})
  }

  saveSpecificItems = (input) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData.skills_group = input.skills_group
    newData.specific_skills = input.specific_skills
    newData.specific_knowledge = input.specific_knowledge
    this.setState({formData: newData})
  }

// cancel and hide form
  cancelUpload = () => {
    //this.setState({formData: null, validated: false})
    this.props.hideForm()
  }

  // catch form change event and save new data
  changeFormData = (event, arrayId) => {
    //copy state formdata object to new temporary variable
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    if (
      event.target.id.includes("credits") ||
      event.target.id.includes("currentTopic") ||
      event.target.id.includes("currentTopicPer")
    ) {
      // save as number
      newData[event.target.id] = parseFloat(event.target.value)
    } else if (event.target.id.includes("skills_group")) {
      // save as array
      this.updateArray(newData.skills_group, parseInt(event.target.value))
    } else{
      // save as string
      newData[event.target.id] = event.target.value
    }
    // store updated state object to state
    this.setState({formData: newData})
  }

  // helper function to update an array - add or remove element
  updateArray = (inputArray, value) => {
    if (inputArray.includes(value)) {
      // remove from array
      inputArray = inputArray.splice(inputArray.indexOf(value), 1)
    } else {
      // add to array and sort acs
      inputArray.push(value)
      inputArray.sort((a, b) => a - b)
    }
    return inputArray
  }

  removeItemFrom = (arr, id) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[arr].splice(id, 1)
    this.setState({formData: newData})
  }

  analyzeCourseDescription = () => {
    this.setState({descAnalysisLoading: true})
    Axios.post( Config.server.analyzeCourseDescription, {description: this.state.formData.description}, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      this.setState({descAnalysisLoading: false})
      if (response && response.data.error === false){
        let newModalData = JSON.parse(JSON.stringify(this.state.formData))
        newModalData.skills_group = response.data.data.prediction.skills_groups
        this.setState({formData: newModalData})
        this.props.showMessage({type: 'success', content: "Description analysis done."})
      } else {
        this.props.showMessage({type: 'error', content: "Something went wrong!"})
      }
    })
    .catch((error) => {
      this.setState({descAnalysisLoading: false})
      console.log(error)
      this.props.showMessage({type: 'error', content: "Something went wrong!"})
    })
  }

  render(){
    const { userDataMerged, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, saveLocally } = this.props
    const { formValidated, formData, showSkillsModal } = this.state

    // show user name who edited the data row in the table; hide if unknown or error
    const userLabel = (userId) => {
      if ( Array.isArray(userDataMerged) && userDataMerged.length ){
        let userName = userDataMerged.find(x => x.id === userId).name
        return userName ? " by " + userName : ""
      } else {
        return ""
      }
    }
/*
    // helper for display selected skills/knowledge by each skill group
    const selectedSkillsList = (category, groupId) => {
      let output = []
      if (category === "skills") {
      rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
        if (formData.specific_skills.includes(skillId)) {
        output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
        }
      })
      } else {
      rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
        if (formData.specific_knowledge.includes(knowledgeId)) {
        output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
        }
      })
      }
      return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
    }
*/
    // skryva již vybrané topicy ze select nabidky
    const reducedTopics = () => {
      // let output = topcs.filter((topic, index) => {
      //   return addModalData.topics.find(o => o.topicId === index) === undefined
      // })
      // return output
      return topics
    }

    const reducedSkillsGroups = () => {
      return Array.isArray(rsSkillsGroup) ? (
        rsSkillsGroup.filter(skillGroup => !formData.skills_group.includes(skillGroup.id))
      ) : null
    }

    return (
      <div className="training_form">

        {typeof saveLocally !== 'function' ? (<h4>{formData.hasOwnProperty("created") ? "Edit course" : "Add new course"}</h4>) : null}

        <Form noValidate validated={formValidated} onSubmit={this.saveForm} >

          <Form.Group as={Row} controlId="name">
            <Form.Label column sm={3}><b>Course name *</b></Form.Label>
            <Col sm={8}>
            <Form.Control required value={formData.name} onChange={this.changeFormData}/>
            <Form.Control.Feedback type="invalid">
              Please provide a valid name of the course.
            </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="type" >
            <Form.Label column sm={3}>
              <b>Type *</b>
            </Form.Label>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="Mandatory"
                name="type"
                value="mandatory"
                checked={formData.type === 'mandatory'}
                onChange={this.changeFormData}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please choose a type.
              </Form.Control.Feedback>
            </Col>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="Voluntary"
                name="type"
                value="voluntary"
                checked={formData.type === 'voluntary'}
                onChange={this.changeFormData}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="semester" >
            <Form.Label column sm={3}>
              <b>Semester * </b>
            </Form.Label>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="Winter"
                name="semester"
                value="winter"
                checked={formData.semester === 'winter'}
                onChange={this.changeFormData}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please choose a semester.
              </Form.Control.Feedback>
            </Col>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="Summer"
                name="semester"
                value="summer"
                checked={formData.semester === 'summer'}
                onChange={this.changeFormData}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="training" >
            <Form.Label column sm={3}>
              <b>Training *</b>
            </Form.Label>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="Yes"
                name="training"
                value='Yes'
                checked={formData.training === 'Yes'}
                onChange={this.changeFormData}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please choose a presence of a training.
              </Form.Control.Feedback>
            </Col>
            <Col sm={4} className="vertical ">
              <Form.Check
                type="radio"
                label="No"
                name="training"
                value='No'
                checked={formData.training === 'No'}
                onChange={this.changeFormData}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} >
            <Form.Label column sm={3}>
              <b>ECTS Credits *</b>
            </Form.Label>
            <Col sm={4}>
              <Form.Control type="number" id="credits" required
              onChange={this.changeFormData} value={formData.credits} min="0"/>
              <Form.Control.Feedback type="invalid">
                Please choose a number of credits.
            </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} >
            <Form.Label column sm={3}>
              <b>Topics (ECTS %)</b>
            </Form.Label>
            <Col sm={4} className="vertical">
              <Form.Control as="select" id="currentTopic"
              onChange={this.changeFormData} value={formData.currentTopic}> >
                {['', ...reducedTopics()].map((topic, index) => {
                  return <option key={index} style={{background: topic? areas[topic.areaId].color : '', color: 'white'}} value={index}>{topic.name}</option>
                })}
              </Form.Control>
            </Col>
            <Col sm={2} className="vertical percents">
              {formData.currentTopic !== "" ? <><Form.Control type="number" id="currentTopicPer"
              onChange={this.changeFormData} value={formData.currentTopicPer ? formData.currentTopicPer : 100} min="0" max="100"/>&nbsp;%</> : ""}
            </Col>
            <Col sm={2} className="vertical">
              {formData.currentTopic !== "" ? <Button variant="outline-success" size="sm" disabled={!formData.currentTopic} onClick={this.addTopic}>Save</Button> : ""}
            </Col>
          </Form.Group>

          <Form.Group as={Row} >
            <Col sm={3} ></Col>
            <Col sm={8} >
              <table><tbody>
              {formData.topics.map((topic, index) => {
                return (
                  <tr key={index}>
                    <td className="topicListPer" >{topic.ectsPer*100}&nbsp;%&nbsp;</td>
                    <td><div className="modal-badge" style={{background: areas[reducedTopics()[topic.topicId].areaId].color}}>{reducedTopics()[topic.topicId].name}</div></td>
                    <td><EditIcon color="action" className="editIcon" fontSize="small" title="Edit topic percentage" onClick={()=>this.editTopic(index)}/></td>
                    <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove topic" onClick={()=>this.removeItemFrom("topics", index)}/></td>
                  </tr>
                )
              })}
              </tbody></table>
            </Col>
          </Form.Group>

          <Form.Group as={Row} >
            <Form.Label column sm={3}>
              <b>Description</b>
            </Form.Label>
            <Col sm={8}>
              <Form.Control as="textarea" id="description" rows={3} onChange={this.changeFormData} value={formData.description}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} >
            <Col sm={3}>&nbsp;</Col>
            <Col sm={8}>
              <Button variant="outline-primary" size="sm" disabled={formData.description.length === 0 || this.state.descAnalysisLoading} onClick={this.analyzeCourseDescription}>{this.state.descAnalysisLoading ? <>Analysis in progress &nbsp;<Spinner animation="border" variant="primary" size="sm" /></> : "Analyze"}</Button>
            </Col>
          </Form.Group>

          {/* ------ skill groups ---------*/}

          {Array.isArray(reducedSkillsGroups()) ? (<>
            <Form.Group as={Row} >
              <Form.Label column sm={3}>
                <b>Skills Groups</b>
              </Form.Label>
              <Col sm={8}>
                <Form.Control as="select" id="skills_group" onChange={this.changeFormData} value=''>
                  {[{id:null,name:''}, ...reducedSkillsGroups()].map(skill => {
                    return (<option className="sel-option" value={skill.id} key={skill.id} title={skill.name}>{skill.name}</option>)
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} >
              <Col sm={3} ></Col>
              <Col sm={8} >
                {/*descAnalysisLoading ? (
                  <table className="skill-group-list"><tbody>
                    <tr><td><Spinner animation="border" size="sm" variant="primary" /></td></tr>
                    <tr><td>Loading...</td></tr>
                  </tbody></table>
                ) : (*/}
                  <table><tbody>
                  {formData.skills_group.map((skillId, index) => {
                    let color = rsSkillsGroup.find(i => i.id === skillId).color
                    return (
                      <tr key={index}>
                        <td><div className="modal-badge ecsf" style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}>{rsSkillsGroup.find(a => a.id === skillId).name}</div></td>
                        <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove skill group" onClick={()=>this.removeItemFrom("skills_group", index)}/></td>
                      </tr>
                    )
                  })}
                  </tbody></table>

              </Col>
            </Form.Group>
          </>) : (
            <Form.Group as={Row} >
              <Form.Label column sm={3}>
                <b>Skills Groups</b>
              </Form.Label>
              <Col sm={8}>
                <Form.Control as="select" id="skills_group" title="Currently disabled" disabled={true}></Form.Control>
              </Col>
            </Form.Group>
          ) }

          {/* ------ skills a knowledge ---------*/}

          <Form.Group as={Row} >
            <Form.Label column sm={3}>
              <b>ENISA Skills and Knowledge</b>
            </Form.Label>
            <Col sm={8} >
              <Button variant="outline-success" size="sm" disabled={formData.skills_group.length > 0 ? false : true} onClick={()=>this.setState({showSkillsModal: true})}>Choose</Button>
            </Col>
          </Form.Group>

          {formData.hasOwnProperty("created") ? (<>
            <Form.Group as={Row} >
              <Form.Label column sm={4}>Created</Form.Label>
              <Form.Label column sm={8} id="created">{formData.created + " CET" + userLabel(formData.created_by)}</Form.Label>
            </Form.Group>

            <Form.Group as={Row} >
              <Form.Label column sm={4}>Updated</Form.Label>
              <Form.Label column sm={8} id="updated">{formData.updated + " CET" + userLabel(formData.updated_by)}</Form.Label>
            </Form.Group>
          </>) : ""}

          <div className="upload_buttons">
            <Button variant="danger" onClick={this.cancelUpload}>Cancel</Button>
            <Button type="submit" variant="success" >Confirm</Button>
          </div>

        </Form>

        <SkillSelectionModal rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} showSkillsModal={showSkillsModal} hideSkillModal={()=>this.setState({showSkillsModal: false})} formData={formData} saveFormData={(newData)=>this.saveSpecificItems(newData)}/>

      </div>
    )
  }
}

export default CourseForm
