import React from 'react'
import { Collapse, Form } from 'react-bootstrap'
import LoadFilterOptions from './LoadFilterOptions'
import Slider from '@material-ui/core/Slider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'


class Filter extends React.Component {
  constructor(props) {
    super(props)
    let allOptions = LoadFilterOptions(props.data)
    this.state = {
      can_lead_to_certification: [],
      content_type: [],
      country: [],
      duration: [],
      includes_exams_for_certification: [],
      language: [],
      skills_group: [],
      organizer: [],
      price: Math.max(...allOptions.price),
      timing: [],
      type_format: [],
      allOptions: allOptions,
      collapseOptions: {
        country: false,
        organizer: false,
        can_lead_to_certification: false,
        includes_exams_for_certification: false,
        content_type: false,
        duration: false,
        language: false,
        skills_group: false,
        timing: false,
        type_format: false
      }
    }
  }

  getRelevantData = (filterData) => {
    /* vrací vyfiltrovaná data */
    let objsToShow = JSON.parse(JSON.stringify(this.props.data))

    if (Array.isArray(filterData.can_lead_to_certification) && filterData.can_lead_to_certification.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.can_lead_to_certification.some(r => r === item.can_lead_to_certification)
        })
      }

    if (Array.isArray(filterData.content_type) && filterData.content_type.length){
      objsToShow = objsToShow.filter((item) => {
        return filterData.content_type.some(r => r === item.content_type)
      })
    }

    if (Array.isArray(filterData.country) && filterData.country.length){
      objsToShow = objsToShow.filter((item) => {
        return filterData.country.some(r => r === item.country)
      })
    }

    if (Array.isArray(filterData.duration) && filterData.duration.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.duration.some(r => r === item.duration)
        })
      }

      if (Array.isArray(filterData.includes_exams_for_certification) && filterData.includes_exams_for_certification.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.includes_exams_for_certification.some(r => r === item.includes_exams_for_certification)
        })
      }

      if (Array.isArray(filterData.language) && filterData.language.length){
        objsToShow = objsToShow.filter((item) => {
            return filterData.language.some(r => item.language.includes(r))
        })
      }

      if (Array.isArray(filterData.skills_group) && filterData.skills_group.length){
        objsToShow = objsToShow.filter((item) => {
            return filterData.skills_group.every(r => item.skills_group.includes(parseInt(r)))
        })
      }

      if (Array.isArray(filterData.organizer) && filterData.organizer.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.organizer.some(r => r === item.organizer)
        })
      }

      if (Array.isArray(filterData.timing) && filterData.timing.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.timing.some(r => r === item.timing)
        })
      }

      if (Array.isArray(filterData.type_format) && filterData.type_format.length){
        objsToShow = objsToShow.filter((item) => {
          return filterData.type_format.some(r => r === item.type_format)
        })
      }

    // zobraz pouze objekty za nižší nebo stejnou cenu
    objsToShow = objsToShow.filter((item) => {
        let price = isNaN(parseFloat(item.price)) ? 0 : parseFloat(item.price)
        return price <= filterData.price
    })

    return objsToShow
  }

  filteringRange = (data, range, subject) => {
    if (range[0] !== 0 || range[1] !== 100){
      data = data.filter((item) => {
        let path = subject.split(".")
        if (path.length === 2){
          return parseFloat(item[path[0]][path[1]]) >= range[0] && parseFloat(item[path[0]][path[1]]) <= range[1]
        } else {
          return parseFloat(item[subject]) >= range[0] && parseFloat(item[subject]) <= range[1]
        }
      })
    }
    return data
  }

  updateArray = (arr, value) => {
    if (!arr.includes(value)){
      arr.push(value)
    } else {
      arr = arr.filter((item) => {return item !== value})
    }
    return arr
  }

  onFilterChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    switch (e.target.id.split("-", 1)[0]) {
      case 'can_lead_to_certification' : newState.can_lead_to_certification = this.updateArray(newState.can_lead_to_certification, e.target.value); break;
      case 'content_type' : newState.content_type = this.updateArray(newState.content_type, e.target.value); break;
      case 'country' : newState.country = this.updateArray(newState.country, e.target.value); break;
      case 'duration' : newState.duration = this.updateArray(newState.duration, e.target.value); break;
      case 'price' : newState.price = e.target.value; break;
      case 'skills_group' : newState.skills_group = this.updateArray(newState.skills_group, e.target.value); break;
      case 'includes_exams_for_certification' : newState.includes_exams_for_certification = this.updateArray(newState.includes_exams_for_certification, e.target.value); break;
      case 'language' : newState.language = this.updateArray(newState.language, e.target.value); break;
      case 'organizer' : newState.organizer = this.updateArray(newState.organizer, e.target.value); break;
      case 'timing' : newState.timing = this.updateArray(newState.timing, e.target.value); break;
      case 'type_format' : newState.type_format = this.updateArray(newState.type_format, e.target.value); break;
      default: break;
    }
    this.setState(newState)
    // toShow předá data do nadřazené komponenty
    // getRelevantData pomocí kritérií v newState vyfiltruje data
    this.props.toShow(this.getRelevantData(newState))
  }

  // vypíše seznam možných hodnot (checkbox)
  printFilterOptions = (id, label, options, toShow) => {

    let newState = JSON.parse(JSON.stringify(this.state))
    /* Sort options, if options are numbers use The Compare Function inside of sort function */
    if (options.length && typeof options[0] === "number") { options.sort(function(a, b){return a - b}) }
    else { options = options.sort() }

    let additionalOpions =
      <div>
        <Collapse in={this.state.collapseOptions[id]}>
          <div id="collapse-text">
            {options.slice(toShow, options.length).map((value, index) => {
              /* If rendered section is skills_group substitute group ID for group name in a label */
              if (id === "skills_group") {
                return (<Form.Check name='filter-checkbox' label={this.props.skillsGroup.find(i => i.id === parseInt(value)).name ? this.props.skillsGroup.find(i => i.id === parseInt(value)).name : 'Undefined'} key={index+toShow} id={id + `-${index+toShow+1}`} value={value} />)
              /* Else print value as a label */
              } else {
                return (<Form.Check name='filter-checkbox' label={value ? value : 'Undefined'} key={index+toShow} id={id + `-${index+toShow+1}`} value={value} />)
              }
            })}
          </div>
        </Collapse>
        <div className="filterCheckCollapseButton" onClick={() => {
          newState.collapseOptions[id] = !newState.collapseOptions[id]
          this.setState(newState)
        }}>{this.state.collapseOptions[id] ? "Less" : "More" }{this.state.collapseOptions[id] ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
        </div>
      </div>

    return (
      <Form.Group controlId={id}>
        <Form.Label className="break"><h6>{label}</h6></Form.Label><br/>
        {options.slice(0, toShow).map((value, index) => {
          /* If rendered section is skills_group substitute group ID for group name in a label */
          if (id === "skills_group") {
            return (<Form.Check name='filter-checkbox' label={this.props.skillsGroup.find(i => i.id === parseInt(value)).name ? this.props.skillsGroup.find(i => i.id === parseInt(value)).name : 'Undefined'} key={index} id={id + `-${index+1}`} value={value} />)
          /* Else print value as a label */
          } else {
            return (<Form.Check name='filter-checkbox' label={value ? value : 'Undefined'} key={index} id={id + `-${index+1}`} value={value} />)
          }
        })}
        { options.length > toShow ? (additionalOpions) : ""
        }

      </Form.Group>
    )
  }

  // vypíše seznam možných hodnot (range)
  printRangeOption = (id, label) => {
    return (
      <Form.Group className="numberFilter">
        <Form.Label className="smallLabel">{label}</Form.Label>
        <div className={"filterSlider"}>
          <Slider
            value={this.state[id]}
            onChange={(event,newValue) => this.onFilterChange({target:{value: newValue, id: id}})} valueLabelDisplay="auto" marks={[{value: 0, label: "0%"}, {value: 100, label: "100%"}]} aria-labelledby="range-slider" getAriaValueText={value => `${value}%`}
          />
        </div>
      </Form.Group>
    )
  }


  render(){

    const { allOptions } = this.state
    let maxPrice = Math.max(...allOptions.price)

    return (
      <div className="filter_form" onChange={this.onFilterChange}>

        {this.printFilterOptions("organizer", "Organizer", allOptions.organizer, 5)}
        {this.printFilterOptions("country", "Country", allOptions.country, 5)}
        {this.printFilterOptions("can_lead_to_certification", "Can lead to certification", allOptions.can_lead_to_certification, 3)}
        {this.printFilterOptions("includes_exams_for_certification", "Includes exams for certification", allOptions.includes_exams_for_certification, 5)}
        {this.printFilterOptions("content_type", "Content type", allOptions.content_type, 5)}
        {this.printFilterOptions("duration", "Duration", allOptions.duration, 5)}
        {this.printFilterOptions("language", "Language", allOptions.language, 5)}
        {this.printFilterOptions("skills_group", "Skills group", allOptions.skills_group, 5)}
        {this.printFilterOptions("timing", "Timing", allOptions.timing, 5)}
        {this.printFilterOptions("type_format", "Type/Format", allOptions.type_format, 5)}

        <Form.Group className="numberFilter">
          <Form.Label className="break"><h6>Training price max.</h6></Form.Label>
          <div className={"filterSlider"}>
            <Slider
              disabled defaultValue={30} // Odebrat po sjednocení formátu dat
              value={this.state["price"]} valueLabelDisplay="auto" getAriaValueText={value => `${value}€`} aria-labelledby="slider"
              onChange={(event,newValue) => this.onFilterChange({target:{value: newValue, id: "price"}})}
              marks={[{value: 0, label: "0 €"}, {value: maxPrice, label: maxPrice +" €"}]}
              max={maxPrice} min={0} step={100} id="slider-cost"
            />
          </div>
        </Form.Group>

      </div>
    )
  }

}

export default Filter
