import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import dropImg from '../assets/drop-here-certs.png'

import Certification from './Certification'

const CertificationsList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
  text-align: center;
`

class TimeTable extends React.Component {

  exportJsonFile = () => {
    let itemsToExport = []
    // const columnsToExport = Object.keys(this.props.columns) // export certs from all columns
    const columnsToExport = ["selected"] // export certf from selected columns
    columnsToExport.forEach(columnId => {
      this.props.columns[columnId].certificationIds.forEach(certId => {
        let item = this.props.certifications[certId]
        item.position = columnId
        itemsToExport.push(item)
      })
    })

    const element = document.createElement("a")
    const file = new Blob([JSON.stringify({certifications: itemsToExport})], {type: 'application/json'})
    element.href = URL.createObjectURL(file)
    element.download = "my-certifications.json"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  importJsonFile = (event) => {
    var file = event.target.files[0]
    let self = this
    if (event.target.files.length && file.type.match("application/json")) {
      var reader = new FileReader()
      reader.onload = function(e) {
        self.props.importData(reader.result)
      }
      reader.readAsText(file)
      document.getElementById("fileImport").value = null
    }
  }

  render(){

    const printDroppable = (column) => {
      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={false}
        >
          {(provided, snapshot) => (
            <CertificationsList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.certificationIds.map((certification, index) => {
                return <Certification key={this.props.certifications[certification].id} certification={this.props.certifications[certification]} index={index} removeCertification={this.props.removeCertification} openAddModal={this.props.openAddModal}
                columnId={column.id}/>
              })}
              {provided.placeholder}
              {column.certificationIds.length === 0 ? <img src={dropImg} className="dropImg" alt='dropHere'/> : ''}
            </CertificationsList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="time-table">
        <h3 className="section-title"> Your Certifications</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <input type="file" id="fileImport" accept="application/JSON" onChange={this.importJsonFile}/>
              <Button variant="outline-success" onClick={() => document.getElementById("fileImport").click()}>Import</Button>
              <Button variant="outline-danger" onClick={this.exportJsonFile}>Export</Button>
            </div>

            <Container>
              <Row>
                <Col>
                  {printDroppable(this.props.columns['selected'])}
                </Col>
              </Row>
            </Container>

          </div>
        </div>

      </Col>
    )
  }
}

export default TimeTable
