const enisaProfilesMap = [
  { profile: "Chief Information Security Officer (Ciso)", skills: [0, 5, 12, 13, 15, 18, 20, 21, 22, 23, 25, 27, 30]},
  { profile: "Cyber Incident Responder", skills: [1, 5, 11, 15, 17, 26, 29, 30]},
  { profile: "Cyber Legal, Policy & Compliance Officer", skills: [1, 2, 3, 4, 15]},
  { profile: "Cyber Threat Intelligence Specialist", skills: [2, 11, 14, 16, 24, 25, 26, 27, 28, 29]},
  { profile: "Cybersecurity Architect", skills: [1, 3, 4, 9, 12, 23, 24, 27, 30]},
  { profile: "Cybersecurity Auditor", skills: [7, 13, 15, 30]},
  { profile: "Cybersecurity Educator", skills: [1, 8, 15, 18]},
  { profile: "Cybersecurity Implementer", skills: [1, 9, 12, 13, 17, 24, 28, 29]},
  { profile: "Cybersecurity Researcher", skills: [1, 4, 15, 21, 22, 24, 26, 27, 28]},
  { profile: "Cybersecurity Risk Manager", skills: [4, 18, 23, 25, 28, 29, 30]},
  { profile: "Digital Forensics Investigator", skills: [1, 7, 15, 26, 28, 29]},
  { profile: "Penetration Tester", skills: [1, 21, 24, 26, 27, 28]}
]

export default enisaProfilesMap
