import React from 'react';
import {Image} from "antd";


const ProfileImage = ({ src, onClick, profile, roleClicked, anyClicked }) => {
    const buttonStyle = {
        opacity: profile!==roleClicked && anyClicked()?'40%':'100%',
        width: '200px',
        cursor: 'pointer',
    };

    // console.log("---")
    // console.log(!anyClicked())
    // console.log(roleClicked);
    // console.log(profile);
    // console.log("---")
    // console.log(buttonStyle)

    return (
        <Image src={src} onClick={onClick(profile)} style={buttonStyle} preview={false}/>
    );
};

export default ProfileImage