const title = "Penetration Tester";

// const alternativeTitles = [
//     "Pentester",
//     "Ethical Hacker",
//     "Vulnerability Analyst",
//     "Cybersecurity Tester",
//     "Offensive Cybersecurity Expert",
//     "Defensive Cybersecurity Expert",
//     "Red Team Expert",
//     "Red Teamer"
// ];

const alternativeTitles = [
    { key: 1, title: "Pentester" },
    { key: 2, title: "Ethical Hacker" },
    { key: 3, title: "Vulnerability Analyst" },
    { key: 4, title: "Cybersecurity Tester" },
    { key: 5, title: "Offensive Cybersecurity Expert" },
    { key: 6, title: "Defensive Cybersecurity Expert" },
    { key: 7, title: "Red Team Expert" },
    { key: 8, title: "Red Teamer" }
];

// const summaryStatement = [
//     "Simulate cyber attacks against computer systems, networks or applications to identify vulnerabilities. Provide recommendations for improving security posture."
// ];

const summaryStatement = [{ key: 1, statement: "Simulate cyber attacks against computer systems, networks or applications to identify vulnerabilities. Provide recommendations for improving security posture." }];

// const mission = [
//     "Plans, designs, implements and executes penetration testing activities and attack scenarios to evaluate the effectiveness of deployed or planned security measures.",
//     "Assess the effectiveness of security controls, reveals and utilise cybersecurity vulnerabilities, assessing their criticality if exploited by threat actors."
// ];

const mission = [
    { key: 1, mission: "Plans, designs, implements and executes penetration testing activities and attack scenarios to evaluate the effectiveness of deployed or planned security measures." },
    { key: 2, mission: "Assess the effectiveness of security controls, reveals and utilise cybersecurity vulnerabilities, assessing their criticality if exploited by threat actors." }
];

// const deliverables = [
//     "Penetration testing reports",
//     "Vulnerability assessment reports",
//     "Recommendations for improving security posture"
// ];

const deliverables = [
    { key: 1, deliverable: "Penetration testing reports", description: 'A report providing a detailed and comprehensive analysis of a system\'s vulnerabilities identified during a security testing. The report might also include suggested remediation actions.'},
    { key: 2, deliverable: "Vulnerability assessment reports", description: 'A report listing and assessing the criticality of vulnerabilities uncovered in a system during a (usually automatic) vulnerability scanning. The report might also suggest basic remediation actions.'},
    { key: 3, deliverable: "Recommendations for improving security posture" }
];

const mainTasks = [
    {key:1, task:"Identify, analyse and assess technical and organisational cybersecurity vulnerabilities"},
    {key:2, task:"Identify attack vectors, uncover and demonstrate exploitation of technical cybersecurity vulnerabilities"},
    {key:3, task:"Test systems and operations compliance with regulatory standards"},
    {key:4, task:"Select and develop appropriate penetration testing techniques"},
    {key:5, task:"Organise test plans and procedures for penetration testing"},
    {key:6, task:"Establish procedures for penetration testing result analysis and reporting"},
    {key:7, task:"Document and report penetration testing results to stakeholders"},
    {key:8, task:"Deploy penetration testing tools and test programs"}
];

const keySkills = [
    { key: 1, skill: "Develop codes, scripts and programmes" },
    { key: 2, skill: "Perform social engineering" },
    { key: 3, skill: "Identify and exploit vulnerabilities" },
    { key: 4, skill: "Conduct ethical hacking" },
    { key: 5, skill: "Think creatively and outside the box" },
    { key: 6, skill: "Identify and solve cybersecurity-related issues" },
    { key: 7, skill: "Communicate, present and report to relevant stakeholders" },
    { key: 8, skill: "Use penetration testing tools effectively" },
    { key: 9, skill: "Conduct technical analysis and reporting" },
    { key: 10, skill: "Decompose and analyse systems to identify weaknesses and ineffective controls" },
    { key: 11, skill: "Review codes assess their security" }
];

// • Cybersecurity attack procedures
// • Information technology (IT) and operational technology (OT) appliances
// • Offensive and defensive security procedures
// • Operating systems security
// • Computer networks security
// • Penetration testing procedures
// • Penetration testing standards, methodologies and frameworks
// • Penetration testing tools
// • Computer programming
// • Computer systems vulnerabilities
// • Cybersecurity recommendations and best practices
// • Cybersecurity-related certifications

const keyKnowledge = [
    { key: 1, knowledge: "Cybersecurity attack procedures" },
    { key: 2, knowledge: "Information technology (IT) and operational technology (OT) appliances" },
    { key: 3, knowledge: "Offensive and defensive security procedures" },
    { key: 4, knowledge: "Operating systems security" },
    { key: 5, knowledge: "Computer networks security" },
    { key: 6, knowledge: "Penetration testing procedures" },
    { key: 7, knowledge: "Penetration testing standards, methodologies and frameworks" },
    { key: 8, knowledge: "Penetration testing tools" },
    { key: 9, knowledge: "Computer programming" },
    { key: 10, knowledge: "Computer systems vulnerabilities" },
    { key: 11, knowledge: "Cybersecurity recommendations and best practices" },
    { key: 12, knowledge: "Cybersecurity-related certifications" }
];

const eCompetences = [
    { key: 1, competence: "B.2. Component Integration", level: ['Level 4'] },
    { key: 2, competence: "B.3. Testing", level: ['Level 4'] },
    { key: 3, competence: "B.4. Solution Deployment", level: ['Level 2'] },
    { key: 4, competence: "B.5. Documentation Production", level: ['Level 3'] },
    { key: 5, competence: "E.3. Risk Management", level: ['Level 4'] }
];

export const PENTESTData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}