import React from 'react'
import Axios from 'axios'
import { Badge, Collapse, Empty, Modal, Result, Space, Spin, Table, Tooltip, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, DownCircleOutlined, LoadingOutlined, StopOutlined, UpCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import {Config} from './config_section.js'

const { Title } = Typography
const { Panel } = Collapse
const { confirm } = Modal

const UserManager = ({ showMessage, authData, usersData, setUsersData }) => {
  const [changeLoading, setChangeLoading] = React.useState(false)

  function updateUsersData(action, userId) {
    if (!changeLoading) {
      confirm({
        title: 'Are you sure?',
        icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          return new Promise((resolve, reject) => {
            // showMessage({type: 'loading', content: "Loading...", key: 'adminActionStatus'})
            setChangeLoading(true)
            Axios.post( Config.server.updateUsersData, {...authData, action: action, user_id: userId}, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                if (response && response.data.error === false){
                  setUsersData({
                    newUsers: response.data.data.new_users.map(obj=> ({ ...obj, key: obj.id})),
                    blockedUsers: response.data.data.blocked_users.map(obj=> ({ ...obj, key: obj.id})),
                    adminUsers: response.data.data.admin_users.map(obj=> ({ ...obj, key: obj.id})),
                    registeredUsers: response.data.data.registered_users.map(obj=> ({ ...obj, key: obj.id}))
                  })
                  showMessage({type: 'success', content: "Updated successfully.", key: 'adminActionStatus'})
                  setChangeLoading(false)
                  resolve()
                } else {
                  console.log(response.data.message)
                  showMessage({type: 'error', content: 'Something went wrong!', key: 'adminActionStatus'})
                  setChangeLoading(false)
                  reject()
                }
              })
              .catch((error) => {
                showMessage({type: 'error', content: 'Something went wrong!', key: 'adminActionStatus'})
                setChangeLoading(false)
                console.log("Server is unavailable")
                console.log(error)
                reject()
              })
          }).catch(() => {
            showMessage({type: 'error', content: "Something went wrong."})
          })
        }
      })
    } else {
      showMessage({type: 'warning', content: 'Wait for the previous task to complete.', key: 'adminActionStatus'})
    }
  }

  function actionColumns (type) {
    return [{
      title: 'Action',
      key: 'action',
      width: '57px',
      render: (record) => (
        <Space size="small">
        {type === "admins" ? (
          changeLoading ? (
            <Tooltip placement="topRight" arrowPointAtCenter title="Wait for the previous task!"><DownCircleOutlined className="disabledIcon" /></Tooltip>
          ) : usersData.adminUsers.length <= 1 ? (
            <Tooltip placement="topRight" arrowPointAtCenter title="At least one admin must remain!"><DownCircleOutlined className="disabledIcon" /></Tooltip>
          ) : (
            <Tooltip placement="topRight" arrowPointAtCenter title="Remove admin privileges"><DownCircleOutlined className="deleteIcon" onClick={()=>updateUsersData(7, record.id)} /></Tooltip>
          )
        ) : type === "new" ? (
          <>
            <Tooltip title={changeLoading ? "Wait for the previous task!" : "Confirm registration"}><CheckCircleOutlined className={changeLoading ? "disabledIcon" : "confirmIcon"} onClick={()=>updateUsersData(2, record.id)} /></Tooltip>
            <Tooltip placement="topRight" arrowPointAtCenter title={changeLoading ? "Wait for the previous task!" : "Remove registration"}><CloseCircleOutlined className={changeLoading ? "disabledIcon" : "deleteIcon"} onClick={()=>updateUsersData(1, record.id)} /></Tooltip>
          </>
        ) : type === "registered" ? (
          <>
            <Tooltip title={changeLoading ? "Wait for the previous task!" : "Add admin privileges"}><UpCircleOutlined  className={changeLoading ? "disabledIcon" : "confirmIcon"} onClick={()=>updateUsersData(6, record.id)} /></Tooltip>
            <Tooltip title={changeLoading ? "Wait for the previous task!" : "Block user"}><StopOutlined className={changeLoading ? "disabledIcon" : "deleteIcon"} onClick={()=>updateUsersData(3, record.id)} /></Tooltip>
          </>
        ) : type === "blocked" ? (
          <>
            <Tooltip title={changeLoading ? "Wait for the previous task!" : "Allow user"}><CheckCircleOutlined className={changeLoading ? "disabledIcon" : "confirmIcon"} onClick={()=>updateUsersData(4, record.id)} /></Tooltip>
            <Tooltip placement="topRight" arrowPointAtCenter title={changeLoading ? "Wait for the previous task!" : "Remove user permanently"}><DeleteOutlined className={changeLoading ? "disabledIcon" : "deleteIcon"} onClick={()=>updateUsersData(5, record.id)} /></Tooltip>
          </>
        ) : "" }
        </Space>
      )
    }]
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '48px',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'User',
      dataIndex: 'email',
      key: 'email',
      sorter:  (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter:  (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter:  (a, b) => a.company.localeCompare(b.company),
    },
    {
      title: 'Last Visit [CET]',
      dataIndex: 'last_visit',
      key: 'last_visit',
      width: '160px',
      sorter:  (a, b) => a.last_visit.localeCompare(b.last_visit),
    },
    {
      title: 'Created [CET]',
      dataIndex: 'created',
      key: 'created',
      width: '160px',
      sorter:  (a, b) => a.created.localeCompare(b.created),
    }
  ]

  return (
    <div id="user-manager" >
      <span><Title className="pageTitle">User Manager</Title></span>
      {
        usersData === null ? (
          <div className="vertCenter"><Spin tip="Loading..." indicator={<LoadingOutlined setstyle={{ fontSize: 24 }} spin />} /></div>
        ) : (
          !Array.isArray(usersData.adminUsers) || usersData === 0 ? (
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
            />
          ) : (
            [...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers, ...usersData.newUsers].length === 0 ? (
              <Empty/>
            ) : (
              <>
                <Collapse accordion>
                 <Panel header={<Space>New registrations <Badge count={usersData.newUsers.length} showZero overflowCount={999} /></Space>} key="1" className="panel-header">
                   {usersData.newUsers.length ? (
                     <Table size="small" pagination={false} dataSource={usersData.newUsers} columns={[...tableColumns, ...actionColumns("new")]} />
                   ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"None"} />}
                 </Panel>
                 <Panel header={<Space>Admins <Badge count={usersData.adminUsers.length} showZero overflowCount={999} /></Space>} key="2" className="panel-header">
                 {usersData.adminUsers.length ? (
                   <Table size="small" pagination={false} dataSource={usersData.adminUsers} columns={[...tableColumns, ...actionColumns("admins")]} />
                 ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"None"} />}
                 </Panel>
                 <Panel header={<Space>Registered <Badge count={usersData.registeredUsers.length} showZero overflowCount={999} /></Space>} key="3" className="panel-header">
                 {usersData.registeredUsers.length ? (
                   <Table size="small" pagination={false} dataSource={usersData.registeredUsers} columns={[...tableColumns, ...actionColumns("registered")]} />
                 ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"None"} />}
                 </Panel>
                 <Panel header={<Space>Blocked <Badge count={usersData.blockedUsers.length} showZero overflowCount={999} /></Space>} key="4" className="panel-header">
                 {usersData.blockedUsers.length ? (
                   <Table size="small" pagination={false} dataSource={usersData.blockedUsers} columns={[...tableColumns, ...actionColumns("blocked")]} />
                 ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"None"} />}
                 </Panel>
               </Collapse>
              </>
            )
          )
        )
      }
    </div>
  )
}

export default UserManager
