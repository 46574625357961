const comps = [
'Asset / Inventory Management',
'Business Continuity',
'Client Relationship Management',
'Collection Operations',
'Computer Forensics',
'Computer Languages',
'Computer Network Defense',
'Computers and Electronics',
'Contracting/Procurement',
'Data Analysis',
'Data Management',
'Data Privacy and Protection',
'Database Administration',
'Database Management Systems',
'Encryption',
'Enterprise Architecture',
'External Awareness',
'Identity Management',
'Incident Management',
'Information Assurance',
'Information Management',
'Information Systems/Network Security',
'Information Technology Assessment',
'Infrastructure Design',
'Intelligence Analysis',
'Knowledge Management',
'Legal, Government, and Jurisprudence',
'Mathematical Reasoning',
'Modeling and Simulation',
'Network Management',
'Operating Systems',
'Operations Support',
'Organizational Awareness',
'Policy Management',
'Problem Solving',
'Process Control',
'Requirements Analysis',
'Risk Management',
'Software Development',
'Software Testing and Evaluation',
'System Administration',
'Systems Integration',
'Systems Testing and Evaluation',
'Target Development',
'Technology Awareness',
'Telecommunications',
'Third Party Oversight/Acquisition Management',
'Threat Analysis',
'Vulnerabilities Assessment',
'Web Technology'
]

export default comps
