import React from 'react'
import {Alert, Button, Container, Modal, Row } from 'react-bootstrap'
import { DragDropContext } from 'react-beautiful-dnd'

import TimeTable from './TimeTable.js'
import ListOfCourses from './ListOfCourses.js'
import Statistics from './Statistics.js'
import { checkColumnStructure, checkCoursesStructure } from "./utils"
import dndStructure from './initStructure/dndStructure'
import sampleCourses from './initStructure/SampleCourses'
import CourseForm from './CourseForm'

class Curricula extends React.Component {
  constructor() {
    super()
    this.state = {
      courses: {},
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedCourse: null,
      sampleCoursesLoaded: false,
      showAddModal: false,
      // addModalValidated: false,
      addModalData: null,
      modalCourseId: null
    }
  }

  closeAddModal = () => this.setState({
    showAddModal: false,
    // addModalValidated: false,
  })

  openAddModal = (courseId) => {
    let modalData = null
    if (courseId !== null) {
      modalData = this.state.courses[courseId]
    }
    this.setState({showAddModal: true, modalCourseId: courseId, addModalData: modalData})
  }

  createCourseDnDId = (name, prefix, pool) => {
    // Create base part of item ID in DnD structure
    let newIdBase = prefix + "_" + name.replace(/ /g,"_").toLowerCase()
    var counter = 0
    while (pool.includes(newIdBase+"_"+counter)) {
      counter++
    }
    return newIdBase+"_"+counter
  }

  submitModal = () =>{
    if (this.state.modalCourseId === null) {
      this.addCustomCourse(this.state.addModalData)
    } else {
      this.updateCourse(this.state.addModalData)
    }
    this.closeAddModal()
  }

  addCustomCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let newState = {
      courses: JSON.parse(JSON.stringify(this.state.courses)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    // generate local course id
    newCourse.id = this.createCourseDnDId(newCourse.name, "user", Object.keys(newState.courses))
    // add to correct column
    const coursePosition = newCourse.type + '-' + newCourse.semester.charAt(0)
    newState.columns[coursePosition].courseIds.push(newCourse.id)
    newState.courses[newCourse.id] = newCourse

    this.setState(newState)
  }

  updateCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let oldId = this.state.modalCourseId
    let newState = {
      courses: JSON.parse(JSON.stringify(this.state.courses)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    newCourse.id = this.createCourseDnDId(newCourse.name, "user", Object.keys(newState.courses))
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    let oldPosition = Object.keys(this.state.columns).find(columnId => this.state.columns[columnId].courseIds.includes(oldId))
    let newPosition = isNaN(oldPosition.charAt(0)) ? (
      newCourse.type + '-' + newCourse.semester.charAt(0)
    ) : (
      oldPosition.charAt(0) + '-' + newCourse.semester.charAt(0)
    )

    // update courses
    delete newState.courses[oldId]
    newState.courses[newCourse.id] = newCourse

    // update columns
    let courseIndex = newState.columns[oldPosition].courseIds.indexOf(oldId)
    if (newPosition === oldPosition) {
      newState.columns[oldPosition].courseIds[courseIndex] = newCourse.id
    } else {
      newState.columns[oldPosition].courseIds.splice(courseIndex, 1 )
      newState.columns[newPosition].courseIds.push(newCourse.id)
    }

    this.setState(newState)
  }
/*
  duplicateCourse = () => {
    let newCourse = JSON.parse(JSON.stringify(this.state.courses[this.state.modalCourseId]))
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newPosition = null
    Object.values(this.state.columns).some(item => {
      if(item.courseIds.includes(newCourse.id)){
        newPosition = item.id
        return true
      } else {
        return null
      }
    })
    newCourse.name += " (copy)"
    newCourse.id += this.createCourseDnDId(newCourse.name, "user")
    newColumns[newPosition].courseIds.push(newCourse.id)

     this.setState({
       courses: {...this.state.courses, [newCourse.id]: newCourse},
       columns: newColumns
     })
    this.closeAddModal()
  }
*/
  removeForeverCourse = (course) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[course.position].courseIds.splice( newState.columns[course.position].courseIds.indexOf(course.id), 1 )
    delete newState.courses[course.id]
    this.setState({columns: newState.columns, courses: newState.courses})
  }

  removeCourse = (course) => {
    let newPosition = course.type + '-' + course.semester.charAt(0)
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    newColumns[course.position].courseIds.splice( newColumns[course.position].courseIds.indexOf(course.id), 1 )
    newColumns[newPosition].courseIds.push(course.id)
    this.setState({columns: newColumns})
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)
      if (!newData.hasOwnProperty("courses")) {
        this.setState({error:{message: "Wrong input file format!"}})
        return
      }

      // check valid position and item structure
      let columnTest = checkColumnStructure(newData.courses, dndStructure.columns)
      let courseTest = checkCoursesStructure(newData.courses)
      if (columnTest && courseTest) {

        // prepare of writing to the state
        let newState = {
          courses: JSON.parse(JSON.stringify(this.state.courses)),
          columns: JSON.parse(JSON.stringify(this.state.columns))
        }

        // testing id duplicity and generating new id
        newData.courses.forEach(newCourse => {

          /* Create new unique ID of course for use in DnD structure */
          newCourse.id = this.createCourseDnDId(newCourse.name, "import", Object.keys(newState.courses))

          // saving to the new state object
          newState.columns[newCourse.position].courseIds.push(newCourse.id)
          delete newCourse.position
          newState.courses[newCourse.id] = newCourse

        })
        // write to the state
        this.setState(newState)
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch(e) {
      console.log(e)
      this.setState({error:{message: "Sorry, something went wrong!"}})
    }

  }

  getCoursePosition = (course) => {
    return (
      course.type === "voluntary" && course.semester === "summer" ? 'voluntary-s' :
      course.type === "voluntary" && course.semester === "winter" ? 'voluntary-w' :
      course.type === "mandatory" && course.semester === "summer" ? 'mandatory-s' :
      course.type === "mandatory" && course.semester === "winter" ? 'mandatory-w' :
      course.type === "custom" && course.semester === "summer" ? 'custom-s' :
      course.type === "custom" && course.semester === "winter" ? 'custom-w' :
      null
    )
  }

  loadSamples = () => {
    // slouzi pro upload sample courses
    if (typeof(sampleCourses) !== "undefined" && sampleCourses !== null && sampleCourses.length !== 0){
      let sortedSampleCourses = sampleCourses.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      /* Prepare new empty state variables */
      let newColumns = JSON.parse(JSON.stringify(this.state.columns))
      let newCourses = JSON.parse(JSON.stringify(this.state.courses))

      /* Add selected courses */
      sortedSampleCourses.forEach((newCourse) => {
        /* Create new unique ID of courses for use in DnD structure */
        newCourse.id = this.createCourseDnDId(newCourse.name, "sample", Object.keys(newCourses))
        /* Add courses ID to DnD structure */
        const newPosition = this.getCoursePosition(newCourse)
        if (newPosition === null){
          console.log("import error")
          console.log(newCourse)
          return
        }
        newColumns[newPosition].courseIds.push(newCourse.id)
        /* Push object containing courses data to courses array */
        newCourses[newCourse.id] = newCourse
      })

      this.setState({columns: newColumns, courses: newCourses, sampleCoursesLoaded: true})
    } else {
      this.setState({error:{message: "There are no sample courses available!"}})
    }
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedCourse = this.state.courses[start.draggableId].id
    this.setState({
      homeIndex,
      draggedCourse,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }
    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]
    if (start === finish) {
      const newCourseIds = Array.from(start.courseIds)
      newCourseIds.splice(source.index, 1)
      newCourseIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        courseIds: newCourseIds
      }
      const newState = {
        ...this.state,
        homeIndex: null,
        draggedCourse: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }
      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startCourseIds = Array.from(start.courseIds)
    startCourseIds.splice(source.index, 1)
    const newStart = {
      ...start,
      courseIds: startCourseIds
    }

    const finishCourseIds = Array.from(finish.courseIds)
    finishCourseIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      courseIds: finishCourseIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedCourse: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  render(){
    const { columns, error, courses, addModalData, draggedCourse, showAddModal, /*addModalValidated,*/ modalCourseId, sampleCoursesLoaded } = this.state
    const { rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles, showMessage } = this.props


    // console.log(Object.keys(this.state.courses).sort())
    // console.log([].concat(...Object.values(this.state.columns).map(column => column.courseIds)).sort());

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <div><Button variant="link" onClick={()=>this.setState({error:null})}>Go back</Button></div>
          </div>
      ) : (
          <Container fluid>
            <Row>
              <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                <ListOfCourses columns={columns} courses={courses}
                  draggedCourse={draggedCourse}
                  sampleCoursesLoaded={sampleCoursesLoaded}
                  addCustomCourse={this.addCustomCourse}
                  removeCourse={this.removeForeverCourse}
                  loadSamples={this.loadSamples}
                  rsSkillsGroup={rsSkillsGroup}
                  openAddModal={this.openAddModal}/>
                <TimeTable columns={columns} courses={courses}
                  draggedCourse={draggedCourse}
                  removeCourse={this.removeCourse}
                  importData={this.importData}
                  rsSkillsGroup={rsSkillsGroup}
                  openAddModal={this.openAddModal}/>
                <Statistics columns={columns} courses={courses} rsSkillsGroup={rsSkillsGroup} rsProfiles={rsProfiles}/>
              </DragDropContext>
            </Row>
          </Container>
        )}

        <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

          {/* }<Form noValidate validated={addModalValidated} onSubmit={this.submitModal}> */}

            <Modal.Header closeButton className={modalCourseId === null ? "" : " edit"}>
              <Modal.Title>{modalCourseId === null ? "Add new course" : "Edit course"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <CourseForm saveLocally={(newData)=>{this.setState({addModalData: newData}, () => {this.submitModal()})}} formData={addModalData} userDataMerged={null} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} authData={null} hideForm={()=>this.closeAddModal()} showMessage={showMessage} getPublicData={null}></CourseForm>

            </Modal.Body>
            {/* <Modal.Footer>
              {modalCourseId === null ? "" : <Button variant="outline-dark" className="left" onClick={this.duplicateCourse}>Duplicate</Button>}
              <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form> */}
        </Modal>

      </div>
    )
  }

}

export default Curricula
