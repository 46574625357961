const skillsInTrainings = [
  {
      "training_title": "Cyber Systems Security through Ethical Hacking",
      "organizer": "AKMI",
      "short_description": "Hackademic  A specialized seminar that offers information and training to participants about computer security systems and the basic principles of fortifying computers, mobile phones and tablets from external attacks.",
      "link": "https://ekarinos.weebly.com/uploads/4/4/5/1/44512607/capture-1_orig.png",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Greece",
      "timing": "Fixed dates",
      "course_dates": "21.03.2020",
      "duration": "12 hours",
      "content_type": "Theoretical and hands on",
      "price": "€120,00",
      "prerequisites": "Basic Network and Programmic Skills",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Data Security",
          "Operating Systems",
          "Risk Management",
          "Testing and Evaluation"
      ],
      "specific_skill": [
          "Document, report present and communicate with various stakeholders",
          "Work on operating systems, servers, clouds and relevant infrastructures",
          "Adapt and customise penetration testing tools and techniques"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of information security",
          "Knowledge of operating systems security",
          "Knowledge of cybersecurity risks and threats",
          "Knowledge of test methodologies and practices"
      ],
      "other_skills": [
          "Communicate and disseminate the scientific outcomes",
          "Perform social engineering"
      ],
      "other_knowledge": [
          "Knowledge of monitoring, implementing, testing and evaluating the effectiveness of the controls"
      ]
  },
  {
      "training_title": "Cyber Systems Security through Ethical Hacking",
      "organizer": "AKMI",
      "short_description": "Hackademic  Cisco Certified CyberOps Associate certification program validates the day-to-day, tactical knowledge and skills that Security Operations Center (SOC) teams need to detect and respond to cybersecurity threats.   The CyberOps Associate exam and training cover knowledge and skills related to security concepts, security monitoring, hostbased analysis, network intrusion analysis, and security policies and procedures.",
      "link": "https://www.cisco.com/c/en/us/training-events/training-certifications/certifications/associate/cyberops-associate.html",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Greece",
      "timing": "Fixed dates",
      "course_dates": "November 2022",
      "duration": "60 hours",
      "content_type": "Theoretical and hands on",
      "price": "€450,00",
      "prerequisites": "Basic Network and Programmic Skills",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Digital Forensics",
          "Enterprise Architecture and Infrastructure Design",
          "Intelligence Analysis",
          "Risk Management",
          "Testing and Evaluation",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Collect information while preserving its integrity",
          "Select appropriate specifications, procedures and controls",
          "Conduct technical analysis and reporting",
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
          "Identify and exploit vulnerabilities",
          "Model threats, actors and TTPs"
      ],
      "specific_knowledge": [
          "Knowledge of digital forensics analysis techniques",
          "Understanding of cybersecurity-related standards and compliance requirements",
          "Knowledge of cybersecurity risks and threats",
          "Knowledge of test methodologies and practices",
          "Knowledge of cyber threats and vulnerabilities"
      ],
      "other_skills": [
          "Identify, analyse and correlate events"
      ],
      "other_knowledge": [
          "Knowledge of digital forensics methods, best practices and tools",
          "Knowledge of risk sharing options and best practices"
      ]
  },
  {
      "training_title": "Introduction to Ethical Hacking Tools",
      "organizer": "  E-Learning Ε.Κ.Π.Α",
      "short_description": "The Training presents in detail the twenty best tools used in Ethical Hacking, their features, and the capabilities they provide. In addition, the Open Systems Interconnection (OSI) Model is presented",
      "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u3/ReloadContentPreview.htm",
      "language": "Greek",
      "type_format": "Online",
      "country": "Greece",
      "timing": "Available online",
      "course_dates": "2020",
      "duration": ">3 hours",
      "content_type": "Theoretical only",
      "price": "Free",
      "prerequisites": "Ethical Hacking Cyber Security, Information Systems Security",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Digital Forensics",
          "Information Security Controls Assessment",
          "Organizational Awareness",
          "Technology Fluency",
          "Testing and Evaluation"
      ],
      "specific_skill": [
          "Report, communicate and present to stakeholders",
          "Identify, analyse and correlate events",
          "Apply auditing tools and techniques",
          "Influence an organisation’s cybersecurity culture",
          "Anticipate future cybersecurity threats, trends, needs and challenges in the organisation",
          "Conduct ethical hacking"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of digital forensics methods, best practices and tools",
          "Knowledge of conformity assessment methodologies",
          "Knowledge of best practices on cybersecurity",
          "Knowledge of test methodologies and practices"
      ],
      "other_skills": [
          "Explain and present digital evidence in a simple, straightforward and easy to understand way",
          "Assess the security and performance of solutions",
          "Build a cybersecurity risk-aware environment",
          "Identify and exploit vulnerabilities "
      ],
      "other_knowledge": [
          "OSI (Open Systems Interconnection) model and its layers.",
          "Knowledge of cybersecurity solutions, technical and organisational controls",
          "Knowledge of monitoring, implementing, testing and evaluating the effectiveness of the controls"
      ]
  },
  {
      "training_title": "Introduction to Concepts: Ethical Hacking, Cyber Security, Information Systems Security",
      "organizer": "  E-Learning Ε.Κ.Π.Α",
      "short_description": "The course is an introduction to concepts: Information System (IS), Information Systems Security and Ethical Hacking. In this context, the definition of Threat of an Information System (IS) is first presented. Next, the effects that threats can bring to an Information System, as well as the types of Threats, are presented. Finally, the method of identifying threats is presented as the first step for the protection of Information Systems.",
      "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u1/ReloadContentPreview.htm",
      "language": "Greek",
      "type_format": "Online",
      "country": "Greece",
      "timing": "Available online",
      "course_dates": "2020",
      "duration": ">3 hours",
      "content_type": "Theoretical only",
      "price": "Free",
      "prerequisites": "It is recommended to study the Introduction to Ethical Hacking Tools course of the same course series.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Data Privacy",
          "Law, Policy, and Ethics",
          "Organizational Awareness",
          "Problem Solving and Critical Thinking"
      ],
      "specific_skill": [
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
          "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies",
          "Influence an organisation’s cybersecurity culture",
          "Think creatively and outside the box"
      ],
      "specific_knowledge": [
          "Knowledge of privacy impact assessment methodologies",
          "Understanding of ethical cybersecurity organisation requirements"
      ],
      "other_skills": [
          "Build a cybersecurity risk-aware environment"
      ],
      "other_knowledge": []
  },
  {
      "training_title": "Security Threats and Risk Management Methods",
      "organizer": "  E-Learning Ε.Κ.Π.Α",
      "short_description": "The course deals with the subject of Threats to Information Systems, as well as the ways to deal with them. In this context, the definition of Threat (Threat), the effects that threats can bring to an Information System, as well as the types of Threats are first presented. At the same time, the methodology of the risk analysis of an Information System is presented, as well as the basic characteristics, requirements and implementation steps of the OCTAVE Allegro Strategy. Finally, issues related to risk management and the implementation of Security Plans are presented.",
      "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u2/ReloadContentPreview.htm",
      "language": "Greek",
      "type_format": "Online",
      "country": "Greece",
      "timing": "Available online",
      "course_dates": "2020",
      "duration": ">3 hours",
      "content_type": "Theoretical only",
      "price": "Free",
      "prerequisites": "It is recommended to study the courses Introduction to Ethical Hacking Tools and Introduction to Concepts: Ethical Hacking, Cyber Security, Information Systems Security of the same course series.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Data, Asset and Inventory Management",
          "Enterprise Architecture and Infrastructure Design",
          "Information Security Controls Assessment",
          "Technology Fluency",
          "Testing and Evaluation",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Manage cybersecurity resources",
          "Conduct user and business requirements analysis",
          "Assess and enhance an organisation’s cybersecurity posture",
          "Anticipate future cybersecurity threats, trends, needs and challenges in the organisation",
          "Identify and exploit vulnerabilities",
          "Identify threat actors TTPs and campaigns"
      ],
      "specific_knowledge": [
          "Basic understanding of data storage, processing and protections within systems, services and infrastructures",
          "Understanding of cybersecurity-related standards and compliance requirements",
          "Knowledge of conformity assessment methodologies",
          "Understanding of espionage and coercion threats and risk in international research",
          "Knowledge of cybersecurity methods, methodologies, tools and techniques",
          "Knowledge of cyber threats and vulnerabilities"
      ],
      "other_skills": [],
      "other_knowledge": [
          "OCTAVE Allegro",
          "Knowledge of cybersecurity attacks tactics and techniques"
      ]
  },
  {
      "training_title": "Cyber-Security for protection of classified information",
      "organizer": "INTENSEC RO SRL",
      "short_description": "Learn why cyber-security is important in the process of protection of classified information and how to protect classified information from the perspective of INFOSEC (security of electronic classified information)",
      "link": "https://intensec.ro/index.php/cybertraining/",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Romania",
      "timing": "On demand",
      "course_dates": "",
      "duration": "3 days",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "Recommendation from the employer",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Data Security",
          "Incident Management"
      ],
      "specific_skill": [],
      "specific_knowledge": [
          "Knowledge of security technologies and solutions",
          "Knowledge of cybersecurity incident handling methodologies"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Specialist in procedures and security tools for IT&C system",
      "organizer": "Fist Mixt Development-1MD",
      "short_description": "Learn how to maintain a high level of security in a IT&C network, and how to protect some of the most important assets of the organization: their data and services offered.",
      "link": "https://cyburg.ro/specialist-in-proceduri-si-instrumente-de-securitate-a-sistemelor-informatice/",
      "language": "Romanian",
      "type_format": "Hybrid",
      "country": "Romania",
      "timing": "Fixed dates",
      "course_dates": "22/08/2022-26/08/2022",
      "duration": "5 days",
      "content_type": "Theoretical and hands on",
      "price": "€500,00",
      "prerequisites": "Higher education, laptop",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Information Systems and Network Security",
          "Organizational Awareness",
          "Risk Management"
      ],
      "specific_skill": [
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Hacking: Binary Exploitation",
      "organizer": "Fraunhofer AISEC",
      "short_description": "More and more devices and systems can now be reached through the internet and other networks, exposing them to direct attacks. Our solution for this problem is to understand and predict binary exploitation from the viewpoint of hackers.",
      "link": "https://www.academy.fraunhofer.de/en/continuing-education/information-communication/cybersecurity/hacking--binary-exploitation.html",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Germany",
      "timing": "On demand",
      "course_dates": "N/A",
      "duration": "3 days",
      "content_type": "Theoretical and hands on",
      "price": "€1 800,00",
      "prerequisites": "Linux basics: Routine operations with the Bourne-Again Shell (BASH) and the GNU Debugger (GDB),   Programming knowledge: Fluent reading and understanding of code in C, programming experience in C or Python  Assembler: Reading and understanding of x86_64 assembler, programming in assembler is not required",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Testing and Evaluation",
          "Software Development",
          "Operating Systems",
          "Threat Analysis",
          "Technology Fluency"
      ],
      "specific_skill": [
          "Identify and exploit vulnerabilities",
          "Develop and test secure code and scripts",
          "Work on operating systems, servers, clouds and relevant infrastructures",
          "Knowledge of the latest cybersecurity trends"
      ],
      "specific_knowledge": [
          "Knowledge of programming languages",
          "Knowledge of operating systems security",
          "Knowledge of cyber threats and vulnerabilities",
          "Knowledge of best practices on cybersecurity"
      ],
      "other_skills": [
          "Conduct ethical hacking"
      ],
      "other_knowledge": [
          "Knowledge of secure coding practices, Knowledge of scripting and programming languages"
      ]
  },
  {
      "training_title": "CyberHOT Summer School",
      "organizer": "University of Piraeus & Technical University of Crete",
      "short_description": "Hands-on cybersecurity training on, (1) threat and attack monitoring, (2) risk assessment, (3) security management, (4) technical vulnerability assessment, and (5) digital forensics.",
      "link": "https://www.cyberhot.eu/home",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Greece",
      "timing": "Fixed dates",
      "course_dates": "29-30th September 2022",
      "duration": "2 days",
      "content_type": "Hands one",
      "price": "€490-600.00",
      "prerequisites": "MetasploitNmapBurp-ProxyOWASP-Top 10Basic Linux Knowledge-BashSQLPHP",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Threat Analysis",
          "Information Systems and Network Security",
          "Testing and Evaluation",
          "Enterprise Architecture and Infrastructure Design",
          "Software Development",
          "Technology Fluency",
          "Problem Solving and Critical Thinking",
          "Policy Development"
      ],
      "specific_skill": [
          "Use penetration testing tools effectively",
          "Conduct user and business requirements analysis",
          "Knowledge of the latest cybersecurity trends",
          "Provide practical solutions to cybersecurity issues",
          "Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity attacks tactics and techniques",
          "Knowledge of cybersecurity tactics, techniques and procedures",
          "Knowledge of test methodologies and practices",
          "Knowledge of scripting and programming languages",
          "Knowledge of best practices on cybersecurity"
      ],
      "other_skills": [
          "Identify and exploit vulnerabilities",
          "Establish a cybersecurity plan"
      ],
      "other_knowledge": [
          "Knowledge of cyber threats and vulnerabilities",
          "Knowledge of offensive and defensive security practices"
      ]
  },
  {
      "training_title": "Cyber Security in e-Governance",
      "organizer": "EIT DIGITAL",
      "short_description": "Participants will learn about legal aspects of e-governance, the requirements for electronic identity and how critical information infrastructure is protected in the public sector. From the start-up and e-service creation side, the programme will cover the topics of design process and prototyping, business model validation and approach lecturers by successful Estonian CyberTech companies. Finally, participants will be gathered with real-life experience with cyber security incidents and vulnerability detection. Moreover, one day (8-hour long) hands-on cyber exercise will be organized with mentors and experts for improving practical skills and understanding about CyberTech solutions.",
      "link": "https://summerschool.eitdigital.eu/cyber-security-in-e-governance",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Estonia",
      "timing": "Fixed dates",
      "course_dates": "31 July - 5 August 2022",
      "duration": "6 days",
      "content_type": "Theoretical and hands on",
      "price": "€850,00",
      "prerequisites": "No need to have specific technical background",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Security Controls Assessment",
          "Enterprise Architecture and Infrastructure Design",
          "Business Continuity",
          "Intelligence Analysis",
          "Law, Policy, and Ethics",
          "Data Privacy"
      ],
      "specific_skill": [
          "Follow and practice auditing frameworks, standards and methodologies",
          "Understand core organisational business processes",
          "Conduct technical analysis and reporting",
          "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies",
          "Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity solutions, technical and organisational controls",
          "Understanding of cybersecurity-related standards and compliance requirements",
          "Knowledge of cybersecurity and privacy standards, frameworks, policies, regulations, legislations, certifications and best practices",
          "Knowledge of privacy impact assessment methodologies"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": " Cyber Security for Blockchain",
      "organizer": "EIT DIGITAL",
      "short_description": "The Summer School will focus on Smart Contracts and their nearly unlimited use-cases in the financial and public sector. The participants will learn how to use one of the most popular blockchain frameworks, Ethereum, a prominent smart contract platform, and its dominant programming language, Solidity, with emphasis on security issues and cyber security concerns. Also, the student will gain insights into how decentralized finances are connected to ransomware and threats from cybercriminals. On top of that, the participants will understand proactive security measures based on network monitoring and security incident handling based on machine learning.",
      "link": "https://summerschool.eitdigital.eu/cyber-security-for-blockchain",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Slovakia",
      "timing": "Fixed dates",
      "course_dates": "15 August - 26 August 2022",
      "duration": "12 days",
      "content_type": "Theoretical and hands on",
      "price": "€1 600,00",
      "prerequisites": "Basic knowledge in cyber security and machine learning. Be aware what is blockchain and what is Ethereum.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Threat Analysis",
          "Incident Management",
          "Information Systems and Network Security",
          "Operating Systems",
          "Data Privacy",
          "Data, Asset and Inventory Management"
      ],
      "specific_skill": [
          "Manage and analyse log files",
          "Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools",
          "Manage cybersecurity resources"
      ],
      "specific_knowledge": [
          "Knowledge of cyber threats and vulnerabilities",
          "Knowledge of offensive and defensive security practices",
          "Knowledge of operating systems internals, networking protocols and services",
          "Basic understanding of data storage, processing and protections within systems, services and infrastructures"
      ],
      "other_skills": [],
      "other_knowledge": [
          "Knowledge of cybersecurity attacks tactics and techniques"
      ]
  },
  {
      "training_title": "Certified Information Security Manager CISM -certification and exam preparation",
      "organizer": "SBA Research",
      "short_description": "The Certified Information Security Manager (CISM) is a globally recognized certification for experts in the field of information security management in companies.  Acquiring the title of CISM identifies you as a qualified expert in the field of information security management and serves as proof that employees have the necessary experience and know-how with regard to planning, implementation, control and monitoring of information security in a company. .  The exam preparation course is a good and comprehensive preparation for the ISACA exam to obtain the CISM certification.",
      "link": "https://www.sba-research.org/professional-services/security-training/#cism",
      "language": "German",
      "type_format": "Face-to-face",
      "country": "Austria",
      "timing": "Fixed dates",
      "course_dates": "10.10.2022 – 12.10.2022        09:00 – 17:00",
      "duration": "3 days",
      "content_type": "Theoretical and hands on",
      "price": "€1,960 (excl. VAT) per participant",
      "prerequisites": "",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Security Controls Assessment"
      ],
      "specific_skill": [
          "Assess and enhance an organisation’s cybersecurity posture"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity solutions, technical and organisational controls"
      ],
      "other_skills": [
          "Assess the security and performance of solutions;Follow and practice auditing frameworks, standards and methodologies"
      ],
      "other_knowledge": [
          "Knowledge of cybersecurity solution; technical and organisational controls;Advanced knowledge of auditing frameworks, standards, methodologies and certifications; Knowledge of conformity assessment methodologies"
      ]
  },
  {
      "training_title": "Certified Information Systems Auditor CISA - certification and exam preparation",
      "organizer": "SBA Research",
      "short_description": "The Certified Information Systems Auditor (CISA) is a globally recognized certification for professionals in the areas of IT security, IT auditing and auditing, IT risk management and governance.  Acquiring the title of CISA identifies the participant as a qualified expert in the field of IT testing and IT control. The certification serves as proof of your ability to conduct IT audits in accordance with globally recognized standards and guidelines to ensure that a company's IT and business systems are appropriately controlled and monitored and thus contribute to the company's success.",
      "link": "https://www.sba-research.org/professional-services/security-training/#cisa-training",
      "language": "German",
      "type_format": "Face-to-face",
      "country": "Austria",
      "timing": "Fixed dates",
      "course_dates": "09/19/2022 – 09/22/2022        09:00 – 17:00",
      "duration": "4 days",
      "content_type": "Theoretical and hands on",
      "price": "€2490 (excl. VAT) per participant",
      "prerequisites": "",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Incident Management",
          "Risk Management",
          "Information Systems and Network Security"
      ],
      "specific_skill": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards"
      ],
      "specific_knowledge": [
          "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)",
          "Knowledge of risk management frameworks",
          "Knowledge of security controls "
      ],
      "other_skills": [
          "Manage and analyse log files",
          "Analyse and consolidate organisation’s quality and risk management practices; Enable business assets owners, executives and other stakeholders to make  risk- informed decisions to manage and mitigate risks; Define and apply  maturity models for cybersecurity management"
      ],
      "other_knowledge": [
          "Knowledge of cybersecurity incident handling methodologies",
          "Advanced knowledge of risk management frameworks, standards, methodologies, tools, guidelines and best practices; Knowledge of technical and organisational controls that appropriately mitigate cybersecurity risks;Knowledge of cybersecurity risks and threats"
      ]
  },
  {
      "training_title": "CyberSecurity Essentials",
      "organizer": "SBA research",
      "short_description": "The aim of the course is to offer participants an introduction to the topics of cyber security and IT and information security. The teaching of well-founded basic knowledge and essential threat scenarios is just as much a part as the discussion of modern solutions and methods for dealing with cyber risks. As a research and consulting center, it is important for SBA Research to offer the participants of our courses independent and objective assessments on various topics. After completing the courses, participants will be able to make qualitative decisions using specific methods and security standards.",
      "link": "https://www.sba-research.org/professional-services/security-training/cybersecurity-essentials/",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Austria",
      "timing": "On demand",
      "course_dates": "",
      "duration": "3 days",
      "content_type": "Theoretical only",
      "price": "€1,960 (excl. VAT) per participant",
      "prerequisites": "",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Systems and Network Security"
      ],
      "specific_skill": [],
      "specific_knowledge": [
          "Knowledge of cybersecurity tactics, techniques and procedures"
      ],
      "other_skills": [],
      "other_knowledge": [
          "Understanding of security-related standards and requirements;Knowledge of computer networks security; Understanding of security-related standards and requirements;Knowledge of cybersecurity tactics, techniques and procedures"
      ]
  },
  {
      "training_title": "Technical Basics and Security of the Blockchain",
      "organizer": "Education4Industry GmbH (University4Industry) in cooperation with Fraunhofer",
      "short_description": "Yyou will learn the technical basics of blockchain technology, and about the security that needs to be considered. You will also learn the difference between anonymity and pseudonymity and how to protect yourself against fraud when dealing with blockchains.",
      "link": "https://www.university4industry.com/skills/technical-basics-and-security-of-the-blockchain",
      "language": "English",
      "type_format": "Online",
      "country": "Germany",
      "timing": "Available online",
      "course_dates": "Not relevant",
      "duration": "up to 2 h. Test 30 min.",
      "content_type": "Theoretical only",
      "price": "350,00 €   excl. VAT",
      "prerequisites": "None",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Privacy",
          "Technology Fluency"
      ],
      "specific_skill": [
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
          "Knowledge of the latest cybersecurity trends"
      ],
      "specific_knowledge": [
          "Knowledge of Privacy-Enhancing Technologies (PET)",
          "Knowledge of research, development and innovation (RDI) relevant to cybersecurity subject matters"
      ],
      "other_skills": [
          "Blockchain specific"
      ],
      "other_knowledge": [
          "Privacy in Blockchains",
          "Understanding Blockchain technology"
      ]
  },
  {
      "training_title": "Capture The Flag",
      "organizer": "Research Institute CODE",
      "short_description": "Learn and evolve your cyber security capabilities. Have Fun, have a great time with friends",
      "link": "https://www.unibw.de/code/events/capture-the-flag-2022-the-spanning-tree-catching-b8tes",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Germany",
      "timing": "Fixed dates",
      "course_dates": "Registration is possible online until 5th of October 2022. The event starts on 25th of November",
      "duration": "NA",
      "content_type": "Hands one",
      "price": "NA",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Problem Solving and Critical Thinking",
          "Incident Management",
          "Information Systems and Network Security",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Work as part of a team and collaborate with colleagues",
          "Provide practical solutions to cybersecurity issues",
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Collect, analyse and correlate cyber threat information originating from multiple sources"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)",
          "Knowledge of offensive and defensive security practices",
          "Knowledge of cybersecurity attacks tactics and techniques"
      ],
      "other_skills": [
          "Analyse and solve complex problems and security challenges, Solve and troubleshoot problems"
      ],
      "other_knowledge": []
  },
  {
      "training_title": "Cyber Incident Handling Workshop",
      "organizer": "Airbus Cybersecurity",
      "short_description": "Table-top game to learn how to deal with cyber incidents from different perspectives.",
      "link": "https://airbus-cyber-security.com/products-and-services/consultancy/cyber-security-exercises/",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Germany",
      "timing": "On demand",
      "course_dates": "",
      "duration": "NA",
      "content_type": "Theoretical only",
      "price": "NA",
      "prerequisites": "Knowledge in Cyber Security on a strategic, organizational, conceptual or technical level",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Incident Management",
          "Risk Management",
          "Business Continuity",
          "Collaborate and Communicate",
          "Policy Development"
      ],
      "specific_skill": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Analyse and consolidate organisation’s quality and risk management practices",
          "Understand core organisational business processes",
          "Collaborate with other team members and colleagues",
          "Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity incident handling practices and tools",
          "Knowledge of cybersecurity risks and threats",
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response,"
      ],
      "other_knowledge": [
          "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)"
      ]
  },
  {
      "training_title": "CyberRange: Advanced Persistent Threats and Targeted Attacks",
      "organizer": "Airbus Cybersecurity",
      "short_description": "Hands-on labs to learn current techniques of APTs and Targeted Attacks",
      "link": "https://airbus-cyber-security.com/products-and-services/consultancy/cyber-security-exercises/",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Germany",
      "timing": "On demand",
      "course_dates": "",
      "duration": "NA",
      "content_type": "Hands one",
      "price": "NA",
      "prerequisites": "Knowledge about networks and operating systems as well as attack vectors",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Business Continuity",
          "Collaborate and Communicate",
          "Incident Management",
          "Risk Management",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Understand core organisational business processes",
          "Work as part of a team and collaborate with colleagues",
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Enable business assets owners, executives and other stakeholders to make risk- informed decisions to manage and mitigate risks",
          "Model threats, actors and TTPs"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity incident handling practices and tools",
          "Knowledge of cybersecurity risks and threats",
          "Knowledge of cyber threats and vulnerabilities"
      ],
      "other_skills": [
          "Document, report present and communicate with various stakeholders"
      ],
      "other_knowledge": []
  },
  {
      "training_title": "Ethical Hacking: Initiation",
      "organizer": "Technobel",
      "short_description": "If today hackers regularly make the headlines, ethical hacking is rarely highlighted. However, it is a discipline essential to the security policies put in place by organizations, which aims to identify network security flaws for the purposes of prevention and anticipation. This three-day training course divided into 18 modules will allow you to understand in detail what ethical hacking is. It will also be possible for you to follow a development module which will take up this course structure in greater depth.",
      "link": "https://www.technobel.be/fr/formations/ETS/voirPlus/64",
      "language": "French",
      "type_format": "Face-to-face",
      "country": "Belgium",
      "timing": "Fixed dates",
      "course_dates": "19/09/2022-22/09/2022, 5/12/2022-07/12/2022, 06/02/2023-08/02/2023, 08/05/2023-10/05/2023, 11/09/2023-13/09/2023",
      "duration": "3 days",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "Knowledge of computer networks, Knowledge of operating systems (client/server)",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Threat Analysis"
      ],
      "specific_skill": [
          "Identify threat actors TTPs and campaigns"
      ],
      "specific_knowledge": [
          "Knowledge of cyber threats and vulnerabilities"
      ],
      "other_skills": [
          "Model threats, actors and TTPs; Collect, analyse and correlate cyber threat information originating from multiple sources"
      ],
      "other_knowledge": [
          "Knowledge of cybersecurity attacks tactics and techniques"
      ]
  },
  {
      "training_title": "Ethical Hacking: Development",
      "organizer": "Technobel",
      "short_description": "A discipline that has become essential among cybersecurity specialists today, ethical hacking aims, above all, to identify flaws and prevent threats before they are carried out. This three-day training will allow you to deepen the subjects seen in the training \"Ethical Hacking: initiation\"",
      "link": "https://www.technobel.be/fr/formations/ETS/voirPlus/75",
      "language": "French",
      "type_format": "Face-to-face",
      "country": "Belgium",
      "timing": "Fixed dates",
      "course_dates": "10/10/2022-12/10/2022, 03/07/2023-05/07/2023, 27/11/2023-29/11/2023",
      "duration": "3 days",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "Follow-up of the \"Ethical Hacking: initiation\" training or equivalent experience",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Threat Analysis"
      ],
      "specific_skill": [
          "Collect, analyse and correlate cyber threat information originating from multiple sources"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity attacks tactics and techniques"
      ],
      "other_skills": [
          "Identify threat actors TTPs and campaigns; Identify non-cyber events with implications on cyber-related activities; Model threats, actors and TTPs"
      ],
      "other_knowledge": [
          "Knowledge of cyber threats and vulnerabilities; Advanced knowledge of cybersecurity attacks tactics and techniques; Knowledge of advanced and persistent cyber threats and threat actors"
      ]
  },
  {
      "training_title": "IT security awareness training platform",
      "organizer": "Cyber Security Academy (CSA)",
      "short_description": "The platform provides training for all company employees to recognize cyber threats and thus enables the strengthening of cyber security culture in the organisation. platform for employees",
      "link": "https://www.responsu.com/",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "N/A",
      "duration": "Up to 4,5 h. Test",
      "content_type": "Theoretical and hands on",
      "price": "N/A",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Privacy",
          "Organizational Awareness",
          "Data Security",
          "Problem Solving and Critical Thinking"
      ],
      "specific_skill": [
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
          "Influence an organisation’s cybersecurity culture",
          "Establish a cybersecurity plan"
      ],
      "specific_knowledge": [
          "Advanced knowledge of data privacy and protection laws and regulations",
          "Understanding of responsible disclosure of cybersecurity-related information"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "ESET Cyber Security Awareness Training",
      "organizer": "ESET Lithuania",
      "short_description": "The training is aimed for company employees (non-IT specialists) who use IT tools in their daily work.",
      "link": "https://www.eset.com/lt/",
      "language": "Lithuanian",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "N/A",
      "duration": "1,5 h, and test 0,5 h.",
      "content_type": "Theoretical and hands on",
      "price": "50 € per person, 700 € per company",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Organizational Awareness",
          "Data Privacy",
          "Data Security",
          "Risk Management",
          "Workforce Management"
      ],
      "specific_skill": [
          "Influence an organisation’s cybersecurity culture",
          "Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties",
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
          "Guide and communicate with implementers and IT/OT personnel "
      ],
      "specific_knowledge": [
          "Knowledge of privacy impact assessment methodologies",
          "Knowledge of information security",
          "Knowledge of risk management frameworks"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "INTRODUCTION TO CYBER SECURITY",
      "organizer": "VILNIUS CODING SCHOOL",
      "short_description": "This cybersecurity training is aimed for anyone who is interested in cybersecurity and would like to work in the field.",
      "link": "https://www.vilniuscoding.lt/mokymai/kibernetinis-saugumas-cyber-security-vilnius-coding-school/",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "2022-09-26, 2022-10-24",
      "duration": "72 h",
      "content_type": "Theoretical and hands on",
      "price": "€1 150,00",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Incident Management",
          "Problem Solving and Critical Thinking",
          "Threat Analysis",
          "Testing and Evaluation"
      ],
      "specific_skill": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Provide practical solutions to cybersecurity issues",
          "Collect, analyse and correlate cyber threat information originating from multiple sources",
          "Identify and exploit vulnerabilities "
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity incident handling methodologies"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Information security training and testing service",
      "organizer": "JSC ATEA",
      "short_description": "The service results in an increased level of information security competence and resilience to cyber-attacks.",
      "link": "https://www.atea.lt/informacijos-saugumo-mokymai-darbuotojams-ar-verta-investuoti/",
      "language": "Lithuanian",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "2022-10-21",
      "duration": "N/A",
      "content_type": "Theoretical and hands on",
      "price": "N/A",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Privacy",
          "Database Administration",
          "Incident Management",
          "Problem Solving and Critical Thinking",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Provide practical solutions to cybersecurity issues",
          "Collect, analyse and correlate cyber threat information originating from multiple sources"
      ],
      "specific_knowledge": [
          "Knowledge of privacy-by-design methodologies",
          "Knowledge of cybersecurity incident handling practices and tools",
          "Knowledge of cybersecurity attacks tactics and techniques"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Cybersecurity training for staff: get ready for the knowledge test!",
      "organizer": "JSC „Mokesčių srautas“",
      "short_description": "A quarter of cybersecurity incidents are caused by human negligence and lack of information. With the rapid development of online scams, attentiveness and vigilance are becoming critical employee qualities that need to be developed.",
      "link": "https://www.countline.lt/renginys/kibernetinio-saugumo-mokymai-darbuotojams/",
      "language": "Lithuanian",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Available online",
      "course_dates": "N/A",
      "duration": "2 h, and test 0,5 h.",
      "content_type": "Theoretical and hands on",
      "price": "N/A",
      "prerequisites": "Not provided",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Security",
          "Data Privacy",
          "Organizational Awareness"
      ],
      "specific_skill": [
          "Workplace security, two-factor authentication;",
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
          "Influence an organisation’s cybersecurity culture"
      ],
      "specific_knowledge": [
          "Knowledge of security technologies and solutions",
          "Knowledge of privacy impact assessment methodologies"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Postgraduate specialist study programme Information Systems Security and Auditing Management (ISSMA)",
      "organizer": "University of Zagreb",
      "short_description": " Information Security Management, Information Security Architecture design, Information Security Auditing",
      "link": "https://www.foi.unizg.hr/en/about-us/psp/spds",
      "language": "Croatian",
      "type_format": "Hybrid",
      "country": "Croatia",
      "timing": "Fixed dates",
      "course_dates": "Fridays Satursdays",
      "duration": "18 months",
      "content_type": "Theoretical and hands on",
      "price": "€4 700,00",
      "prerequisites": "None",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Security Controls Assessment",
          "Threat Analysis",
          "Business Continuity"
      ],
      "specific_skill": [
          "Apply auditing tools and techniques"
      ],
      "specific_knowledge": [
          "Advanced knowledge of auditing frameworks, standards, methodologies and certifications"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Postgraduate specialist study Information security",
      "organizer": "University of Zagreb",
      "short_description": "The program integrates security policy, management and technical aspects of information security, as well as risk management.",
      "link": "https://www.fer.unizg.hr/en/studies/specialist/information_security",
      "language": "Croatian",
      "type_format": "Face-to-face",
      "country": "Croatia",
      "timing": "Fixed dates",
      "course_dates": "n-a",
      "duration": "n-a",
      "content_type": "Theoretical and hands on",
      "price": "n/a",
      "prerequisites": "None",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Risk Management",
          "Information Security Controls Assessment",
          "Data Security",
          "Digital Forensics",
          "Information Systems and Network Security",
          "Operating Systems"
      ],
      "specific_skill": [
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
          "Assess the security and performance of solutions"
      ],
      "specific_knowledge": [
          "Knowledge of risk management frameworks",
          "Knowledge of cybersecurity solutions, technical and organisational controls"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Specialist in procedures and security tools for IT&C system",
      "organizer": "Cyburg",
      "short_description": "The program has a strong orientation towards the protection of sensitive information and the best practices in the field.",
      "link": "https://cyburg.ro/en/specialist-in-proceduri-si-instrumente-de-securitate-a-sistemelor-informatice/",
      "language": "Romanian",
      "type_format": "Face-to-face",
      "country": "Romania",
      "timing": "Fixed dates",
      "course_dates": "Juli 2022",
      "duration": "4 days",
      "content_type": "Theoretical and hands on",
      "price": "n/a",
      "prerequisites": "None",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Operating Systems",
          "Risk Management",
          "Threat Analysis",
          "Data Security"
      ],
      "specific_skill": [],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Executive lab: competencies and psychological tools",
      "organizer": "MRU",
      "short_description": "Module 1. Team concentration and formation (8 academic hours)  Module 2. How to effectively lead yourself and your team? (8 academic hours)  Module 3. Basic aspects of people management for team leaders (8 academic hours)  Module 4. Methods of stress and emotion regulation (8 academic hours) Module 5. Fundamentals of educational leadership (8 academic hours) Module 6. Application of behavioral theories in business (8 academic hours) Module 7. Psychology of work environment in business context",
      "link": "https://www.mokymulab.eu/501-vadovu-labas-kompetencijos-ir-psichologiniai-irankiai-7-moduliu-56-ak-val-trukmes-mokymai-vidurines-grandies-vadovams.htmls",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "LT",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "56 hours",
      "content_type": "Theoretical and hands on",
      "price": "€1 800,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Business Continuity",
          "Strategic Relationship Management"
      ],
      "specific_skill": [
          "Understand core organisational business processes",
          "Communicate, coordinate and cooperate with internal and external stakeholders "
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": " Psychological and organizational aspects of personnel performance management and management",
      "organizer": "MRU",
      "short_description": "The training is intended for heads of institutions and leading employees of various levels, who are striving for the highest personal efficiency, strengthening leadership competencies.",
      "link": "https://www.mokymulab.eu/477-personalo-veiklos-valdymo-bei-vadovavimo-psichologiniai-ir-organizaciniai-aspektai-prof-dr-t-sudnickas-8-akad-val.htmls",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "8 hours",
      "content_type": "Theoretical and hands on",
      "price": "€260,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Business Continuity",
          "Collaborate and Communicate",
          "Strategic Relationship Management",
          "Workforce Management"
      ],
      "specific_skill": [
          "Understand core organisational business processes",
          "Work as part of a team and collaborate with colleagues"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Application of the GDPR: emerging practices, common mistakes  ",
      "organizer": "MRU",
      "short_description": "To provide knowledge about GDPR legal regulation news (as far as the topics mentioned in the program are concerned); present the latest GDPR application practices, mistakes made by data controllers and/or data processors, the position of the State Data Protection Inspectorate on the most important personal data protection issues; Provide examples of good practice in the application of the GDPR.",
      "link": "https://www.mokymulab.eu/458-es-bendrojo-duomenu-apsaugos-reglamento-taikymas-praktika-klaidos-prof-dr-d-stitilis-5-akad-val.htmls",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "5 hours",
      "content_type": "Theoretical and hands on",
      "price": "€300,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Data Privacy",
          "Law, Policy, and Ethics"
      ],
      "specific_skill": [
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
          "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies"
      ],
      "specific_knowledge": [
          "Advanced knowledge of National, EU and international cybersecurity and related privacy standards, legislation, policies and regulations"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Development of a success scenario (planning the development and implementation of the strategy)  ",
      "organizer": "MRU",
      "short_description": "1. What is the situation now? 2. SWOT analysis of the situation: a. What we want to give up. b. What we want to keep. 3. Creating a fantastic future scenario. 4. Creating a realistic scenario. 5. Scenario implementation plan: a. What action will you take? b. Sequence of actions. 6. How will you measure achievement? 7. Celebration of victories.",
      "link": "https://www.mokymulab.eu/387-sekmes-scenarijaus-sukurimas-strategijos-sukurimo-ir-igyvendinimo-planavimas-l-dauciuniene-8-ak-val.htmls",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "8 hours",
      "content_type": "Theoretical and hands on",
      "price": "€260,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Business Continuity",
          "Strategic Relationship Management"
      ],
      "specific_skill": [
          "Understand core organisational business processes",
          "Work in a team and cooperate with different external Subject Matter Experts whenever needed "
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Leadership and organization management ",
      "organizer": "MRU",
      "short_description": "The training is intended for leaders and managers who not only seek to improve their management but also leadership skills, to gather followers for their personal development and growth. Topics: manager versus leader; management styles and leadership; preparing for leadership: what it takes to lead successfully; team leadership: essential skills and tools; transformational leadership: how to inspire employees to perform tasks.",
      "link": "https://www.mokymulab.eu/89-lyderyste-ir-organizaciju-valdymas-dr-r-smith-8-akad-val.htmls",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "8 hours",
      "content_type": "Theoretical and hands on",
      "price": "€260,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Problem Solving and Critical Thinking",
          "Strategic Relationship Management"
      ],
      "specific_skill": [
          "Command, communicate and report",
          "Think creatively and outside the box",
          "Work in a team and cooperate with different external Subject Matter Experts whenever needed "
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Cybersecurity requirements for companies and organizations implementation of requirements in practice",
      "organizer": "MRU",
      "short_description": "Topics: Cyber ​​security threats, trends. What do official authority reports show? Safety consolidation in Lithuania. What do we have for today and what are our future plans? Lithuanian national cyber security strategy, its potential impact on companies and organizations, individual sectors. Lithuanian cyber security legal system. Regulatory trends. Changes to the Cyber ​​Security Act and their impact? What fundamental changes have been made and will be made in the accompanying legal acts? Amendments to the Code of Administrative Offenses. NKSC laws and practical nuances of their implementation. Cyber ​​security obligations for the public and private sector. Requirements of accompanying legal acts, their implementation in practice. Specific duties for cyber security entities. What documents are mandatory for cyber security requirements? Requirements for the content of documents. Cyber ​​incident management. The procedure and deadlines for reporting a cyber incident. Risk assessment using the ARSIS method. Organizational and technical requirements of cyber security, their implementation. Relationship with email information security requirements and personal data security requirements in accordance with the GDPR. Practical aspects of the implementation of organizational and technical security requirements in the private and public sector. How to ensure IT security policy.",
      "link": "https://www.mokymulab.eu/failai/files/Prof.%20dr.%20Darius%20Stitilis_programa%202020-04-24.pdf",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "5 hours",
      "content_type": "Hands one",
      "price": "€300,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Data Privacy",
          "Law, Policy, and Ethics"
      ],
      "specific_skill": [
          "Review and enhance security documents, reports, SLAs and ensure the security objectives"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "The essence of the company's finances and the management model  ",
      "organizer": "MRU",
      "short_description": "Topics: Determination of required internal financing (sales) volumes. Financial resilience reserve Profitability management. Asset management",
      "link": "https://www.mokymulab.eu/144-imones-finansu-esme-ir-valdymo-modelis--prof-dr-g-cernius-4-akad-val.htmls",
      "language": "Lithuanian",
      "type_format": "Face-to-face",
      "country": "Lithuania",
      "timing": "On demand",
      "course_dates": "On demand",
      "duration": "4 hours",
      "content_type": "Theoretical and hands on",
      "price": "€250,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Business Continuity"
      ],
      "specific_skill": [
          "Command, communicate and report"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Fundamental principals of cyber security and risk management",
      "organizer": "MRU",
      "short_description": "To provide knowledge about the risks posed by the application of information technologies in organizations and their impact on the activities carried out. To teach practical processes of situation analysis and assessment of possible risks. To help understand the application of optimal prevention mechanisms, prioritization of resource allocation.",
      "link": "https://stdb.mruni.eu/studiju_dalyko_aprasas.php?id=76095&l=en",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "Autumn, weekly",
      "duration": "48 hours",
      "content_type": "Theoretical and hands on",
      "price": "€1 530,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Privacy",
          "Law, Policy, and Ethics"
      ],
      "specific_skill": [
          "Review and enhance security documents, reports, SLAs and ensure the security objectives"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Organisational Cyber Security Culture",
      "organizer": "MRU",
      "short_description": "To provide knowledge about the impact of the cyber culture of the organization's staff on the cyber security of the organization, to understand the cyber culture program and the implementation processes of cyber culture change, the factors of influence to develop, maintain and measure the organization's cyber culture.",
      "link": "https://stdb.mruni.eu/studiju_dalyko_aprasas.php?id=79410&l=en",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "Autumn, weekly",
      "duration": "32 hours",
      "content_type": "Theoretical and hands on",
      "price": "€370,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Business Continuity"
      ],
      "specific_skill": [
          "Command, communicate and report"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Privacy and Data Protection",
      "organizer": "MRU",
      "short_description": "Aim of the subject is introduce students to the privacy and personal data protection, also related legal regulation",
      "link": "https://stdb.mruni.eu/studiju_dalyko_aprasas.php?id=76090&l=en",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "Spring, weekly",
      "duration": "48 hours",
      "content_type": "Theoretical and hands on",
      "price": "€1 530,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Data Privacy",
          "Law, Policy, and Ethics"
      ],
      "specific_skill": [
          "Review and enhance security documents, reports, SLAs and ensure the security objectives"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Legal Environment of Cyber Security",
      "organizer": "MRU",
      "short_description": "To put high quality lawyers, having knowledge of new technology principles, development trends and legal regulation in the area of information security and data protection.",
      "link": "https://stdb.mruni.eu/studiju_dalyko_aprasas.php?id=79148&l=en",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Lithuania",
      "timing": "Fixed dates",
      "course_dates": "Autumn, weekly",
      "duration": "48 hours",
      "content_type": "Theoretical and hands on",
      "price": "€1 530,00",
      "prerequisites": "Non",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Business Continuity"
      ],
      "specific_skill": [
          "Command, communicate and report"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Cybersecurity Overview for IT Administrators",
      "organizer": "Masaryk University",
      "short_description": "The course is designed to give IT administrators insight into activities related to responding to cybersecurity threats and incidents. It introduces the role of a CSIRT team in an organization and describes its services and the steps taken to resolve a security incident. The attendee will learn concepts of infrastructure protection on the network and system level and become familiar with network segmentation, firewalls, and end-host protection. The course also provides insight into the phases of a penetration testing process (from interactions with clients to actual testing to reporting) and investigates network and system forensics incidents.",
      "link": "https://bootcamp.nc3.cz/courses/course-v1:Masaryk_University+Cybersecurity_Overview+2022/about",
      "language": "English",
      "type_format": "Online",
      "country": "Czechia",
      "timing": "On demand",
      "course_dates": "",
      "duration": "25 hours",
      "content_type": "Theoretical and hands on",
      "price": "",
      "prerequisites": "Knowledge of computer networking concepts and protocols, and network security methodologies; knowledge of the standard networking and routing protocols (e.g., TCP/IP), services (e.g., web, mail, DNS), and how they interact to provide network communications; basic knowledge of operating system administration; basic understanding of principles of operating systems and their architectures and knowledge of command line and scripting.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Systems and Network Security",
          "Data Security",
          "Data Privacy",
          "Operating Systems",
          "Network Management",
          "System Administration",
          "Incident Management"
      ],
      "specific_skill": [
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
          "Work on operating systems, servers, clouds and relevant infrastructures",
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response"
      ],
      "specific_knowledge": [
          "Understanding of security-related standards and requirements",
          "Knowledge of information security",
          "Knowledge of privacy-by-design methodologies",
          "Knowledge of operating systems internals, networking protocols and services"
      ],
      "other_skills": [],
      "other_knowledge": [
          "Knowledge of computer networks security"
      ]
  },
  {
      "training_title": "Cybersecurity Exercise",
      "organizer": "Masaryk University",
      "short_description": "Our exercise is focused on practicing cooperation and communication in resolving a cyber security incident with training in the necessary technical, procedural, and legal skills. Participants will undergo training under the guidance of lecturers with hands-on activities to help them understand the issue. Later, they will play the role of a cybersecurity team trying to detect, classify and resolve a cybersecurity incident together with gathering evidence and sharing relevant information with all involved parties. ",
      "link": "",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Czechia",
      "timing": "On demand",
      "course_dates": "",
      "duration": "8-40 hours",
      "content_type": "Theoretical and hands on",
      "price": "21000-41000 per event (without Vat)",
      "prerequisites": "Knowledge of computer networking concepts and protocols, and network security methodologies; knowledge of the standard networking and routing protocols (e.g., TCP/IP), services (e.g., web, mail, DNS), and how they interact to provide network communications; basic knowledge of operating system administration; basic understanding of principles of operating systems and their architectures and knowledge of command line and scripting.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Incident Management",
          "Threat Analysis",
          "Data Security",
          "Digital Forensics",
          "Information Systems and Network Security",
          "Data Privacy"
      ],
      "specific_skill": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
          "Collect, analyse and correlate cyber threat information originating from multiple sources",
          "Collect information while preserving its integrity",
          "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy"
      ],
      "specific_knowledge": [
          "Advanced knowledge of data privacy and protection laws and regulations"
      ],
      "other_skills": [
          "Manage and analyse log files "
      ],
      "other_knowledge": []
  },
  {
      "training_title": "Cybersecurity Education for universities",
      "organizer": "Masaryk University (Enhancement of cyber security level and awareness in the environment of public universities in the Czech Republic (ROZV/C12/2021))",
      "short_description": "The training course is divided into five modules that introduce the user to security principles and threats that can be encountered in cyberspace. In the course, the user will gain knowledge related to device security, electronic communication, and passwords or learn what types of attacks can be encountered. At the end of each module, it is possible to take a test to verify the knowledge.",
      "link": "https://www.crp-kyber.cz/crp-kyber21#Vystupy21",
      "language": "Czech English",
      "type_format": "Online",
      "country": "Czechia",
      "timing": "Available online",
      "course_dates": "",
      "duration": "30 hours",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "The course is intended for all users who want to learn basic security principles and protect themselves in cyberspace.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Education and Training Delivery"
      ],
      "specific_skill": [
          "Motivate and incentivise learners"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Cybersecurity training for universities",
      "organizer": "Masaryk University (Enhancement of cyber security level and awareness in the environment of public universities in the Czech Republic (ROZV/C12/2021))",
      "short_description": "The training for university users (staff) aims to introduce basic safety principles. Due to staff workload, the course is designed to convey these basics in a concise format.",
      "link": "https://www.crp-kyber.cz/crp-kyber21#Vystupy21",
      "language": "Czech English",
      "type_format": "Online",
      "country": "Czechia",
      "timing": "Available online",
      "course_dates": "",
      "duration": "8-10 hours",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "The course is intended for all users who want to learn basic security principles and protect themselves in cyberspace.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Education and Training Delivery"
      ],
      "specific_skill": [
          "Motivate and incentivise learners"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Stories of social engineering",
      "organizer": "Masaryk University",
      "short_description": "The course introduces users to basic social engineering techniques using fictional stories. There are characters from the university environment whom the attacker tries to deceive through these methods of attacks. At the end of each story, there is a brief description of how to defend against a particular technique.",
      "link": "https://security.muni.cz/socialni_inzenyrstvi",
      "language": "Czech English",
      "type_format": "Online",
      "country": "Czechia",
      "timing": "Available online",
      "course_dates": "",
      "duration": "8-10 hour",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "This course is for users who already have a basic understanding of security threats but want to expand their knowledge to include new types of attacks. ",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Education and Training Delivery",
          "Law, Policy, and Ethics"
      ],
      "specific_skill": [
          "Motivate and incentivise learners",
          "Practice ethical cybersecurity organisation requirements"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity and privacy standards, frameworks, policies, regulations, legislations, certifications and best practices"
      ],
      "other_skills": [
          "Practice ethical cybersecurity organisation requirements"
      ],
      "other_knowledge": [
          "Understanding of ethical cybersecurity organisation requirements"
      ]
  },
  {
      "training_title": "Cyber Compass ",
      "organizer": "Masaryk University (Information Security Awareness on MU (ROZV/05/2019))",
      "short_description": "The course includes a range of essential information and instructions that guide users to acquire safe habits in work and personal environments. It covers an area of secure passwords, device security, secure communication and incident reporting. The final extra lesson summarizes the most critical points.",
      "link": "https://security.muni.cz/en/cybercompass",
      "language": "Czech English",
      "type_format": "Online",
      "country": "Czechia",
      "timing": "Available online",
      "course_dates": "",
      "duration": "20 hour",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "The course is intended for all users who want to learn basic security principles and protect themselves in cyberspace.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Education and Training Delivery"
      ],
      "specific_skill": [
          "Motivate and incentivise learners"
      ],
      "specific_knowledge": [],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Internet Attacks and Defenses",
      "organizer": "University of Twente",
      "short_description": "MOOC to discuss how to detect and mitigate Internet attacks. Topics include DDoS, IDS and Firewalls",
      "link": "https://learn.dacs.utwente.nl/courses/course-v1:UTwente+201700074B+2021_Q3/about",
      "language": "English",
      "type_format": "Online",
      "country": "Netherlands",
      "timing": "Fixed dates",
      "course_dates": "Feb-May",
      "duration": "",
      "content_type": "Theoretical and hands on",
      "price": "",
      "prerequisites": "Programming, OS (Linux)",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Information Systems and Network Security"
      ],
      "specific_skill": [],
      "specific_knowledge": [
          "Knowledge of computer networks security"
      ],
      "other_skills": [
          "Intrusion Detection;Firewalling"
      ],
      "other_knowledge": [
          "Knowledge of offensive and defensive security practices"
      ]
  },
  {
      "training_title": "Transits II",
      "organizer": "SURFNet",
      "short_description": "Training for new and experienced computer security incident response team (CSIRT) personnel, and individuals interested in establishing a CSIRT",
      "link": "https://www.surf.nl/en/agenda/training-transits-2-fully-booked",
      "language": "Dutch",
      "type_format": "Face-to-face",
      "country": "Netherlands",
      "timing": "Fixed dates",
      "course_dates": "September",
      "duration": "3 days",
      "content_type": "Theoretical only",
      "price": "€500,00",
      "prerequisites": "None",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Incident Management"
      ],
      "specific_skill": [
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response"
      ],
      "specific_knowledge": [
          "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Telecom Nancy Capture the Flag",
      "organizer": "Telecom Nancy",
      "short_description": "This Capture The Flag (CTF) event is a 40-level challenge taking place on the cyber-security platform of TELECOM Nancy (University of Lorraine) on two full days.",
      "link": "https://telecomnancy.univ-lorraine.fr/2022/01/13/cybersecurite/",
      "language": "French",
      "type_format": "Face-to-face",
      "country": "France",
      "timing": "Fixed dates",
      "course_dates": "January",
      "duration": "2 days",
      "content_type": "Hands one",
      "price": "",
      "prerequisites": "Cybersecurity",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Information Systems and Network Security",
          "Testing and Evaluation"
      ],
      "specific_skill": [
          "Conduct ethical hacking"
      ],
      "specific_knowledge": [
          "Knowledge of offensive and defensive security practices"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Cyber-Security for protection of classified information",
      "organizer": "INTENSEC RO SRL",
      "short_description": "Learn why cyber-security is important in the process of protection of classified information and how to protect classified information from the perspective of INFOSEC (security of electronic classified information).",
      "link": "https://intensec.ro/index.php/cybertraining/",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Germany",
      "timing": "On demand",
      "course_dates": "N/A",
      "duration": "3 days",
      "content_type": "Theoretical and hands on",
      "price": "",
      "prerequisites": "Recommendation from the employer.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "",
      "skills_group": [
          "Incident Management",
          "Data Privacy",
          "Information Systems and Network Security"
      ],
      "specific_skill": [
          "Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity incident handling methodologies",
          "Advanced knowledge of data privacy and protection laws and regulations",
          "Knowledge of computer networks security"
      ],
      "other_skills": [
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
          "INFOSEC tools"
      ],
      "other_knowledge": [
          "Protection of classified information; Advanced knowledge of National, EU and international cybersecurity and related privacy standards, legislation, policies and regulations",
          "VPN, IPSec, DMZ, antivirus, Firewall, SSI"
      ]
  },
  {
      "training_title": "Embedded Security Engineering",
      "organizer": "Fraunhofer Academy",
      "short_description": "The participants will become familiarized with a development process for embedded systems, both theoretically and practically, based on a specific application case.",
      "link": "https://www.academy.fraunhofer.de/en/continuing-education/information-communication/cybersecurity/embedded-security-engineering.html",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Germany",
      "timing": "On demand",
      "course_dates": "N/A",
      "duration": "2 days",
      "content_type": "Theoretical and hands on",
      "price": "€1 200,00",
      "prerequisites": "Good understanding of technical systems, ideally in the field of embedded systems",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Operating Systems",
          "Data Security",
          "Digital Forensics",
          "Information Systems and Network Security",
          "Threat Analysis",
          "Physical Device Security"
      ],
      "specific_skill": [
          "Work on operating systems, servers, clouds and relevant infrastructures",
          "Model threats, actors and TTPs"
      ],
      "specific_knowledge": [
          "Knowledge of operating systems internals, networking protocols and services",
          "Knowledge of information security",
          "Knowledge of digital forensics analysis techniques",
          "Knowledge of computer networks security"
      ],
      "other_skills": [
          "embedded systems security; hardware security"
      ],
      "other_knowledge": [
          "Knowledge of operating systems security",
          "TPM 2.0"
      ]
  },
  {
      "training_title": "CyberHOT Summer School",
      "organizer": "University of Piraeus & Technical University of Crete",
      "short_description": "Hands-on cybersecurity training on, (1) threat and attack monitoring, (2) risk assessment, (3) security management, (4) technical vulnerability assessment, and (5) digital forensics.",
      "link": "https://www.cyberhot.eu/home",
      "language": "English",
      "type_format": "Face-to-face",
      "country": "Greece",
      "timing": "Fixed dates",
      "course_dates": "29-30th September 2022",
      "duration": "2 days",
      "content_type": "Hands one",
      "price": "€490-600.00",
      "prerequisites": "MetasploitNmapBurp-ProxyOWASP-Top 10Basic Linux Knowledge-BashSQLPHP",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Threat Analysis",
          "Information Systems and Network Security",
          "Testing and Evaluation",
          "Enterprise Architecture and Infrastructure Design",
          "Software Development",
          "Technology Fluency",
          "Problem Solving and Critical Thinking",
          "Policy Development"
      ],
      "specific_skill": [
          "Use penetration testing tools effectively",
          "Conduct user and business requirements analysis",
          "Knowledge of the latest cybersecurity trends",
          "Provide practical solutions to cybersecurity issues",
          "Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing"
      ],
      "specific_knowledge": [
          "Knowledge of cybersecurity attacks tactics and techniques",
          "Knowledge of cybersecurity tactics, techniques and procedures",
          "Knowledge of test methodologies and practices",
          "Knowledge of scripting and programming languages",
          "Knowledge of best practices on cybersecurity"
      ],
      "other_skills": [
          "Identify and exploit vulnerabilities",
          "Establish a cybersecurity plan"
      ],
      "other_knowledge": [
          "Knowledge of cyber threats and vulnerabilities",
          "Knowledge of offensive and defensive security practices"
      ]
  },
  {
      "training_title": "Web Security",
      "organizer": "Malardalen University Sweden",
      "short_description": "The purpose of this course is to identify, analyse and experiment with the most common web application and web service vulnerabilities and different methods for avoiding them.",
      "link": "https://www.mdu.se/en/malardalen-university/education/courses?kod=DVA489",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Sweden",
      "timing": "Fixed dates",
      "course_dates": "Autumn Semester 2022 (29/08/2022-15/01/2023)",
      "duration": "7.5 credits (so should be around 225 hours)",
      "content_type": "Theoretical and hands on",
      "price": "          1600 EUR (16 875 SEK, 7.5 credit course tuition fees)",
      "prerequisites": "120 credits of which at least 80 credits in technology or informatics, including at least 30 credits in programming or software development. In addition, English course A/English course 6 is required.",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Software Development",
          "Testing and Evaluation"
      ],
      "specific_skill": [
          "Develop codes, scripts and programmes",
          "Identify and exploit vulnerabilities "
      ],
      "specific_knowledge": [
          "Knowledge of programming languages",
          "Advanced knowledge of cybersecurity attack vectors"
      ],
      "other_skills": [
          "Develop and test secure code and scripts"
      ],
      "other_knowledge": [
          "Knowledge of secure coding practices"
      ]
  },
  {
      "training_title": "Securing Critical Infrastructures in the Financial Sector",
      "organizer": "H2020 FINSEC Project",
      "short_description": "The purpose of this course is to understand the main security challenges faced nowadays by financial institutions, to learn about the regulations, guidelines and standards that apply in the finance sector and their impact for financial technologies as well as  new technologies and security challenges raised for finance sectors, to gain insights on physical security and cyber security solutions for the critical infrastructures of this sector, and understand the concept of integrated security that protects both cyber and physical assets.",
      "link": "https://finsecurity.eu/moodle/course/view.php?id=3",
      "language": "English",
      "type_format": "Online",
      "country": "Cyprus",
      "timing": "Available online",
      "course_dates": "No specific dates, the content is available online in open access.",
      "duration": "The online content is structured in 4 parts, with a total of 11 sections (no specific duration is indicated, but it should take around 3-5 days depending on the level of expertise of the learner.)",
      "content_type": "Theoretical only",
      "price": "Content currently provided in open access.",
      "prerequisites": "Basic understanding of financial sector and cybersecurity",
      "can_lead_to_certification": "No",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Enterprise Architecture and Infrastructure Design",
          "Risk Management",
          "Threat Analysis",
          "Data Analysis",
          "Law, Policy, and Ethics",
          "Physical Device Security"
      ],
      "specific_skill": [
          "Integrate cybersecurity solutions to the organisation’s infrastructure",
          "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
          "Collect, analyse and correlate cyber threat information originating from multiple sources",
          "Analyse and implement cybersecurity standards, frameworks, policies, regulations, legislations, certifications and best practices"
      ],
      "specific_knowledge": [
          "Understanding of cybersecurity-related standards and compliance requirements",
          "Knowledge of cybersecurity risks and threats",
          "Knowledge of cyber threats and vulnerabilities",
          "Knowledge of statistics and forecasting methodologies",
          "Knowledge of cybersecurity-related legal framework, regulations, standards"
      ],
      "other_skills": [],
      "other_knowledge": [
          "Knowledge on cyber-physical infrastructures and their vulnerabilities"
      ]
  },
  {
      "training_title": "Cyber Humanum Est",
      "organizer": "Defense Base of Nancy / Lorraine INP / COMCYBER / Ministry of Armed Forces / University of Lorraine",
      "short_description": "Produced with the support of the cyber defense operational reserve and university staff, this Cyber War Game is played simultaneously on three separate physical sites in Nancy, France. It involves the mobilization of more than 110 people (organizers and participants), and relies two cyber-range platforms: the one of the Nancy defense base and the one of the TELECOM Nancy engineering school. The exercise features three fictitious countries with embassies, tactical command posts, OIVs (Organisms of Vital Importance), newspapers, the APT54 group of attackers and even spies.",
      "link": "https://cyberhumanumest.com/",
      "language": "French",
      "type_format": "Face-to-face",
      "country": "France",
      "timing": "Fixed dates",
      "course_dates": "06/02/2023-09/02/2023",
      "duration": "4 days and 1 night",
      "content_type": "Hands one",
      "price": "Not applicable",
      "prerequisites": "Computer science background (programming, networking, systems). The exercice typically mix students from different engineering and bachelor curricula.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Project Management",
          "Workforce Management",
          "Collaborate and Communicate",
          "Information Systems and Network Security",
          "Testing and Evaluation",
          "Information Security Controls Assessment",
          "Incident Management"
      ],
      "specific_skill": [
          "Develop, champion and lead the execution of a cybersecurity strategy",
          "Work under pressure",
          "Collaborate with other team members and colleagues",
          "Identify and exploit vulnerabilities",
          "Apply auditing tools and techniques",
          "Practice all technical, functional and operational aspects of cybersecurity incident handling and response"
      ],
      "specific_knowledge": [
          "Knowledge of resource management",
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of offensive and defensive security practices",
          "Advanced knowledge of cybersecurity attack vectors",
          "Knowledge of cybersecurity solutions, technical and organisational controls",
          "Knowledge of cybersecurity incident handling practices and tools"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "Security Management",
      "organizer": "University of Lorraine / TELECOM Nancy",
      "short_description": "Security management is a crucial activity for companies and organizations, in order to prevent cyber attacks and provide adequate responses to security incidents. This course gives to Master-level students an introduction to methods and tools related to security management in an integrated manner, through concrete examples, that are analyzed over the cyber-range training platform of TELECOM Nancy, France. It permits to overview the functioning of a SOC (Security Operating Center), to understand the key elements of a security policy, and to analyze the different steps of a cyber kill chain with respect to this policy. The concepts covered in the course are also addressed in a practical manner over the cyber-range, with the case of APT1-oriented attacks, considering the network, system and application components of an IT infrastructure.",
      "link": "https://telecomnancy.univ-lorraine.fr",
      "language": "French",
      "type_format": "Face-to-face",
      "country": "France",
      "timing": "Fixed dates",
      "course_dates": "28/11/2023-02/12/2023",
      "duration": "5 days",
      "content_type": "Theoretical and hands on",
      "price": "1500 EUR",
      "prerequisites": "Computer science background (programming, networking, systems)",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Project Management",
          "Threat Analysis",
          "Network Management",
          "Collaborate and Communicate",
          "Enterprise Architecture and Infrastructure Design"
      ],
      "specific_skill": [
          "Develop, champion and lead the execution of a cybersecurity strategy",
          "Collect, analyse and correlate cyber threat information originating from multiple sources",
          "Collaborate with other team members and colleagues",
          "Select appropriate specifications, procedures and controls"
      ],
      "specific_knowledge": [
          "Knowledge of management practices",
          "Knowledge of cybersecurity attacks tactics and techniques",
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of security architecture reference models and security solutions"
      ],
      "other_skills": [
          "Design and implementation of a security policy",
          "Using and parametering security monitoring and configuration tools in an integrated manner",
          "Build resilience against points of failure across the architecture"
      ],
      "other_knowledge": [
          "Knowledge on security policy specifications",
          "Knowledge on the functioning of a security operations center (SOC)",
          "Knowledge on security management"
      ]
  },
  {
      "training_title": "Data Security Fundamentals (FUNDAMENTALS OF DATA SECURITY in the official website)",
      "organizer": "University of Insubria (Università degli Studi dell’Insubria)",
      "short_description": "Basic knowledge for the design and verification of mechanisms for data protection in information systems and networks",
      "link": "https://www.uninsubria.eu/ugov/degreecourse/117153#0",
      "language": "Italian",
      "type_format": "Face-to-face",
      "country": "Italy",
      "timing": "Fixed dates",
      "course_dates": "Feb 2023 - May 2023",
      "duration": "1 semester (48 hours of lessons and a total workload of 150-180 hours in the semester - 6 ECTS)",
      "content_type": "Theoretical only",
      "price": "",
      "prerequisites": "None",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Threat Analysis",
          "Data Security",
          "Information Systems and Network Security",
          "Data Privacy",
          "Problem Solving and Critical Thinking"
      ],
      "specific_skill": [
          "Communicate and report",
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
          "Solve and troubleshoot problems"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Knowledge of cyber threats and vulnerabilities",
          "Understanding of responsible disclosure of cybersecurity-related information",
          "Understanding of security-related standards and requirements",
          "Knowledge of privacy-by-design methodologies"
      ],
      "other_skills": [],
      "other_knowledge": [
          "Knowledge of information security "
      ]
  },
  {
      "training_title": "CriptoCert Certified Crypto Analyst",
      "organizer": "CriptoCert",
      "short_description": "Technical training to prepare and obtain CriptoCert’s Certified Crypto Analysis professional certification, focused in improving the candidate’s cryptography and data protection skills and knowledge.",
      "link": "https://www.criptocert.com/CriptoCert_Analyst.html",
      "language": "Spanish",
      "type_format": "Online",
      "country": "Spain",
      "timing": "Available online",
      "course_dates": "",
      "duration": "80 study hours + 2 exam hours",
      "content_type": "Theoretical only",
      "price": "590 € + IVA (spanish taxes)",
      "prerequisites": "Technology and/or Engineering foundations.   Recommended entry profiles: - Students of technological courses and masters - University students of technical careers - State security forces and bodies - Computer security specialists - Managers and directors in charge of data protection - Crypto Solution Architects - Specialists in cryptocurrencies and blockchain - Programmers and developers of applications and software hardware engineers - Information technology and communications professionals",
      "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
      "includes_exams_for_certification": "Yes",
      "skills_group": [
          "Threat Analysis",
          "Data Security",
          "Information Systems and Network Security",
          "Data Privacy"
      ],
      "specific_skill": [
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users"
      ],
      "specific_knowledge": [
          "Knowledge of cyber threats and vulnerabilities",
          "Understanding of responsible disclosure of cybersecurity-related information",
          "Understanding of security-related standards and requirements",
          "Knowledge of Privacy-Enhancing Technologies (PET)"
      ],
      "other_skills": [],
      "other_knowledge": []
  },
  {
      "training_title": "NECS PhD Winter School",
      "organizer": "ORGANIZERS - CONCORDIA project - SPARTA project - ECHO project - CyberSec4Europe project  OTHERS SUPPORTING: - Università Di Trento - NECS - National Research Council of Italy - Cyber Security National Lab (Italy)",
      "short_description": "The scope of the NECS PhD Winter School is to present advances on both attacks and defenses in the realm of cybersecurity. The School is a collaboration of the four Cybersecurity Competence Networks: CONCORDIA, SPARTA, ECHO and CyberSec4Europe. It is an opportunity for the four pilots to presents to young researchers, and most importantly, to put in practice selected innovation and results.",
      "link": "https://necs-winterschool.disi.unitn.it/",
      "language": "English",
      "type_format": "Hybrid",
      "country": "Italy",
      "timing": "Fixed dates",
      "course_dates": "17/01/2022 - 21/01/2022",
      "duration": "5 days (34 hours aprox.)",
      "content_type": "Theoretical and hands on",
      "price": "500 € (physical attendance), 200 € (online)",
      "prerequisites": "Being a PhD student",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Information Systems and Network Security",
          "Law, Policy, and Ethics",
          "Threat Analysis"
      ],
      "specific_skill": [
          "Communicate and disseminate the scientific outcomes",
          "Practice ethical cybersecurity organisation requirements"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Understanding of security-related standards and requirements",
          "Understanding of ethical cybersecurity organisation requirements",
          "Knowledge of cyber threats and vulnerabilities"
      ],
      "other_skills": [
          "Communicate or author publications, reports, training material"
      ],
      "other_knowledge": []
  },
  {
      "training_title": "DATA PROTECTION",
      "organizer": "University of Maribor (Univerza v Mariboru)",
      "short_description": "The objective of this course is for students to be able to demonstrate understanding of data protection approaches, procedures, and algorithms to analyse individual components and solutions, and to implement and deploy protection measures.",
      "link": "https://aips.um.si/PredmetiBP5/UcnaEnotaInfo.asp?UEID=20927&Leto=2021&Jezik=A",
      "language": "Slovenian",
      "type_format": "Face-to-face",
      "country": "Slovenia",
      "timing": "Fixed dates",
      "course_dates": "01/10/2022 - 21/01/2023",
      "duration": "1 semester (45 hours of lessons, 30 hours of computer work and 105 hours of individual student's work - 6 ECTS)",
      "content_type": "Theoretical and hands on",
      "price": "",
      "prerequisites": "Recommended knowledge / understanding of informatics, information systems, computer programming and basics of cybersecurity.",
      "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
      "includes_exams_for_certification": "No",
      "skills_group": [
          "Collaborate and Communicate",
          "Data Security",
          "Data Privacy",
          "Problem Solving and Critical Thinking"
      ],
      "specific_skill": [
          "Report, communicate and present to stakeholders",
          "Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties",
          "Provide practical solutions to cybersecurity issues"
      ],
      "specific_knowledge": [
          "Understanding of the multidiscipline aspect of cybersecurity",
          "Understanding of responsible disclosure of cybersecurity-related information",
          "Knowledge of Privacy-Enhancing Technologies (PET)"
      ],
      "other_skills": [
          "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
          "Generate new ideas and transfer theory into practice"
      ],
      "other_knowledge": [
          "Knowledge of privacy-by-design methodologies"
      ]
  }
]

export default skillsInTrainings
