import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Modal, Form, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons';


const ForgotPassModal = ({ visible, onModalConfirm, onCancel, showMessage }) => {
  const [forgotPasswordModalLoading, setForgotPasswordModalLoading] = React.useState(false)
  const [form] = Form.useForm()

  return (
    <Modal
      centered
      open={visible}
      width={400}
      title="Forgot password"
      okText="OK"
      confirmLoading={forgotPasswordModalLoading}
      cancelText="Cancel"
      onCancel={onCancel}
      className="forgot-modal"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setForgotPasswordModalLoading(true)

            Axios.post( Config.server.forgotPassword, values, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                setForgotPasswordModalLoading(false)
                try {
                  if (response && response.data.error === false){
                    console.log(response.data.email.message)
                    if (response.data.email.error === false){
                      form.resetFields()
                      showMessage({type: 'success', content: "New password has been sent to your e-mail."})
                      onModalConfirm()
                    } else {
                      showMessage({type: 'error', content: "We are sorry. Something went wrong."})
                    }
                  } else {
                    console.log("error: " + response.data.message)
                    showMessage({type: 'error', content: response.data.message && response.data.message.includes("Wrong e-mail") ? response.data.message : "Something went wrong!"})
                  }
                } catch (e) {
                  console.log(e)
                  showMessage({type: 'error', content: "Something went wrong!"})
                }
              })
              .catch((error) => {
                setForgotPasswordModalLoading(false)
                console.log(error)
                showMessage({type: 'error', content: "Something went wrong!"})
              })

          })
      }}
    >
      <Form form={form} className="loginForm" layout="horizontal" name="forgot_password_modal" >
        <Form.Item>
          Please enter your account e-mail (login).
        </Form.Item>
        <Form.Item name="email" rules={[{type: 'email', required: true, message: 'Please fill in your e-mail address!'}]}>
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail address" />
        </Form.Item>
        <Form.Item>
          A new password will be sent to you.
        </Form.Item>
      </Form>

    </Modal>
  )
}

export default ForgotPassModal
