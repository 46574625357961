const topics = [

  // Computer Science
  { name: "Computer Systems", areaId: 0, comps: [ 12, 30, 40, 13, 43, 22, 7, 23 ] },
  { name: "Industrial Applications", areaId: 0, comps: [23] },
  { name: "Communication Theory", areaId: 0, comps: [ 45, 49 ] },
  { name: "Computer Networks", areaId: 0, comps: [ 29, 23 ] },
  { name: "Quantum Computing", areaId: 0, comps: [] },
  { name: "Theoretical Computer Science", areaId: 0, comps: [] },
  { name: "Software Engineering", areaId: 0, comps: [ 5, 23, 38, 41, 49 ] },

  // Cryptology
  { name: "Advanced Cryptology", areaId: 1, comps: [ 14 ] },
  { name: "Cryptanalysis", areaId: 1, comps: [ 14 ] },
  { name: "Fundamental Cryptology", areaId: 1, comps: [ 17, 14, 19 ] },
  { name: "Post-quantum Cryptography", areaId: 1, comps: [] },

  //Security
  { name: "Hardware and Software Security", areaId: 2, comps: [ 39, 21 ] },
  { name: "Network Security", areaId: 2, comps: [ 21, 49, 6 ] },
  { name: "Security Systems", areaId: 2, comps: [ 7, 18, 0 ] },
  { name: "System Security", areaId: 2, comps: [ 42 ] },
  { name: "Incident Response", areaId: 2, comps: [ 4, 18 ] },

  // Mathematics
  { name: "Algebra and Discrete Mathematics", areaId: 3, comps: [ 27 ] },
  { name: "Complexity Theory", areaId: 3, comps: [] },
  { name: "Number Theory", areaId: 3, comps: [] },
  { name: "Probability and Statistics", areaId: 3, comps: [ 9, 28 ] },
  { name: "Topology and Analysis", areaId: 3, comps: [] },

  // Privacy
  { name: "Data Extraction", areaId: 4, comps: [ 9 ] },
  { name: "Data Privacy", areaId: 4, comps: [ 11 ] },
  { name: "Privacy-Enhancing Technologies", areaId: 4, comps: [ 11, 17 ] },

  // Humanistic and Soc. Science
  { name: "Human Aspects of Security and Privacy", areaId: 5, comps: [ 2, 25, 17 ] },
  { name: "Security Architecture", areaId: 5, comps: [ 15, 16 ] },
  { name: "Cybercrime", areaId: 5, comps: [ 4 ] },
  { name: "Law and Regulations", areaId: 5, comps: [ 32, 26, 8 ] },
  { name: "Security Management and Risk Analysis", areaId: 5, comps: [ 10, 44, 33, 3, 34, 20, 24, 31, 37, 35, 48, 47, 36, 2, 1, 0, 46 ] },

  { name: "Other", areaId: 6, comps: [] }
]


export default topics
