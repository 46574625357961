import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Form, Input, Modal, Select } from 'antd'

const AddProfileModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles }) => {
  const [modalLoading, setModalLoading] = React.useState(false)
  const [form] = Form.useForm()

  function nameExists(value) {
    return rsProfiles.find(e => e.name === value) ? true : false
  }

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord === "new"}
      title="Add a new profile"
      width={850}
      okText="Add"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={()=>{
        form.resetFields()
        onCancel()
      }}
      className="add-job-modal"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setModalLoading(true)
            const reqData = {
              name: values.name,
              skills_group: values.skills_group ? values.skills_group : []
            }

            Axios.post( Config.server.addProfile, {...authData, ...reqData}, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                setModalLoading(false)
                if (response && response.data.error === false){
                  form.resetFields()
                  showMessage({type: 'success', content: "New job successfully added."})
                  refresh()
                } else {
                  console.log("error: " + response.data.message)
                  showMessage({type: 'error', content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!"})
                }
              })
              .catch((error) => {
                setModalLoading(false)
                console.log(error)
                showMessage({type: 'error', content: "Something went wrong!"})
              })

          })
      }}
    >

      <Form form={form} layout="horizontal" name="add_job_modal" labelCol={{ span: 7 }} wrapperCol={{ span: 15}} labelWrap colon={false} className="content-scroll">

        <Form.Item name="name" label="Name" rules={[
          {required: true, message: 'Please fill in the name!'},
          {type: 'string', min: 2, max: 1023 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || !nameExists(value)) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('This profile is already stored in the database!'))
            }
          })
        ]}>
          <Input />
        </Form.Item>

        <Form.Item name="skills_group" label="Skills Group" rules={[{type: 'array', max: 255 }]}>
          <Select showSearch showArrow allowClear mode="multiple" optionFilterProp={"label"} options={
            rsSkillsGroup
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            .map(skill => ({value: skill.id, label: skill.name}))
          }>
          </Select>
        </Form.Item>

      </Form>

    </Modal>
  )
}

export default AddProfileModalForm
