import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Certification from './Certification'

const CertificationsList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  //border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
`

class ListOfCertifications extends React.Component {

  render(){

    const printDroppable = (column) => {
      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={false}
        >
          {(provided, snapshot) => (
            <CertificationsList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.certificationIds.map((certification, index) => {
                return <Certification key={this.props.certifications[certification].id} certification={this.props.certifications[certification]} index={index} removeCertification={this.props.removeCertification} openAddModal={this.props.openAddModal}   columnId={column.id}/>
              })}
              {provided.placeholder}
            </CertificationsList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="list-of-courses">
        <h3 className="section-title"> Available Certifications</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <Button variant="outline-primary" onClick={()=> this.props.openAddModal(null)}>Add certification</Button>
              <Button variant="outline-primary" onClick={()=> this.props.openBrowseModal(null)}>Browse available certifications</Button>
              <Button variant="outline-secondary" onClick={this.props.loadSamples} disabled={this.props.sampleCertificationsLoaded}>{this.props.sampleCertificationsLoaded ? 'Sample certifications loaded' : 'Load sample certifications'}</Button>
            </div>

            <Container >
              <Row>
                <Col>
                  {printDroppable(this.props.columns['available'])}
                </Col>
              </Row>
            </Container>

          </div>
        </div>

      </Col>
    )
  }
}

export default ListOfCertifications
