import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import dropImg from '../assets/drop-here-courses_2.png'

import Course from './Course'

const CoursesList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
  text-align: center;
`

class TimeTable extends React.Component {

  exportJsonFile = () => {
      let itemsToExport = []
    // const columnsToExport = Object.keys(this.props.columns) // export courses from all columns
    const columnsToExport = ['1-w', '1-s', '2-w', '2-s', '3-w', '3-s'] // export courses from selected columns
    columnsToExport.forEach(columnId => {
      this.props.columns[columnId].courseIds.forEach(courseId => {
        let item = this.props.courses[courseId]
        item.position = columnId
        itemsToExport.push(item)
      })
    })

    const element = document.createElement("a")
    const file = new Blob([JSON.stringify({courses: itemsToExport})], {type: 'application/json'});
    element.href = URL.createObjectURL(file)
    element.download = "my-curricula.json"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  importJsonFile = (event) => {
    var file = event.target.files[0]
    let self = this
    if (event.target.files.length && file.type.match("application/json")) {
      var reader = new FileReader()
      reader.onload = function(e) {
        self.props.importData(reader.result)
      }
      reader.readAsText(file)
      document.getElementById("fileImport").value = null
    }
  }

  render(){

    const printDroppable = (column) => {
      let draggedCourse = this.props.draggedCourse ? this.props.courses[this.props.draggedCourse] : null
      let isDropDisabled =  draggedCourse === null ? false :
        column.semester === draggedCourse.semester ? false : true

      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot) => (
            <CoursesList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.courseIds.map((course, index) => {
                return <Course key={this.props.courses[course].id} course={this.props.courses[course]} index={index} removeCourse={this.props.removeCourse} openAddModal={this.props.openAddModal}
                rsSkillsGroup={this.props.rsSkillsGroup}
                columnId={column.id}/>
              })}
              {provided.placeholder}
              {column.courseIds.length === 0 ? <img src={dropImg} className="dropImg" alt='dropHere'/> : ''}
            </CoursesList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="time-table">
        <h3 className="section-title"> Your Curricula</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <input type="file" id="fileImport" accept="application/JSON" onChange={this.importJsonFile}/>
              <Button variant="outline-success" onClick={()=>document.getElementById("fileImport").click()}>Import</Button>
              <Button variant="outline-danger" onClick={this.exportJsonFile}>Export</Button>
            </div>

            <h5 className="main-title">1st year</h5>

            <Container>
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['1-w'])}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['1-s'])}

                </Col>
              </Row>
            </Container>

            <h5 className="main-title second">2nd year</h5>

            <Container>
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['2-w'])}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['2-s'])}

                </Col>
              </Row>
            </Container>

            <h5 className="main-title second">3rd year</h5>

            <Container>
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['3-w'])}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['3-s'])}

                </Col>
              </Row>
            </Container>



          </div>
        </div>

      </Col>
    )
  }
}

export default TimeTable
