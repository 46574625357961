import React, { useState, useRef, useCallback } from "react";
import { Popover, Table, Tag, Tooltip, Typography } from "antd";

import "./Roles.scss";
import QueueAnim from "rc-queue-anim";

import { CISOData } from "./roles-data/CISO-data";
import { CIRData } from "./roles-data/CIR-data";
import { LPCData } from "./roles-data/LPC-data";
import { CTIData } from "./roles-data/CTI-data";
import { ARCHData } from "./roles-data/ARCH-data";
import { AUDData } from "./roles-data/AUD-data";
import { EDUData } from "./roles-data/EDU-data";
import { CIMPLData } from "./roles-data/CIMPL-data";
import { CYRESEARCHData } from "./roles-data/CYRESEARCH-data";
import { RISKMANAGERData } from "./roles-data/RISKMANAGER-data";
import { FORENData } from "./roles-data/FOREN-data";
import { PENTESTData } from "./roles-data/PENTEST-data";

import { getECFByTitle } from "./roles-data/roles_utils";
import RoleRow from "./RoleRow";

const { Title } = Typography;

const Roles = () => {
  // use primarly Ant 4.x graphic library for your compoments : https://4x.ant.design/components/overview/
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [isImageClicked2, setIsImageClicked2] = useState(false);
  const [isImageClicked3, setIsImageClicked3] = useState(false);
  const [isImageClicked4, setIsImageClicked4] = useState(false);

  const [roleClicked, setRoleClicked] = useState("CISO");

  // function to determine if any of the images are clicked
  const isAnyImageClicked = () => {
    if (
      isImageClicked ||
      isImageClicked2 ||
      isImageClicked3 ||
      isImageClicked4
    ) {
      return true;
    } else {
      return false;
    }
  };

  // function to determine the imported data based on the id of the image clicked
  const getRoleData = (roleClicked) => {
    //console.log(roleClicked);
    if (roleClicked === "CISO") {
      return CISOData;
    } else if (roleClicked === "CIR") {
      return CIRData;
    } else if (roleClicked === "LPC") {
      return LPCData;
    } else if (roleClicked === "CTI") {
      return CTIData;
    } else if (roleClicked === "ARCH") {
      return ARCHData;
    } else if (roleClicked === "AUD") {
      return AUDData;
    } else if (roleClicked === "EDU") {
      return EDUData;
    } else if (roleClicked === "CIMPL") {
      return CIMPLData;
    } else if (roleClicked === "CYRESEARCH") {
      return CYRESEARCHData;
    } else if (roleClicked === "RISKMANAGER") {
      return RISKMANAGERData;
    } else if (roleClicked === "FOREN") {
      return FORENData;
    } else if (roleClicked === "PENTEST") {
      return PENTESTData;
    }
  };

  //define a function to handle the click event with an id parameter
  const handleImageClick = (id) => (event) => {
    setRoleClicked(id);

    if ((id === "CISO" || id === "CIR" || id === "LPC") && !isImageClicked) {
      setIsImageClicked(true);
      setIsImageClicked2(false);
      setIsImageClicked3(false);
      setIsImageClicked4(false);
    } else if (
      (id === "CISO" || id === "CIR" || id === "LPC") &&
      isImageClicked
    ) {
      setIsImageClicked(false);
    }
    if ((id === "CTI" || id === "ARCH" || id === "AUD") && !isImageClicked2) {
      setIsImageClicked2(true);
      setIsImageClicked(false);
      setIsImageClicked3(false);
      setIsImageClicked4(false);
    } else if (
      (id === "CTI" || id === "ARCH" || id === "AUD") &&
      isImageClicked2
    ) {
      setIsImageClicked2(false);
    }
    if (
      (id === "EDU" || id === "CIMPL" || id === "CYRESEARCH") &&
      !isImageClicked3
    ) {
      setIsImageClicked3(true);
      setIsImageClicked(false);
      setIsImageClicked2(false);
      setIsImageClicked4(false);
    } else if (
      (id === "EDU" || id === "CIMPL" || id === "CYRESEARCH") &&
      isImageClicked3
    ) {
      setIsImageClicked3(false);
    }
    if (
      (id === "RISKMANAGER" || id === "FOREN" || id === "PENTEST") &&
      !isImageClicked4
    ) {
      setIsImageClicked4(true);
      setIsImageClicked(false);
      setIsImageClicked2(false);
      setIsImageClicked3(false);
    } else if (
      (id === "RISKMANAGER" || id === "FOREN" || id === "PENTEST") &&
      isImageClicked4
    ) {
      setIsImageClicked4(false);
    }
  };
  // const onChange = (key) => {
  //     console.log(key);
  // };

  const items = [
    {
      key: "1",
      label: (
        <>
          <Tooltip title="Lists typical alternative titles under the same profile">
            <span>Alternative Title(s)</span>
          </Tooltip>
        </>
      ),
      children: (
        <Table
          className="custom-table"
          columns={[
            {
              title: "Titles",
              dataIndex: "title",
              key: "title",
            },
          ]}
          dataSource={getRoleData(roleClicked).alternativeTitles}
          pagination={false}
        />
      ),
      // <List
      //     size="large"
      //     //itemLayout="horizontal"
      //     itemLayout="vertical"
      //     dataSource={getRoleData(roleClicked).alternativeTitles}
      //     //dataSource={CISOData.alternativeTitles}
      //     renderItem={item => (
      //         <List.Item>
      //             {item}
      //         </List.Item>
      //     )}
      // />
    },
    {
      key: "2",
      label: (
        <>
          <Tooltip title="Indicates the main purpose of the profile">
            <span>Summary Statement</span>
          </Tooltip>
        </>
      ),
      children: (
        <Table
          className="custom-table"
          columns={[
            {
              title: "Summary Statement",
              dataIndex: "summary",
              key: "summary",
            },
          ]}
          dataSource={getRoleData(roleClicked).summaryStatement}
          pagination={false}
        />
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Tooltip title="Describes the rationale of the profile">
            <span>Mission</span>
          </Tooltip>
        </>
      ),
      children: (
        <Table
          className="custom-table"
          columns={[
            {
              title: "Mission",
              dataIndex: "mission",
              key: "mission",
            },
          ]}
          dataSource={getRoleData(roleClicked).mission}
          pagination={false}
        />
      ),
      // <List
      //     size="large"
      //     itemLayout="vertical"
      //     dataSource={getRoleData(roleClicked).mission}
      //     renderItem={item => (
      //         <List.Item>
      //             {item}
      //         </List.Item>
      //     )}
      // />
    },
    {
      key: "4",
      label: (
        <>
          <Tooltip title="The list of deliverables provides some indicative and practical examples of the deliverable(s) / output(s) of each role profile. The deliverables listed are offered as examples as the list is not exhaustive, and thus mat not cover every aspect of each profile">
            <span>Deliverables</span>
          </Tooltip>
        </>
      ),
      children: (
        <Table
          className="custom-table"
          columns={[
            {
              title: "Deliverables",
              dataIndex: "deliverable",
              key: "deliverable",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
          ]}
          dataSource={getRoleData(roleClicked).deliverables}
          pagination={false}
        />
      ),
      // <List
      //     size="large"
      //     itemLayout="vertical"
      //     dataSource={getRoleData(roleClicked).deliverables}
      //     renderItem={item => (
      //         <List.Item>
      //             {item}
      //         </List.Item>
      //     )}
      // />
    },
    {
      key: "5",
      label: (
        <>
          <Tooltip title="A list of typical tasks performed by the profiled role">
            <span>Main Task(s)</span>
          </Tooltip>
        </>
      ),
      children: (
        // <List
        //     size="large"
        //     itemLayout="vertical"
        //     dataSource={CISOData.mainTasks}
        //     renderItem={item => (
        //         <List.Item>
        //             {item}
        //         </List.Item>
        //     )}
        // />
        <Table
          className="custom-table"
          columns={[
            {
              title: "Task",
              dataIndex: "task",
              key: "task",
            },
          ]}
          dataSource={getRoleData(roleClicked).mainTasks}
          pagination={true}
        />
      ),
    },
    {
      key: "6",
      label: (
        <>
          <Tooltip title="A list of typical tasks performed by the profiled role">
            <span>Key Skill(s)</span>
          </Tooltip>
        </>
      ),
      children: (
        // <List
        //     size="large"
        //     itemLayout="vertical"
        //     dataSource={CISOData.keySkills}
        //     renderItem={item => (
        //         <List.Item>
        //             {item}
        //         </List.Item>
        //     )}
        // />
        <Table
          className="custom-table"
          columns={[
            {
              title: "Skill",
              dataIndex: "skill",
              key: "skill",
            },
          ]}
          dataSource={getRoleData(roleClicked).keySkills}
          pagination={true}
        />
      ),
    },
    {
      key: "7",
      label: (
        <>
          <Tooltip title="A list of the essential knowledge required to perform the work functions and duties in the profiled role">
            <span>Key Knowledge</span>
          </Tooltip>
        </>
      ),
      children: (
        // <List
        //     size="large"
        //     itemLayout="vertical"
        //     dataSource={CISOData.keyKnowledge}
        //     renderItem={item => (
        //         <List.Item>
        //             {item}
        //         </List.Item>
        //     )}
        // />
        <Table
          className="custom-table"
          columns={[
            {
              title: "Knowledge",
              dataIndex: "knowledge",
              key: "knowledge",
            },
          ]}
          dataSource={getRoleData(roleClicked).keyKnowledge}
          pagination={true}
        />
      ),
    },
    {
      key: "8",
      label: (
        <>
          <Tooltip
            title="The European e-Competence Framework (e-CF) provides a reference of 41 competences as required and applied in an IT professional work context,
                                 using a common language for competences, skills, knowledge and proficiency levels that is applied and understood across Europe"
          >
            <span>e-Competences</span>
          </Tooltip>
        </>
      ),
      children: (
        <Table
          columns={[
            {
              title: "Competence",
              dataIndex: "competence",
              key: "competence",
              width: "20%",
            },
            {
              title: "Description",
              dataIndex: "competence",
              key: "description",
              render: (_, { competence }) => (
                <>
                  {/*{getECFByTitle(competence).description}*/}
                  {getECFByTitle(competence).description}
                  {/*{competence}*/}
                  {/*{console.log("1"+competence+"1")}*/}
                  {/*{console.log(getECFByTitle(competence).description)}*/}
                </>
              ),
            },
            {
              title: "Level",
              dataIndex: "level",
              key: "level",
              width: "30%",
              render: (_, { level, competence }) => (
                <div className="level-column">
                  {level.map((tag) => {
                    //let color = tag.length > 5 ? 'geekblue' : 'green';
                    // if (tag === 'loser') {
                    //     color = 'volcano';
                    // }
                    return (
                      <>
                        {[
                          "Level 1",
                          "Level 2",
                          "Level 3",
                          "Level 4",
                          "Level 5",
                        ].map((level_iter) => {
                          return (
                            <>
                              {/*{ (getECFByTitle(competence).levels[level_iter.toLocaleLowerCase().replace(" ", "")] === "-") && (*/}
                              {/*    <Popover*/}
                              {/*        showArrow={false}*/}
                              {/*        content=*/}
                              {/*            {*/}
                              {/*                getECFByTitle(competence).levels[level_iter.toLocaleLowerCase().replace(" ", "")]*/}
                              {/*            }*/}
                              {/*        title={competence + ": " + level_iter}>*/}
                              {/*        <Tag color="grey" key={level}>*/}
                              {/*            {level_iter}*/}
                              {/*        </Tag>*/}
                              {/*    </Popover>*/}
                              {/*)}*/}
                              {level_iter === tag && (
                                <Popover
                                  showArrow={false}
                                  content={
                                    getECFByTitle(competence).levels[
                                      level_iter
                                        .toLocaleLowerCase()
                                        .replace(" ", "")
                                    ]
                                  }
                                  title={competence + ": " + level_iter}
                                >
                                  <Tag color="green" key={level}>
                                    {level_iter}
                                  </Tag>
                                </Popover>
                              )}
                              {level_iter !== tag &&
                                getECFByTitle(competence).levels[
                                  level_iter
                                    .toLocaleLowerCase()
                                    .replace(" ", "")
                                ] !== "-" && (
                                  <Popover
                                    showArrow={false}
                                    content={
                                      getECFByTitle(competence).levels[
                                        level_iter
                                          .toLocaleLowerCase()
                                          .replace(" ", "")
                                      ]
                                    }
                                    title={competence + ": " + level_iter}
                                  >
                                    <Tag color="gray" key={level}>
                                      {level_iter}
                                    </Tag>
                                  </Popover>
                                )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              ),
            },
          ]}
          dataSource={getRoleData(roleClicked).eCompetences}
          pagination={false}
        />
      ),
    },
  ];

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const existingElementRef1 = useRef(null);
  const scrollToExistingElement1 = useCallback(() => {
    if (existingElementRef1.current) {
      existingElementRef1.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const existingElementRef2 = useRef(null);
  const scrollToExistingElement2 = useCallback(() => {
    if (existingElementRef2.current) {
      existingElementRef2.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const existingElementRef3 = useRef(null);
  const scrollToExistingElement3 = useCallback(() => {
    if (existingElementRef3.current) {
      existingElementRef3.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const existingElementRef4 = useRef(null);
  const scrollToExistingElement4 = useCallback(() => {
    if (existingElementRef4.current) {
      existingElementRef4.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <section id="roles">
      <div className="section-container">
        <div className="text-content">
          <Title className="pageTitle">
            European Cybersecurity Skills Framework (ECSF)
          </Title>

          <Typography className={"text-before-explorer"}>
            The European Cybersecurity Skills Framework (ECSF) is a practical
            tool to support the identification and articulation of tasks,
            competences, skills and knowledge associated with the roles of
            European cybersecurity professionals. It is the EU reference point
            for defining and assessing relevant skills, as defined in the
            Cybersecurity Skills Academy, which was recently announced by the
            European Commission.
          </Typography>

          <Typography className={"text-before-explorer"}>
            The ECSF summarises the cybersecurity-related roles into 12
            profiles, which are individually analysed into the details of their
            corresponding responsibilities, skills, synergies and
            interdependencies. It provides a common understanding of the
            relevant roles, competencies, skills and knowledge mostly required
            in cybersecurity, facilitates recognition of cybersecurity skills,
            and supports the design of cybersecurity-related training
            programmes.
          </Typography>

          <div
            style={
              {
                // backgroundColor: "aliceblue",
              }
            }
          >
            <Title level={3}>ECSF Goals in Brief</Title>
            {/*<Title>*/}
            {/*    <Tooltip title=*/}
            {/*                 "European Cybersecurity Skills Framework"*/}
            {/*    >*/}
            {/*        <span>ECSF</span>*/}
            {/*    </Tooltip> Goals in Brief*/}
            {/*</Title>*/}
            <Typography className={"text-before-explorer"}>
              <ul>
                <li>
                  Use of the ECSF ensures a common terminology and shared
                  understanding between the demand (workplace, recruitment) and
                  supply (qualification, training) of cybersecurity
                  professionals across the EU.
                </li>
                <li>
                  The ECSF supports the identification of the critical skill
                  sets required from a workforce perspective. It enables
                  providers of learning programmes to support the development of
                  this critical set of skills and helps policy-makers support
                  targeted initiatives to mitigate the gaps identified in
                  skills.
                </li>
                <li>
                  The framework facilitates an understanding of leading
                  cybersecurity professional roles and the essential skills they
                  require, including soft skills, along with the legislative
                  aspects (if any). In particular, it enables non-experts and HR
                  departments to understand the requirements for resource
                  planning, recruitment and career planning in supporting
                  cybersecurity.
                </li>
                <li>
                  The framework promotes harmonisation in cybersecurity
                  education, training, and workforce development. At the same
                  time, this common European language in the context of
                  cybersecurity skills and roles connects well with the entire
                  ICT professional domain.
                </li>
                <li>
                  The ECSF contributes to achieving enhanced shielding against
                  cyberattacks and to ensuring secure IT systems in society. It
                  provides a standard structure and advice on how to implement
                  capacity building within the European cybersecurity workforce.
                </li>
              </ul>
            </Typography>
            <div style={{ height: "70px" }}></div> {/* Vertical spacing */}
            <QueueAnim delay={50} className="queue-simple">
              <Title level={3}>ECSF Explorer</Title>

              <Typography className={"text-before-explorer"}>
                The ECSF Explorer is a tool that allows you to explore the ECSF
                in a more interactive way. You can click on the roles to see
                their alternative titles, summary statement, mission,
                deliverables, main tasks, key skills, key knowledge and
                e-competences. In the e-competences section, you can hover over
                the level tags to see their description. The green tags
                represent the levels that are required for the role, while the
                grey tags represent the rest of the levels for that
                e-competence.
              </Typography>

              <div key={"a"} className={"rolesTriplet"}>
                <div ref={existingElementRef1} style={{ height: "70px" }}></div>{" "}
                {/* Vertical spacing */}
                <RoleRow
                  ref={existingElementRef1}
                  onChildsChildRendered={scrollToExistingElement1}
                  onClick={handleClick()}
                  roleClicked={roleClicked}
                  handleImageClick={handleImageClick}
                  isAnyImageClicked={isAnyImageClicked}
                  items={items}
                  isImageClicked={isImageClicked}
                  getRoleData={getRoleData}
                  leftRoleProfile={"CISO"}
                />
              </div>

              <div key={"b"} className={"rolesTriplet"}>
                <div ref={existingElementRef2} style={{ height: "70px" }}></div>{" "}
                {/* Vertical spacing */}
                <RoleRow
                  ref={existingElementRef2}
                  onChildsChildRendered={scrollToExistingElement2}
                  onClick={handleClick()}
                  roleClicked={roleClicked}
                  handleImageClick={handleImageClick}
                  isAnyImageClicked={isAnyImageClicked}
                  items={items}
                  isImageClicked={isImageClicked2}
                  getRoleData={getRoleData}
                  leftRoleProfile={"CTI"}
                />
              </div>
              <div key={"c"} className={"rolesTriplet"}>
                <div ref={existingElementRef3} style={{ height: "70px" }}></div>{" "}
                {/* Vertical spacing */}
                <RoleRow
                  ref={existingElementRef3}
                  onChildsChildRendered={scrollToExistingElement3}
                  onClick={handleClick()}
                  roleClicked={roleClicked}
                  handleImageClick={handleImageClick}
                  isAnyImageClicked={isAnyImageClicked}
                  items={items}
                  isImageClicked={isImageClicked3}
                  getRoleData={getRoleData}
                  leftRoleProfile={"EDU"}
                />
              </div>
              <div key={"d"} className={"rolesTriplet"}>
                <div ref={existingElementRef4} style={{ height: "70px" }}></div>{" "}
                {/* Vertical spacing */}
                <RoleRow
                  ref={existingElementRef4}
                  onChildsChildRendered={scrollToExistingElement4}
                  onClick={handleClick()}
                  roleClicked={roleClicked}
                  handleImageClick={handleImageClick}
                  isAnyImageClicked={isAnyImageClicked}
                  items={items}
                  isImageClicked={isImageClicked4}
                  getRoleData={getRoleData}
                  leftRoleProfile={"RISKMANAGER"}
                />
              </div>
              {!isImageClicked4 ? (
                <div style={{ height: "600px" }}></div>
              ) : null}
            </QueueAnim>
            {/*<div style={{'--radius': '1000px'}}>*/}
            {/*    <CircularImageGallery images={images} />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roles;
