const title = "Cybersecurity Researcher";

// const alternativeTitles = [
//     "Security Researcher",
//     "Security Analyst",
//     "Vulnerability Researcher",
//     "Malware Analyst"
// ];

const alternativeTitles = [
    { key: 1, title: "Security Researcher" },
    { key: 2, title: "Security Analyst" },
    { key: 3, title: "Vulnerability Researcher" },
    { key: 4, title: "Malware Analyst" }
];

// const summaryStatement = [
//     "Conduct research to identify new threats and vulnerabilities in software, hardware or systems. Develop new techniques to detect and prevent cyber attacks."
// ];

const summaryStatement = [{ key: 1, summary: "Conduct research to identify new threats and vulnerabilities in software, hardware or systems. Develop new techniques to detect and prevent cyber attacks."}];

// const mission = [
//     "Conduct research to identify new threats and vulnerabilities in software, hardware or systems.",
//     "Develop new techniques to detect and prevent cyber attacks."
// ];

const mission = [
    { key: 1, mission: "Conduct research to identify new threats and vulnerabilities in software, hardware or systems." },
    { key: 2, mission: "Develop new techniques to detect and prevent cyber attacks." }
];

// const deliverables = [
//     "Threat intelligence reports",
//     "Vulnerability research reports",
//     "Malware analysis reports",
//     "Security research papers"
// ];

const deliverables = [
    { key : 1, deliverable: "Publication in Cybersecurity", description: "Academic publication releasing findings and results of research in the cybersecurity context. The purpose of the publication might be to advance the technology and/or develop new innovated solutions."},
    { key: 2, deliverable: "Threat intelligence reports" },
    { key: 3, deliverable: "Vulnerability research reports" },
    { key: 4, deliverable: "Malware analysis reports" },
    { key: 5, deliverable: "Security research papers" }
];

const mainTasks = [
    {key:1, task:"Analyse and assess cybersecurity technologies, solutions, developments and processes"},
    {key:2, task:"Conduct research, innovation and development work in cybersecurity-related topics"},
    {key:3, task:"Manifest and generate research and innovation ideas"},
    {key:4, task:"Advance the current state-of-the-art in cybersecurity-related topics"},
    {key:5, task:"Assist in the development of innovative cybersecurity-related solutions"},
    {key:6, task:"Conduct experiments and develop a proof of concept, pilots and prototypes for cybersecurity solutions"},
    {key:7, task:"Select and apply frameworks, methods, standards, tools and protocols including a building and testing a proof of concept to support projects"},
    {key:8, task:"Contributes towards cutting-edge cybersecurity business ideas, services and solutions"},
    {key:9, task:"Assist in cybersecurity-related capacity building including awareness, theoretical training, practical training, testing, mentoring, supervising and sharing"},
    {key:10, task:"Identify cross-sectoral cybersecurity achievements and apply them in a different context or propose innovative approaches and solutions"},
    {key:11, task:"Lead or participate in the innovation processes and projects including project management and budgeting"},
    {key:12, task:"Publish and present scientific works and research and development results"}
];

const keySkills = [
    { key: 1, skill: "Generate new ideas and transfer theory into practice" },
    { key: 2, skill: "Decompose and analyse systems to identify weaknesses and ineffective controls" },
    { key: 3, skill: "Decompose and analyse systems to develop security and privacy requirements and identify effective solutions" },
    { key: 4, skill: "Monitor new advancements in cybersecurity-related technologies" },
    { key: 5, skill: "Communicate, present and report to relevant stakeholders" },
    { key: 6, skill: "Identify and solve cybersecurity-related issues" },
    { key: 7, skill: "Collaborate with other team members and colleagues" },
];

// • Cybersecurity-related research, development and innovation (RDI)
// • Cybersecurity standards, methodologies and frameworks
// • Legal, regulatory and legislative requirements on releasing or using cybersecurity related technologies
// • Multidiscipline aspect of cybersecurity
// • Responsible information disclosure procedures

const keyKnowledge = [
    { key: 1, skill: "Cybersecurity-related research, development and innovation (RDI)" },
    { key: 2, skill: "Cybersecurity standards, methodologies and frameworks" },
    { key: 3, skill: "Legal, regulatory and legislative requirements on releasing or using cybersecurity related technologies" },
    { key: 4, skill: "Multidiscipline aspect of cybersecurity" },
    { key: 5, skill: "Responsible information disclosure procedures" },
];

const eCompetences = [
    { key: 1, competence: "A.7. Technology Trend Monitoring", level: ['Level 5'] },
    { key: 2, competence: "A.9. Innovating", level: ['Level 5'] },
    { key: 3, competence: "D.7. Data Science and Analytics", level: ['Level 4'] },
    { key: 4, competence: "C.4. Problem Management", level: ['Level 3'] },
    { key: 5, competence: "D.10. Information and Knowledge Management", level: ['Level 3'] },
];

export const CYRESEARCHData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}