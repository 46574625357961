import { ServerURL } from '../../config.js'

let subfolder = "job-ads-analyzer"

export const Config = {
	server       :   {
		"getJobs"							: ServerURL+subfolder+"/getJobs.php",
		"addJob"							: ServerURL+subfolder+"/addJob.php",
		"editJob"							: ServerURL+subfolder+"/editJob.php",
		"deleteJob"							: ServerURL+subfolder+"/deleteJob.php",
		"processPrediction"					: ServerURL+subfolder+"/processPrediction.php",
		"analyzeJob"						: ServerURL+subfolder+"/analyzeJob.php",
		"processDescription"				: ServerURL+subfolder+"/processDescription.php",
		"analyzeGenderBalance"				: ServerURL+subfolder+"/analyzeGenderBalance.php"
	}

}
