import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Table, Row, Col } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
// import "antd/dist/antd.css";

const SkillsGraph = ({ nodesData, linksData }) => {
  const svgRef = useRef();
  const [highlightedNode, setHighlightedNode] = useState(null);

  useEffect(() => {
    const width = 860
    const height = 900

    // style settings
    const nodeColor = "#2E93fA";
    const nodeColorHighlight = "#FF9800";
    const nodeSizeScale = [5, 30];
    const nodeOpacityHidden = 0.1;
    
    const edgeColor = "#bbb";
    const edgeOpacity = 0.7;
    const edgeOpacityHidden = 0.1;
    const edgeSizeScale = [1, 10];

    const labelBackgroundColor = "white";
    const labelOpacityHidden = 0.5;
    const labelSize = "13px";
    const labelSizeHighlight = "14px";
    const labelWeight = "600";
    const labelWeightHighlight = "700";

    const labelBackgroundOpacity = 0.6;
    const labelBackgroundOpacityHighlighted = 0.8;

    // Create an SVG element
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Remove any existing elements
    svg.selectAll("*").remove();

    // Create a simulation with force-directed layout
    const simulation = d3.forceSimulation(nodesData)
      .force("link", d3.forceLink(linksData).id(d => d.id).distance(380))
      .force("charge", d3.forceManyBody().strength(-200))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // Define node size scaling based on weight
    const nodeRadiusScale = d3.scaleLinear()
      .domain(d3.extent(nodesData, d => d.weight))
      .range(nodeSizeScale);

    // Define link width scaling based on weight
    const linkWidthScale = d3.scaleLinear()
      .domain(d3.extent(linksData, d => d.weight))
      .range(edgeSizeScale);

    // Create a marker for arrows on directed links
    svg.append("defs").selectAll("marker")
      .data(["arrow"])
      .enter().append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", 0)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#999");

    // Create links (edges)
    const link = svg.append("g")
      .attr("class", "links")
      .selectAll("line")
      .data(linksData)
      .enter().append("line")
      .attr("stroke-width", d => linkWidthScale(d.weight))
      .attr("stroke", edgeColor)
      .attr("opacity", edgeOpacity)
      .attr("marker-end", d => d.type === "directed" ? "url(#arrow)" : null);

    // Create nodes
    const node = svg.append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(nodesData)
      .enter().append("circle")
      .attr("r", d => nodeRadiusScale(d.weight))
      .attr("fill", nodeColor)
      .call(d3.drag()
        .on("start", dragStarted)
        .on("drag", dragged)
        .on("end", dragEnded));

		// Create a group for each node's label and background rectangle
		const labelGroup = svg.append("g")
    .attr("class", "labels")
    .selectAll("g")
    .data(nodesData)
    .enter().append("g");

		// Add a background rectangle for each label
		const labelBackground = labelGroup.append("rect")
			.attr("x", d => -d.label.length * 3.55 - 5 ) // Adjust the x position based on label length
			.attr("width", d => d.label.length * 6.7 + 20) // Adjust the width based on label length
			.attr("y", d => -nodeRadiusScale(d.weight) - 23) // Position it above the node
			.attr("height", 20) // Fixed height for the background
			.attr("fill", labelBackgroundColor)
			.attr("opacity", labelBackgroundOpacity)
			.attr("rx", 4) // Rounded corners
			.attr("ry", 4); // Rounded corners

		// Add text labels for each node
		const label = labelGroup.append("text")
			.attr("text-anchor", "middle")
			.text(d => d.label)
			.style("font-size", labelSize)
			.style("font-weight", labelWeight)
			.attr("font-family", "sans-serif")
			.attr("y", d => -nodeRadiusScale(d.weight)/2 - 0) // Dynamic y position
			.attr("dy", d => -nodeRadiusScale(d.weight)/2 - 8); // Dynamic dy
      
    // Highlighting logic
    function updateHighlight() {
      node.style("opacity", d => {
        if (highlightedNode === null) return 1;
        if (highlightedNode === d) return 1;
        return isConnected(highlightedNode, d) ? 1 : nodeOpacityHidden;
      });

      node.style("fill", d => {
        if (highlightedNode === d) return nodeColorHighlight
        else return nodeColor 
      });

      link.style("opacity", d => {
        if (highlightedNode === null) return edgeOpacity;
        return d.source.id === highlightedNode.id || d.target.id === highlightedNode.id ? 1 : edgeOpacityHidden;
      });

			// Highlighting logic based on highlightedNode
			label.style("opacity", d => {
				if (highlightedNode === null) return 1;
				if (highlightedNode === d) return 1;
				return isConnected(highlightedNode, d) ? 1 : labelOpacityHidden;
			});

			// Update font-size for highlighted and normal nodes
			label.style("font-size", d => {
				if (highlightedNode === d) return labelSizeHighlight; // Larger font size for highlighted node
				else return labelSize; // Default font size
			});		

			// Update font-weight for highlighted and normal nodes
			label.style("font-weight", d => {
				if (highlightedNode === d) return labelWeightHighlight; // Larger font size for highlighted node
				else return labelWeight; // Default font size
			});		

			// Update the background rectangle height for highlighted and normal nodes
			labelBackground.attr("height", d => {
				if (highlightedNode === d) return 23; // Taller background for highlighted node
				else return 20; // Default height
			});

			labelBackground.attr("x", d => {
				if (highlightedNode === d) return -d.label.length * 3.8 - 5 
				else return -d.label.length * 3.55 - 5
			})

			labelBackground.attr("width", d => {
				if (highlightedNode === d) return d.label.length * 6.7 + 35
				else return d.label.length * 6.7 + 20
			})

			labelBackground.attr("opacity", d => {
				if (highlightedNode === d) return labelBackgroundOpacityHighlighted; // Taller background for highlighted node
				else return labelBackgroundOpacity; // Default height
			});

			// Bring the highlighted labelGroup to the front
			labelGroup.each(function(d) {
				if (highlightedNode === d) {
					d3.select(this).raise(); // Move the highlighted labelGroup to the front
				}
			});


      // label.style("opacity", d => {
      //   if (highlightedNode === null) return 1;
      //   if (highlightedNode === d) return 1;
      //   return isConnected(highlightedNode, d) ? 1 : 0.1;
      // });

      // label.style("font-size", d => {
      //   if (highlightedNode === d) return "15px"
      //   else return "13px";
      // });
    }

    // Check if two nodes are connected (either source or target)
    function isConnected(a, b) {
      return linksData.some(link => (link.source.id === a.id && link.target.id === b.id) ||
        (link.source.id === b.id && link.target.id === a.id));
    }

    // Update the positions of the nodes and links as the simulation runs
    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);

			labelGroup
				.attr("transform", d => `translate(${d.x},${d.y})`); // Move label group based on node position

      // label
      //   .attr("x", d => d.x)
			// 	.attr("y", d => d.y - nodeRadiusScale(d.weight)/2 - 0) // Dynamic dy based on node size
			// 	.attr("dy", d => -nodeRadiusScale(d.weight)/2 - 5); // More negative offset based on node size
    });

    // Apply highlighting when hovered
    updateHighlight();

    // Dragging functionality for nodes
    function dragStarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragEnded(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

  }, [nodesData, linksData, highlightedNode]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: "30px",
      align: "right",
    },
    {
      title: 'Skill Name',
      dataIndex: 'label',
      key: 'label',
      onCell: record => ({
        onMouseEnter: () => setHighlightedNode(record),
        onMouseLeave: () => setHighlightedNode(null)
      }),
    },
  ];

  return (
    <Row gutter={16} className="skill-connection">

			<Col span={8}>
				<Table 
					dataSource={nodesData} 
					columns={columns} 
					pagination={false} 
					size="small" 
					rowKey="id" 
					title={() => <span className="comment-table"><InfoCircleOutlined /> Move mouse over the skills in the table.</span>}
				/>
			</Col>

			<Col span={16}>
				<svg ref={svgRef}></svg>
			</Col>

    </Row>
  )
};


export default SkillsGraph;
