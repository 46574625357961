const studyPrograms =
[
    {
        "name": "AGH University of Science and Technology",
        "country": "Poland",
        "cor": {
            "lat": 50.066893,
            "lng": 19.913448
        },
        "world_university_rankings": "801-1000",
        "programs": [
            {
                "study_program": "Cyber­-bezpieczeństwo",
                "department": "The Faculty of Computer Science, Electronics and Telecommunications",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "Polish"
                ],
                "duration": "other",
                "cost": "",
                "link": "https://kandydaci.agh.edu.pl/studia/cyberbezpieczenstwo/https://syllabuskrk.agh.edu.pl/2019-2020/pl/magnesite/study_plans/stacjonarne-cyberbezpieczenstwo--2https://syllabuskrk.agh.edu.pl/2019-2020/pl/magnesite/study_plans/stacjonarne-cyberbezpieczenstwo--2/descriptionhttps://syllabuskrk.agh.edu.pl/2019-2020/en/magnesite/study_plans/stacjonarne-cyberbezpieczenstwo--2",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "cryptography": 5,
                    "humanistic": 13.6,
                    "privacy": 5.7,
                    "security": 40.7,
                    "computer_science": 13.6,
                    "mathematics": 21.4
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            },
            {
                "study_program": "Cyberbezpieczeństwo",
                "department": "Faculty of Computer Science, Electronics and Telecommunication ",
                "degree": "Master",
                "degree_title": "Engineer",
                "language": [
                    "Polish"
                ],
                "duration": "other",
                "cost": "",
                "link": "https://syllabuskrk.agh.edu.pl/2019-2020/pl/magnesite/study_plans/stacjonarne-cyberbezpieczenstwo--2/description",
                "practical_lectures": 19.6,
                "percentage_of_subjects_on": {
                    "computer_science": 9.8,
                    "cryptography": 2.2,
                    "humanistic": 18.5,
                    "mathematics": 14.1,
                    "privacy": 8.7,
                    "security": 46.7
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 0
    },
    {
        "name": "Aalto University",
        "country": "Finland",
        "cor": {
            "lat": 60.184839,
            "lng": 24.82248
        },
        "programs": [
            {
                "study_program": "Master's Programme in Security and Cloud Computing",
                "department": "Department of Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "9000",
                "link": "https://secclo.eu/programme/programme/",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 68,
                    "cryptography": 4,
                    "humanistic": 14,
                    "mathematics": 0,
                    "privacy": 4,
                    "security": 10
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "world_university_rankings": "184",
        "id": 1
    },
    {
        "name": "Adam Mickiewicz University",
        "country": "Poland",
        "cor": {
            "lat": 52.408384,
            "lng": 16.915273
        },
        "world_university_rankings": "801-1000",
        "programs": [
            {
                "study_program": "Security of information systems (Bezpieczeństwo systemów)",
                "department": "Department of Informatics and Mathematics ",
                "degree": "Master",
                "degree_title": "MSc degree",
                "language": [
                    "Polish"
                ],
                "duration": "other",
                "cost": "",
                "link": "https://www.wmi.amu.edu.pl/pl/stacjonarne/ii-stopnia#informatyka",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "computer_science": 32.14,
                    "cryptography": 0,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 67.86
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Data Analysis",
                    "Network Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 2
    },
    {
        "name": "Armed Force University of Munich",
        "country": "Germany",
        "cor": {
            "lat": 48.080282,
            "lng": 11.638115
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Master Cyber-Sicherheit (Master Cyber Security)",
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "German"
                ],
                "duration": "1 year",
                "cost": "",
                "link": "https://www.unibw.de/inf/studium/studiengang-cyber-sicherheit",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 37.5,
                    "cryptography": 12.5,
                    "humanistic": 9.38,
                    "mathematics": 0,
                    "privacy": 9.38,
                    "security": 31.24
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 3
    },
    {
        "name": "Brno University of Technology",
        "country": "Czech Republic",
        "cor": {
            "lat": 49.226149,
            "lng": 16.575368
        },
        "world_university_rankings": "801 - 1000",
        "programs": [
            {
                "study_program": "Information Security",
                "department": "Faculty of Electrical Engineering and Communication",
                "degree": "Bachelor",
                "degree_title": "Bachelor",
                "language": [
                    "Czech"
                ],
                "duration": "3 years",
                "cost": "0",
                "link": "https://www.vutbr.cz/en/students/programmes/branch/13486",
                "practical_lectures": 76,
                "percentage_of_subjects_on": {
                    "computer_science": 20,
                    "cryptography": 12,
                    "humanistic": 32,
                    "mathematics": 20,
                    "privacy": 0,
                    "security": 16
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            },
            {
                "study_program": "Information Security",
                "department": "Faculty of Electrical Engineering and Communication",
                "degree": "Master",
                "degree_title": "Master",
                "language": [
                    "Czech"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.vutbr.cz/en/students/programmes/branch/12615",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 39,
                    "cryptography": 15,
                    "humanistic": 23,
                    "mathematics": 8,
                    "privacy": 0,
                    "security": 15
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 4
    },
    {
        "name": "Concordia University",
        "country": "Canada",
        "cor": {
            "lat": 45.568281,
            "lng": -122.636964
        },
        "world_university_rankings": "601-800",
        "programs": [
            {
                "study_program": "Information Systems Security",
                "department": "Concordia Institute for Information Systems Engineering, Gina Cody School of Engineering and Computer Science",
                "degree": "Master",
                "degree_title": "Master of Applied Science in Information Systems Security",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "18271",
                "link": "http://www.concordia.ca/academics/graduate/information-systems-masc.html",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 6.3,
                    "cryptography": 37.5,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 56.2
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy"
                ]
            }
        ],
        "id": 5
    },
    {
        "name": "Deakin University",
        "country": "Australia",
        "cor": {
            "lat": -38.198423,
            "lng": 144.298821
        },
        "world_university_rankings": "351-400",
        "programs": [
            {
                "study_program": "Master of Cyber Security",
                "department": "Faculty of Science, Engineering and Built Environment",
                "degree": "Master",
                "degree_title": "Master of Cyber Security",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "16740",
                "link": "https://www.deakin.edu.au/course/master-cyber-security",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 21.4,
                    "cryptography": 0,
                    "humanistic": 14.3,
                    "mathematics": 0,
                    "privacy": 12.5,
                    "security": 51.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Data Analysis",
                    "Network Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 6
    },
    {
        "name": "ETH Zurich",
        "country": "Switzerland",
        "cor": {
            "lat": 47.372515,
            "lng": 8.542198
        },
        "world_university_rankings": "13",
        "programs": [
            {
                "study_program": "Master in Cyber Security",
                "department": "Department of Computer Science",
                "degree": "Master",
                "degree_title": "MSc CS ETH Zurich - EPF Lausanne",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "2188",
                "link": "https://inf.ethz.ch/studies/master/master-cybsec.html",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "computer_science": "",
                    "cryptography": "",
                    "humanistic": "",
                    "mathematics": "",
                    "privacy": "",
                    "security": ""
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Physical Device Security",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 7
    },
    {
        "name": "Edith Cowan University",
        "country": "Australia",
        "cor": {
            "lat": -37.813222,
            "lng": 144.959656
        },
        "world_university_rankings": "501-600",
        "programs": [
            {
                "study_program": "Master of Cyber Security",
                "department": "School of Engineering",
                "degree": "Master",
                "degree_title": "Master of Cyber Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "27500",
                "link": "https://www.ecu.edu.au/degrees/courses/master-of-cyber-security",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 10,
                    "cryptography": 5,
                    "humanistic": 25,
                    "mathematics": 0,
                    "privacy": 15,
                    "security": 45
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            }
        ],
        "id": 8
    },
    {
        "name": "Eötvös Loránd University",
        "country": "Hungary",
        "cor": {
            "lat": 47.49097,
            "lng": 19.05876
        },
        "world_university_rankings": "601-800",
        "programs": [
            {
                "study_program": "Cyber Security",
                "department": "Faculty of Informatics",
                "degree": "Master",
                "degree_title": "Cyber Security in Computer Science MSc",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "12000",
                "link": "https://www.inf.elte.hu/en/content/cyber-security-in-computer-science-msc.t.1734?m=362",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "computer_science": 19.5,
                    "cryptography": 22.3,
                    "humanistic": 2.5,
                    "mathematics": 5.7,
                    "privacy": 19.5,
                    "security": 30.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 9
    },
    {
        "name": "George Washington University",
        "country": "USA",
        "cor": {
            "lat": 38.89898,
            "lng": -77.048717
        },
        "world_university_rankings": "181",
        "programs": [
            {
                "study_program": "Master of Cybersecurity Strategy & Information Management",
                "department": "Department of Professional Studies",
                "degree": "Master",
                "degree_title": "Master of Cybersecurity Strategy & Information Management",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "27100",
                "link": "https://www.programs.gwu.edu/graduate/cybersecurity-strategy-and-information-management",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 6.3,
                    "cryptography": 0,
                    "humanistic": 60.3,
                    "mathematics": 4.2,
                    "privacy": 14.6,
                    "security": 14.6
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Policy Development",
                    "Data Analysis",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Data, Asset and Inventory Management"
                ]
            },
            {
                "study_program": "Master of Science in Cybersecurity in Computer Science",
                "department": "Department of Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science in Cybersecurity in Computer Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "27100",
                "link": "https://www.cs.seas.gwu.edu/master-science-cybersecurity-computer-science",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 68.7,
                    "cryptography": 6.3,
                    "humanistic": 0,
                    "mathematics": 6.3,
                    "privacy": 0,
                    "security": 18.7
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 10
    },
    {
        "name": "Georgia Institute Of Technology",
        "country": "USA",
        "cor": {
            "lat": 33.7756,
            "lng": -84.396339
        },
        "world_university_rankings": "34",
        "programs": [
            {
                "study_program": "Master of Science in Cybersecurity – Information Security track",
                "department": "Institute for Information Security & Privacy",
                "degree": "Master",
                "degree_title": "Master Of Science in Cybersecurity – Information Security track",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "16838",
                "link": "https://www.scs.gatech.edu/content/master-science-information-security",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 17.8,
                    "humanistic": 3.6,
                    "mathematics": 0,
                    "privacy": 3.6,
                    "security": 75
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 11
    },
    {
        "name": "Hochschule Mannheim",
        "country": "Germany",
        "cor": {
            "lat": 49.470097,
            "lng": 8.482649
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Bachelor of Cyber Security",
                "department": "Computer Science",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German"
                ],
                "duration": "other",
                "cost": "0",
                "link": "https://www.hs-mannheim.de/studieninteressierte/studienangebot/bachelorstudiengaenge/cyber-security.html",
                "practical_lectures": 23.3,
                "percentage_of_subjects_on": {
                    "computer_science": 48.7,
                    "cryptography": 0,
                    "humanistic": 25,
                    "mathematics": 15,
                    "privacy": 2.5,
                    "security": 8.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 12
    },
    {
        "name": "Hochschule Mittweida",
        "country": "Germany",
        "cor": {
            "lat": 50.987614,
            "lng": 12.973113
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "IT-Sicherheit (IT-Security)",
                "department": "Applied Computer and Life Sciences",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German"
                ],
                "duration": "3 years",
                "cost": "0",
                "link": "https://www.cb.hs-mittweida.de/studium/it-sicherheit.html",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 52.3,
                    "cryptography": 4.3,
                    "humanistic": 13,
                    "mathematics": 4.3,
                    "privacy": 4.3,
                    "security": 21.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Master of Cybercrime/Cybersecurity",
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "German"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.cb.hs-mittweida.de/studienangebote-der-fakultaet/cybercrimecybersecurity.html",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 28.3,
                    "cryptography": 13.3,
                    "humanistic": 36.7,
                    "mathematics": 6.7,
                    "privacy": 1.7,
                    "security": 13.3
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management"
                ]
            }
        ],
        "id": 13
    },
    {
        "name": "Hochschule Offenburg",
        "country": "Germany",
        "cor": {
            "lat": 48.458849,
            "lng": 7.942106
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Unternehmens- und IT-Sicherheit (Corporate and IT security)",
                "department": "",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science (B.Sc.)",
                "language": [
                    "German"
                ],
                "duration": "other",
                "cost": "812",
                "link": "https://mi.hs-offenburg.de/studium/bachelor-studiengaenge/unternehmens-und-it-sicherheit/",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 33.6,
                    "cryptography": 3.4,
                    "humanistic": 32.8,
                    "mathematics": 6.9,
                    "privacy": 1.7,
                    "security": 21.6
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            },
            {
                "study_program": "Master of Enterprise and IT Security",
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "852",
                "link": "https://incoming.hs-offenburg.de/en/international-masters-degree-programs/enterprise-and-it-security/",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 13.9,
                    "cryptography": 11.1,
                    "humanistic": 22.2,
                    "mathematics": 0,
                    "privacy": 13.9,
                    "security": 38.9
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 14
    },
    {
        "name": "Hochschule Stralsund",
        "country": "Germany",
        "cor": {
            "lat": 54.338975,
            "lng": 13.074123
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Bachelor of IT Security and Mobile Systems",
                "department": "Electrical Engineering and Computer Science",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German"
                ],
                "duration": "other",
                "cost": "0",
                "link": "https://www.hochschule-stralsund.de/host/fakultaeten/elektrotechnik-und-informatik/studienangebot/it-sicherheit-und-mobile-systeme-smsb/",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 58.3,
                    "cryptography": 4.2,
                    "humanistic": 14.6,
                    "mathematics": 8.3,
                    "privacy": 2.1,
                    "security": 12.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 15
    },
    {
        "name": "Imperial College London",
        "country": "United Kingdom",
        "cor": {
            "lat": 51.49876,
            "lng": -0.174834
        },
        "world_university_rankings": "9th",
        "programs": [
            {
                "study_program": "MSc Computing (Security and Reliability)",
                "department": "Department of Computing",
                "degree": "Master",
                "degree_title": "MSc",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "16700",
                "link": "https://www.imperial.ac.uk/study/pg/computing/secure-software-systems/",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 100,
                    "cryptography": 0,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 0
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            },
            {
                "study_program": "MSc Security and Resilience: Science and Technology",
                "department": "Institute for Security Science and Technology",
                "degree": "Master",
                "degree_title": "MSc",
                "language": [
                    "English"
                ],
                "duration": "3 years",
                "cost": "11300",
                "link": "https://www.imperial.ac.uk/security-institute/education/msc/",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 17.9,
                    "cryptography": 3.6,
                    "humanistic": 42.7,
                    "mathematics": 3.6,
                    "privacy": 14.3,
                    "security": 17.9
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 16
    },
    {
        "name": "KAIST",
        "country": "South Korea",
        "cor": {
            "lat": 36.372125,
            "lng": 127.36039
        },
        "world_university_rankings": "102",
        "programs": [
            {
                "study_program": "Secure Computing",
                "department": "Graduate School of Information Security",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "",
                "link": "https://gsis.kaist.ac.kr/education/course",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 15.9,
                    "cryptography": 2.27,
                    "humanistic": 45.48,
                    "mathematics": 31.85,
                    "privacy": 0,
                    "security": 4.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 17
    },
    {
        "name": "Kaunas University of Technology",
        "country": "Lithuania",
        "cor": {
            "lat": 54.89909,
            "lng": 23.912835
        },
        "world_university_rankings": "1001+",
        "programs": [
            {
                "study_program": "Information and Information Technology Security",
                "department": "Faculty of Informatics, Computer Department",
                "degree": "Master",
                "degree_title": "Master of Computing",
                "language": [
                    "Lithuanian"
                ],
                "duration": "2 years",
                "cost": "7474",
                "link": "https://admissions.ktu.edu/programme/m-information-and-information-technology-security/",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "computer_science": 6.3,
                    "cryptography": 7.9,
                    "humanistic": 8.3,
                    "mathematics": 2.1,
                    "privacy": 2.1,
                    "security": 73.3
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 18
    },
    {
        "name": "Korea University",
        "country": "South Korea",
        "cor": {
            "lat": 37.591045,
            "lng": 127.027247
        },
        "world_university_rankings": "198",
        "programs": [
            {
                "study_program": "Information Security Convergence",
                "department": "Graduate School of Information Security",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "",
                "link": "https://infosecurity.korea.ac.kr/infosecurity/major/Major.do",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 58.33,
                    "cryptography": 16.67,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Data, Asset and Inventory Management"
                ]
            }
        ],
        "id": 19
    },
    {
        "name": "La Trobe University",
        "country": "Australia",
        "cor": {
            "lat": -37.720625,
            "lng": 145.048382
        },
        "world_university_rankings": "301-350",
        "programs": [
            {
                "study_program": "Master of Cybersecurity (Computer Science)",
                "department": "Department of Computer Science and Information Technology",
                "degree": "Master",
                "degree_title": "Master of Cybersecurity (Computer Science)",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "21850",
                "link": "https://www.latrobe.edu.au/courses/master-of-cybersecurity-computer-science",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 1.8,
                    "cryptography": 3.6,
                    "humanistic": 26.8,
                    "mathematics": 0,
                    "privacy": 1.8,
                    "security": 66
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy"
                ]
            }
        ],
        "id": 20
    },
    {
        "name": "Masaryk University",
        "country": "Czech Republic",
        "cor": {
            "lat": 49.21004562724486,
            "lng": 16.598971056631367
        },
        "world_university_rankings": "601 – 800",
        "programs": [
            {
                "study_program": "Computer Systems, Communication and Security",
                "department": "Faculty of Informatics",
                "degree": "Master",
                "degree_title": "Master",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.muni.cz/uchazeci/navazujici-magisterske-studium/vyberte-si-obor/25300-computer-systems-communication-and-security-eng?plan=1_25302",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 32,
                    "cryptography": 27,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 41
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 21
    },
    {
        "name": "Monash University",
        "country": "Australia",
        "cor": {
            "lat": -37.916097,
            "lng": 145.1507
        },
        "world_university_rankings": "84",
        "programs": [
            {
                "study_program": "Cybersecurity",
                "department": "Faculty of Information Technology, Cybersecurity Lab",
                "degree": "Master",
                "degree_title": "Master of Networks and Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "20000",
                "link": "https://www.monash.edu/study/courses/find-a-course/2019/networks-and-security-c6002",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 40.6,
                    "cryptography": 6.3,
                    "humanistic": 12.5,
                    "mathematics": 0,
                    "privacy": 12.5,
                    "security": 28.1
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 22
    },
    {
        "name": "New Brunswick Community College",
        "country": "Canada",
        "cor": {
            "lat": 45.961539,
            "lng": -66.6536
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Information Technology: Cybersecurity",
                "department": "Saint John Campus",
                "degree": "Master",
                "degree_title": "Master of Cybersecurity",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "9810",
                "link": "https://nbcc.ca/programs-courses/program-details?baseCurriculumId=dd3a4616-5e03-4a27-b585-d074efdd4178",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 3.1,
                    "cryptography": 0,
                    "humanistic": 25,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 71.9
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Network Management"
                ]
            }
        ],
        "id": 23
    },
    {
        "name": "Northeastern University Toronto",
        "country": "Canada",
        "cor": {
            "lat": 43.648791,
            "lng": -79.381304
        },
        "world_university_rankings": "184",
        "programs": [
            {
                "study_program": "Cybersecurity, Ms",
                "department": "Khoury College of Computer Sciences",
                "degree": "Master",
                "degree_title": "Master of Cybersecurity",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "23333",
                "link": "http://catalog.northeastern.edu/graduate/computer-information-science/cybersecurity/cybersecurity-ms/#programrequirementstext",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 13.9,
                    "humanistic": 30.6,
                    "mathematics": 0,
                    "privacy": 2.8,
                    "security": 52.7
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 24
    },
    {
        "name": "Norwegian University of Science and Technology",
        "country": "Norway",
        "cor": {
            "lat": 63.419336,
            "lng": 10.402351
        },
        "world_university_rankings": "351 – 400",
        "programs": [
            {
                "study_program": "Information Security",
                "department": "Faculty of Information Technology and Electrical Engineering.",
                "degree": "Master",
                "degree_title": "Master in Information Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.ntnu.edu/studies/mis",
                "practical_lectures": 20,
                "percentage_of_subjects_on": {
                    "computer_science": 22,
                    "cryptography": 10,
                    "humanistic": 28,
                    "mathematics": 0,
                    "privacy": 5,
                    "security": 35
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 25
    },
    {
        "name": "Orebro University",
        "country": "Sweden",
        "cor": {
            "lat": 59.254777,
            "lng": 15.249232
        },
        "world_university_rankings": "401 – 500",
        "programs": [
            {
                "study_program": "Master's Programme in Information Systems - Information Security Management",
                "department": "School of Business",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "20000",
                "link": "https://www.oru.se/english/study/master-students/masters-programmes/masters-programme-in-information-systems---information-security-management/",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 3,
                    "cryptography": 2,
                    "humanistic": 77,
                    "mathematics": 0,
                    "privacy": 2,
                    "security": 16
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            }
        ],
        "id": 26
    },
    {
        "name": "Red River College, Winnipeg",
        "country": "Canada",
        "cor": {
            "lat": 49.919051,
            "lng": -97.211275
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Information Security",
                "department": "",
                "degree": "Master",
                "degree_title": "Post-graduate diploma in Information Security",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "18440",
                "link": "https://catalogue.rrc.ca/Programs/WPG/Fulltime/INFEF-AD",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 5.6,
                    "cryptography": 6.9,
                    "humanistic": 41.7,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 45.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            }
        ],
        "id": 27
    },
    {
        "name": "Royal Holloway (University of London)",
        "country": "United Kingdom",
        "cor": {
            "lat": 51.425646,
            "lng": -0.562928
        },
        "world_university_rankings": "251-300th",
        "programs": [
            {
                "study_program": "Computer Science (Information Security)",
                "department": "Computer Science",
                "degree": "Bachelor",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "3 years",
                "cost": "31500",
                "link": "https://www.royalholloway.ac.uk/studying-here/undergraduate/computer-science/computer-science-information-security/",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 75,
                    "cryptography": 5,
                    "humanistic": 0,
                    "mathematics": 6.7,
                    "privacy": 0,
                    "security": 13.3
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Information Security",
                "department": "Information Security Group",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "12800",
                "link": "https://www.royalholloway.ac.uk/studying-here/postgraduate/information-security/information-security/",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 4.2,
                    "cryptography": 8.3,
                    "humanistic": 33.3,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 54.2
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy"
                ]
            },
            {
                "study_program": "Information Security with a Year in industry",
                "department": "Information Security Group",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "25600",
                "link": "https://www.royalholloway.ac.uk/studying-here/postgraduate/information-security/information-security-with-a-year-in-industry/",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 25,
                    "humanistic": 50,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy"
                ]
            },
            {
                "study_program": "Mathematics of Cryptography and Communications",
                "department": "Mathematics department & Information Security Group",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "9000",
                "link": "www.royalholloway.ac.uk/studying-here/postgraduate/mathematics/mathematics-of-cryptography-and-communications/",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "cryptography": 56.25,
                    "humanistic": 0,
                    "mathematics": 12.5,
                    "privacy": 0,
                    "security": 0,
                    "computer_science": 31.25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Business Continuity",
                    "Project Management",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 28
    },
    {
        "name": "Royal Melbourne Institute of Technology",
        "country": "Australia",
        "cor": {
            "lat": -37.81347,
            "lng": 144.966661
        },
        "world_university_rankings": "247",
        "programs": [
            {
                "study_program": "Master of Cyber Security",
                "department": "School of Engineering",
                "degree": "Master",
                "degree_title": "Master of Cyber Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "15360",
                "link": "https://www.rmit.edu.au/study-with-us/levels-of-study/postgraduate-study/masters-by-coursework/mc159",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 31.2,
                    "humanistic": 43.8,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 29
    },
    {
        "name": "Ruhr University Bochum",
        "country": "Germany",
        "cor": {
            "lat": 51.445626,
            "lng": 7.261674
        },
        "world_university_rankings": "251 - 300th",
        "programs": [
            {
                "study_program": "Bachelor IT-Security/Information Technology",
                "department": "Electrical Engineering and Information Technology",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German"
                ],
                "duration": "3 years",
                "cost": "1992",
                "link": "http://studienangebot.rub.de/de/it-sicherheit-informationstechnik/bachelor-1-fach",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 51.1,
                    "cryptography": 14.1,
                    "humanistic": 1.1,
                    "mathematics": 21.7,
                    "privacy": 2.2,
                    "security": 9.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Master IT-Security/Information Technology",
                "department": "Electrical Engineering and Information Technology",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "German"
                ],
                "duration": "2 years",
                "cost": "1328",
                "link": "http://studienangebot.rub.de/de/it-sicherheit-informationstechnik/master-1-fach",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": "",
                    "cryptography": "",
                    "humanistic": "",
                    "mathematics": "",
                    "privacy": "",
                    "security": ""
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            },
            {
                "study_program": "Master IT-Security/Networks and Systems",
                "department": "Electrical Engineering and Information Technology",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "German"
                ],
                "duration": "2 years",
                "cost": "1328",
                "link": "http://studienangebot.rub.de/de/it-sicherheit-netze-und-systeme/master-1-fach",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 44.5,
                    "cryptography": 33.3,
                    "humanistic": 0,
                    "mathematics": 11.1,
                    "privacy": 0,
                    "security": 11.1
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 30
    },
    {
        "name": "Sapienza University of Rome",
        "country": "Italy",
        "cor": {
            "lat": 41.901996392,
            "lng": 12.509497962
        },
        "world_university_rankings": "251–300th",
        "programs": [
            {
                "study_program": "Cybersecurity",
                "department": "Information Engineering, Informatics and Statistics",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "Italian"
                ],
                "duration": "2 years",
                "cost": "3000",
                "link": "https://corsidilaurea.uniroma1.it/en/corso/2018/29389/home",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 28.1,
                    "cryptography": 9.4,
                    "humanistic": 25,
                    "mathematics": 12.5,
                    "privacy": 0,
                    "security": 25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management"
                ]
            },
            {
                "department": "Facoltà di Farmacia e Medicina",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "Italian"
                ],
                "duration": "1 year",
                "cost": "3000",
                "link": "https://www.uniroma1.it/en/offerta-formativa/master/2019/scienze-forensi-criminologia-investigazione-security-intelligence",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 0,
                    "humanistic": 100,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 0
                },
                "study_program": "Scienze Forensi (Criminologia-Investigazione-Security-Intelligence)",
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Law, Policy, and Ethics",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            },
            {
                "study_program": "Sicurezza delle informazioni e informazione strategica",
                "department": "Dipartimento di Ingegneria informatica, automatica e gestionale",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "Italian"
                ],
                "duration": "1 year",
                "cost": "7500",
                "link": "https://www.uniroma1.it/en/offerta-formativa/master/2019/sicurezza-delle-informazioni-e-informazione-strategica",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 17.4,
                    "cryptography": 3.3,
                    "humanistic": 65.2,
                    "mathematics": 0,
                    "privacy": 7.6,
                    "security": 6.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 31
    },
    {
        "name": "Slovak University of Technology in Bratislava (STU)",
        "country": "Slovak Republic",
        "cor": {
            "lat": 48.154818,
            "lng": 17.117559
        },
        "world_university_rankings": "1001+",
        "programs": [
            {
                "study_program": "Information Security",
                "department": "Faculty of Informatics and Information Technologies",
                "degree": "Master",
                "degree_title": "Engineer",
                "language": [
                    "Slovak"
                ],
                "duration": "2 years",
                "cost": "",
                "link": "https://www.fiit.stuba.sk/study-programs.html?page_id=2090https://www.fiit.stuba.sk/bak/informacna-bezpecnost.html?page_id=1828",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 6.3,
                    "cryptography": 12.5,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 81.2
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 32
    },
    {
        "name": "Stockholm University",
        "country": "Sweden",
        "cor": {
            "lat": 59.363191,
            "lng": 18.060588
        },
        "world_university_rankings": "153",
        "programs": [
            {
                "study_program": "Master Programme in Information Security",
                "department": "Department of Computer and Systems Sciences",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "25000",
                "link": "https://www.su.se/english/search-courses-and-programmes/smino-1.411366?semester=HT19&eventcode=43360",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 10,
                    "cryptography": 5,
                    "humanistic": 32,
                    "mathematics": 5,
                    "privacy": 5,
                    "security": 43
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy"
                ]
            }
        ],
        "id": 33
    },
    {
        "name": "Syracuse University",
        "country": "USA",
        "cor": {
            "lat": 43.039153,
            "lng": -76.135245
        },
        "world_university_rankings": "251-300",
        "programs": [
            {
                "study_program": "Cybersecurity Semester",
                "department": "Electrical Engineering and Computer Science",
                "degree": "Bachelor",
                "degree_title": "Bachelor in Cybersecurity",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "",
                "link": "https://eng-cs.syr.edu/our-departments/electrical-engineering-and-computer-science/undergraduate/academic-programs/?programID=1557&degree=undergraduate_program",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 8.3,
                    "cryptography": 8.3,
                    "humanistic": 8.3,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 75.1
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Identity Management",
                    "Network Management"
                ]
            },
            {
                "study_program": "Master of Science in Cybersecurity",
                "department": "Electrical Engineering and Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science in Cybersecurity",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "42750",
                "link": "https://eng-cs.syr.edu/our-departments/electrical-engineering-and-computer-science/graduate/academic-programs/?programID=1557&degree=masters_program",
                "practical_lectures": "50 %",
                "percentage_of_subjects_on": {
                    "computer_science": 30,
                    "cryptography": 5,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 65
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 34
    },
    {
        "name": "Technical University of Darmstadt",
        "country": "Germany",
        "cor": {
            "lat": 49.874938,
            "lng": 8.656071
        },
        "world_university_rankings": "251 - 300th",
        "programs": [
            {
                "study_program": "IT security",
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "German",
                    "English"
                ],
                "duration": "2 years",
                "cost": "1080",
                "link": "https://www.tu-darmstadt.de/studieren/studieninteressierte/studienangebot_studiengaenge/studiengang_183744.de.jsp",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 16.7,
                    "cryptography": 41.7,
                    "humanistic": 0,
                    "mathematics": 8.3,
                    "privacy": 8.3,
                    "security": 25
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 35
    },
    {
        "name": "Technical University of Denmark",
        "country": "Denmark",
        "cor": {
            "lat": 55.785622,
            "lng": 12.521295
        },
        "world_university_rankings": "163th",
        "programs": [
            {
                "study_program": "Computer Science and Engineering",
                "department": "Department of Applied Mathematics and Computer Science",
                "degree": "Master",
                "degree_title": "MSc in Computer Science and Engineering",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.dtu.dk/english/education/msc/programmes/computer_science_and_engineering#study-programme__study-lines__computer-security",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 0,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 0
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 36
    },
    {
        "name": "Technická univerzita Ostrava",
        "country": "Czech Republic",
        "cor": {
            "lat": 49.8337,
            "lng": 18.163655
        },
        "world_university_rankings": "1001+",
        "programs": [
            {
                "study_program": "Information and Communication Security",
                "department": "Faculty of Electrical Engineering and Computer Science",
                "degree": "Master",
                "degree_title": "Engineer",
                "language": [
                    "Czech",
                    "English"
                ],
                "duration": "2 years",
                "cost": "0",
                "link": "https://www.vsb.cz/en/study/degree-students/master-degree/master-degree-detail/index.html?programmeId=791",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "computer_science": 50,
                    "cryptography": 7,
                    "humanistic": 7,
                    "mathematics": 7,
                    "privacy": 0,
                    "security": 29
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 37
    },
    {
        "name": "Technische Hochschule Deggendorf",
        "country": "Germany",
        "cor": {
            "lat": 48.829505,
            "lng": 12.954699
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Bachelor of Cyber Security",
                "department": "Computer Science",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science (B.Sc.)",
                "language": [
                    "German"
                ],
                "duration": "other",
                "cost": "52",
                "link": "https://www.th-deg.de/de/inf/studiengaenge/bachelor-cyber-security",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 46.9,
                    "cryptography": 8.3,
                    "humanistic": 0,
                    "mathematics": 12.5,
                    "privacy": 0,
                    "security": 32.3
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Industrial IT-Security",
                "department": "",
                "degree": "Master",
                "degree_title": "Master in Engineering",
                "language": [
                    "German"
                ],
                "duration": "other",
                "cost": "19910",
                "link": "https://www.th-deg.de/de/weiterbildung/master/master-cyber-security",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 8.3,
                    "cryptography": 0,
                    "humanistic": 41.7,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 50
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Physical Device Security"
                ]
            }
        ],
        "id": 38
    },
    {
        "name": "The Royal Institute of Technology (KTH)",
        "country": "Sweden",
        "cor": {
            "lat": 59.349825,
            "lng": 18.070414
        },
        "world_university_rankings": "187",
        "programs": [
            {
                "study_program": "Communications Systems specialization",
                "department": "Aalto Department of Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": 9000,
                "link": "https://secclo.eu/programme/programme/",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 66,
                    "cryptography": 3,
                    "humanistic": 13,
                    "mathematics": 0,
                    "privacy": 3,
                    "security": 15
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 39
    },
    {
        "name": "The University of Edinburgh",
        "country": "United Kingdom",
        "cor": {
            "lat": 55.944552,
            "lng": -3.189338
        },
        "world_university_rankings": "29th",
        "programs": [
            {
                "study_program": "Cyber Security, Privacy and Trust",
                "department": "Informatics",
                "degree": "Master",
                "degree_title": "MSc",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "15500",
                "link": "https://www.ed.ac.uk/studying/postgraduate/degrees/index.php?r=site/view&edition=2019&id=971",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 33.3,
                    "humanistic": 0,
                    "mathematics": 16.7,
                    "privacy": 8.3,
                    "security": 41.7
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 40
    },
    {
        "name": "UCL",
        "country": "United Kingdom",
        "cor": {
            "lat": 51.524613,
            "lng": -0.133986
        },
        "world_university_rankings": "14th",
        "programs": [
            {
                "study_program": "Security and Crime Science BSc",
                "department": "Department of Security and Crime Science",
                "degree": "Bachelor",
                "degree_title": "BSc",
                "language": [
                    "English"
                ],
                "duration": "3 years",
                "cost": "31500",
                "link": "https://www.ucl.ac.uk/security-crime-science/study/undergraduate/security-and-crime-science-bsc",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 8.8,
                    "cryptography": 0,
                    "humanistic": 64.7,
                    "mathematics": 11.8,
                    "privacy": 11.8,
                    "security": 2.9
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Law, Policy, and Ethics",
                    "Policy Development",
                    "Data Analysis",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Data, Asset and Inventory Management"
                ]
            },
            {
                "study_program": "Information Security MSc",
                "department": "Department of Computer Science",
                "degree": "Master",
                "degree_title": "MSc",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "14400",
                "link": "https://www.ucl.ac.uk/prospective-students/graduate/taught-degrees/information-security-msc",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 25,
                    "humanistic": 12.5,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 62.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            }
        ],
        "id": 41
    },
    {
        "name": "University of Bologna",
        "country": "Italy",
        "cor": {
            "lat": 44.489664,
            "lng": 11.338998
        },
        "world_university_rankings": "180th",
        "programs": [
            {
                "study_program": "Master in digital technology management/cyber security",
                "department": "Bologna Business School",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "14800",
                "link": "https://www.bbs.unibo.eu/hp/master-fulltime/digital-technology-management-cyber-security-2/#presentazione",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 6.3,
                    "cryptography": 3.1,
                    "humanistic": 57.8,
                    "mathematics": 0,
                    "privacy": 4.7,
                    "security": 28.1
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Analysis",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            }
        ],
        "id": 42
    },
    {
        "name": "University of Bonn",
        "country": "Germany",
        "cor": {
            "lat": 50.727057,
            "lng": 7.083336
        },
        "world_university_rankings": "110th",
        "programs": [
            {
                "study_program": "Bachelor Cyber Security",
                "department": "Computer Science",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German",
                    "English"
                ],
                "duration": "3 years",
                "cost": "1680",
                "link": "https://www.informatik.uni-bonn.de/de/fuer-studierende/bachelorstudiengang-cyber-security",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 48.53,
                    "cryptography": 1.47,
                    "humanistic": 13.24,
                    "mathematics": 20.59,
                    "privacy": 7.35,
                    "security": 8.82
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 43
    },
    {
        "name": "University of Bristol",
        "country": "United Kingdom",
        "cor": {
            "lat": 51.45841,
            "lng": -2.602883
        },
        "world_university_rankings": "78th",
        "programs": [
            {
                "department": "School of Mathematics",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "16400",
                "link": "http://www.bris.ac.uk/study/postgraduate/2019/sci/msc-mathematics-of-cybersecurity/",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 6.25,
                    "cryptography": 0,
                    "humanistic": 0,
                    "mathematics": 31.25,
                    "privacy": 43.75,
                    "security": 18.75
                },
                "study_program": "Master in Mathematics of Cybersecurity",
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Business Continuity",
                    "Project Management",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 44
    },
    {
        "name": "University of California, Berkeley",
        "country": "USA",
        "cor": {
            "lat": 37.87184,
            "lng": -122.258561
        },
        "world_university_rankings": "15",
        "programs": [
            {
                "study_program": "Master of Information and Cybersecurity",
                "department": "School of Information (I School)",
                "degree": "Master",
                "degree_title": "Master of Information and Cybersecurity",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "62839",
                "link": "https://cybersecurity.berkeley.edu/#curriculum",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 1.7,
                    "cryptography": 7.6,
                    "humanistic": 33.8,
                    "mathematics": 5.3,
                    "privacy": 10.6,
                    "security": 41
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            }
        ],
        "id": 45
    },
    {
        "name": "University of Las Palmas de Gran Canaria",
        "country": "Spain",
        "cor": {
            "lat": 28.099321,
            "lng": -15.419908
        },
        "world_university_rankings": "601-800th",
        "programs": [
            {
                "study_program": "Computer Engineering",
                "department": "Computer Engineering School",
                "degree_title": "",
                "language": [
                    "Spanish"
                ],
                "duration": "4 years",
                "link": "https://www2.ulpgc.es/index.php?pagina=plan_estudio&ver=wpe003&codTitulacion=4008&tipotitulacion=G&codPlan=41",
                "percentage_of_subjects_on": {
                    "computer_science": 72,
                    "cryptography": 0,
                    "humanistic": 6,
                    "mathematics": 16,
                    "privacy": 0,
                    "security": 6
                },
                "degree": "Bachelor",
                "cost": "3500",
                "practical_lectures": 75,
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate"
                ]
            }
        ],
        "id": 46
    },
    {
        "name": "University of Milan",
        "country": "Italy",
        "cor": {
            "lat": 45.456591507,
            "lng": 9.19008757298
        },
        "world_university_rankings": "301–350th",
        "programs": [
            {
                "study_program": "Computer Systems and Networks Security",
                "department": "Computer Systems and Networks Security",
                "degree": "Bachelor",
                "degree_title": "NA - Dottore",
                "language": [
                    "Italian"
                ],
                "duration": "3 years",
                "cost": "4000",
                "link": "https://www.unimi.it/en/education/computer-systems-and-networks-security-online",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 45,
                    "cryptography": 6.3,
                    "humanistic": 10,
                    "mathematics": 15,
                    "privacy": 1.3,
                    "security": 22.4
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Cybersecurity",
                "department": "Dipartimento di Informatica \"Giovanni degli Antoni\"",
                "degree": "Master",
                "degree_title": "Dottore Magistrale/ Master in cybersecurity",
                "language": [
                    "Italian"
                ],
                "duration": "1 year",
                "cost": "5000",
                "link": "https://www.unimi.it/it/corsi/corsi-post-laurea/master-e-perfezionamento/catalogo-master/aa-2019/2020-master-cybersecurity",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 7.7,
                    "cryptography": 1.9,
                    "humanistic": 53.9,
                    "mathematics": 0,
                    "privacy": 9.6,
                    "security": 26.9
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Analysis",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy"
                ]
            },
            {
                "study_program": "Informatics Security (Curriculum: Methods for information technology security)",
                "department": "Computer Science and Technology",
                "degree": "Master",
                "degree_title": "Dottore Magistrale",
                "language": [
                    "Italian"
                ],
                "duration": "2 years",
                "cost": "4000",
                "link": "https://www.unimi.it/en/ugov/of/cdsi20190000f2yof2",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 2.5,
                    "humanistic": 42.5,
                    "mathematics": 10,
                    "privacy": 10,
                    "security": 35
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Privacy",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Informatics Security (Curriculum: Safe systems)",
                "department": "Computer Science and Technology",
                "degree": "Master",
                "degree_title": "Dottore Magistrale",
                "language": [
                    "Italian"
                ],
                "duration": "2 years",
                "cost": "4000",
                "link": "https://www.unimi.it/en/ugov/of/cdsi20190000f2yof2",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 11.4,
                    "humanistic": 29.5,
                    "mathematics": 0,
                    "privacy": 9.1,
                    "security": 50
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Data Privacy"
                ]
            }
        ],
        "id": 47
    },
    {
        "name": "University of New South Wales Canberra",
        "country": "Australia",
        "cor": {
            "lat": -35.291808,
            "lng": 149.166184
        },
        "world_university_rankings": "101-150",
        "programs": [
            {
                "study_program": "Master of Cyber Security, Digital Forensics",
                "department": "UNSW Canberra Cyber",
                "degree": "Master",
                "degree_title": "Master of Cyber Security, Digital Forensics",
                "language": [
                    "English"
                ],
                "duration": "1 year",
                "cost": "17570",
                "link": "https://www.unsw.adfa.edu.au/degree/postgraduate-coursework/master-cyber-security-digital-forensics-8628",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 0,
                    "humanistic": 25,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 75
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Data Analysis",
                    "Data Privacy"
                ]
            }
        ],
        "id": 48
    },
    {
        "name": "University of Ontario Institute of Technology",
        "country": "Canada",
        "cor": {
            "lat": 43.945912,
            "lng": -78.895956
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Information Technology - Networking and Information Technology Security",
                "department": "Faculty of Business and Information Technology",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Information Technology (Honours)",
                "language": [
                    "English"
                ],
                "duration": "4 years",
                "cost": "22740",
                "link": "https://ontariotechu.ca/programs/business-and-information-technology/information-technology-networking-and-information-technology-security-specialization.php",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 36.8,
                    "cryptography": 2.8,
                    "humanistic": 13.2,
                    "mathematics": 13.2,
                    "privacy": 6.3,
                    "security": 27.7
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Networking and Information Technology Security - Advanced Entry",
                "department": "Faculty of Business and Information Technology",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Information Technology (Honours)",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "22740",
                "link": "https://ontariotechu.ca/programs/business-and-information-technology/information-technology-pathways-program-networking-and-information-technology-security-direct-entry.php",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 15.8,
                    "cryptography": 2.6,
                    "humanistic": 19.7,
                    "mathematics": 10.5,
                    "privacy": 11.8,
                    "security": 39.6
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics"
                ]
            },
            {
                "study_program": "Information Technology Security",
                "department": "Faculty of Business and Information Technology",
                "degree": "Master",
                "degree_title": "Master in Information Technology Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "16210",
                "link": "https://gradstudies.ontariotechu.ca/future_students/programs/masters_programs/information_technology_security/index.php",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 12.5,
                    "cryptography": 9.4,
                    "humanistic": 21.9,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 56.2
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics"
                ]
            }
        ],
        "id": 49
    },
    {
        "name": "University of Oslo",
        "country": "Norway",
        "cor": {
            "lat": 59.939969,
            "lng": 10.721814
        },
        "world_university_rankings": "121",
        "programs": [
            {
                "study_program": "Informatics: Programming and System Architecture",
                "department": "The Faculty of Mathematics and Natural Sciences",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "340",
                "link": "https://www.uio.no/english/studies/programmes/informatics-programming-master/programme-options/information-security/structure/index.html",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 8,
                    "cryptography": 17,
                    "humanistic": 17,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 58
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 50
    },
    {
        "name": "University of Oxford",
        "country": "United Kingdom",
        "cor": {
            "lat": 51.75481,
            "lng": -1.254431
        },
        "world_university_rankings": "1st",
        "programs": [
            {
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "33300",
                "link": "http://www.cs.ox.ac.uk/softeng/security/",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 0,
                    "humanistic": 0,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 0
                },
                "study_program": "Software and Systems Security",
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Identity Management",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            }
        ],
        "id": 51
    },
    {
        "name": "University of Saarland",
        "country": "Germany",
        "cor": {
            "lat": 49.254965,
            "lng": 7.040782
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Cybersecurity",
                "department": "Center for IT-Security, Privacy and Accountability (CISPA)",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science",
                "language": [
                    "German",
                    "English"
                ],
                "duration": "3 years",
                "cost": "1674",
                "link": "https://cysec.uni-saarland.de/bachelor/",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 58.93,
                    "cryptography": 10.71,
                    "humanistic": 0,
                    "mathematics": 21.43,
                    "privacy": 1.79,
                    "security": 7.14
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Software Development",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 52
    },
    {
        "name": "University of San Diego",
        "country": "USA",
        "cor": {
            "lat": 32.772145,
            "lng": -117.188224
        },
        "world_university_rankings": "601-800",
        "programs": [
            {
                "study_program": "Master of Science in Cyber Security Engineering",
                "department": "Center for Cyber Security Engineering and Technology",
                "degree": "Master",
                "degree_title": "Master of Science in Cyber Security Engineering ",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "34000",
                "link": "https://onlinedegrees.sandiego.edu/programs/master-of-science-in-cyber-security-engineering/",
                "practical_lectures": 75,
                "percentage_of_subjects_on": {
                    "computer_science": 5,
                    "cryptography": 15,
                    "humanistic": 2.5,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 77.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics"
                ]
            },
            {
                "study_program": "Master of Science in Cyber Security Operations and Leadership",
                "department": "Center for Cyber Security Engineering and Technology",
                "degree": "Master",
                "degree_title": "Master of Science in Cyber Security Operations and Leadership",
                "language": [
                    "English"
                ],
                "duration": "other",
                "cost": "34000",
                "link": "https://onlinedegrees.sandiego.edu/programs/master-of-science-in-cyber-security-operations-and-leadership/",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 5.6,
                    "cryptography": 13.8,
                    "humanistic": 5.6,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 75
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Identity Management",
                    "Network Management",
                    "Digital Forensics"
                ]
            }
        ],
        "id": 53
    },
    {
        "name": "University of Trento",
        "country": "Italy",
        "cor": {
            "lat": 46.06787,
            "lng": 11.12108
        },
        "world_university_rankings": "251-300th",
        "programs": [
            {
                "study_program": "EIT Digital track on Cyber Security",
                "department": "DISI security research group",
                "degree": "Master",
                "degree_title": "Master of Science in Computer Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "1500",
                "link": "https://securitylab.disi.unitn.it/doku.php?id=teaching_activitiesandhttps://masterschool.eitdigital.eu/programmes/cse/",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 4.2,
                    "cryptography": 16.7,
                    "humanistic": 33.3,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 45.8
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Problem Solving and Critical Thinking"
                ]
            },
            {
                "study_program": "Master in International Security Studies",
                "department": "School of International Studies (University of Trento) and Sant’Anna School of Advanced Studies (University of Pisa)",
                "degree": "Master",
                "degree_title": "Master of Science in International Security Studies",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "3345",
                "link": "https://international.unitn.it/miss/overview",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 0,
                    "cryptography": 0,
                    "humanistic": 100,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 0
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Risk Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Law, Policy, and Ethics",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            },
            {
                "study_program": "Master of Science in Computer Science  - curriculum \"Security and Privacy\"",
                "department": "Computer Science",
                "degree": "Master",
                "degree_title": "Master of Science in Computer Science",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "3345",
                "link": "https://offertaformativa.unitn.it/en/lm/computer-science/course-content",
                "practical_lectures": 25,
                "percentage_of_subjects_on": {
                    "computer_science": 13.8,
                    "cryptography": 11.1,
                    "humanistic": 55.7,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 19.4
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            },
            {
                "study_program": "Master of Science in Mathematics  - curriculum \"Coding Theory and Cryptography\" - (Research-oriented)",
                "department": "Department of Mathematics",
                "degree": "Master",
                "degree_title": "Master of Science in Mathematics",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "3345",
                "link": "https://offertaformativa.unitn.it/it/lm/matematica/cosa-si-studia#CodingTheoryCryptography",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 5,
                    "cryptography": 30,
                    "humanistic": 0,
                    "mathematics": 65,
                    "privacy": 0,
                    "security": 0
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Business Continuity",
                    "Project Management",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            },
            {
                "study_program": "Master of Science in Mathematics  - curriculum \"Coding Theory and Cryptography\" - (Stage-oriented)",
                "department": "Department of Mathematics",
                "degree": "Master",
                "degree_title": "Master of Science in Mathematics",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "3345",
                "link": "https://offertaformativa.unitn.it/it/lm/matematica/cosa-si-studia#CodingTheoryCryptography",
                "practical_lectures": 50,
                "percentage_of_subjects_on": {
                    "computer_science": 37.5,
                    "cryptography": 25,
                    "humanistic": 0,
                    "mathematics": 37.5,
                    "privacy": 0,
                    "security": 0
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Intelligence Analysis",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 54
    },
    {
        "name": "University of Winnipeg",
        "country": "Canada",
        "cor": {
            "lat": 49.891261,
            "lng": -97.153326
        },
        "world_university_rankings": "",
        "programs": [
            {
                "study_program": "Network Security Diploma",
                "department": "Professional, Applied & Continuing Education (PACE)",
                "degree": "Master",
                "degree_title": "Master of Network Security (Network Security Diploma)",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "14333",
                "link": "http://pace.uwinnipegcourses.ca/full-time-programs/network-security-diploma",
                "practical_lectures": "",
                "percentage_of_subjects_on": {
                    "computer_science": 13.7,
                    "cryptography": 0,
                    "humanistic": 46,
                    "mathematics": 0,
                    "privacy": 0,
                    "security": 40.3
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Information Security Controls Assessment",
                    "Enterprise Architecture and Infrastructure Design",
                    "Intelligence Analysis",
                    "Organizational Awareness",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management"
                ]
            }
        ],
        "id": 55
    },
    {
        "name": "Warsaw University of Technology",
        "country": "Poland",
        "cor": {
            "lat": 52.218832458,
            "lng": 21.006333308
        },
        "world_university_rankings": "1001+",
        "programs": [
            {
                "study_program": "Cybersecurity",
                "department": "The Faculty of Electronics and Information Technology, The Institute of Telecommunications, Division of Cybersecurity",
                "degree": "Bachelor",
                "degree_title": "Bachelor of Science in Engineering in the field of Cybersecurity",
                "language": [
                    "English",
                    "Polish"
                ],
                "duration": "other",
                "cost": "",
                "link": "https://usosweb.usos.pw.edu.pl/kontroler.php?_action=katalog2%2Fprogramy%2FpokazProgram&prg_kod=103B-ISP-CB&lang=en",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "humanistic": 0,
                    "mathematics": 6.2,
                    "privacy": 0,
                    "security": 51.3,
                    "computer_science": 15,
                    "cryptography": 27.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Identity Management",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security"
                ]
            }
        ],
        "id": 56
    },
    {
        "name": "Yeungnam University",
        "country": "South Korea",
        "cor": {
            "lat": 35.832242,
            "lng": 128.757575
        },
        "world_university_rankings": "601-800",
        "programs": [
            {
                "study_program": "Cybersecurity Curriculum",
                "department": "Department of Cyber Security",
                "degree": "Master",
                "degree_title": "",
                "language": [
                    "English"
                ],
                "duration": "3 years",
                "cost": "",
                "link": "http://secure.ync.ac.kr/about/about05.html",
                "practical_lectures": 0,
                "percentage_of_subjects_on": {
                    "computer_science": 34.1,
                    "cryptography": 2.45,
                    "humanistic": 19.51,
                    "mathematics": 2.44,
                    "privacy": 0,
                    "security": 41.5
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Testing and Evaluation",
                    "Incident Management",
                    "Enterprise Architecture and Infrastructure Design",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Law, Policy, and Ethics",
                    "Network Management",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Problem Solving and Critical Thinking"
                ]
            }
        ],
        "id": 57
    },
    {
        "name": "École Polytechnique Fédérale de Lausanne",
        "country": "Switzerland",
        "cor": {
            "lat": 46.518831258,
            "lng": 6.559331096
        },
        "world_university_rankings": "38",
        "programs": [
            {
                "study_program": "Computer Science - Cybersecurity",
                "department": "School of Computer and Communication Sciences",
                "degree": "Master",
                "degree_title": "Master in Cyber Security",
                "language": [
                    "English"
                ],
                "duration": "2 years",
                "cost": "",
                "link": "https://www.epfl.ch/education/master/programs/cyber-security/https://edu.epfl.ch/studyplan/en/master/computer-science-cybersecurity",
                "practical_lectures": 100,
                "percentage_of_subjects_on": {
                    "humanistic": 0,
                    "mathematics": 10,
                    "privacy": 30.2,
                    "security": 17.6,
                    "computer_science": 30.2,
                    "cryptography": 12
                },
                "note": "Data collected in academic year 2019-2020",
                "rewire_skills": [
                    "Strategic Relationship Management",
                    "Collaborate and Communicate",
                    "Threat Analysis",
                    "Data Security",
                    "Information Systems and Network Security",
                    "Risk Management",
                    "Testing and Evaluation",
                    "Enterprise Architecture and Infrastructure Design",
                    "Business Continuity",
                    "Project Management",
                    "Organizational Awareness",
                    "Software Development",
                    "Identity Management",
                    "Policy Development",
                    "Data Analysis",
                    "Network Management",
                    "Digital Forensics",
                    "Education and Training Delivery",
                    "Workforce Management",
                    "Data Privacy",
                    "Physical Device Security",
                    "Data, Asset and Inventory Management",
                    "Problem Solving and Critical Thinking",
                    "Technology Fluency"
                ]
            }
        ],
        "id": 58
    }
]

export default studyPrograms
