import React from 'react'
import Axios from 'axios'
import { Button, Divider, Empty, Input, Modal, Popover, Result, Space, Spin, Table, Tooltip, Typography  } from 'antd'
import { DeleteOutlined, DownOutlined, EditOutlined, InfoCircleOutlined, LeftOutlined, LoadingOutlined, PlusOutlined, QuestionCircleOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import Chart from "react-apexcharts"

import {Config} from './config_section.js'
import './JobAdsAnalyzer.scss'
import AddJobModalForm from './modals/AddJobModalForm'
import EditJobModalForm from './modals/EditJobModalForm'
import MapModal from './modals/MapModal'
import CreateYourAd from './CreateYourAd'
import ModelStatistics from './ModelStatistics'
import JobStatistics from './JobStatistics'

const { confirm } = Modal
const { Link, Title } = Typography

const JobAdsAnalyzer = ({ showMessage, authData, user, section }) => {

  // state hook
  const [jobs, setJobs] = React.useState(null)
  const [modelStatistics, setModelStatistics] = React.useState(null)
  const [jobStatistics, setJobStatistics] = React.useState(null)
  const [rsSkillsGroup, setRsSkillsGroup] = React.useState(null)
  const [rsSpecificKnowledge, setRsSpecificKnowledge] = React.useState(null)
  const [rsSpecificSkills, setRsSpecificSkills] = React.useState(null)
  const [rsProfiles, setRsProfiles] = React.useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([])
  const [addModalVisible, setAddModalVisible] = React.useState(false)
  const [editModalVisible, setEditModalVisible] = React.useState(false)
  const [mapModalVisible, setMapModalVisible] = React.useState(false)
  const [processLoading, setProcessLoading] = React.useState(false)
  const [resultsExpanded, setResultsExpanded] = React.useState(false)
  const [processed, setProcessed] = React.useState(null)
  const [filteredInfo, setFilteredInfo] = React.useState({})
  const [sortedInfo, setSortedInfo] = React.useState({})
  const [searchText, setSearchText] = React.useState('')
  const [searchedColumn, setSearchedColumn] = React.useState('')
  const searchInput = React.useRef(null)

  /*
  stavy dat:
    init loading = null
    chyba pri loadingu = 0
    prazdne data = []
    plne data = [data]
  */

  // componentDidMount
  React.useEffect(() => {
     getData()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getData () {
    setJobs(null)
    setModelStatistics(null)
    setJobStatistics(null)
    setRsSkillsGroup(null)
    setRsSpecificKnowledge(null)
    setRsSpecificSkills(null)
    setRsProfiles(null)
    Axios.get( Config.server.getJobs)
      .then((response) => {
        if (response && response.data.error === false){
          const data = response.data.data
          setJobs(data.jobs.map(obj=> ({ ...obj, key: obj.id})))
          setModelStatistics({
            top_skills: sortSkillResults(data.model_statistics.top_skills, data.rs_skill_group),
            radar_plot: sortSkillResults(data.model_statistics.radar_plot, data.rs_skill_group)
          })
          setJobStatistics(data.job_statistics.data)
          setRsSkillsGroup(data.rs_skill_group.map(obj=> ({ ...obj, key: obj.id})))
          setRsSpecificKnowledge(data.rs_specific_knowledge.map(obj=> ({ ...obj, key: obj.id})))
          setRsSpecificSkills(data.rs_specific_skills.map(obj=> ({ ...obj, key: obj.id})))
          setRsProfiles(data.rs_profiles.map(obj=> ({ ...obj, key: obj.id})))
        } else {
          console.log(response.data.message)
          setJobs(0)
          setModelStatistics(0)
          setJobStatistics(0)
          setRsSkillsGroup(0)
          setRsSpecificKnowledge(0)
          setRsSpecificSkills(0)
          setRsProfiles(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setJobs(0)
        setModelStatistics(0)
        setJobStatistics(0)
        setRsSkillsGroup(0)
        setRsSpecificKnowledge(0)
        setRsSpecificSkills(0)
        setRsProfiles(0)
      })
  }

  function dataStatus() {
    if (
      jobs === null &&
      modelStatistics === null &&
      jobStatistics === null &&
      rsSkillsGroup === null &&
      rsSpecificKnowledge === null &&
      rsSpecificSkills === null &&
      rsProfiles === null
    ) {
      return "loading"
    } else if (
      Array.isArray(jobs) &&
      typeof modelStatistics === 'object' && modelStatistics !== null &&
      typeof jobStatistics === 'object' && jobStatistics !== null &&
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(rsSpecificKnowledge) &&
      Array.isArray(rsSpecificSkills) &&
      Array.isArray(rsProfiles)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }

  function isRowEditable (record) {
    if (user) {
      if ((user.id === record.owner && user.admin_level === 1) || user.admin_level === 2) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  function showDeleteConfirm (id) {
    confirm({
      title: 'Are you sure you want to delete this job?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return new Promise((resolve, reject) => {
          Axios.post( Config.server.deleteJob, {...authData, id: id}, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
              if (response && response.data.error === false){
                showMessage({type: 'success', content: "Job successfully deleted."})
                getData()
                resolve()
              } else {
                showMessage({type: 'error', content: "Something went wrong."})
                reject()
              }
            })
            .catch((error) => {
              console.log(error)
              showMessage({type: 'error', content: "Something went wrong."})
              reject()
            })
        }).catch(() => {
          showMessage({type: 'error', content: "Something went wrong."})
        })
      }
    })
  }

  function modalConfirm () {
    setAddModalVisible(false)
    setEditModalVisible(false)
    getData()
  }

  function filterViaMap (mapFilteredData) {
    setFilteredInfo({country: mapFilteredData.countries})
    setMapModalVisible(false)
  }

  function resetFilterSorter () {
    setFilteredInfo({})
    setSortedInfo({})
    setSearchText('')
  }

  function changeFilterSorter (pagination, filters, sorter, extra) {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }

  function sortSkillResults (array, rs_skill_group) {
    if (rs_skill_group === null) rs_skill_group = rsSkillsGroup
    return array.sort((a,b) => {
      if (a.percentage > b.percentage) return -1
	    if (a.percentage < b.percentage) return 1
      if (rs_skill_group.find(e=>e.id === a.skillId).name > rs_skill_group.find(e=>e.id === b.skillId).name) return 1
	    if (rs_skill_group.find(e=>e.id === a.skillId).name < rs_skill_group.find(e=>e.id === b.skillId).name) return -1
      else return 0
    })
  }

  function processPrediction () {
    showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0})
    setProcessLoading(true)
    Axios.post( "https://cyberability-platform.informacni-bezpecnost.cz/data/job-ads-analyzer/processPrediction.php", {keys: selectedRowKeys, result: 'raw'}, {headers: { 'Content-Type': 'application/json' }})
    // Axios.post( Config.server.processPrediction, {keys: selectedRowKeys}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        setProcessLoading(false)
        showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        if (response && response.data.error === false){
          showMessage({type: 'success', content: "Done."})
          setProcessed(sortSkillResults(response.data.data.prediction, null))
        } else {
          console.log(response.data.message)
          showMessage({type: 'error', content: "Something went wrong!"})
        }
      })
      .catch((error) => {
        setProcessLoading(false)
        showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        console.log(error)
        showMessage({type: 'error', content: "Something went wrong!"})
      })
  }

  function getColumnSearchProps (dataIndex) { return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm()
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="searchButtons">
          <Button
            type="link"
            size="small"
            disabled={selectedKeys.length === 0}
            onClick={() => {
              clearFilters()
              setSearchText('')
              confirm()
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={() => {
              confirm()
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100)
      }
    },
  }}

  function getColumnOptions (column) {
    if (jobs) {
      if (column === "date") {
        return Array.from(new Set(jobs.map(s => moment(s[column]).format('YYYY')))).map(t => {return {text: t, value: t}}).sort((a, b) => a.text.localeCompare(b.text))
      } else {
        // compare the items ignoring the case, but for "equal" strings it picks the first occurrence
        return jobs.map(s => s[column]).reduce((result, element) => {
          var normalize = x => typeof x === 'string' ? x.toLowerCase() : x
          var normalizedElement = normalize(element)
          if (result.every(otherElement => normalize(otherElement) !== normalizedElement)) result.push(element)
          return result
        }, []).map(t => {return {text: t, value: t}}).sort((a, b) => a.text.localeCompare(b.text))
      }
    } else {
      return null
    }
  }

  function radarPlotData() {
    if (!modelStatistics.hasOwnProperty('radar_plot')) return null
    let output = modelStatistics.radar_plot.map(e => {
      let resultRecord = processed.find(i => i.skillId === e.skillId)
      e.analysis_percentage = resultRecord ? resultRecord.percentage : 0
      return e
    })
    return output
  }

  const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

  const tableColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
      filteredValue: filteredInfo.title || null,
      sorter:  (a, b) => a.title.localeCompare(b.title),
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: (text, record) => (
        <table><tbody><tr><td>
          {searchedColumn === "title" ? (
            <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text : ""} />
          ) : (
            record.title
          )}
        </td><td>
          <div className="infoIcon">
            {record.description ? (
              <Popover placement="right" title="Job description" content={
                <div className="infoPopover">{record.description}</div>
              }>
                <InfoCircleOutlined />
              </Popover>
            ) : ( null )}
          </div>
        </td></tr></tbody></table>
      )
    },
    {
      title: 'Source',
      // dataIndex: 'source',
      key: 'source',
      width: "92px",
      filters: getColumnOptions("source"),
      filterSearch: true,
      filteredValue: filteredInfo.source || null,
      onFilter: (value, record) => record.source.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter:  (a, b) => a.source.localeCompare(b.source),
      sortOrder: sortedInfo.columnKey === 'source' && sortedInfo.order,
      render: (record) => record.link ? <a href={record.link} target="_blank" rel="noreferrer">{record.source}</a> : record.source
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: "15%",
      filters: getColumnOptions("company"),
      filterSearch: true,
      filteredValue: filteredInfo.company || null,
      onFilter: (value, record) => record.company.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter:  (a, b) => a.company.localeCompare(b.company),
      sortOrder: sortedInfo.columnKey === 'company' && sortedInfo.order,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: "10%",
      filters: getColumnOptions("country"),
      filterSearch: true,
      filteredValue: filteredInfo.country || null,
      onFilter: (value, record) => record.country.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter:  (a, b) => a.country.localeCompare(b.country),
      sortOrder: sortedInfo.columnKey === 'country' && sortedInfo.order
    },
    {
      title: 'Continent',
      dataIndex: 'continent',
      key: 'continent',
      width: "96px",
      filteredValue: null,
      sorter:  (a, b) => a.continent.localeCompare(b.continent),
      sortOrder: sortedInfo.columnKey === 'continent' && sortedInfo.order
    },
    {
      title: 'Year',
      dataIndex: 'date',
      key: 'date',
      width: "65px",
      // filters: getColumnOptions("date"),
      // filterSearch: true,
      // filteredValue: filteredInfo.year || null,
      // onFilter: (value, record) => record.date.indexOf(value) === 0,
      filteredValue: null,
      sorter:  (a, b) => a.date.localeCompare(b.date),
      sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
      render: (record) =>  moment(record).format('YYYY')
    },
    {
      title: 'Profile',
      dataIndex: 'enisa_profile',
      key: 'enisa_profile',
      width: "95px",
      filters: getColumnOptions("enisa_profile"),
      filterSearch: true,
      filteredValue: filteredInfo.enisa_profile || null,
      onFilter: (value, record) => record.enisa_profile.toLowerCase() === value.toLowerCase(),
      sorter:  (a, b) => a.enisa_profile.localeCompare(b.enisa_profile),
      sortOrder: sortedInfo.columnKey === 'enisa_profile' && sortedInfo.order,
      render: (text) => {
        return <Tooltip title={text}>{
          text === "Cybersecurity Architect" ? "CAr" :
          text === "Cybersecurity Auditor" ? "CAu" :
          text.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
        }</Tooltip>
      }
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: "80px",
    //   filteredValue: filteredInfo.type || null,
    //   onFilter: (value, record) => record.type.toLowerCase() === value.toLowerCase(),
    //   filterMultiple: false,
    //   filters: getColumnOptions("type"),
    //   sorter:  (a, b) => a.type.localeCompare(b.type),
    //   sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
    // },
  ]

  const tableColumnsAdmin = user ? [
    {
      title: 'Partners',
      dataIndex: 'partners',
      key: 'partners',
      width: "100px",
      filters: getColumnOptions("partners"),
      filterSearch: true,
      filteredValue: filteredInfo.partners || null,
      onFilter: (value, record) => record.partners.toLowerCase().indexOf(value.toLowerCase()) === 0,
      sorter:  (a, b) => a.partners.localeCompare(b.partners),
      sortOrder: sortedInfo.columnKey === 'partners' && sortedInfo.order
    },
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      filteredValue: null,
      render: (record) => (
        <Space size="small">
          {isRowEditable(record) ? <Tooltip title="Edit row"><EditOutlined className="editIcon" onClick={() => setEditModalVisible(jobs.find(obj => obj.id === record.id))} /></Tooltip> : <EditOutlined className="disabledIcon" title="Not allowed"/> }
          {isRowEditable(record) ? <Tooltip title="Delete row"><DeleteOutlined className="deleteIcon" onClick={() => showDeleteConfirm(record.id)} /></Tooltip> : <DeleteOutlined className="disabledIcon" title="Not allowed"/>}
        </Space>
      ),
    }
  ] : []

  const resultsColumns = [

    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      render: value => rsSkillsGroup.find(e => e.id === value).name
    },
    {
      title: 'Occurrence',
      dataIndex: 'percentage',
      key: 'percentage',
      width: "90px",
      align: "right",
      render: value => <>{value} %</>
    }
  ]

  const topSkillsColumns = [
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      render: value => rsSkillsGroup.find(e => e.id === value).name
    },
    {
      title: 'Occurrence',
      dataIndex: 'percentage',
      key: 'percentage',
      width: "90px",
      align: "right",
      render: value => <>{value} %</>
    }
  ]

  return (
    <section id="job-ads-analyzer">
    {
      section === "job-ads-analyzer-statistics-model" ? (
        <ModelStatistics rsSkillsGroup={rsSkillsGroup} modelStatistics={modelStatistics}/>
      ) : section.includes("job-ads-analyzer-statistics-job") ? (
        <JobStatistics rsSkillsGroup={rsSkillsGroup} jobStatistics={jobStatistics} jobs={jobs} rsProfiles={rsProfiles}/>
      ) : section.includes("job-ads-analyzer-create-your-ad") ? (
        <CreateYourAd showMessage={showMessage} rsSkillsGroup={rsSkillsGroup} rsProfiles={rsProfiles}/>
      ) : (
        <>
          <span><Title className="pageTitle">Cybersecurity Job Ads Analyzer</Title></span>
          {dataStatus() === "loading" ? (
            <div className="vertCenter">
              <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          ) : dataStatus() === "error" ? (
            <div className="vertCenter">
              <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
              />
            </div>
          ) : jobs.length === 0 ? (
            <div className="vertCenter">
              <Empty/>
            </div>
          ) : processed === null ? (
            <section className="main-content">

              <div className="dataHandleButtons" >
                <Space>
                  <Tooltip title={user? "" : "Only for registered users."}><Button onClick={()=>setAddModalVisible(true)} disabled={user ? false : true} ><PlusOutlined className="icon-position-fix"/> Add job</Button></Tooltip>
                  <Button disabled={Object.keys(sortedInfo).length === 0 && Object.keys(filteredInfo).length === 0 && searchText === ""} onClick={()=>resetFilterSorter()} >Clear filters and sorters</Button>
                  <Button disabled={selectedRowKeys.length < 1} onClick={()=>setSelectedRowKeys([])} >Clear selection</Button>
                </Space>
                <Button className="right" onClick={()=>setMapModalVisible(true)} >Show in map</Button>
              </div>

              <Table size="small"
                dataSource={jobs}
                columns={[...tableColumns, ...tableColumnsAdmin]}
                scroll={{ y: "calc(100vh - 470px)" }}
                rowSelection={{
                  selectedRowKeys,
                  columnWidth: "40px",
                  onChange: setSelectedRowKeys,
                  selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
                }}
                onChange={changeFilterSorter}
                footer={() =>
                  <Space size="large">
                    <span>Selected {selectedRowKeys.length} items</span>
                    <Button type="primary" disabled={!selectedRowKeys.length} loading={processLoading} onClick={()=>processPrediction()}>Process</Button>
                  </Space>
                }
              />
            </section>
          ) : (
            <section className="main-content results">
              <div className="dataHandleButtons" >
                <Space size="large" align="start">
                  <Button onClick={()=>setProcessed(null)} className="icon-position-fix"><LeftOutlined /> Back</Button>
                </Space>
              </div>

              <Divider><Title level={4}>Analysis results</Title></Divider>

              <Table size="small" pagination={false} columns={resultsColumns} dataSource={resultsExpanded ? processed : processed.slice(0, 10)} className="resultTable" title={() => 'Skill occurrence in your job ads selection'} rowKey="rank"/>

              <div className="showMore">
                {processed.length > 10 ? (
                  <Link onClick={()=>{
                    setResultsExpanded(resultsExpanded ? false : true)
                  }}>
                    {resultsExpanded ? <><UpOutlined /> Show less</> : <><DownOutlined /> Show more</>}
                  </Link>
                ) : ( "&nbsp" )}
              </div>

              {modelStatistics.hasOwnProperty('top_skills') && modelStatistics.hasOwnProperty('radar_plot')  ? (
                <>
                  <Divider><Title level={4}>Current statistics</Title></Divider>

                  <Table size="small" pagination={false} columns={topSkillsColumns} dataSource={modelStatistics.top_skills} className="topSkillsTable" title={() => 'List of the 10 most sought-after skills (for medium dataset)'} rowKey="rank"/>

                  {Array.isArray(radarPlotData()) ? (
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[
                          {
                            name: "Your skill occurrence",
                            data: radarPlotData().map(e => e.analysis_percentage)
                          },
                          {
                            name: "Skill occurrence",
                            data: radarPlotData().map(e => e.percentage)
                          }
                        ]}
                        options= {{
                          colors: [colors[3], colors[0]],
                          legend: {
                            offsetY: -100
                          },
                          chart: {
                            toolbar: {
                              show: false
                            },
                          },
                          xaxis: {
                            categories: radarPlotData().map(i => rsSkillsGroup.find(e => e.id === i.skillId).name)
                          },
                          yaxis: {
                            labels: {
                              formatter: function (value) {
                                return value + " %";
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  ) : null }
                </>
              ) : null }

            </section>
          )}
        </>
      )}
      <AddJobModalForm jobs={jobs} visible={addModalVisible} userId={user ? user.id : null} onModalConfirm={modalConfirm} showMessage={showMessage} onCancel={()=>setAddModalVisible(false)} authData={authData} />
      <EditJobModalForm jobs={jobs} visible={editModalVisible} onModalConfirm={modalConfirm} showMessage={showMessage} onCancel={()=>setEditModalVisible(false)} authData={authData} />
      {Array.isArray(jobs) ? <MapModal jobs={jobs} visible={mapModalVisible} filterViaMap={filterViaMap} onCancel={()=>setMapModalVisible(false)} /> : null}
    </section>
  )
}

export default JobAdsAnalyzer
