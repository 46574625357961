import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import { Button, Modal, Space, Typography } from 'antd'

const { Title } = Typography

const FeedbackSection= ({ showMessage, visitorId, setAskToFeedback, askToFeedback }) => {
  const feedbackType = "feedback_section"

  const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false)
  const [feedbackModalTimer, setFeedbackModalTimer] = React.useState(null)
  const [answer, setAnswer] = React.useState([])
  
  React.useEffect(() => {
    if (visitorId && askToFeedback === feedbackType) {
      visitorFeedback()
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorId, askToFeedback])
  
  React.useEffect(() => {    
    return () => {
      clearTimeout(feedbackModalTimer)
    }
  }, [feedbackModalTimer])

  function visitorFeedback () {
    Axios.post( Config.server.visitorFeedback, {visitor_id: visitorId, feedback_type: feedbackType}, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      if (!response.data.error) {
        if (response.data.ask_feedback === false) {
          // console.log("Feedback: User already answered. Move to next question.")
          setFeedbackModalVisible(false)
          setAskToFeedback("")
        } else {
          // console.log("Feedback: Ask to user!")
          setFeedbackModalTimer(
            setTimeout(() => {
              setFeedbackModalVisible(true)
            }, 120000) // 2 minutes in milliseconds
            // }, 2000) // 2 seconds in milliseconds
          )
        }
      } else {
        console.log("Feedback error: " + response.data.message)
        setFeedbackModalVisible(false)
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  function handleAnswer(element) {
    if (answer.includes(element)) {
      setAnswer(answer.filter(item => item !== element))
    } else {
      setAnswer([...answer, element])
    }
  }

  function sendFeedback(){
    showMessage({type: 'success', content: "Thank you for your feedback."})
    setFeedbackModalVisible(false)
    Axios.post( Config.server.visitorFeedback, {visitor_id: visitorId, feedback_type: feedbackType, answer: answer}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        if (response && response.data.error === false) {
          // console.log("Feedback: User answered. Move to next question.")
          setAskToFeedback("")
        } else {
          console.log(response.data.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  return (
    <Modal
      centered
      // open={true}
      open={feedbackModalVisible}
      title="Please provide us with feedback"
      width={400}
      footer={null}
      onCancel={()=>setFeedbackModalVisible(false)}
      className="feedback-modal section"
    >
      <Title level={4}>Which module did you find more useful?</Title>
      <Space direction="vertical" align="center" size="middle">
        {["CyberSecurity Profiler", "Job Analyzer", "ECSF Roles", "Career Path"].map((moduleName, index) => 
          <div key={index} className={answer.includes(moduleName) ? "pseudo-button selected" : "pseudo-button"} onClick={()=>handleAnswer(moduleName)}>{(index+1) + ". " + moduleName}</div>
        )}
        
        <Button type="primary" size="medium" className="send-button" disabled={answer.length < 1} onClick={sendFeedback}>Confirm</Button>
      </Space>
      
    </Modal>
  )
}

export default FeedbackSection
