import { ServerURL } from '../../config.js'

let subfolder = "admin"

export const Config = {
	server	: {
		"getData"							: ServerURL+subfolder+"/getData.php",
		"addCertification"					: ServerURL+subfolder+"/addCertification.php",
		"editCertification"					: ServerURL+subfolder+"/editCertification.php",
		"deleteCertification"				: ServerURL+subfolder+"/deleteCertification.php",
		"addStudyProgram"					: ServerURL+subfolder+"/addStudyProgram.php",
		"editStudyProgram"					: ServerURL+subfolder+"/editStudyProgram.php",
		"deleteStudyProgram"				: ServerURL+subfolder+"/deleteStudyProgram.php",
		"addTraining"						: ServerURL+subfolder+"/addTraining.php",
		"editTraining"						: ServerURL+subfolder+"/editTraining.php",
		"deleteTraining"					: ServerURL+subfolder+"/deleteTraining.php",
		"addSkillsGroup"					: ServerURL+subfolder+"/addSkillsGroup.php",
		"editSkillsGroup"					: ServerURL+subfolder+"/editSkillsGroup.php",
		"deleteSkillsGroup"					: ServerURL+subfolder+"/deleteSkillsGroup.php",
		"addProfile"						: ServerURL+subfolder+"/addProfile.php",
		"editProfile"						: ServerURL+subfolder+"/editProfile.php",
		"deleteProfile"						: ServerURL+subfolder+"/deleteProfile.php",
		"getUsersData"						: ServerURL+subfolder+"/getUsersData.php",
		"updateUsersData"					: ServerURL+subfolder+"/updateUsersData.php",
		"getVisitorsStats"					: ServerURL+subfolder+"/getVisitorsStats.php",
	}
}
