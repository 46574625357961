const areas = [
  {name: 'Computer Science', color: '#007acc'},
  {name: 'Cryptology', color: '#37ce9f'},
  {name: 'Security', color: '#FEAF33'},
  {name: 'Mathematics', color: '#FF475F'},
  {name: 'Privacy', color: '#7861CC'},
  {name: 'Humanistic & Social Sc.', color: '#93B2D1'},
  {name: 'Other', color: '#8c8c8c'}
]

export default areas
