function LoadFilterOptions (input) {

    // Přidá možnost do arr pokud v něm ještě není 
    function pushToArray(arr, obj) {
      if(Array.isArray(obj)){
        obj.forEach((value) => {
          const index = arr.indexOf(value)
          if (index === -1) {arr.push(value)}
        })
      } else {
        const index = arr.indexOf(obj)
        if (index === -1) {arr.push(obj)}
      }
    }
  
    var output = {
    "domain" : [],
    "role_of_the_ecsf" : [],
    "duration" : [],
    "iso_17024" : [],
    "language" : [],
    "organization" : [],
    "price" : [],
    "skills_group" : [],
    "type_format" : []
    }
  
    input.forEach(certification => {
      
        pushToArray(output['domain'], certification.domain)
        pushToArray(output['role_of_the_ecsf'], certification.role_of_the_ecsf)
        pushToArray(output['duration'], certification.duration)
        pushToArray(output['iso_17024'], certification.iso_17024)
        certification.language.forEach(language => {
            pushToArray(output['language'], language)
        })
        pushToArray(output['organization'], certification.organization)
        if (!isNaN(parseFloat(certification.price))) pushToArray(output['price'], parseFloat(certification.price))
        certification.skills_group.forEach(group => {
          pushToArray(output['skills_group'], group)
        })
        pushToArray(output['type_format'], certification.type_format)
      
    })
  
    return output
  }
  
  export default LoadFilterOptions  