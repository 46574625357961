// Cybersecurity Solutions Architect
// Cybersecurity Designer
// Data Security Architect

const title = 'Cybersecurity Architect';

// const alternativeTitles = [
//     'Cybersecurity Solutions Architect',
//     'Cybersecurity Designer',
//     'Data Security Architect'
// ];

const alternativeTitles = [
    { key: 1, title: 'Cybersecurity Solutions Architect' },
    { key: 2, title: 'Cybersecurity Designer' },
    { key: 3, title: 'Data Security Architect' }
];

// Plans and designs security-by-design solutions (infrastructures, systems, assets, software, hardware and services) and cybersecurity controls.

// const summaryStatement = [
//     'Plans and designs security-by-design solutions (infrastructures, systems, assets, software, hardware and services) and cybersecurity controls.'
// ];

const summaryStatement = [{ key: 1, summary: 'Plans and designs security-by-design solutions (infrastructures, systems, assets, software, hardware and services) and cybersecurity controls.' }];

// Designs solutions based on security-by-design and privacy-by-design principles. Creates and continuously improves architectural models and develops appropriate architectural documentation and specifications. Coordinate secure development, integration and maintenance of cybersecurity components in line with standards and other related requirements.

// const mission = [
//     'Designs solutions based on security-by-design and privacy-by-design principles.',
//     'Creates and continuously improves architectural models and develops appropriate architectural documentation and specifications.',
//     'Coordinate secure development, integration and maintenance of cybersecurity components in line with standards and other related requirements.'
// ];

const mission = [
    { key: 1, mission: 'Designs solutions based on security-by-design and privacy-by-design principles.' },
    { key: 2, mission: 'Creates and continuously improves architectural models and develops appropriate architectural documentation and specifications.' },
    { key: 3, mission: 'Coordinate secure development, integration and maintenance of cybersecurity components in line with standards and other related requirements.' }
];

// • Cybersecurity Architecture Diagram • Cybersecurity Requirements Report

// const deliverables = [
//     'Cybersecurity Architecture Diagram',
//     'Cybersecurity Requirements Report'
// ];

const deliverables = [
    { key: 1, deliverable: 'Cybersecurity Architecture Diagram', description: 'A visual representation of an organisation’s cybersecurity system architecture used to protect assets against cyber-attacks.'},
    { key: 2, deliverable: 'Cybersecurity Requirements Report', description: 'A report listing a set of requirements needed for ensuring the cybersecurity of a system.'}
];

// • Design and propose a secure architecture to implement the organisation’s strategy • Develop organisation’s cybersecurity architecture to address security and privacy requirements
// • Produce architectural documentation and specifications
// • Present high-level security architecture design to stakeholders
// • Establish a secure environment during the development lifecycle of systems, services and products
// • Coordinate the development, integration and maintenance of cybersecurity components ensuring the cybersecurity specifications
// • Analyse and evaluate the cybersecurity of the organisation’s architecture
// • Assure the security of the solution architectures through security reviews and certification
// • Collaborate with other teams and colleagues
// • Evaluate the impact of cybersecurity solutions on the design
// and performance of the organisation’s architecture
// • Adapt the organisation’s architecture to emerging threats
// • Assess the implemented architecture to maintain an appropriate level of security

const mainTasks = [
    {
        key: 1,
        task: 'Design and propose a secure architecture to implement the organisation’s strategy'
    },
    {
        key: 2,
        task: 'Develop organisation’s cybersecurity architecture to address security and privacy requirements'
    },
    {
        key: 3,
        task: 'Produce architectural documentation and specifications'
    },
    {
        key: 4,
        task: 'Present high-level security architecture design to stakeholders'
    },
    {
        key: 5,
        task: 'Establish a secure environment during the development lifecycle of systems, services and products'
    },
    {
        key: 6,
        task: 'Coordinate the development, integration and maintenance of cybersecurity components ensuring the cybersecurity specifications'
    },
    {
        key: 7,
        task: 'Analyse and evaluate the cybersecurity of the organisation’s architecture'
    },
    {
        key: 8,
        task: 'Assure the security of the solution architectures through security reviews and certification'
    },
    {
        key: 9,
        task: 'Collaborate with other teams and colleagues'
    },
    {
        key: 10,
        task: 'Evaluate the impact of cybersecurity solutions on the design and performance of the organisation’s architecture'
    },
    {
        key: 11,
        task: 'Adapt the organisation’s architecture to emerging threats'
    },
    {
        key: 12,
        task: 'Assess the implemented architecture to maintain an appropriate level of security'
    }
];

// • Conduct user and business security requirements analysis
// • Draw cybersecurity architectural and functional specifications
// • Decompose and analyse systems to develop security and privacy requirements and identify effective solutions
// • Design systems and architectures based on security and privacy by design and by defaults cybersecurity principles
// • Guide and communicate with implementers and IT/OT personnel
// • Communicate, present and report to relevant stakeholders
// • Propose cybersecurity architectures based on stakeholder’s needs and budget
// • Select appropriate specifications, procedures and controls
// • Build resilience against points of failure across the architecture
// • Coordinate the integration of security solutions

const keySkills = [
    {
        key: 1,
        skill: 'Conduct user and business security requirements analysis'
    },
    {
        key: 2,
        skill: 'Draw cybersecurity architectural and functional specifications'
    },
    {
        key: 3,
        skill: 'Decompose and analyse systems to develop security and privacy requirements and identify effective solutions'
    },
    {
        key: 4,
        skill: 'Design systems and architectures based on security and privacy by design and by defaults cybersecurity principles'
    },
    {
        key: 5,
        skill: 'Guide and communicate with implementers and IT/OT personnel'
    },
    {
        key: 6,
        skill: 'Communicate, present and report to relevant stakeholders'
    },
    {
        key: 7,
        skill: 'Propose cybersecurity architectures based on stakeholder’s needs and budget'
    },
    {
        key: 8,
        skill: 'Select appropriate specifications, procedures and controls'
    },
    {
        key: 9,
        skill: 'Build resilience against points of failure across the architecture'
    },
    {
        key: 10,
        skill: 'Coordinate the integration of security solutions'
    }
];

// • Cybersecurity-related certifications
// • Cybersecurity recommendations and best practices
// • Cybersecurity standards, methodologies and frameworks
// • Cybersecurity-related requirements analysis
// • Secure development lifecycle
// • Security architecture reference models
// • Cybersecurity-related technologies
// • Cybersecurity controls and solutions
// • Cybersecurity risks
// • Cyber threats
// • Cybersecurity trends
// • Legal, regulatory and legislative compliance requirements, recommendations and best practices
// • Legacy cybersecurity procedures
// • Privacy-Enhancing Technologies (PET)

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Cybersecurity-related certifications'
    },
    {
        key: 2,
        knowledge: 'Cybersecurity recommendations and best practices'
    },
    {
        key: 3,
        knowledge: 'Cybersecurity standards, methodologies and frameworks'
    },
    {
        key: 4,
        knowledge: 'Cybersecurity-related requirements analysis'
    },
    {
        key: 5,
        knowledge: 'Secure development lifecycle'
    },
    {
        key: 6,
        knowledge: 'Security architecture reference models'
    },
    {
        key: 7,
        knowledge: 'Cybersecurity-related technologies'
    },
    {
        key: 8,
        knowledge: 'Cybersecurity controls and solutions'
    },
    {
        key: 9,
        knowledge: 'Cybersecurity risks'
    },
    {
        key: 10,
        knowledge: 'Cyber threats'
    },
    {
        key: 11,
        knowledge: 'Cybersecurity trends'
    },
    {
        key: 12,
        knowledge: 'Legal, regulatory and legislative compliance requirements, recommendations and best practices'
    },
    {
        key: 13,
        knowledge: 'Legacy cybersecurity procedures'
    },
    {
        key: 14,
        knowledge: 'Privacy-Enhancing Technologies (PET)'
    },
    {
        key: 15,
        knowledge: 'Privacy-by-design standards, methodologies and frameworks'
    }
];

// e-Competences (from e-CF)
// A.5. Architecture Design
// A.6. Application Design
// B.1. Application Development B.3. Testing
// B.6. ICT Systems Engineering
// Level 5 Level 3 Level 3 Level 3 Level 4


const eCompetences = [
    {
        key: 1,
        competence: 'A.5. Architecture Design',
        level: ['Level 5']
    },
    {
        key: 2,
        competence: 'A.6. Application Design',
        level: ['Level 3']
    },
    {
        key: 3,
        competence: 'B.1. Application Development',
        level: ['Level 3']
    },
    {
        key: 4,
        competence: 'B.3. Testing',
        level: ['Level 3']
    },
    {
        key: 5,
        competence: 'B.6. ICT Systems Engineering',
        level: ['Level 4']
    }
];

export const ARCHData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}