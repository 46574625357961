import React from 'react'
import { Button, Modal, Space } from 'antd'
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { features } from "../../../assets/countries.geo.json"
import _ from 'lodash'


const MapModal = ({ jobs, visible, filterViaMap, onCancel }) => {
  const [selectedCountries, setSelectedCountries] = React.useState([])

  const unselectedNormal = {
     fillColor: "white",
     fillOpacity: 0.2,
     weight: 1,
     color: "#000000",
  }

  const unselectedHover = {
    fillColor: '#78dfff',
    fillOpacity: 0.2,
    weight: 1,
  }

  const selectedNormal = {
    fillColor: '#3898ff',
    fillOpacity: 0.3,
    weight: 2
  }

  const selectedHover = {
    fillColor: '#3898ff',
    fillOpacity: 0.5,
    weight: 2
  }

  function mouseOver(e) {
    let layer = e.target
    layer.openPopup(e.latlng);
    let ishiglightedlayer = (layer.options.weight === 2)
    if (ishiglightedlayer) {
      // layer.setStyle({ fillOpacity: 0.5})
      layer.setStyle(selectedHover)
    } else {
      // layer.setStyle({ fillColor: '#78dfff' })
      layer.setStyle(unselectedHover)
    }
  }

  function mouseOut(e) {
    let layer = e.target
    let ishiglightedlayer = (layer.options.weight === 2)
    if (ishiglightedlayer) {
      // layer.setStyle({ fillOpacity: 0.3 })
      layer.setStyle(selectedNormal)
    } else {
      // layer.setStyle({ fillColor: 'white' })
      layer.setStyle(unselectedNormal)
    }
  }

  function selectLayer(e) {
    let layer = e.target
    let ishiglightedlayer = (layer.options.weight === 2)
    if (ishiglightedlayer) {
      setSelectedCountries((oldArray) => oldArray.filter(item => item !== layer.feature))
      // vraci se do hover efektu nevybrane country
      layer.setStyle(unselectedHover)
    } else {
      setSelectedCountries((oldArray) => _.uniqWith(oldArray.concat(layer.feature)))
      // jde do hover efektu vybrane country
      layer.setStyle(selectedHover)
    }
  }

  function onEachCountry(country, layer) {
    // layer.setStyle({ color: "#000000", fillColor: "white", weight: 1 })
    layer.options.color = unselectedNormal.color
    layer.options.fillColor = unselectedNormal.fillColor
    layer.options.weight = unselectedNormal.weight

    // popup
    const countryName = country.properties.ADMIN
    const countryAds = jobs.filter((ad) => ad.country === countryName)
    let popupContent = `
      <div class="popover-content">
        <h2>${countryName}</h2>
        <span class="ant-typography ant-typography-secondary">Number of job adds: </span>`
    if (countryAds.length > 0) {
      popupContent += `<strong>${countryAds.length}</strong>`
    } else {
      popupContent += `none`
    }
    popupContent += `</div>`
    layer.bindPopup(popupContent)

    // events
    layer.on({
      mouseover: mouseOver,
      mouseout: mouseOut,
      click: selectLayer
    })
  }

  function style(feature){
    if (!selectedCountries.includes(feature)) {
      return unselectedNormal
    } else {
      return selectedNormal
    }
  }

  function resetMap(){
    setSelectedCountries([])
  }


  return (
    <Modal
      className="mapModal"
      centered
      open={visible}
      title="Map filtering"
      width={5000}
      onCancel={onCancel}
      footer={[
        <Space key="space" size="small">
          <span>Selected {selectedCountries.length} countries</span>
          <Button key="reset" onClick={resetMap}>
            Reset
          </Button>
          <Button key="back" onClick={onCancel}>
            Return
          </Button>
          <Button key="submit" type="primary" onClick={() => {
            filterViaMap({countries: selectedCountries.map(item => {return item.properties.ADMIN}).sort()})
            setSelectedCountries([])
          }}>
            Submit
          </Button>
        </Space>
      ]}
    >
      <MapContainer
        style={{ height: "80vh" }}
        zoom={2.4}
        scrollWheelZoom={true}
        minZoom={2.4}
        center={[30, 0]}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        <GeoJSON data={features} onEachFeature={onEachCountry} style={style}/>
      </MapContainer>

    </Modal>
  )
}

export default MapModal
