export const checkColumnStructure = (newCourses, currentColumns) => {
  const currentColIds = Object.keys(currentColumns)
  newCourses.forEach( newCourse => {
     if (!currentColIds.includes(newCourse.position)) return false
  })
  return true
}


export const checkCoursesStructure = (items) => {

  // This checks if every key in obj1 has a corresponding key in obj2 with the same value type, and if every key in obj2 exists in obj1. If both conditions are met, it returns true; otherwise, it returns false.
  const objComparison = (obj1, obj2) =>
    Object.keys(obj1).every(key => {
      const test1_1 = obj2.hasOwnProperty(key)
      const test1_2 = typeof obj1[key] === typeof obj2[key]
      if(!test1_1) console.log(key)
      if(!test1_2) console.log(key, typeof obj1[key], typeof obj2[key])
      return test1_1 && test1_2
    }) &&
    Object.keys(obj2).every(key => {
      if (!obj1.hasOwnProperty(key)) console.log(key)
      return obj1.hasOwnProperty(key)
    })

  const emptyItem = {id: "", name: "", semester: "", training: "", type: "", credits: 0, description: "", topics: [{ectsPer: null, topicId: null}], skills_group: [], specific_skills: [], specific_knowledge: [], created: "", created_by: 0, updated: "", updated_by: 0, position: ""}

  return items.every( item => objComparison(item, emptyItem))
}
