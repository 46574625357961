import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { Popover, Typography  } from 'antd'
import Chart from "react-apexcharts"
import skillsInTrainings from "./initStructure/skillsInTrainings"
import studyPrograms from "./initStructure/studyPrograms"
import skillsInCertifications from './initStructure/skillsInCertifications'
// import mapSpartaToRewire from "./initStructure/mapSpartaToRewire"
import enisaProfilesMap from "./initStructure/enisaProfilesMap"
import rewireSkills from "./initStructure/rewireSkills"

const { Title } = Typography

class Statistics extends React.Component {

  constructor() {
    super()
    this.state = {
      programSkills: {
        names: [],
        occurrences: []
      },
      trainingSkills: {
        names: [],
        occurrences: []
      },
      certificationSkills: {
        names: [],
        occurrences: []
      },
      programProfiles: {
        medians: [],
        profileCoverage: []
      },
      trainingProfiles: {
        medians: [],
        profileCoverage: []
      },
      certificationProfiles: {
        medians: [],
        profileCoverage: []
      }
    }
  }

  /* Run functions on load */
  componentDidMount() {
    // nachystání datasetu studijnich programů pro zpracování
    let study_programs_dataset = []
    studyPrograms.forEach((university, i) => {
      university.programs.forEach((study_program, j) => {
        study_programs_dataset.push({
          university: university.name,
          country: university.country,
          ...study_program})
      })
    })

    this.setState({
      trainingSkills: this.computeChartData(skillsInTrainings),
      certificationSkills: this.computeChartData(skillsInCertifications),
      programSkills: this.computeChartProgramsData(),
      programProfiles: this.computeProfileCoverage(study_programs_dataset, "rewire_skills"),
      trainingProfiles: this.computeProfileCoverage(skillsInTrainings, "skills_group"),
      certificationProfiles: this.computeProfileCoverage(skillsInCertifications, "skills_group")
    })
  }

  checkInputDataValidity = (dataset, atr_name) => {
    let result = true
    dataset.forEach(item => {
      item[atr_name].forEach(x => {
        if (!rewireSkills.includes(x)) {
          console.log("neznama skill: " + x)
          result = false
        }
      })
    })
    return result
  }

  computeChartProgramsData = () => {
    let rewireSkillSum = []
    studyPrograms.forEach((university, i) => {
      university.programs.forEach((program, j) => {
        program.rewire_skills.forEach((skill, k) => {
          // jelikož chybí mapování na tyto skilly, v grafu je skryjem
          if (skill !== "Strategic Relationship Management" && skill !== "Collaborate and Communicate") rewireSkillSum.push(skill)
        })
      })
    })
    const skillOcurrences = [...rewireSkillSum.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map()).entries()].sort((a, b) => b[1] - a[1])
    return  {
      names: skillOcurrences.map(skill => skill[0]),
      occurences: skillOcurrences.map(skill => skill[1])
    }
  }

  /* Compute data for Trainings chart */
  computeChartData = (data) => {
    /* Prepare new state */
    var newChartTrainingsOptions = {
      names: rewireSkills,
      occurences: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }

    /* Iterate over all Trainings */
    for(let i = 0, size = data.length; i < size ; i++){
      let skills_group = data[i].skills_group;
      /* Iterate over all skills groups in a Training */
      for (let skill of skills_group) {
        /* Iterate over all possible skills groups names */
        for (let i = 0, size = newChartTrainingsOptions.names.length; i < size ; i++){
          let category = newChartTrainingsOptions.names[i]
          /* Compare all skills groups names to current skills group */
          if (skill === category) {
            /* Increment number of occurrences for matching name */
            newChartTrainingsOptions.occurences[i]++
           }
        }
      }
    }
    /* Replace all underscores in skill names*/
    newChartTrainingsOptions.names = newChartTrainingsOptions.names.map(x => x.replace(/_/g, ' '))

    /* Sort names and occurences */
    let to_sort_names = newChartTrainingsOptions.names
    let to_sort_occurences = newChartTrainingsOptions.occurences

    /* Remove unsorted data */
    newChartTrainingsOptions.names = []
    newChartTrainingsOptions.occurences = []

    while (to_sort_occurences.length > 0) {
      /* Find item with most occurences */
      let max = Math.max(...to_sort_occurences)
      /* Find index of item */
      let index = to_sort_occurences.indexOf(max)

      /* Push items */
      newChartTrainingsOptions.names.push(to_sort_names[index])
      newChartTrainingsOptions.occurences.push(to_sort_occurences[index])

      /* Remove this items */
      to_sort_names.splice(index, 1)
      to_sort_occurences.splice(index, 1)
    }

    return newChartTrainingsOptions
  }

  computeProfileCoverage = (input_dataset, atr_name) => {
    let profile_coverage = []
    input_dataset.forEach(input_item => {
      let new_dataset = {...input_item, profile_coverage: enisaProfilesMap.map(profile=> {
        let included = []
        let missing = []
        profile.skills.forEach(skill => {
          if (input_item[atr_name].includes(rewireSkills[skill])) {
            included.push(rewireSkills[skill])
          } else {
            missing.push(rewireSkills[skill])
          }
        })
        return {
          percent: parseInt((included.length/profile.skills.length*100).toFixed(0)),
          profile_name: profile.profile,
          included: included,
          missing: missing
        }
      })}
      profile_coverage.push(new_dataset)
    })

    let medians = [[],[],[],[],[],[],[],[],[],[],[],[]]
    profile_coverage.forEach((program, i) => {
      program.profile_coverage.forEach((profile, j) => {
        medians[j].push(parseInt(profile.percent))
      })
    })

    return {
      medians: medians.map((e,i) => {return {profile: enisaProfilesMap[i].profile, median: this.median(e)}}),
      profileCoverage: profile_coverage
    }
  }

  median = (arr) => {
    const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  }

  render(){

    const { programSkills, trainingSkills, certificationSkills, programProfiles, trainingProfiles, certificationProfiles } = this.state
    const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

    const programProfileMediansSorted = programProfiles.medians ? JSON.parse(JSON.stringify(programProfiles.medians)).sort((a, b) => b.median - a.median) : ""
    const trainingProfileMediansSorted = trainingProfiles.medians ? JSON.parse(JSON.stringify(trainingProfiles.medians)).sort((a, b) => b.median - a.median) : ""
    const certificationProfileMediansSorted = certificationProfiles.medians ? JSON.parse(JSON.stringify(certificationProfiles.medians)).sort((a, b) => b.median - a.median) : ""

    function percentageLevel (percent) {
      return (
        percent > 67 ? "High" :
        percent > 33 ? "Medium" :
        percent >= 0 ? "Low" : "Error"
      )
    }

    return (
      <div className="main">
        <div className="charts">
          <h3 className="section-title"> Statistics</h3>
          <div className="content">
            <div className="content-scroll">
              <Container>
                <Row>
                  <Col>
                  <h5 className="main-title">Enisa profiles in study programs</h5>
                  {programProfiles ?
                    <Table bordered striped className="profiles-overview" size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          {enisaProfilesMap.map((e,i) => <th key={i}>{e.profile}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {programProfiles.profileCoverage.map((program, i) =>
                          <tr key={i}><th>{program.university} | {program.study_program} ({program.degree})</th>
                            {program.profile_coverage.map((prof, j) =>
                              <Popover key={j} content={
                                <div className="skills-popover" >
                                  <Title level={4}>{prof.profile_name}</Title>
                                  <Title level={5}>Included Skills</Title>
                                  <ul>
                                    {prof.included.length ? prof.included.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                  <Title level={5}>Missing Skills</Title>
                                  <ul>
                                    {prof.missing.length ? prof.missing.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                </div>
                              }>
                                <td className={prof.percent === 100 ? 'green' : 'normal'}>{percentageLevel(prof.percent)}</td>
                              </Popover>
                            )}
                          </tr>
                        )}
                      </tbody>
                      <thead>
                        <tr>
                          <th>Result (median)</th>
                          {programProfiles.medians.map((e,i) => <th key={i}>{percentageLevel(e.median)}</th>)}
                        </tr>
                      </thead>
                    </Table>
                  : ""}
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <h5 className="main-title">Enisa profiles in trainigs</h5>
                  {trainingProfiles ?
                    <Table bordered striped className="profiles-overview" size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          {enisaProfilesMap.map((e,i) => <th key={i}>{e.profile}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {trainingProfiles.profileCoverage.map((training, i) =>
                          <tr key={i}><th>{training.organizer} | {training.training_title}</th>
                            {training.profile_coverage.map((prof, j) =>
                              <Popover key={j} content={
                                <div className="skills-popover" >
                                  <Title level={4}>{prof.profile_name}</Title>
                                  <Title level={5}>Included Skills</Title>
                                  <ul>
                                    {prof.included.length ? prof.included.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                  <Title level={5}>Missing Skills</Title>
                                  <ul>
                                    {prof.missing.length ? prof.missing.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                </div>
                              }>
                                <td className={prof.percent === 100 ? 'green' : 'normal'}>{percentageLevel(prof.percent)}</td>
                              </Popover>
                            )}
                          </tr>
                        )}
                      </tbody>
                      <thead>
                        <tr>
                          <th>Result (median)</th>
                          {trainingProfiles.medians.map((e,i) => <th key={i}>{percentageLevel(e.median)}</th>)}
                        </tr>
                      </thead>
                    </Table>
                  : ""}
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <h5 className="main-title">Enisa profiles in certifications</h5>
                  {certificationProfiles ?
                    <Table bordered striped className="profiles-overview" size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          {enisaProfilesMap.map((e,i) => <th key={i}>{e.profile}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {certificationProfiles.profileCoverage.map((certification, i) =>
                          <tr key={i}><th>{certification.certifying_organization} | {certification.certification_title}</th>
                            {certification.profile_coverage.map((prof, j) =>
                              <Popover key={j} content={
                                <div className="skills-popover" >
                                  <Title level={4}>{prof.profile_name}</Title>
                                  <Title level={5}>Included Skills</Title>
                                  <ul>
                                    {prof.included.length ? prof.included.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                  <Title level={5}>Missing Skills</Title>
                                  <ul>
                                    {prof.missing.length ? prof.missing.map((skill, k) => <li key={k}>{skill}<br/></li>) : <span className="empty">none</span>}
                                  </ul>
                                </div>
                              }>
                                <td className={prof.percent === 100 ? 'green' : 'normal'}>{percentageLevel(prof.percent)}</td>
                              </Popover>
                            )}
                          </tr>
                        )}
                      </tbody>
                      <thead>
                        <tr>
                          <th>Result (median)</th>
                          {certificationProfiles.medians.map((e,i) => <th key={i}>{percentageLevel(e.median)}</th>)}
                        </tr>
                      </thead>
                    </Table>
                  : ""}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Coverage of Enisa profiles in study programs</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Coverage of Enisa profile in study programs",
                          data: programProfileMediansSorted ? programProfileMediansSorted.map(e => e.median) : []
                        }]}
                        options= {{
                          colors: [colors[4]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: programProfileMediansSorted ? programProfileMediansSorted.map(e => e.profile) : []
                          },
                          yaxis: {
                            max: 100,
                            min: 0,
                            labels: {
                              formatter: (value) => {return value + " %"}
                            }
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Coverage of Enisa profiles in trainigs</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Coverage of Enisa profile in trainigs",
                          data: trainingProfileMediansSorted ? trainingProfileMediansSorted.map(e => e.median) : []
                        }]}
                        options= {{
                          colors: [colors[0]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: trainingProfileMediansSorted ? trainingProfileMediansSorted.map(e => e.profile) : []
                          },
                          yaxis: {
                            max: 100,
                            min: 0,
                            labels: {
                              formatter: (value) => {return value + " %"}
                            }
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Coverage of Enisa profiles in certifications</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Coverage of Enisa profile in certifications",
                          data: certificationProfileMediansSorted ? certificationProfileMediansSorted.map(e => e.median) : []
                        }]}
                        options= {{
                          colors: [colors[1]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: certificationProfileMediansSorted ? certificationProfileMediansSorted.map(e => e.profile) : []
                          },
                          yaxis: {
                            max: 100,
                            min: 0,
                            labels: {
                              formatter: (value) => {return value + " %"}
                            }
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Skill occurences in study programs</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Skill occurences in study programs",
                          data: programSkills.occurences ? programSkills.occurences : []
                        }]}
                        options= {{
                          colors: [colors[2]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: programSkills.names
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Skill occurences in trainings</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Skill occurences in trainings",
                          data: trainingSkills.occurences ? trainingSkills.occurences : []
                        }]}
                        options= {{
                          colors: [colors[3]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: trainingSkills.names
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5 className="main-title">Skill occurences in certifications</h5>
                    <div className="chart-container">
                      <Chart
                        type="radar"
                        series={[{
                          name: "Skill occurences in certifications",
                          data: certificationSkills.occurences ? certificationSkills.occurences : []
                        }]}
                        options= {{
                          colors: [colors[4]],
                          chart: {
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: certificationSkills.names
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

              </Container>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Statistics
