function LoadFilterOptions (input) {

    // Přidá možnost do arr pokud v něm ještě není 
    function pushToArray(arr, obj) {
      if(Array.isArray(obj)){
        obj.forEach((value) => {
          const index = arr.indexOf(value)
          if (index === -1) {arr.push(value)}
        })
      } else {
        const index = arr.indexOf(obj)
        if (index === -1) {arr.push(obj)}
      }
    }
  
    var output = {
    "can_lead_to_certification" : [],
    "content_type" : [],
    "country" : [],
    "duration" : [],
    "includes_exams_for_certification" : [],
    "language" : [],
    "organizer" : [],
    "price" : [],
    "skills_group" : [],
    "timing" : [],
    "type_format" : []
    }
  
    input.forEach(training => {
      
        pushToArray(output['can_lead_to_certification'], training.can_lead_to_certification)
        pushToArray(output['content_type'], training.content_type)
        pushToArray(output['country'], training.country)
        pushToArray(output['duration'], training.duration)
        pushToArray(output['includes_exams_for_certification'], training.includes_exams_for_certification)
        training.language.forEach(language => {
            pushToArray(output['language'], language)
        })
        pushToArray(output['organizer'], training.organizer)
        if (!isNaN(parseFloat(training.price))) pushToArray(output['price'], parseFloat(training.price))
        training.skills_group.forEach(group => {
          pushToArray(output['skills_group'], group)
        })
        pushToArray(output['timing'], training.timing)
        pushToArray(output['type_format'], training.type_format)
      
    })
  
    return output
  }
  
  export default LoadFilterOptions  