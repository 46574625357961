const rewireSkills = [
  "Business Continuity",
  "Collaborate and Communicate",
  "Data Analysis",
  "Data Privacy",
  "Data Security",
  "Data, Asset and Inventory Management",
  "Database Administration",
  "Digital Forensics",
  "Education and Training Delivery",
  "Enterprise Architecture and Infrastructure Design",
  "Identity Management",
  "Incident Management",
  "Information Systems and Network Security",
  "Information Security Controls Assessment",
  "Intelligence Analysis",
  "Law, Policy, and Ethics",
  "Network Management",
  "Operating Systems",
  "Organizational Awareness",
  "Physical Device Security",
  "Policy Development",
  "Problem Solving and Critical Thinking",
  "Project Management",
  "Risk Management",
  "Software Development",
  "Strategic Relationship Management",
  "System Administration",
  "Technology Fluency",
  "Testing and Evaluation",
  "Threat Analysis",
  "Workforce Management"
]

export default rewireSkills
