import React from 'react'
import { Col, Table } from 'react-bootstrap'
import { Popover, Typography  } from 'antd'
// import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'

const { Title } = Typography


class Statistics extends React.Component {

  render(){

    const { trainings, rsSkillsGroup, rsProfiles } = this.props

    const selectedSkillIds = () => {
      let tmp = []
      trainings.forEach(training => {
        training.skills_group.forEach(skillId => {
          tmp = tmp.concat(skillId)
        })
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const enisaProfileCoverage = () => {
      return rsProfiles.map(profile => {
        let included = []
        let missing = []
        profile.skills_group.forEach(skillId => {
          if (selectedSkillIds().includes(skillId)) {
            included.push(skillId)
          } else {
            missing.push(skillId)
          }
        })
        return {
          coverage: parseInt((included.length/profile.skills_group.length*100).toFixed(0)),
          name: profile.name,
          included: included,
          missing: missing
        }
      })
    }

    const median = (arr) => {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b)
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
    }

    const percentageLevel = (percent) => {
      return (
        percent > 67 ? "High" :
        percent > 33 ? "Medium" :
        percent >= 0 ? "Low" : "Error"
      )
    }


    return (
      <Col className="statistics" lg ="12" xl="4">
        <h3 className="section-title"> Statistics</h3>
        <div className="content">

          {trainings.length > 0 ? (
          <div className="content-scroll">

            <h6>ENISA Profiles coverage: </h6>
            <Table bordered className="profiles-overview" size="sm">
              <thead>
                <tr>
                  <th>Profile Name</th><th>Coverage</th>
                </tr>
              </thead>
              <tbody>
                {enisaProfileCoverage().map((profile, i) =>
                  <Popover key={i} content={
                    <div className="skills-popover" >
                      <Title level={4}>{profile.name}</Title>
                      <Title level={5}>Included Skills</Title>
                      <ul>
                        {profile.included.length ? profile.included.map((skillId, k) => <li key={k}>{rsSkillsGroup.find(e=>e.id === skillId).name}<br/></li>) : <span className="empty">none</span>}
                      </ul>
                      <Title level={5}>Missing Skills</Title>
                      <ul>
                        {profile.missing.length ? profile.missing.map((skillId, k) => <li key={k}>{rsSkillsGroup.find(e=>e.id === skillId).name}<br/></li>) : <span className="empty">none</span>}
                      </ul>
                    </div>
                  }>
                    <tr key={i}>
                      <td>{profile.name}</td>
                      <td className={profile.coverage === 100 ? 'green' : 'normal'}>{percentageLevel(profile.coverage)}</td>
                    </tr>
                  </Popover>
                )}
              </tbody>
              <thead>
                <tr><td>Result (median)</td><td>{percentageLevel(median(enisaProfileCoverage().map(profile=>profile.coverage)))}</td></tr>
              </thead>
            </Table>

            {/*
            <h6>ENISA profiles overview: </h6>

            {enisaProfilesMap.map((item, index) =>
              <div key={index}>
                <h5>{item.profile}</h5>
                <ul>
                  {item.skills.map((skillId, idx) =>
                    <li key={idx}>{rsSkillsGroup[skillId].name}</li>
                  )}
                </ul>
              </div>
            )}
            */}

          </div>
          ) : (
            <div className="empty-data">
              <font className="header">There are no trainings yet.</font><br/>Drag some course to your training list.
            </div>
          )}
        </div>

      </Col>
    )
  }
}

export default Statistics
