import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Table, Row, Col } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';


const ProfileCircularGraph = ({ data }) => {
 
  const [pairFrequencies, setPairFrequencies] = useState([]);
  const [profileMap, setProfileMap] = useState([]);
  const [hoveredProfile, setHoveredProfile] = useState(null); // Pro sledování profilu, na který se ukazuje kurzorem
  const svgRef = useRef();
  const initializedRef = useRef(false); // Přidaná reference, aby se kreslení provedlo pouze jednou

  const createProfileIdMap = (data) => {
    const profileNames = new Set();
    data.forEach((item) => {
      profileNames.add(item.profile);
      Object.keys(item).forEach((key) => {
        if (key !== "profile") profileNames.add(key);
      });
    });

    let id = 1;
    const profilesWithId = Array.from(profileNames).map((name) => ({
      id: id++,
      name,
    }));

    return profilesWithId;
  };

  const calculatePairFrequencies = (data, profileIdMap) => {
    const frequencyMap = new Map();

    data.forEach((item) => {
      const sourceId = profileIdMap.find((profile) => profile.name === item.profile)?.id;

      Object.keys(item).forEach((targetProfile) => {
        if (targetProfile === "profile") return; // Skip the profile key
        const targetId = profileIdMap.find((profile) => profile.name === targetProfile)?.id;
        const weight = item[targetProfile];

        if (weight > 0 && sourceId && targetId) {
          const pairKey = `${sourceId}-${targetId}`;

          if (frequencyMap.has(pairKey)) {
            const existing = frequencyMap.get(pairKey);
            frequencyMap.set(pairKey, {
              ...existing,
              weight: existing.weight + weight,
            });
          } else {
            frequencyMap.set(pairKey, {
              source: sourceId,
              target: targetId,
              weight,
            });
          }
        }
      });
    });

    return Array.from(frequencyMap.values());
  };

  const drawChordDiagram = React.useCallback((pairFrequencies, profileIdMap, hoveredProfile) => {
    const width = 900;
    const height = 900;
    const outerRadius = Math.min(width, height) * 0.5 - 240;
    const innerRadius = outerRadius - 20;

    // Clear existing svg
    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("width", width)
      .attr("height", height);

    const matrix = createMatrix(pairFrequencies, profileIdMap.length);

    const chord = d3.chord().padAngle(0.05).sortSubgroups(d3.descending);

    const chords = chord(matrix);

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    const ribbon = d3
      .ribbon()
      .radius(innerRadius)
      .padAngle(1 / innerRadius);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Create groups for each profile
    const group = svg
      .append("g")
      .attr("font-size", 12) // Adjust font size
      .attr("font-family", "sans-serif")
      .selectAll("g")
      .data(chords.groups)
      .join("g");

    group
      .append("path")
      .attr("fill", (d) => color(d.index))
      .attr("d", arc);

    group
      .append("text")
      .each((d) => {
        d.angle = (d.startAngle + d.endAngle) / 2;
      })
      .attr("dy", ".35em")
      .attr(
        "transform",
        (d) => `
          rotate(${(d.angle * 180) / Math.PI - 90})
          translate(${outerRadius + 5})
          ${d.angle > Math.PI ? "rotate(180)" : ""}
        `
      )
      .attr("text-anchor", (d) => (d.angle > Math.PI ? "end" : null))     
      .text((d) => profileIdMap[d.index].name)
      .style("font-weight", d => d.index === hoveredProfile ? "600" : "normal");

    // Draw the ribbons
    svg
      .append("g")
      .attr("fill-opacity", 0.67)
      .selectAll("path")
      .data(chords)
      .join("path")
      .attr("d", ribbon)
      .attr("fill", (d) => color(d.source.index))
      .attr("stroke", (d) => d3.rgb(color(d.source.index)).darker())
      .style("visibility", (d) => {
        // Zobrazit jen ty, které souvisí s profilem, na který se ukazuje
        if (d.source.index === d.target.index) return "hidden";
        if (hoveredProfile === null) return "visible";
        return d.source.index === hoveredProfile || d.target.index === hoveredProfile
          ? "visible"
          : "hidden";
      });

  }, []);

  const createMatrix = (pairFrequencies, size) => {
    const matrix = Array(size)
      .fill(null)
      .map(() => Array(size).fill(0));

    pairFrequencies.forEach(({ source, target, weight }) => {
      matrix[source - 1][target - 1] = weight;
      matrix[target - 1][source - 1] = weight; // Symetrická matice
    });

    return matrix;
  };

  useEffect(() => {
    if (!initializedRef.current) {
      const profilesWithId = createProfileIdMap(data);
      setProfileMap(profilesWithId);

      const frequencies = calculatePairFrequencies(data, profilesWithId);
      setPairFrequencies(frequencies);

      initializedRef.current = true; // Zajistíme, že se toto volá jen jednou
    }
  }, [data, drawChordDiagram]);

  useEffect(() => {
    if (profileMap.length > 0) {
      drawChordDiagram(pairFrequencies, profileMap, hoveredProfile); // Kreslení po změně hoveredProfile
    }
  }, [hoveredProfile, profileMap, pairFrequencies, drawChordDiagram]);

  const handleMouseEnter = (id) => {
    if (hoveredProfile !== id - 1) {
      setHoveredProfile(id - 1);
    }
  };

  const handleMouseLeave = () => {
    if (hoveredProfile !== null) {
      setHoveredProfile(null);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "right",
      width: "30px",
    },
    {
      title: "Profile Name",
      dataIndex: "name",
      key: "name",
      width: "280px",
    },
  ];

  return (
    <div>
      <Row gutter={16} className="profile-match">
        
        <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Table
            dataSource={profileMap}
            columns={columns}
            pagination={false}
            size="small" 
            rowKey="id"
            title={() => <span className="comment-table"><InfoCircleOutlined /> Move mouse over the profiles in the table.</span>}
            onRow={(record) => ({
              onMouseEnter: () => handleMouseEnter(record.id),
              onMouseLeave: handleMouseLeave,
            })}
          />
        </Col>

        <Col span={16}>
          <svg ref={svgRef}></svg>
        </Col>
      
      </Row>
    </div>
  );
};

export default ProfileCircularGraph;