const title = 'Cybersecurity Implementer';

// const alternativeTitles = [
//     "Information Security Implementer",
//     "Cybersecurity Solutions Expert",
//     "Cybersecurity Developer",
//     "Cybersecurity Engineer",
//     "Development, Security & Operations (DevSecOps) Engineer"
// ];

const alternativeTitles = [
    {key:1, title:"Information Security Implementer"},
    {key:2, title:"Cybersecurity Solutions Expert"},
    {key:3, title:"Cybersecurity Developer"},
    {key:4, title:"Cybersecurity Engineer"},
    {key:5, title:"Development, Security & Operations (DevSecOps) Engineer"}
];

// const summaryStatement = [
//     "Develop, deploy and operate cybersecurity solutions (systems, assets, software, controls and services) on infrastructures and products."
// ];

const summaryStatement = [{key:1, summary:"Develop, deploy and operate cybersecurity solutions (systems, assets, software, controls and services) on infrastructures and products."}];

// const mission = [
//     "Develop, deploy and operate cybersecurity solutions (systems, assets, software, controls and services) on infrastructures and products."
// ];

const mission = [
    {key:1, mission:"Develop, deploy and operate cybersecurity solutions (systems, assets, software, controls and services) on infrastructures and products."}
];

// const deliverables = [
//     "Cybersecurity solutions design",
//     "Cybersecurity solutions development",
//     "Cybersecurity solutions deployment",
//     "Cybersecurity solutions operation and maintenance"
// ];

const deliverables = [
    {key:1, deliverable:"Cybersecurity solutions design", description:'Cybersecurity solutions might include tools and services that aim to protect organizations against cyber-attacks.'},
    {key:2, deliverable:"Cybersecurity solutions development"},
    {key:3, deliverable:"Cybersecurity solutions deployment"},
    {key:4, deliverable:"Cybersecurity solutions operation and maintenance"}
];

const mainTasks = [
    {key:1, task:"Develop, implement, maintain, upgrade and test cybersecurity products"},
    {key:2, task:"Provide cybersecurity-related support to users and customers"},
    {key:3, task:"Integrate cybersecurity solutions and ensure their sound operation"},
    {key:4, task:"Securely configure systems, services and products"},
    {key:5, task:"Maintain and upgrade the security of systems, services and products"},
    {key:6, task:"Implement cybersecurity procedures and controls"},
    {key:7, task:"Monitor and assure the performance of the implemented cybersecurity controls"},
    {key:8, task:"Document and report on the security of systems, services and products"},
    {key:9, task:"Work closely with IT/OT personnel on cybersecurity-related actions"},
    {key:10, task:"Implement patches to address technical vulnerabilities"}
];

const keySkills = [
    {
        key: 1,
        skill: "Communicate, present and report to relevant stakeholders"
    },
    {
        key: 2,
        skill: "Integrate cybersecurity solutions to the organisation’s infrastructure"
    },
    {
        key: 3,
        skill: "Configure solutions according to the organisation’s security policy"
    },
    {
        key: 4,
        skill: "Assess the security and performance of solutions"
    },
    {
        key: 5,
        skill: "Develop code, scripts and programmes"
    },
    {
        key: 6,
        skill: "Identify and solve cybersecurity-related issues"
    },
    {
        key: 7,
        skill: "Collaborate with other team members and colleagues"
    }
];

const keyKnowledge = [
    {
        key: 1,
        knowledge: "Secure development lifecycle"
    },
    {
        key: 2,
        knowledge: "Computer programming"
    },
    {
        key: 3,
        knowledge: "Operating systems security"
    },
    {
        key: 4,
        knowledge: "Computer networks security"
    },
    {
        key: 5,
        knowledge: "Cybersecurity controls and solutions"
    },
    {
        key: 6,
        knowledge: "Offensive and defensive security practices"
    },
    {
        key: 7,
        knowledge: "Secure coding recommendations and best practices"
    },
    {
        key: 8,
        knowledge: "Cybersecurity recommendations and best practices"
    },
    {
        key: 9,
        knowledge: "Testing standards, methodologies and frameworks"
    },
    {
        key: 10,
        knowledge: "Testing procedures"
    },
    {
        key: 11,
        knowledge: "Cybersecurity-related technologies"
    }
];


// A.5. Architecture Design
// A.6. Application Design
// B.1. Application Development B.3. Testing
// B.6. ICT Systems Engineering
// Level 3 Level 3 Level 3 Level 3 Level 4

const eCompetences = [
    {
        key: '1',
        competence: 'A.5. Architecture Design',
        level: ['Level 3']
    },
    {
        key: '2',
        competence: 'A.6. Application Design',
        level: ['Level 3']
    },
    {
        key: '3',
        competence: 'B.1. Application Development',
        level: ['Level 3']
    },
    {
        key: '4',
        competence: 'B.3. Testing',
        level: ['Level 3']
    },
    {
        key: '5',
        competence: 'B.6. ICT Systems Engineering',
        level: ['Level 4']
    }
];

export const CIMPLData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}