import React from "react";
import { Avatar, Divider, Timeline, Typography, Space } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";

import "./Contact.scss";

import logo_vut from "../../assets/logo_vut.png";
import logo_kth from "../../assets/logo_kth.png";
import logo_tuc from "../../assets/logo_tuc.png";

const { Link, Paragraph, Title } = Typography;

const Contact = () => {
  return (
    <section id="contacts">
      <div className="section-container">
        <div className="text-content">
          <Title level={1} className="pageTitle">
            CyberABILITY Platform Team
          </Title>

          <Timeline mode="alternate">

            <Timeline.Item className="right" dot={<Avatar src={logo_vut} />}>
              <Title level={4}>Brno University of Technology</Title>
              Department of Telecommunications
              <br />
              Technicka 12
              <br />
              616 00 BRNO
              <br />
              Czech Republic
            </Timeline.Item>

            <Timeline.Item className="left" dot={<Avatar src={logo_kth} />}>
              <Title level={4}>KTH Royal Institute of Technology</Title>
              SE-100 44 Stockholm
              <br />
              Sweden
            </Timeline.Item>

            <Timeline.Item className="right" dot={<Avatar src={logo_tuc} />}>
              <Title level={4}>Technical University of Crete</Title>
              University Campus, Akrotiri
              <br />
              Chania 73100
              <br />
              Greece
            </Timeline.Item>
            
          </Timeline>

          <Divider><Title level={4}>DEMO</Title></Divider>
          
          <Paragraph className={"demo"}>
            <Link href="https://www.youtube.com/watch?v=e_rmfbnvYu0" target="_blank">
              <Space size={"small"}>
                <YoutubeOutlined /> Cyber Security Profiler
              </Space>
            </Link>
            <br />
            <Link href="https://www.youtube.com/watch?v=ltFiBpvki6g" target="_blank">
              <Space size={"small"}>
                <YoutubeOutlined /> Job Ads Analyzer
              </Space>
            </Link>
          </Paragraph>

        </div>
      </div>
    </section>
  );
};

export default Contact;
