import React from 'react'
import { Col, Table } from 'react-bootstrap'
import { Popover, /*Table as AntTable,*/ Typography } from 'antd'

const { Title } = Typography


class Statistics extends React.Component {

  render(){

    const { columns, rsSkillsGroup, rsProfiles, semestersToShow, selectedProfiles } = this.props
/*
    const round = (value, precision) => {
      var multiplier = Math.pow(10, precision || 0)
      return Math.round(value * multiplier) / multiplier
    }
*/
    const courses = () => {
      let tmp = [];
      ['1-w', '1-s', '2-w', '2-s', '3-w', '3-s', '4-w', '4-s', '5-w', '5-s'].forEach(columnId => {
        columns[columnId].courseIds.forEach(courseId => {
          tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: columnId}})))
        })
      })
      return tmp
    }

    const ectsSum = () => {
      let ects = {'1-w': 0, '1-s': 0, '2-w': 0, '2-s': 0, '3-w': 0, '3-s': 0, '4-w': 0, '4-s': 0, '5-w': 0, '5-s': 0, 'sum': 0}
      courses().forEach((course, index) => {
        ects[course.position] += course.credits
        ects['sum'] += course.credits
      })
      return ects
    }

    // ecsf Framework

    const enisaProfileCoverage = () => {
      return rsProfiles.map(profile => {
        let included = []
        let missing = []
        const selectedSkills = selectedSkillIds()
        profile.skills_group.forEach(skillId => {
          if (selectedSkills.includes(skillId)) {
            included.push(skillId)
          } else {
            missing.push(skillId)
          }
        })
        return {
          coverage: parseInt((included.length/profile.skills_group.length*100).toFixed(0)),
          name: profile.name,
          included: included,
          missing: missing,
          id: profile.id
        }
      })
    }


    const selectedSkillIds = () => {
      let tmp = []
      courses().forEach(course => {
        course.skills_group.forEach(skillId => {
          tmp = tmp.concat(skillId)
        })
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const missingSkills = () => {
      let tmp = []
      enisaProfileCoverage().filter(profile => selectedProfiles.includes(profile.id)).forEach(profile => {
        tmp = tmp.concat(profile.missing) 
      })	
      let output = [...new Set(tmp)].sort((a,b) => {return a - b}).map((skillId,index) => {
        return <div className="work-role" key={index}>&nbsp;•&nbsp;{rsSkillsGroup.find(e => e.id === skillId).name}</div>
      })
      if (!output.some((el) => {return el !== null})) {
        output = <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
      }
      return output
    }

    const percentageLevel = (percent) => {
      return (
        percent > 67 ? "High" :
        percent > 33 ? "Medium" :
        percent >= 0 ? "Low" : "Error"
      )
    }


    return (
      <Col className="statistics" lg ="12" xl="4">
        <h3 className="section-title"> Statistics</h3>
        <div className="content">

          {courses().length > 0 ? (
          <div className="content-scroll">

            <h6>Total ECTS credits: </h6>

            <Table className="ects-overview" size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>winter</th>
                  <th>summer</th>
                  <th>sum</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <th>1st year</th>
                <td>{ectsSum()['1-w']}</td>
                <td>{semestersToShow >= 2 ? ectsSum()['1-s'] : "-"}</td>
                <td>{ectsSum()['1-w'] + ectsSum()['1-s']}</td>
              </tr>
              {semestersToShow >= 3 ? (
                <tr>
                  <th>2nd year</th>
                  <td>{ectsSum()['2-w']}</td>
                  <td>{semestersToShow >= 4 ? ectsSum()['2-s'] : "-"}</td>
                  <td>{ectsSum()['2-w'] + ectsSum()['2-s']}</td>
                </tr>
              ) : null }
              {semestersToShow >= 5 ? (
                <tr>
                  <th>3rd year</th>
                  <td>{ectsSum()['3-w']}</td>
                  <td>{semestersToShow >= 6 ? ectsSum()['3-s'] : "-"}</td>
                  <td>{ectsSum()['3-w'] + ectsSum()['3-s']}</td>
                </tr>
              ) : null }
              {semestersToShow >= 7 ? (
                <tr>
                  <th>4th year</th>
                  <td>{ectsSum()['4-w']}</td>
                  <td>{semestersToShow >= 8 ? ectsSum()['4-s'] : "-"}</td>
                  <td>{ectsSum()['4-w'] + ectsSum()['4-s']}</td>
                </tr>
              ) : null }
              {semestersToShow >= 9 ? (
                <tr>
                  <th>5th year</th>
                  <td>{ectsSum()['5-w']}</td>
                  <td>{semestersToShow >= 10 ? ectsSum()['5-s'] : "-"}</td>
                  <td>{ectsSum()['5-w'] + ectsSum()['5-s']}</td>
                </tr>
              ) : null }
              <tr>
                <th >sum</th>
                <td></td><td></td>
                <td ><b>{ectsSum()['sum']}</b></td>
              </tr>
              </tbody>
            </Table>

            <h6>ENISA Profiles coverage: </h6>
            {/*
            <AntTable size="small" 
              columns={[
                {title: "Profile Name", dataIndex: "name"}, 
                {title: "Coverage", dataIndex: "coverage", render: (coverage) => percentageLevel(coverage)}
              ]} 
              dataSource={enisaProfileCoverage().map((profile, i) => {return {name: profile.name, coverage: profile.coverage}})}
            />   
            */}
            <Table bordered className="profiles-overview" size="sm">
              <thead>
                <tr>
                  <th>Profile Name</th><th>Coverage</th>
                </tr>
              </thead>
              <tbody>
                {enisaProfileCoverage().map((profile, i) =>
                  <tr key={i} className={selectedProfiles.includes(profile.id) ? "selected" : ""}>
                    <td>{profile.name}</td>
                    <Popover placement="leftBottom" arrowPointAtCenter content={
                      <div className="skills-popover" >
                        <Title level={4}>{profile.name}</Title>
                        <Title level={5}>Included Skills</Title>
                        <ul>
                          {profile.included.length ? profile.included.map((skillId, k) => <li key={k}>{rsSkillsGroup.find(e=>e.id === skillId).name}<br/></li>) : <span className="empty">none</span>}
                        </ul>
                        <Title level={5}>Missing Skills</Title>
                        <ul>
                          {profile.missing.length ? profile.missing.map((skillId, k) => <li key={k}>{rsSkillsGroup.find(e=>e.id === skillId).name}<br/></li>) : <span className="empty">none</span>}
                        </ul>
                      </div>
                    }>
                      <td className={profile.coverage === 100 ? 'green' : 'normal'}>{percentageLevel(profile.coverage)}</td>
                    </Popover>
                  </tr>
                )}
              </tbody>
            </Table>
            
            <h6>Missing skills to cover selected profiles: </h6>
            {missingSkills()}

          </div>
          ) : (
            <div className="empty-data">
              <font className="header">Your curricula is empty.</font><br/>Perform an analysis and see your curricula.
            </div>
          )}
        </div>

      </Col>
    )
  }
}

export default Statistics
