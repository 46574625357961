import { ServerURL } from '../../config.js'

let subfolder = "app"

export const Config = {
	server       :   {
		"register"					:	ServerURL+subfolder+"/register.php",
		"login"						:	ServerURL+subfolder+"/login.php",
		"logout"					:	ServerURL+subfolder+"/logout.php",
		"editUser"					:	ServerURL+subfolder+"/editUser.php",
		"forgotPassword"			:	ServerURL+subfolder+"/forgotPassword.php",
		"saveVisitor"				:	ServerURL+subfolder+"/saveVisitor.php",
		"visitorFeedback"			:	ServerURL+subfolder+"/visitorFeedback.php",
	}

}
