const sampleTrainings = [
  {
    "name": "Cyber Systems Security through Ethical Hacking",
    "organizer": "AKMI",
    "description": "Hackademic A specialized seminar that offers information and training to participants about computer security systems and the basic principles of fortifying computers, mobile phones and tablets from external attacks.",
    "link": "https://ekarinos.weebly.com/uploads/4/4/5/1/44512607/capture-1_orig.png",
    "language": [
      "English"
    ],
    "duration": "12",
    "type_format": "Hybrid",
    "country": "Greece",
    "timing": "Fixed dates",
    "dates": ["2020-03-21"],
    "content_type": "Theoretical and hands on",
    "prerequisites": "Basic Network and Programmic Skills",
    "can_lead_to_certification": "No",
    "includes_exams_for_certification": "No",
    "price": "120",
    "skills_group": [
      2,
      5,
      18,
      24,
      29
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Cyber Systems Security through Ethical Hacking",
    "organizer": "AKMI",
    "description": "Hackademic Cisco Certified CyberOps Associate certification program validates the day-to-day, tactical knowledge and skills that Security Operations Center (SOC) teams need to detect and respond to cybersecurity threats.   The CyberOps Associate exam and training cover knowledge and skills related to security concepts, security monitoring, hostbased analysis, network intrusion analysis, and security policies and procedures.",
    "link": "https://www.cisco.com/c/en/us/training-events/training-certifications/certifications/associate/cyberops-associate.html",
    "language": [
      "English"
    ],
    "duration": "60",
    "type_format": "Hybrid",
    "country": "Greece",
    "timing": "Fixed dates",
    "dates": ["2022-11-01"],
    "content_type": "Theoretical and hands on",
    "prerequisites": "Basic Network and Programmic Skills",
    "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
    "includes_exams_for_certification": "Yes",
    "price": "450",
    "skills_group": [
      8,
      10,
      15,
      24,
      29,
      30
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Introduction to Ethical Hacking Tools",
    "organizer": "  E-Learning Ε.Κ.Π.Α",
    "description": "The Training presents in detail the twenty best tools used in Ethical Hacking, their features, and the capabilities they provide. In addition, the Open Systems Interconnection (OSI) Model is presented",
    "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u3/ReloadContentPreview.htm",
    "language": [
      "Greek"
    ],
    "duration": "3",
    "type_format": "Online",
    "country": "Greece",
    "timing": "Available online",
    "dates": ["2020-01-01"],
    "content_type": "Theoretical only",
    "prerequisites": "Ethical Hacking Cyber Security, Information Systems Security",
    "can_lead_to_certification": "No",
    "includes_exams_for_certification": "No",
    "price": "0",
    "skills_group": [
      2,
      8,
      14,
      19,
      28,
      29
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Introduction to Concepts: Ethical Hacking, Cyber Security, Information Systems Security",
    "organizer": "  E-Learning Ε.Κ.Π.Α",
    "description": "The course is an introduction to concepts: Information System (IS), Information Systems Security and Ethical Hacking. In this context, the definition of Threat of an Information System (IS) is first presented. Next, the effects that threats can bring to an Information System, as well as the types of Threats, are presented. Finally, the method of identifying threats is presented as the first step for the protection of Information Systems.",
    "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u1/ReloadContentPreview.htm",
    "language": [
      "Greek"
    ],
    "duration": "3",
    "type_format": "Online",
    "country": "Greece",
    "timing": "Available online",
    "dates": ["2020-01-01"],
    "content_type": "Theoretical only",
    "prerequisites": "It is recommended to study the Introduction to Ethical Hacking Tools course of the same course series.",
    "can_lead_to_certification": "No",
    "includes_exams_for_certification": "No",
    "price": "0",
    "skills_group": [
      4,
      16,
      19,
      22
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Security Threats and Risk Management Methods",
    "organizer": "  E-Learning Ε.Κ.Π.Α",
    "description": "The course deals with the subject of Threats to Information Systems, as well as the ways to deal with them. In this context, the definition of Threat (Threat), the effects that threats can bring to an Information System, as well as the types of Threats are first presented. At the same time, the methodology of the risk analysis of an Information System is presented, as well as the basic characteristics, requirements and implementation steps of the OCTAVE Allegro Strategy. Finally, issues related to risk management and the implementation of Security Plans are presented.",
    "link": "https://freecourses.elearningekpa.gr/enotites/psd004-c1-u2/ReloadContentPreview.htm",
    "language": [
      "Greek"
    ],
    "duration": "3",
    "type_format": "Online",
    "country": "Greece",
    "timing": "Available online",
    "dates": ["2020-01-01"],
    "content_type": "Theoretical only",
    "prerequisites": "It is recommended to study the courses Introduction to Ethical Hacking Tools and Introduction to Concepts: Ethical Hacking, Cyber Security, Information Systems Security of the same course series.",
    "can_lead_to_certification": "No",
    "includes_exams_for_certification": "No",
    "price": "0",
    "skills_group": [
      6,
      10,
      14,
      28,
      29,
      30
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Cyber-Security for protection of classified information",
    "organizer": "INTENSEC RO SRL",
    "description": "Learn why cyber-security is important in the process of protection of classified information and how to protect classified information from the perspective of INFOSEC (security of electronic classified information)",
    "link": "https://intensec.ro/index.php/cybertraining/",
    "language": [
      "English"
    ],
    "duration": "24",
    "type_format": "Face-to-face",
    "country": "Romania",
    "timing": "On demand",
    "dates": [],
    "content_type": "Theoretical only",
    "prerequisites": "Recommendation from the employer",
    "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
    "includes_exams_for_certification": "No",
    "price": "0",
    "skills_group": [
      5,
      12
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Specialist in procedures and security tools for IT&C system",
    "organizer": "Fist Mixt Development-1MD",
    "description": "Learn how to maintain a high level of security in a IT&C network, and how to protect some of the most important assets of the organization: their data and services offered.",
    "link": "https://cyburg.ro/specialist-in-proceduri-si-instrumente-de-securitate-a-sistemelor-informatice/",
    "language": [
      "Romanian"
    ],
    "duration": "40",
    "type_format": "Hybrid",
    "country": "Romania",
    "timing": "Fixed dates",
    "dates": ["2022-08-22","2022-08-26"],
    "content_type": "Theoretical and hands on",
    "prerequisites": "Higher education, laptop",
    "can_lead_to_certification": "Yes (based on specific standards, recognized by national/international organizations)",
    "includes_exams_for_certification": "Yes",
    "price": "500",
    "skills_group": [
      13,
      19,
      24
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Hacking: Binary Exploitation",
    "organizer": "Fraunhofer AISEC",
    "description": "More and more devices and systems can now be reached through the internet and other networks, exposing them to direct attacks. Our solution for this problem is to understand and predict binary exploitation from the viewpoint of hackers.",
    "link": "https://www.academy.fraunhofer.de/en/continuing-education/information-communication/cybersecurity/hacking--binary-exploitation.html",
    "language": [
      "English"
    ],
    "duration": "24",
    "type_format": "Face-to-face",
    "country": "Germany",
    "timing": "On demand",
    "dates": [],
    "content_type": "Theoretical and hands on",
    "prerequisites": "Linux basics: Routine operations with the Bourne-Again Shell (BASH) and the GNU Debugger (GDB),   Programming knowledge: Fluent reading and understanding of code in C, programming experience in C or Python  Assembler: Reading and understanding of x86_64 assembler, programming in assembler is not required",
    "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
    "includes_exams_for_certification": "No",
    "price": "1800",
    "skills_group": [
      29,
      25,
      18,
      30,
      28
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "CyberHOT Summer School",
    "organizer": "University of Piraeus & Technical University of Crete",
    "description": "Hands-on cybersecurity training on, (1) threat and attack monitoring, (2) risk assessment, (3) security management, (4) technical vulnerability assessment, and (5) digital forensics.",
    "link": "https://www.cyberhot.eu/home",
    "language": [
      "English"
    ],
    "duration": "16",
    "type_format": "Face-to-face",
    "country": "Greece",
    "timing": "Fixed dates",
    "dates": ["2022-09-29","2022-09-30"],
    "content_type": "Hands one",
    "prerequisites": "MetasploitNmapBurp-ProxyOWASP-Top 10Basic Linux Knowledge-BashSQLPHP",
    "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
    "includes_exams_for_certification": "No",
    "price": "490",
    "skills_group": [
      30,
      13,
      29,
      10,
      25,
      28,
      22,
      21
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  },
  {
    "name": "Cyber Security in e-Governance",
    "organizer": "EIT DIGITAL",
    "description": "Participants will learn about legal aspects of e-governance, the requirements for electronic identity and how critical information infrastructure is protected in the public sector. From the start-up and e-service creation side, the programme will cover the topics of design process and prototyping, business model validation and approach lecturers by successful Estonian CyberTech companies. Finally, participants will be gathered with real-life experience with cyber security incidents and vulnerability detection. Moreover, one day (8-hour long) hands-on cyber exercise will be organized with mentors and experts for improving practical skills and understanding about CyberTech solutions.",
    "link": "https://summerschool.eitdigital.eu/cyber-security-in-e-governance",
    "language": [
      "English"
    ],
    "duration": "48",
    "type_format": "Face-to-face",
    "country": "Estonia",
    "timing": "Fixed dates",
    "dates": ["2022-07-31", "2022-08-05"],
    "content_type": "Theoretical and hands on",
    "prerequisites": "No need to have specific technical background",
    "can_lead_to_certification": "Other (eg. certificate of attendance / participation)",
    "includes_exams_for_certification": "No",
    "price": "850",
    "skills_group": [
      14,
      10,
      1,
      15,
      16,
      4
    ],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 17:46:46",
    "created_by": 0,
    "updated": "2022-12-15 17:46:46",
    "updated_by": 0
  }
]


export default sampleTrainings
