import React from 'react'
import Axios from 'axios'
import { Button, Modal, Space, Table, Tooltip, Typography  } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import {Config} from './config_section.js'
import AddProfileModalForm from './modals-profile/AddProfileModalForm'
import EditProfileModalForm from './modals-profile/EditProfileModalForm'

const { confirm } = Modal
const { Title } = Typography

const Profiles = ({ refresh, showMessage, authData, usersData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles}) => {
  const [editRecord, setEditRecord] = React.useState(null)

  function showDeleteConfirm (id) {
    confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return new Promise((resolve, reject) => {
          Axios.post( Config.server.deleteProfile, {...authData, id: id}, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
              if (response && response.data.error === false){
                showMessage({type: 'success', content: "Record successfully deleted."})
                refresh()
                resolve()
              } else {
                showMessage({type: 'error', content: "Something went wrong."})
                reject()
              }
            })
            .catch((error) => {
              console.log(error)
              showMessage({type: 'error', content: "Something went wrong."})
              reject()
            })
        }).catch(() => {
          showMessage({type: 'error', content: "Something went wrong."})
        })
      }
    })
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '48px',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter:  (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Skills Group',
      dataIndex: 'skills_group',
      key: 'skills_group',
      render: (skills) => (
        <ul className="skills-group">
          {skills.map(skillId => rsSkillsGroup.find(skgroup => skgroup.id === skillId).name).sort().map((skillName,i) => <li key={i}>{skillName}</li>)}
        </ul>
      )
    },
    {
      title: 'Updated [CET]',
      dataIndex: 'updated',
      key: 'updated',
      width: '160px',
      sorter:  (a, b) => a.updated.localeCompare(b.updated),
    },
    {
      title: 'Created [CET]',
      dataIndex: 'created',
      key: 'created',
      width: '160px',
      sorter:  (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (record) => (
        <Space size="small">
          <Tooltip title="Edit row"><EditOutlined className="editIcon" onClick={() => setEditRecord(rsProfiles.find(obj => obj.id === record.id))} /></Tooltip>
          <Tooltip title="Delete row"><DeleteOutlined className="deleteIcon" onClick={() => showDeleteConfirm(record.id)} /></Tooltip>
        </Space>
      ),
    }
  ]

  return (
    <div id="profiles">
      <Title className="pageTitle">ENISA Profiles</Title>

      <div className="dataHandleButtons" >
        <Space>
          <Button onClick={()=>setEditRecord("new")}><PlusOutlined className="icon-position-fix"/> Add Profile</Button>
        </Space>
      </div>
      <Table size="small" className="data-table"
        dataSource={rsProfiles}
        pagination={false}
        columns={tableColumns}
      />

      {/* modals */}
      <AddProfileModalForm editRecord={editRecord} refresh={refresh} showMessage={showMessage} onCancel={()=>setEditRecord(null)} authData={authData} rsSkillsGroup={rsSkillsGroup} rsProfiles={rsProfiles} />
      <EditProfileModalForm editRecord={editRecord} refresh={refresh} showMessage={showMessage} onCancel={()=>setEditRecord(null)} authData={authData} rsSkillsGroup={rsSkillsGroup} rsProfiles={rsProfiles} usersData={usersData} />
    </div>
  )
}

export default Profiles
