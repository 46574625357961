export const getSkillsCSVNodes = (analysis, rsSkillsGroup) => {
  if (!Array.isArray(analysis) || !Array.isArray(rsSkillsGroup)) return []

  return rsSkillsGroup.map(skill => {
    let count = 0
    analysis.forEach(row => {
      row.forEach(skillId => {
        if (skillId === skill.id) count += 1
      })
    })
    return {id: skill.id, label: skill.name, weight: count}
  })
}


export const getSkillsCSVEdges = (analysis, rsSkillsGroup) => {
  if (!Array.isArray(analysis) || !Array.isArray(rsSkillsGroup)) return []

  const frequencyMap = {}
  analysis.forEach((row) => {
    for (let i = 0; i < row.length; i++) {
      for (let j = i + 1; j < row.length; j++) {
        const source = row[i]
        const target = row[j]

        // Generování klíče pro dvojici (zajistíme, že dvojice 1-2 je totéž co 2-1)
        const pairKey = source < target ? `${source}-${target}` : `${target}-${source}`

        // Pokud již existuje, zvýšíme výskyt (weight), jinak inicializujeme
        if (frequencyMap[pairKey]) {
          frequencyMap[pairKey].weight += 1
        } else {
          frequencyMap[pairKey] = { source, target, weight: 1 }
        }
      }
    }
  })
  return Object.values(frequencyMap).map((e,i) => ({...e, type: 'undirected', id: i}))
}


export const getProfilesCSVNodes = (profilePairs, jobs) => {
  if (!(profilePairs instanceof Map) || !Array.isArray(jobs)) return []

  const profileNames = new Set()

  profilePairs.forEach((profiles, key) => {
    if (key !== "") profileNames.add(key);
    Object.keys(profiles).forEach((profile) => {
      if (profile !== "") profileNames.add(profile);
    });
  });

  let id = 1;
  const profilesWithId = Array.from(profileNames).map((name) => ({
    id: id++,
    label: name,
    weight: jobs.filter((job) => job.enisa_profile === name || job.secondary_enisa_profile === name).length,
  }));

  return profilesWithId
}


export const getProfilesCSVEdges = (profilePairs, rsProfiles, profilesCSVNodesData) => {
  if (!Array.isArray(rsProfiles) || !profilesCSVNodesData) return []

  const frequencyMap = new Map()
  profilePairs.forEach((profiles, key) => {
    if (key === "") return; // Ignorování prázdného profilu

    const sourceId = profilesCSVNodesData.find((profile) => profile.label === key)?.id

    Object.keys(profiles).forEach((targetProfile) => {
      if (targetProfile === "") return; // Ignorování prázdného profilu
      const targetId = profilesCSVNodesData.find((profile) => profile.label === targetProfile)?.id
      const weight = profiles[targetProfile]

      if (weight > 0 && sourceId && targetId) {
        const pairKey = `${sourceId}-${targetId}`

        if (frequencyMap.has(pairKey)) {
          const existing = frequencyMap.get(pairKey)
          frequencyMap.set(pairKey, {
            ...existing,
            weight: existing.weight + weight,
          })
        } else {
          frequencyMap.set(pairKey, {
            source: sourceId,
            target: targetId,
            weight,
          })
        }
      }
    })
  })

  return Array.from(frequencyMap.values()).map((e,i) => ({...e, type: 'undirected', id: i}))
}

export const getProfilePairs = (jobs) => {
  if (!Array.isArray(jobs)) {
    return new Map()
  }
  const profileMap = new Map()

  // Procházení databáze a počítání výskytů
  jobs.forEach(({ enisa_profile, secondary_enisa_profile }) => {
    // Inicializace primárního profilu v mapě
    if (!profileMap.has(enisa_profile)) {
      profileMap.set(enisa_profile, {})
    }
    // Inicializace sekundárního profilu v mapě primárního
    const primaryPairings = profileMap.get(enisa_profile)
    if (!primaryPairings[secondary_enisa_profile]) {
      primaryPairings[secondary_enisa_profile] = 0
    }
    // Zvýšení počtu výskytů
    primaryPairings[secondary_enisa_profile]++

    // Opak pro sekundární profil (abychom pokryli obě strany dvojice)
    if (!profileMap.has(secondary_enisa_profile)) {
      profileMap.set(secondary_enisa_profile, {})
    }
    const secondaryPairings = profileMap.get(secondary_enisa_profile)
    if (!secondaryPairings[enisa_profile]) {
      secondaryPairings[enisa_profile] = 0
    }
    secondaryPairings[enisa_profile]++
  })

  return profileMap
}