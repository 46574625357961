import React from 'react'
import {Config} from '../config_section.js'
import Axios from 'axios'
import moment from 'moment'
import { AutoComplete, DatePicker, Form, Input, Modal, Select, Tabs } from 'antd'
import { continents, countries } from 'countries-list'

const { TextArea } = Input
const { Option } = Select

const EditJobModalForm = ({ jobs, visible, onModalConfirm, onCancel, showMessage, authData }) => {
  const [editModalLoading, setEditModalLoading] = React.useState(false)
  const [openAd, setOpenAd] = React.useState(false)
  const [filename, setFilename] = React.useState("")
  const [tabKey, setTabKey] = React.useState("1")
  const [autoCompleteSource, setAutoCompleteSource] = React.useState([])
  const [autoCompleteCompany, setAutoCompleteCompany] = React.useState([])
  const [form] = Form.useForm()

  // set default form values
  React.useEffect(() => {
    if (visible !== openAd && visible) {
      let initialData = JSON.parse(JSON.stringify(visible))
      delete initialData.key
      initialData.date = moment(initialData.date, "YYYY-MM-DD")
      initialData.type = initialData.type ? initialData.type.split(",").map(s => s.trim()) : []
      form.setFieldsValue(initialData)
      setOpenAd(visible)
    }
  }, [visible, openAd, form])

  function updateForm() {
    generateFilename()
    getContinent()
  }

  function generateFilename() {
    let tmp = {
      title: form.getFieldValue("title"),
      date: form.getFieldValue("date"),
      country: form.getFieldValue("country")
    }
    if (tmp.title && tmp.date && tmp.country) {
      let newFileName = getCountryCode(tmp.country) + "_" + tmp.date.format("YYYY-MM-DD") + "_" + tmp.title.replace(/\s/g, '_')
      // filename duplicity check
      if (jobs.find(e => e.filename.includes(newFileName))){
        let i = 1
        // eslint-disable-next-line
        while (jobs.find(e => e.filename.includes(newFileName + "_" + i))){i++}
        newFileName += "_" + i
      }
      setFilename(newFileName + ".txt")
    }
  }

  function getContinent() {
    let formCountry = form.getFieldValue("country")
    if (formCountry) {
      let country = Object.values(countries).find(country => country.name === formCountry)
      let coutryCode = getCountryCode(formCountry)
      form.setFieldsValue({
        continent: continents[country.continent],
        region: coutryCode,
        field : coutryCode
      })
    }
  }

  function getCountryCode(country_name) {
    return Object.keys(countries).find(country_key => countries[country_key].name === country_name)
  }

  function urlExists(value) {
    // linkedin accuracy improvement
    if (value.includes("linkedin")) value = 'linkedin'+value.split('linkedin')[1].split('?')[0]
    return jobs.find(e => e.link.includes(value)) ? true : false
  }

  function getUniqueValuesWithCase(array) {
    return array.reduce((result, element) => {
      var normalize = x => typeof x === 'string' ? x.toLowerCase() : x
      var normalizedElement = normalize(element)
      if (result.every(otherElement => normalize(otherElement) !== normalizedElement))
        result.push(element)
      return result
    }, [])
  }

  return (
    <Modal
      getContainer={false}
      centered
      open={visible}
      title="Edit the job ad"
      width={850}
      okText="Save"
      confirmLoading={editModalLoading}
      cancelText="Cancel"
      onCancel={()=>{
        form.resetFields()
        setOpenAd(false)
        setFilename('')
        onCancel()
      }}
      className="edit-job-modal"
      onOk={() => {
        form
          .validateFields(setTabKey("1"))
          .then((values) => {
            setEditModalLoading(true)
            values.date = moment(values.date).format("YYYY-MM-DD")
            if (values.type) values.type = values.type.sort().join(", ")
            if (values.filename === "" || !values.filename) {
              values.filename = filename
            }
            delete values.created
            delete values.updated
            delete values.owner_name

            Axios.post( Config.server.editJob, {...authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
              .then((response) => {
                setEditModalLoading(false)
                if (response && response.data.error === false){
                  form.resetFields()
                  setOpenAd(false)
                  setFilename('')
                  showMessage({type: 'success', content: "Updated successfully."})
                  onModalConfirm()
                } else {
                  console.log("error: " + response.data.message)
                  showMessage({type: 'error', content: "Something went wrong!"})
                }
              })
              .catch((error) => {
                setEditModalLoading(false)
                console.log(error)
                showMessage({type: 'error', content: "Something went wrong!"})
              })

          })
      }}
    >
      <Form form={form} layout="horizontal" name="edit_job_modal" labelCol={{ span: 7 }} wrapperCol={{ span: 15}} labelWrap colon={false} onValuesChange={updateForm} >

        <Tabs
          activeKey={tabKey}
          onChange={activeKey => setTabKey(activeKey)}
          type="card"
          centered
          animated={{ inkBar: true, tabPane: true }}
          tabBarGutter={7}
          items={[
              {key: "1", label: "General", children: (
                <div className="content-scroll">

                  <Form.Item name="title" label="Title" rules={[{required: true, message: 'Please fill in the job title!'},{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="source" label="Source" rules={[{type: 'string', max: 255 }]}>
                    <AutoComplete
                      options={autoCompleteSource}
                      onSearch={value => {
                        setAutoCompleteSource(getUniqueValuesWithCase(jobs.filter(job => job.source.toLowerCase().startsWith(value.toLowerCase())).map(job => job.source)).sort().map(e => ({value: e})))
                      }}
                    >
                      <Input />
                    </AutoComplete>
                  </Form.Item>

                  <Form.Item name="link" label="Link" rules={[
                    {required: true, message: 'Please fill in the link!'},
                    {type: 'url', warningOnly: true },
                    {type: 'string', min: 6, max: 1023 },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let currentLink = openAd.link
                        if (currentLink.includes("linkedin")) currentLink = 'linkedin'+currentLink.split('linkedin')[1].split('?')[0]


                        if (!value || !urlExists(value) || value.includes(currentLink)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('This link is already stored in the database!'))
                      }
                    })
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="company" label="Company" rules={[{required: true, message: 'Please fill in the company!'},{type: 'string', max: 255 }]}>
                    <AutoComplete
                      options={autoCompleteCompany}
                      onSearch={value => {
                        setAutoCompleteCompany(getUniqueValuesWithCase(jobs.filter(job => job.company.toLowerCase().startsWith(value.toLowerCase())).map(job => job.company)).sort().map(e => ({value: e})))
                      }}
                    >
                      <Input />
                    </AutoComplete>
                  </Form.Item>
  {/*
                  <Form.Item name="city" label="City" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>
  */}
                  <Form.Item name="country" label="Country" rules={[{required: true, message: 'Please fill in the country!'},{type: 'string', max: 255 }]}>
                    <Select showSearch>
                      {
                        Object.values(countries)
                        .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        .map((country, index) => <Option key={index} value={country.name}>{country.name}</Option>)
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item hidden name="region" label="Region" rules={[{required: true, message: 'Please fill in the region!'}, {type: 'string', max: 4 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="continent" label="Continent" rules={[{type: 'string', max: 255 }]}>
                    <Input disabled={true} />
                  </Form.Item>

                  <Form.Item name="date" label="Date" rules={[{required: true, message: 'Please fill in the date of the job ad!'}]}>
                    <DatePicker />
                  </Form.Item>

                  <Form.Item name="enisa_profile" label="ENISA Profile" rules={[{type: 'string', max: 255 }]}>
                    <Select showSearch>
                      <Option value=""></Option>
                      <Option value="Chief Information Security Officer">Chief Information Security Office</Option>
                      <Option value="Cyber Incident Responder">Cyber Incident Responder</Option>
                      <Option value="Cyber Legal, Policy & Compliance Officer">Cyber Legal, Policy & Compliance Officer</Option>
                      <Option value="Cybersecurity Architect">Cybersecurity Architect</Option>
                      <Option value="Cyber Threat Intelligence Specialist">Cyber Threat Intelligence Specialist</Option>
                      <Option value="Cybersecurity Auditor">Cybersecurity Auditor</Option>
                      <Option value="Cybersecurity Educator">Cybersecurity Educator</Option>
                      <Option value="Cybersecurity Implementer">Cybersecurity Implementer</Option>
                      <Option value="Cybersecurity Researcher">Cybersecurity Researcher</Option>
                      <Option value="Digital Forensics Investigator">Digital Forensics Investigator</Option>
                      <Option value="Cybersecurity Risk Manager">Cybersecurity Risk Manager</Option>
                      <Option value="Penetration Tester">Penetration Tester</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="secondary_enisa_profile" label="Secondary ENISA Profile" rules={[{type: 'string', max: 255 }]}>
                    <Select showSearch>
                      <Option value=""></Option>
                      <Option value="Chief Information Security Officer">Chief Information Security Office</Option>
                      <Option value="Cyber Incident Responder">Cyber Incident Responder</Option>
                      <Option value="Cyber Legal, Policy & Compliance Officer">Cyber Legal, Policy & Compliance Officer</Option>
                      <Option value="Cybersecurity Architect">Cybersecurity Architect</Option>
                      <Option value="Cyber Threat Intelligence Specialist">Cyber Threat Intelligence Specialist</Option>
                      <Option value="Cybersecurity Auditor">Cybersecurity Auditor</Option>
                      <Option value="Cybersecurity Educator">Cybersecurity Educator</Option>
                      <Option value="Cybersecurity Implementer">Cybersecurity Implementer</Option>
                      <Option value="Cybersecurity Researcher">Cybersecurity Researcher</Option>
                      <Option value="Digital Forensics Investigator">Digital Forensics Investigator</Option>
                      <Option value="Cybersecurity Risk Manager">Cybersecurity Risk Manager</Option>
                      <Option value="Penetration Tester">Penetration Tester</Option>
                    </Select>
                  </Form.Item>
  {/*
                  <Form.Item name="type" label="Type" rules={[{type: 'array', max: 255 }]}>
                    <Select mode="multiple">
                      <Option value="Design">Design</Option>
                      <Option value="Operate">Operate</Option>
                      <Option value="Manage">Manage</Option>
                    </Select>
                  </Form.Item>
  */}
                  <Form.Item name="partners" label="Partners" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="description" label="Description" rules={[{required: true, message: 'Please fill in the description!'}]}>
                    <TextArea rows={4} />
                  </Form.Item>
  {/*
                  <Form.Item name="industry" label="Industry" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="seniority" label="Seniority" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="experience" label="Experience" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>
  */}
                  <Form.Item name="filename" label="Filename" rules={[{type: 'string', max: 255 }]} extra="Generated automatically" >
                    <Input placeholder={filename} />
                  </Form.Item>

                  <Form.Item name="comment" label="Comment" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>
  {/*
                  <Form.Item name="core_cs" label="Core CS" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>
  */}
                  <Form.Item hidden name="field" label="Field" rules={[{type: 'string', max: 255 }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="updated" label="Updated">
                    <Input disabled />
                  </Form.Item>

                  <Form.Item name="created" label="Created" >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item name="owner_name" label="Created by">
                    <Input disabled />
                  </Form.Item>

                </div>
              )},
              {key: "2", label: "Cybersecurity Skills", children: (
                <div className="content-scroll">

                  <Form.Item name="cs_business_continuity" label="Business Continuity">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_data_analysis" label="Data Analysis">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_data_privacy" label="Data Privacy">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_data_security" label="Data Security">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_digital_forensics" label="Digital Forensics">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_identity_management" label="Identity Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_incident_management" label="Incident Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_information_systems_and_network_security" label="Information Systems and Network Security ">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_information_technology_assessment" label="Information Security Controls Assessment">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_intelligence_analysis" label="Intelligence Analysis">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_law_policy_and_ethics" label="Law, Policy, and Ethics">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_physical_device_security" label="Physical Device Security">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_risk_management" label="Risk Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_testing_and_evaluation" label="Testing and Evaluation">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="cs_threat_analysis" label="Threat Analysis">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                </div>
              )},
              {key: "3", label: "Other IT Skills", children: (
                <div className="content-scroll">

                  <Form.Item name="os_asset_and_inventory_management" label="Asset and Inventory Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_database_administration" label="Database Administration">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_enterprise_architecture" label="Enterprise Architecture and Infrastructure Design">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_network_management" label="Network Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_operating_systems" label="Operating Systems">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_software_development" label="Software Development">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="os_system_administration" label="System Administration">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                </div>
              )},
              {key: "4", label: "Soft Skills", children: (
                <div className="content-scroll">

                  <Form.Item name="ss_communication" label="Collaborate and Communicate">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_education_and_training_delivery" label="Education and Training Delivery">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_organizational_awareness" label="Organizational Awareness">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_policy_development" label="Policy Development">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_problem_solving_and_critical_thinking" label="Problem solving and Critical Thinking">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_project_management" label="Project Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_strategic_relationship_management" label="Strategic Relationship Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_technology_fluency" label="Technology Fluency">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="ss_workforce_management" label="Workforce Management">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                </div>
              )},
              /*
              {key: "5", label: "Certifications", children: (
                <div className="content-scroll">

                  <Form.Item name="crt_sscp" label="SSCP">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_cisa" label="CISA">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_cissp" label="CISSP">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_cism" label="CISM">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_ccsp" label="CCSP">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_security" label="Security+">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_ceh" label="CEH">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_oscp" label="OSCP">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="crt_other" label="Other" rules={[{type: 'string', max: 255 }]}>
                    <Input/>
                  </Form.Item>

                </div>
              )},
              {key: "6", label: "Standards", children: (
                <div className="content-scroll">

                  <Form.Item name="std_sox" label="SOX">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_iso_27k" label="ISO 27k">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_iso_31000" label="ISO 31000">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_iso_22301" label="ISO 22301">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_nist_800" label="NIST 800">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_nist_csf" label="NIST CSF">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_pci_dss" label="PCI DSS">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_hipaa" label="HIPAA">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="std_sox_2" label="SOX">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                </div>
              )},
              {key: "7", label: "Benefits offered", children: (
                <div className="content-scroll">

                  <Form.Item name="bnf_flexible_hours" label="Flexible hours">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="bnf_home_office" label="Home office">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="bnf_private_health_package" label="Private health package">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="bnf_laptop" label="Laptop">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="bnf_company_resort_for_vacation" label="Company resort for vacation">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="bnf_remote_work" label="Remote work">
                    <Select>
                      <Option value=""></Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                    </Select>
                  </Form.Item>

                </div>
              )}
              */
          ]}
        />

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="owner" label="Owner" hidden>
          <Input />
        </Form.Item>

      </Form>

    </Modal>
  )
}

export default EditJobModalForm
