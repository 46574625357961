const skillsInCertifications = [
    {
        "certification_title": "CISSP (Certified Information Systems Security Professional)",
        "certifying_organization": "(ISC)²",
        "short_description": "The CISSP exam evaluates expertise across eight security domains. (Think of domains as topics you need to master based on your professional experience and education.) Passing the exam proves you have the advanced knowledge and technical skills to effectively design, implement and manage a cybersecurity program.",
        "link": "https://www.isc2.org/Certifications/CISSP#",
        "available_in_languages": [
            "English",
            "French",
            "German",
            "Brazilian Portuguese",
            "Spanish Modern",
            "Japanese",
            "Simplified Chinese",
            "Korean"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "A.5. Architecture Design",
            "B.1. Application Development",
            "B.6. ICT Systems Engineering",
            "D.1. Information Security Strategy Development",
            "E.3. Risk Management"
        ],
        "domain": [
            "Communication and Network Security",
            "Identity and access management (IAM)",
            "Security Architecture and Engineering",
            "Asset Security",
            "Security and Risk Management",
            "Security Assessment and Testing",
            "Software Security",
            "Security Operations"
        ],
        "main_topics": "8 Domains: Security and Risk Management Asset Security Security Architecture and engineering Communication and network security Identity and access management (IAM) Security Assessment and Testing Security Operations Software Development Security",
        "level": "Intermediate",
        "prerequisites": "To qualify for the CISSP, candidates must have at least five years of cumulative, paid full-time work experience in two or more of the eight domains: Domain 1. Security and Risk Management Domain 2. Asset Security Domain 3. Security Architecture and Engineering Domain 4. Communication and Network Security Domain 5. Identity and Access Management (IAM) Domain 6. Security Assessment and Testing Domain 7. Security Operations Domain 8. Software Development Security",
        "iso_17024": "Yes",
        "price": "665 Euros (for standard registration EMEA region). (Other prices also available https://www.isc2.org/Register-for-Exam/ISC2-Exam-Pricing)",
        "duration": "4 hours (Maximum amount of time for the CISSP CAT exam) (The non-English linear, fixed-form CISSP exam allows 6 hours to complete)",
        "skills_group": [
            "Risk Management",
            "Identity Management",
            "Network Management",
            "Software Development",
            "Enterprise Architecture and Infrastructure Design"
        ],
        "specific_skill": [
            "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
            "Configure solutions according to the organisation’s security policy",
            "Draw architectural and functional specifications"
        ],
        "specific_knowledge": [
            "Knowledge of risk management frameworks",
            "Knowledge of secure development lifecycle",
            "Knowledge of security architecture reference models and security solutions"
        ],
        "other_skills": [
            "Analyse and consolidate organisation’s quality and risk management practices"
        ],
        "other_knowledge": [
            "Knowledge of risk sharing options and best practices",
            "Knowledge of secure coding practices",
            "Understanding of cybersecurity-related standards and compliance requirements"
        ]
    },
    {
        "certification_title": "CEH (Certified Ethical Hacker)",
        "certifying_organization": "EC-Council",
        "short_description": "The CEH Program certifies individuals in the specific network security discipline of Ethical Hacking from a vendor-neutral perspective. The Certified Ethical Hacker certification will fortify the application knowledge of security officers, auditors, security professionals, site administrators, and anyone who is concerned about the integrity of the network infrastructure. A Certified Ethical Hacker is a skilled professional who understands and knows how to look for the weaknesses and vulnerabilities in target systems and uses the same knowledge and tools as a malicious hacker",
        "link": "https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Hybrid",
        "role_of_the_ecsf": [
            "E.8. Information Security Management"
        ],
        "domain": [
            "Security Assessment and Testing"
        ],
        "main_topics": "• System hacking methodology, phases, concepts, techniques, and tools • Threat vectors, their working, impact, and countermeasures • Different types of web server, web application, and web API attacks, hacking methodology, hacking tools, and countermeasures • Different types of wireless, mobile, cloud, IoT and OT attacks methodology, tools, and countermeasures",
        "level": "Intermediate",
        "prerequisites": "",
        "iso_17024": "Yes",
        "price": "€550,00",
        "duration": "C|EH Knowledge Exam 4 hours C|EH Practical Exam 6 hours practical exam",
        "skills_group": [
            "Digital Forensics",
            "Testing and Evaluation"
        ],
        "specific_skill": [
            "Identify, analyse and correlate events",
            "Use penetration testing tools effectively"
        ],
        "specific_knowledge": [
            "Knowledge of test methodologies and practices"
        ],
        "other_skills": [
            "Adapt and customise penetration testing tools and techniques; Conduct ethical hacking; Use penetration testing tools effectively; Identify and exploit vulnerabilities "
        ],
        "other_knowledge": [
            "Advanced knowledge of cybersecurity attack vectors; Advanced knowledge of penetration testing tools, techniques and methodologies"
        ]
    },
    {
        "certification_title": "OSCP: Offensive Security Certified Professional",
        "certifying_organization": "OffSec ",
        "short_description": "The offensive Security Certified Professional (OSCP) certification is used for Penetration testing. It is the world’s first hands-on offensive IS certification course that an individual can get. The use of this certification will enable the individual to express and demonstrate the working knowledge of Penetration concepts and walk through the life cycle of the same.",
        "link": "https://www.offensive-security.com/pwk-oscp/",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "B.3. Testing",
            "B.5. Documentation Production"
        ],
        "domain": [
            "Communication and Network Security",
            "Security Architecture and Engineering",
            "Security Assessment and Testing",
            "Software Security",
            "Asset Security",
            "Communication and Network Security"
        ],
        "main_topics": "Penetration Testing: What You Should Know Getting Comfortable with Kali Linux Command Line Fun Practical Tools Bash Scripting Passive Information Gathering Active Information Gathering Vulnerability Scanning Web Application Attacks Introduction to Buffer Overflows Windows Buffer Overflows Linux Buffer Overflows Client-Side Attacks Locating Public Exploits Fixing Exploits File Transfers Antivirus Evasion Privilege Escalation Password Attacks Port Redirection and Tunneling Active Directory Attacks The Metasploit Framework PowerShell Empire Assembling the Pieces: Penetration Test Breakdown Trying Harder: The Labs",
        "level": "Advanced",
        "prerequisites": "All students are required to have: Solid understanding of TCP/IP networking Reasonable Windows and Linux administration experience Familiarity with basic Bash and/or Python scripting",
        "iso_17024": "",
        "price": "$1499 (90 days of lab access One exam attempt Self-guided) OSCP Certification Exam Retake Fee\t$249",
        "duration": "23 hours and 45 minutes to complete the exam",
        "skills_group": [
            "Threat Analysis",
            "Information Systems and Network Security",
            "Network Management",
            "Testing and Evaluation"
        ],
        "specific_skill": [
            "Collect, analyse and correlate cyber threat information originating from multiple sources"
        ],
        "specific_knowledge": [
            "Knowledge of computer networks security",
            "Knowledge of TTP frameworks",
            "Knowledge of monitoring, implementing, testing and evaluating the effectiveness of the controls"
        ],
        "other_skills": [
            "Identify and exploit vulnerabilities; Perform social engineering; Conduct ethical hacking; Use and apply CTI platforms and tools; Adapt and customise penetration testing tools and techniques; Use penetration testing tools effectively"
        ],
        "other_knowledge": [
            "Knowledge of test methodologies and practices; Advanced knowledge of cybersecurity attack vectors; Advanced knowledge of penetration testing tools, techniques and methodologies; Advanced knowledge of CTI sharing standards; Knowledge of cybersecurity methods, methodologies, tools and techniques"
        ]
    },
    {
        "certification_title": "GCIH: GIAC Certified Incident Handler",
        "certifying_organization": "GIAC ",
        "short_description": "A GIAC Certified Incident Handler will be responsible for handling all security incidents. All the common attack techniques are analyzed and based on the appropriate tools are utilized to build a counter-attack process so that the data can be secured.",
        "link": "https://www.giac.org/certifications/certified-incident-handler-gcih/",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "A.7. Technology Trend Monitoring",
            "B.5. Documentation Production",
            "C.4. Problem Management"
        ],
        "domain": [
            "Security Operations",
            "Security Assessment and Testing"
        ],
        "main_topics": "Detecting Covert Communications Detecting Evasive Techniques Detecting Exploitation Tools Drive-By Attacks Endpoint Attack and Pivoting Incident Response and Cyber Investigation Memory and Malware Investigation Network Investigations Networked Environment Attack Password Attacks Post-Exploitation Attacks Reconnaissance and Open-Source Intelligence Scanning and Mapping SMB Scanning Web App Attacks ",
        "level": "Intermediate",
        "prerequisites": "There are no specific set pre-requisites for taking up this certification. But it is advisable to have minimum knowledge of Security aspects and dealing with the cyber attacks. A full-time degree in Information security is desirable",
        "iso_17024": "",
        "price": "The certification cost for “GSEC: GIAC Certified Incident Handler” is about $ 1,600",
        "duration": "4 hours",
        "skills_group": [
            "Incident Management",
            "Information Systems and Network Security"
        ],
        "specific_skill": [
            "Practice all technical, functional and operational aspects of cybersecurity incident handling and response"
        ],
        "specific_knowledge": [
            "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)",
            "Knowledge of computer networks security"
        ],
        "other_skills": [
            "Manage and analyse log files "
        ],
        "other_knowledge": [
            "Knowledge of incident handling communication cycle; Knowledge of cybersecurity incident handling practices and tools; Knowledge of cybersecurity incident handling methodologies; Knowledge of recent vulnerability disclosures, data breach incidents and geopolitical events impacting cyber risk"
        ]
    },
    {
        "certification_title": "CWSP® - Certified Wireless Security Professional",
        "certifying_organization": "Certified Wireless Network Professionals",
        "short_description": "ecure new opportunities. Do you know how to assess the vulnerability of a network and help prevent attacks before they happen? Do you know how to perform WLAN security audits and implement compliance monitoring solutions? Do you have experience setting up Wireless Intrusion Prevention Systems (WIPS)? Today’s wireless network security professionals need to have a deep understanding of the latest software, tools, trends and technologies available. Security professionals are often counted on to advise on security policies (i.e. password and acceptable use). Plus, these experts are responsible for configuring an entire network’s Security Design and Architecture.",
        "link": "https://www.cwnp.com/certifications/cwsp",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "A.6. Application Design",
            "E.8. Information Security Management"
        ],
        "domain": [
            "Communication and Network Security",
            "Security Assessment and Testing"
        ],
        "main_topics": "Main areas covered by CWSP WLAN Discovery Techniques Intrusion and Attack Techniques 802.11 Protocol Analysis Wireless Intrusion Prevention Systems (WIPS) Implementation Layer 2 and 3 VPNs used over 802.11 networks Enterprise/SMB/SOHO/Public-Network Security design models Managed Endpoint Security Systems802.11 Authentication and Key CWSP-206 Exam Objectives 2019 (CWSP-206 Exam will expire December 31, 2022) Management Protocols Enterprise/SMB/SOHO/Public-Network Security Solution Implementation Building Robust Security Networks from the ground up Fast BSS Transition (aka. Fast/Secure Roaming) Techniques Thorough coverage of all 802.1X/EAP types used in WLANs Wireless LAN Management Systems (WNMS) Authentication Infrastructure Design Models Using Secure Applications 802.11 Design Architectures Implementing a Thorough Wireless Security Policy",
        "level": "Advanced",
        "prerequisites": "The CWSP certification is a professional level wireless LAN certification for the CWNP Program. To earn a CWSP certification, you must hold a current and valid CWNA credential. You must take the CWSP exam at a Pearson Vue Testing Center and pass with a 70% or higher. ",
        "iso_17024": "",
        "price": "$324.99 ",
        "duration": "90 Minutes",
        "skills_group": [
            "Information Systems and Network Security",
            "Network Management",
            "Operating Systems"
        ],
        "specific_skill": [],
        "specific_knowledge": [
            "Knowledge of computer networks security",
            "Knowledge of TTP frameworks",
            "Knowledge of operating systems internals, networking protocols and services"
        ],
        "other_skills": [],
        "other_knowledge": [
            "Knowledge of offensive and defensive security practices; Knowledge of security controls",
            "Knowledge of operating systems security"
        ]
    },
    {
        "certification_title": "ISO/IEC 27701 Lead Auditor",
        "certifying_organization": "PECB ",
        "short_description": "The ISO/IEC 27701 Lead Auditor training course enables you to develop the necessary skills to perform a Privacy Information Management System (PIMS) audit by applying widely recognized audit principles, procedures and techniques.",
        "link": "https://pecb.com/en/education-and-certification-for-individuals/iso-iec-27701/iso-iec-27701-lead-auditor",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "E.8. Information Security Management",
            "D.3. Education and Training Provision"
        ],
        "domain": [
            "Security and Risk Management",
            "Security and Risk Management"
        ],
        "main_topics": "Introduction to Privacy Information Management System (PIMS) and ISO/IEC 2770, Audit principles, preparation, and launching of an audit, On-site audit activities, Closing the audit activities ",
        "level": "Advanced",
        "prerequisites": " A fundamental understanding of information security and privacy, and a comprehensive knowledge of audit principles.",
        "iso_17024": "Yes",
        "price": "1500 EUR",
        "duration": "Training Days: 5 and Exam Duration : 3 hours ",
        "skills_group": [
            "Information Security Controls Assessment",
            "Risk Management",
            "Problem Solving and Critical Thinking",
            "Collaborate and Communicate"
        ],
        "specific_skill": [
            "Apply auditing tools and techniques",
            "Define and apply maturity models for cybersecurity management",
            "Analyse and solve complex problems and security challenges",
            "Communicate or author publications, reports, training material"
        ],
        "specific_knowledge": [
            "Knowledge of conformity assessment methodologies",
            "Knowledge of risk management frameworks",
            "Understanding of the multidiscipline aspect of cybersecurity"
        ],
        "other_skills": [
            "Define and apply maturity models for cybersecurity management"
        ],
        "other_knowledge": [
            "Knowledge of cybersecurity maturity models"
        ]
    },
    {
        "certification_title": "CISA: Certified Information Systems Auditor",
        "certifying_organization": "ISACA ",
        "short_description": "The certification is suitable for any individual who would like to become an Information Systems Auditor",
        "link": "https://www.isaca.org/credentialing/cisa?utm_source=google&utm_medium=cpc&utm_campaign=CertBAU&utm_content=sem_CertBAU_certification-cisa-eu-category-google&cid=sem_2006817&Appeal=sem&gclid=CjwKCAjw-L-ZBhB4EiwA76YzOXBlcLvCY84IzZVzjvlQWyg9dF9NLRpADnVkp5ZU3AYS19jgp9caGRoCF9EQAvD_BwE",
        "available_in_languages": [
            "English",
            "Spanish",
            "Italian",
            "French",
            "Turkish",
            "Portuguese",
            "Korean",
            "Chinese",
            "Hebrew"
        ],
        "type_format": "Online / Testing centers ",
        "role_of_the_ecsf": [
            "E.6. ICT Quality Management",
            "A.7. Technology Trend Monitoring",
            "C.5. Systems Management",
            "D.10. Information and Knowledge Management",
            "E.6. ICT Quality Management",
            "E.8. Information Security Management",
            "E.7. Business Change Management"
        ],
        "domain": [
            "Communication and Network Security"
        ],
        "main_topics": "Information Systems Auditing Process, Governance and Management of IT, Information Systems Acquisition, Development and Implementation,Information Systems Operations and Business Resilience, Protection of Information Assets ",
        "level": "Advanced",
        "prerequisites": "Five (5) or more years of experience in IS/IT audit, control, assurance, or security. Experience waivers are available for a maximum of three (3) years.",
        "iso_17024": "Yes",
        "price": " $760/ 760 EUR",
        "duration": "Exam Length: 4 hours (240 minutes). The CISA CPE policy requires the attainment of CPE hours over an annual and three-year certification period.   Earn and report an annual minimum of twenty (20) CPE hours. Earn and report a minimum of one hundred and twenty (120) CPE hours for a three-year reporting cycle period. ",
        "skills_group": [
            "Collaborate and Communicate",
            "Data Security",
            "Data, Asset and Inventory Management",
            "Information Systems and Network Security",
            "Information Security Controls Assessment",
            "Law, Policy, and Ethics"
        ],
        "specific_skill": [
            "Collaborate with other team members and colleagues",
            "Manage cybersecurity resources",
            "Follow and practice auditing frameworks, standards and methodologies",
            "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies"
        ],
        "specific_knowledge": [
            "Understanding of the multidiscipline aspect of cybersecurity",
            "Knowledge of security technologies and solutions ",
            "Basic understanding of data storage, processing and protections within systems, services and infrastructures",
            "Understanding of security-related standards and requirements",
            "Advanced knowledge of auditing frameworks, standards, methodologies and certifications",
            "Knowledge of cybersecurity-related legal framework, regulations, standards"
        ],
        "other_skills": [
            "Audit with integrity, being impartial and independent"
        ],
        "other_knowledge": [
            "Advanced knowledge of auditing frameworks, standards, methodologies and certifications"
        ]
    },
    {
        "certification_title": "CISM: Certified Information Security Manager",
        "certifying_organization": "ISACA",
        "short_description": "ISACA’s Certified Information Security Manager (CISM) certification indicates expertise in information security governance, program development and management, incident management and risk management.",
        "link": "https://www.isaca.org/credentialing/cism?utm_source=google&utm_medium=cpc&utm_campaign=CertBAU&utm_content=sem_CertBAU_certification-cism-eu-product-google&cid=sem_2006843&Appeal=sem&gclid=CjwKCAjw-L-ZBhB4EiwA76YzOQaEq8MJtBHO6pRkxbpUF9kpLAGdDj2aZpVKNMlchKywazG3YvHT0RoC7tsQAvD_BwE",
        "available_in_languages": [
            "English",
            "Spanish",
            "Japanese",
            "Chinese",
            "French",
            "German",
            "Italian"
        ],
        "type_format": "ONLINE / TESTING CENTERS",
        "role_of_the_ecsf": [
            "E.9. Information Systems Governance",
            "E.8. Information Security Management",
            "E.3. Risk Management",
            "A.1. IS and Business Strategy Alignment",
            "C.4. Problem Management",
            "E.7. Business Change Management",
            "E.7. Business Change Management",
            "E.2. Project and Portfolio Management"
        ],
        "domain": [
            "Security and Risk Management",
            "Security and Risk Management",
            "Security and Risk Management",
            "Identity and access management (IAM)"
        ],
        "main_topics": "CISM certification proves your expertise in the following domains: Information Security Governance, Information Security Risk Management, Incident ManagementInformation Security Program",
        "level": "Advanced",
        "prerequisites": "The individual should have at least 5 years of full-time work experience in Certified Information Systems.The individual should be in a managerial position where they should be managing the entire information security processes.",
        "iso_17024": "Yes",
        "price": " $760 / 760 EUR",
        "duration": "4 hours (240 minutes). The CISM CPE policy requires the attainment of CPE hours over an annual and three-year certification period",
        "skills_group": [
            "Incident Management",
            "Risk Management",
            "Problem Solving and Critical Thinking",
            "Law, Policy, and Ethics"
        ],
        "specific_skill": [
            "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
            "Propose and manage risk-sharing options",
            "Provide practical solutions to cybersecurity issues",
            "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies"
        ],
        "specific_knowledge": [
            "Knowledge of cybersecurity incident handling methodologies",
            "Understanding of organisation’s mission and business objectives risks"
        ],
        "other_skills": [
            "Analyse and consolidate organisation’s quality and risk management practices; Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards"
        ],
        "other_knowledge": [
            "Advanced knowledge of risk management frameworks, standards, methodologies, tools, guidelines and best practices; Knowledge of risk management frameworks"
        ]
    },
    {
        "certification_title": " CompTIA Security+ Certification",
        "certifying_organization": "Comptia",
        "short_description": "CompTIA Security+ is a global certification that validates the baseline skills necessary to perform core security functions and pursue an IT security career. ",
        "link": "https://www.comptia.org/training/certmaster-learn/security",
        "available_in_languages": [
            "English",
            "Portuguese",
            "Japanese",
            "Thai",
            "Vietnamese"
        ],
        "type_format": "Online / testing centers ",
        "role_of_the_ecsf": [
            "A.5. Architecture Design",
            "E.8. Information Security Management",
            "E.3. Risk Management",
            "A.7. Technology Trend Monitoring"
        ],
        "domain": [
            "Security Operations",
            "Software Security",
            "Asset Security",
            "Communication and Network Security"
        ],
        "main_topics": "The CompTIA Security+ certification exam will verify the successful candidate has the knowledge and skills required to assess the security posture of an enterprise environment and recommend and implement appropriate security solutions; monitor and secure hybrid environments, including cloud, mobile, and IoT; operate with an awareness of applicable laws and policies, including principles of governance, risk, and compliance; identify, analyze, and respond to security events and incidents ",
        "level": "Beginner/Novice",
        "prerequisites": "CompTIA Network+ and two years of experience in IT administration with a security focus ",
        "iso_17024": "Yes",
        "price": "$392 / 392 Eur",
        "duration": "3 years ",
        "skills_group": [
            "Data Security",
            "Information Systems and Network Security",
            "Problem Solving and Critical Thinking"
        ],
        "specific_skill": [
            "Provide practical solutions to cybersecurity issues"
        ],
        "specific_knowledge": [
            "Knowledge of security technologies and solutions ",
            "Knowledge of computer networks security"
        ],
        "other_skills": [
            "Solve and troubleshoot problems"
        ],
        "other_knowledge": [
            "Understanding of responsible disclosure of cybersecurity-related information; Knowledge of information security "
        ]
    },
    {
        "certification_title": "Certified in Risk and Information Systems Control",
        "certifying_organization": "ISACA",
        "short_description": "Certified in Risk and Information Systems Control (CRISC) reflects the latest work practices and knowledge used by CRISC practitioners, changes in the business landscape and the heightened focus on corporate governance and enhanced business resilience. Employers can rest assured that armed with CRISC, their IT team is following governance best practices and taking a proactive, agile approach to ITRM that mitigates risks and threats and optimizes resources and ROI. ",
        "link": "https://www.isaca.org/credentialing/crisc",
        "available_in_languages": [
            "English",
            "Spanish",
            "Korean",
            "Chinese"
        ],
        "type_format": "Online / testing centers ",
        "role_of_the_ecsf": [
            "C.5. Systems Management"
        ],
        "domain": [],
        "main_topics": " Information Technology and Security, Risk Response and Reporting, Risk Enterprise, Governance Assessment. ",
        "level": "Advanced",
        "prerequisites": "Three (3) or more years of experience in IT risk management and IS control. No experience waivers or substitutions",
        "iso_17024": "Yes",
        "price": "$760/ 760 EUR",
        "duration": "The CRISC CPE policy requires the attainment of CPE hours over an annual and three-year certification period. Earn and report an annual minimum of twenty (20) CPE hours. Earn and report a minimum of one hundred and twenty (120) CPE hours for a three-year reporting cycle period. ",
        "skills_group": [
            "Risk Management",
            "Data Privacy",
            "Data Security",
            "Identity Management",
            "Incident Management",
            "Information Systems and Network Security",
            "Information Security Controls Assessment",
            "Problem Solving and Critical Thinking"
        ],
        "specific_skill": [
            "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
            "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
            "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
            "Follow and practice auditing frameworks, standards and methodologies",
            "Analyse and solve complex problems and security challenges"
        ],
        "specific_knowledge": [
            "Knowledge of technical and organisational controls that appropriately mitigate cybersecurity risks",
            "Advanced knowledge of data privacy and protection laws and regulations",
            "Knowledge of security technologies and solutions ",
            "Knowledge of cybersecurity incident handling practices and tools",
            "Knowledge of offensive and defensive security practices",
            "Knowledge of cybersecurity solutions, technical and organisational controls"
        ],
        "other_skills": [],
        "other_knowledge": []
    },
    {
        "certification_title": "SSCP (Systems Security Certified Practitioner)",
        "certifying_organization": "(ISC)²",
        "short_description": "The Systems Security Certified Practitioner (SSCP) is the ideal certification for those with proven technical skills and practical, hands-on security knowledge in operational IT roles. It provides confirmation of a practitioner’s ability to implement, monitor and administer IT infrastructure in accordance with information security policies and procedures that ensure data confidentiality, integrity and availability.",
        "link": "https://www.isc2.org/Certifications/SSCP",
        "available_in_languages": [
            "English",
            "Japanese",
            "Chinese",
            "German",
            "Korean",
            "Spanish"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "C.5. Systems Management",
            "E.3. Risk Management",
            "E.8. Information Security Management"
        ],
        "domain": [
            "Communication and Network Security",
            "Identity and access management (IAM)",
            "Security and Risk Management",
            "Software Security",
            "Security Operations"
        ],
        "main_topics": "SSCP Domains Domain 1. Security Operations and Administration Domain 2. Access Controls Domain 3. Risk Identification, Monitoring and Analysis Domain 4. Incident Response and Recovery Domain 5. Cryptography Domain 6. Network and Communications Security Domain 7. Systems and Application Security",
        "level": "Beginner/Novice",
        "prerequisites": "Candidates must have a minimum of one year cumulative work experience in one or more of the seven domains of the SSCP CBK. A one year prerequisite pathway will be granted for candidates who received a degree (bachelors or masters) in a cybersecurity program.",
        "iso_17024": "Yes",
        "price": "EUR 230 (https://www.isc2.org/Register-for-Exam/ISC2-Exam-Pricing)",
        "duration": "3 Hours",
        "skills_group": [
            "Database Administration",
            "Incident Management",
            "Risk Management",
            "Information Systems and Network Security",
            "System Administration",
            "Data Security"
        ],
        "specific_skill": [
            "Practice all technical, functional and operational aspects of cybersecurity incident handling and response"
        ],
        "specific_knowledge": [
            "Knowledge of information security "
        ],
        "other_skills": [
            "Cryptography"
        ],
        "other_knowledge": []
    },
    {
        "certification_title": "GSEC: GIAC Security Essentials Certification",
        "certifying_organization": "GIAC",
        "short_description": "The GIAC Security Essentials (GSEC) certification validates a practitioner's knowledge of information security beyond simple terminology and concepts. GSEC certification holders are demonstrating that they are qualified for hands-on IT systems roles with respect to security tasks.",
        "link": "https://www.giac.org/certifications/security-essentials-gsec/",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "E.3. Risk Management",
            "C.5. Systems Management",
            "E.8. Information Security Management",
            "B.6. ICT Systems Engineering"
        ],
        "domain": [
            "Security and Risk Management",
            "Identity and access management (IAM)",
            "Security Operations",
            "Communication and Network Security",
            "Asset Security",
            "Security Assessment and Testing"
        ],
        "main_topics": "Active defense, defense in depth, access control & password management Cryptography: basic concepts, algorithms and deployment, and application Defensible network architecture, networking & protocols, and network security Incident handling & response, vulnerability scanning and penetration testing Linux security: structure, permissions, & access; hardening & securing; monitoring & attack detection; & security utilities Security policy, contingency plans, critical controls and IT risk management Web communication security, virtualization and cloud security, and endpoint security Windows: access controls, automation, auditing, forensics, security infrastructure, & securing network services",
        "level": "Intermediate",
        "prerequisites": "The individual should be aware of the topics like security and has a basic understanding of networking and security aspects. For individuals who are completely new to this sector, then they are advised to go through Introduction to Information Security.",
        "iso_17024": "Yes",
        "price": "2400$",
        "duration": "4-5 hours",
        "skills_group": [
            "Incident Management",
            "Information Systems and Network Security",
            "Network Management",
            "Operating Systems",
            "Risk Management",
            "System Administration",
            "Testing and Evaluation",
            "Digital Forensics"
        ],
        "specific_skill": [
            "Manage and analyse log files",
            "Work on operating systems, servers, clouds and relevant infrastructures",
            "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
            "Decompose, analyse systems, spot weaknesses, develop security and privacy requirements and identify effective or ineffective related solutions",
            "Use penetration testing tools effectively",
            "Organise and work in a systematic and deterministic way based on evidence"
        ],
        "specific_knowledge": [
            "Knowledge of cybersecurity incident handling practices and tools",
            "Knowledge of computer networks security",
            "Knowledge of operating systems security",
            "Knowledge of risk management frameworks",
            "Knowledge of test methodologies and practices",
            "Knowledge of digital forensics methods, best practices and tools"
        ],
        "other_skills": [
            "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
            "Identify, analyse and correlate events"
        ],
        "other_knowledge": [
            "Knowledge of incident handling communication cycle; Knowledge of security controls;",
            "Knowledge of offensive and defensive security practices; Knowledge of security controls",
            "Knowledge of operating systems internals, networking protocols and services",
            "Understanding of organisation’s mission and business objectives risks"
        ]
    },
    {
        "certification_title": "EC-Council Certified Security Analyst v10",
        "certifying_organization": "EC-Council",
        "short_description": "ECSA is a globally respected penetration testing program that covers the testing of modern infrastructures, operating systems, and application environments while teaching the students how to document and prepare professional penetration testing report. This program takes the tools and techniques covered in CEH to next level by utilizing EC-Council’s published penetration testing methodology.",
        "link": "https://www.eccouncil.org/wp-content/uploads/2016/07/ECSAv10-Brochure.pdf",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "B.3. Testing",
            "B.5. Documentation Production"
        ],
        "domain": [
            "Security Assessment and Testing",
            "Communication and Network Security"
        ],
        "main_topics": "1. Introduction to Penetration Testing and Methodologies 2. Penetration Testing Scoping and Engagement Methodology 3. Open Source Intelligence (OSINT) Methodology 4. Social Engineering Penetration Testing Methodology 5. Network Penetration Testing Methodology - External 6. Network Penetration Testing Methodology - Internal 7. Network Penetration Testing Methodology - Perimeter Devices 8. Web Application Penetration Testing Methodology 9. Database Penetration Testing Methodology 10. Wireless Penetration Testing Methodology 11. Cloud Penetration Testing Methodology 12. Report Writing and Post Testing Actions",
        "level": "Advanced",
        "prerequisites": "The individual should have a valid degree in the Information security field. Should have valid work experience in networking, Security analysis and should be aware of the industry standards for safeguarding the data. The individual should have knowledge of TCP/IP Should have completed the Certified Ethical Hacking course.",
        "iso_17024": "",
        "price": "$300",
        "duration": "4 hours",
        "skills_group": [
            "Testing and Evaluation"
        ],
        "specific_skill": [
            "Use penetration testing tools effectively"
        ],
        "specific_knowledge": [
            "Knowledge of test methodologies and practices"
        ],
        "other_skills": [
            "Adapt and customise penetration testing tools and techniques; Use and apply CTI platforms and tools; Conduct ethical hacking; Identify and exploit vulnerabilities; Perform social engineering"
        ],
        "other_knowledge": [
            "Advanced knowledge of cybersecurity attack vectors; Advanced knowledge of penetration testing tools, techniques and methodologies; Advanced knowledge of CTI sharing standards"
        ]
    },
    {
        "certification_title": "GIAC Penetration Tester (GPEN)",
        "certifying_organization": "GIAC",
        "short_description": "The GIAC Penetration Tester certification validates a practitioner's ability to properly conduct a penetration test, using best practice techniques and methodologies. GPEN certification holders have the knowledge and skills to conduct exploits and engage in detailed reconnaissance, as well as utilize a process-oriented approach to penetration testing projects.",
        "link": "https://www.giac.org/certifications/penetration-tester-gpen/",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Online",
        "role_of_the_ecsf": [
            "B.3. Testing"
        ],
        "domain": [
            "Security Assessment and Testing",
            "Communication and Network Security"
        ],
        "main_topics": "Advanced Password Attacks Attacking Password Hashes Azure Applications and Attack Strategies Azure Overview, Attacks, and AD Integration Domain Escalation and Persistence Attacks Escalation and Exploitation Exploitation Fundamentals Kerberos Attacks Metasploit Moving Files with Exploits Password Attacks Password Formats and Hashes Penetration Test Planning Penetration Testing with PowerShell and the Windows Command Line Reconnaissance Scanning and Host Discovery Vulnerability Scanning",
        "level": "Advanced",
        "prerequisites": "The individual should be well versed in the Information security domain. Working knowledge of password masking and resolving password attacks is desirable More importantly, the individual should be enthusiastic to break the system.",
        "iso_17024": "Yes",
        "price": "€500,00",
        "duration": "3 hours",
        "skills_group": [
            "Testing and Evaluation"
        ],
        "specific_skill": [
            "Use penetration testing tools effectively"
        ],
        "specific_knowledge": [
            "Knowledge of test methodologies and practices"
        ],
        "other_skills": [
            "Adapt and customise penetration testing tools and techniques; Use and apply CTI platforms and tools; Conduct ethical hacking; Identify and exploit vulnerabilities; Perform social engineering"
        ],
        "other_knowledge": [
            "Knowledge of test methodologies and practices; Advanced knowledge of cybersecurity attack vectors; Advanced knowledge of penetration testing tools, techniques and methodologies; Advanced knowledge of CTI sharing standards; Knowledge of cybersecurity methods, methodologies, tools and techniques; Knowledge of monitoring, implementing, testing and evaluating the effectiveness of the controls"
        ]
    },
    {
        "certification_title": "ISO 27001:2013 Lead Auditor - CQI & IRCA Certified",
        "certifying_organization": "CQI & IRCA",
        "short_description": "CQI and IRCA Certified ISMS Auditor Training courses will equip you with the knowledge and skills to assess organisations' information security management systems to ISO 27001. This teaches you the management systems approach to identifying and managing information security risks, the requirements of ISO 27001, and how to plan, complete and report the audit of an entire ISMS. ",
        "link": "ISMS Auditor/Lead Auditor course | CQI | IRCA (quality.org)",
        "available_in_languages": [
            "English"
        ],
        "type_format": "Hybrid",
        "role_of_the_ecsf": [
            "B.3. Testing",
            "B.5. Documentation Production",
            "E.3. Risk Management",
            "E.6. ICT Quality Management",
            "E.8. Information Security Management"
        ],
        "domain": [
            "Security and Risk Management",
            "Asset Security",
            "Security Assessment and Testing",
            "Identity and access management (IAM)",
            "Security Operations",
            "Software Security"
        ],
        "main_topics": "To review the organisational documentation required by ISO 27001:2013 standard To produce a practical value-added documentation audit report To audit selected security controls To plan, conduct and conclude a practical audit To report findings accurately and factually To evaluate corrective actions The necessary skills to understand the results of your risk assessments and ensure all appropriate controls are deployed and monitored to protect your organisation from an information security breach.",
        "level": "Advanced",
        "prerequisites": "Knowledge of ISO 27001:2013 requirements, including principles and concepts",
        "iso_17024": "Yes",
        "price": "1500 (with 5 days course)",
        "duration": "2 Hours",
        "skills_group": [
            "Risk Management",
            "Operating Systems",
            "Incident Management",
            "Information Systems and Network Security",
            "Information Security Controls Assessment",
            "Law, Policy, and Ethics",
            "Collaborate and Communicate"
        ],
        "specific_skill": [
            "Analyse and consolidate organisation’s quality and risk management practices",
            "Work on operating systems, servers, clouds and relevant infrastructures",
            "Follow and practice auditing frameworks, standards and methodologies",
            "Analyse and implement cybersecurity standards, frameworks, policies, regulations, legislations, certifications and best practices",
            "Communicate and report"
        ],
        "specific_knowledge": [
            "Knowledge of cybersecurity incident handling methodologies",
            "Knowledge of security controls",
            "Knowledge of security controls frameworks, standards"
        ],
        "other_skills": [
            "Understanding of security-related standards and requirements"
        ],
        "other_knowledge": []
    }
]

export default skillsInCertifications
