import React from 'react'
import Axios from 'axios'
import {Alert, Button, Container, Modal, Row } from 'react-bootstrap'
import { DragDropContext } from 'react-beautiful-dnd'

import {Config} from '../config_section.js'
import TimeTable from './TimeTable.js'
import ListOfCourses from './ListOfCourses.js'
import Statistics from './Statistics.js'
import { checkColumnStructure, checkCoursesStructure } from "./utils"
import dndStructure from './initStructure/dndStructure'
import sampleCourses from '../curricula/initStructure/SampleCourses'
import CourseForm from '../curricula/CourseForm'

class StudyProfiler extends React.Component {
  constructor() {
    super()
    this.state = {
      courses: {},
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedCourse: null,
      sampleCoursesLoaded: false,
      showAddModal: false,
      // addModalValidated: false,
      addModalData: null,
      modalCourseId: null,
      semestersToShow: 4,
      analysis: "settings",
      selectedProfiles: []
    }
  }

  closeAddModal = () => this.setState({
    showAddModal: false,
    // addModalValidated: false,
  })

  openAddModal = (courseId) => {
    let modalData = null
    if (courseId !== null) {
      modalData = this.state.courses[courseId]
    }
    this.setState({showAddModal: true, modalCourseId: courseId, addModalData: modalData})
  }

  createCourseDnDId = (name, prefix, pool) => {
    // Create base part of item ID in DnD structure
    let newIdBase = prefix + "_" + name.replace(/ /g,"_").toLowerCase()
    var counter = 0
    while (pool.includes(newIdBase+"_"+counter)) {
      counter++
    }
    return newIdBase+"_"+counter
  }

  submitModal = () =>{
    if (this.state.modalCourseId === null) {
      this.addCustomCourse(this.state.addModalData)
    } else {
      this.updateCourse(this.state.addModalData)
    }
    this.closeAddModal()
  }

  addCustomCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let newState = {
      courses: JSON.parse(JSON.stringify(this.state.courses)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    // generate local course id
    newCourse.id = this.createCourseDnDId(newCourse.name, "user", Object.keys(newState.courses))
    // add to correct column
    const coursePosition = newCourse.type + '-' + newCourse.semester.charAt(0)
    newState.columns[coursePosition].courseIds.push(newCourse.id)
    newState.courses[newCourse.id] = newCourse

    this.setState(newState)
  }

  updateCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let oldId = this.state.modalCourseId
    let newState = {
      courses: JSON.parse(JSON.stringify(this.state.courses)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    newCourse.id = this.createCourseDnDId(newCourse.name, "user", Object.keys(newState.courses))
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    let oldPosition = Object.keys(this.state.columns).find(columnId => this.state.columns[columnId].courseIds.includes(oldId))
    let newPosition = isNaN(oldPosition.charAt(0)) ? (
      newCourse.type + '-' + newCourse.semester.charAt(0)
    ) : (
      oldPosition.charAt(0) + '-' + newCourse.semester.charAt(0)
    )

    // update courses
    delete newState.courses[oldId]
    newState.courses[newCourse.id] = newCourse

    // update columns
    let courseIndex = newState.columns[oldPosition].courseIds.indexOf(oldId)
    if (newPosition === oldPosition) {
      newState.columns[oldPosition].courseIds[courseIndex] = newCourse.id
    } else {
      newState.columns[oldPosition].courseIds.splice(courseIndex, 1 )
      newState.columns[newPosition].courseIds.push(newCourse.id)
    }

    this.setState(newState)
  }

  removeForeverCourse = (course) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[course.position].courseIds.splice( newState.columns[course.position].courseIds.indexOf(course.id), 1 )
    delete newState.courses[course.id]
    this.setState({columns: newState.columns, courses: newState.courses})
  }

  removeCourses = (courses) => {
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    courses.forEach((course, i) => {
      let newPosition = course.type + '-' + course.semester.charAt(0)
      let index = newColumns[course.position].courseIds.indexOf(course.id)
      newColumns[course.position].courseIds.splice(index , 1)
      newColumns[newPosition].courseIds.push(course.id)
    })
    this.setState({columns: newColumns})
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)
      if (!newData.hasOwnProperty("courses")) {
        this.setState({error:{message: "Wrong input file format!"}})
        return
      }

      // check valid position and item structure
      let columnTest = checkColumnStructure(newData.courses, dndStructure.columns)
      let courseTest = checkCoursesStructure(newData.courses)
      if (columnTest && courseTest) {

        // prepare of writing to the state
        let newState = {
          courses: JSON.parse(JSON.stringify(this.state.courses)),
          columns: JSON.parse(JSON.stringify(this.state.columns))
        }

        // testing id duplicity and generating new id
        newData.courses.forEach(newCourse => {

          /* Create new unique ID of course for use in DnD structure */
          newCourse.id = this.createCourseDnDId(newCourse.name, "import", Object.keys(newState.courses))

          // saving to the new state object
          newState.columns[newCourse.position].courseIds.push(newCourse.id)
          delete newCourse.position
          newState.courses[newCourse.id] = newCourse

        })
        // write to the state
        this.setState(newState)
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch(e) {
      console.log(e)
      this.setState({error:{message: "Sorry, something went wrong!"}})
    }

  }

  getCoursePosition = (course) => {
    return (
      course.type === "voluntary" && course.semester === "summer" ? 'voluntary-s' :
      course.type === "voluntary" && course.semester === "winter" ? 'voluntary-w' :
      course.type === "mandatory" && course.semester === "summer" ? 'mandatory-s' :
      course.type === "mandatory" && course.semester === "winter" ? 'mandatory-w' :
      course.type === "custom" && course.semester === "summer" ? 'custom-s' :
      course.type === "custom" && course.semester === "winter" ? 'custom-w' :
      null
    )
  }

  loadSamples = () => {
    // slouzi pro upload sample courses
    if (typeof(sampleCourses) !== "undefined" && sampleCourses !== null && sampleCourses.length !== 0){
      let sortedSampleCourses = sampleCourses.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      /* Prepare new empty state variables */
      let newColumns = JSON.parse(JSON.stringify(this.state.columns))
      let newCourses = JSON.parse(JSON.stringify(this.state.courses))

      /* Add selected courses */
      sortedSampleCourses.forEach((newCourse) => {
        /* Create new unique ID of courses for use in DnD structure */
        newCourse.id = this.createCourseDnDId(newCourse.name, "sample", Object.keys(newCourses))
        /* Add courses ID to DnD structure */
        const newPosition = this.getCoursePosition(newCourse)
        if (newPosition === null){
          console.log("import error")
          console.log(newCourse)
          return
        }
        newColumns[newPosition].courseIds.push(newCourse.id)
        /* Push object containing courses data to courses array */
        newCourses[newCourse.id] = newCourse
      })

      this.setState({columns: newColumns, courses: newCourses, sampleCoursesLoaded: true})
    } else {
      this.setState({error:{message: "There are no sample courses available!"}})
    }
  }

  setSemestersToShow = (newValue) => {
    let coursesToHide = []; // zde musí zůstat středník, jinak to háže chybu
    ["1-w", "1-s", "2-w", "2-s", "3-w", "3-s", "4-w", "4-s", "5-w", "5-s"].forEach((position, i) => {
      if (newValue < i+1) {
        let courseIds = JSON.parse(JSON.stringify(this.state.columns[position].courseIds))
        courseIds.forEach(courseId => {
          coursesToHide.push({...this.state.courses[courseId], position: position})
        })
      }
    })
    this.removeCourses(coursesToHide)
    this.setState({semestersToShow: parseInt(newValue)})
  }

  createNewCurricula = (newCurricula) => {
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newCourses = JSON.parse(JSON.stringify(this.state.courses))
    let err = false;
    ["1-w", "1-s", "2-w", "2-s", "3-w", "3-s", "4-w", "4-s", "5-w", "5-s"].forEach((position, i) => {
      if (newCurricula.length > i) {
        newCurricula[i].forEach(course => {
          if (this.state.courses.hasOwnProperty(course.id) === false) {
            err = true
            return
          }
          // get old position
          let oldPosition = this.getCoursePosition(this.state.courses[course.id])
          // erase old position
          let index = newColumns[oldPosition].courseIds.indexOf(course.id)
          newColumns[oldPosition].courseIds.splice(index, 1)
          // set new position
          newColumns[position].courseIds.push(course.id)
          newCourses[course.id].priority = course.priority
        })
      }
    })
    if (err) this.props.showMessage({type: 'error', content: "Some courses were not processed correctly!", duration: 5})
    if ([].concat(...newCurricula).length === 0) this.props.showMessage({type: 'error', content: "Course combination was not found!", duration: 5})
    this.setState({semestersToShow: newCurricula.length, analysis: "new-curricula", columns: newColumns, courses: newCourses})
  }

  analyzeCurricula = (values) => {
    values.courses = Object.values(this.state.courses)
    values.semesters = values.semesters * 2
    values.credit_tolerance = [values.credit_tolerance * -1, values.credit_tolerance]
    if (!Array.isArray(values.profiles)) values.profiles = [values.profiles]
    this.setState({analysis: "loading", selectedProfiles: values.profiles})
    this.props.showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0})
    console.log(values)
    Axios.post( Config.server.analyzeCurricula, {values}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        this.props.showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        if (response && response.data.error === false){
          this.props.showMessage({type: 'success', content: "Analysis done."})
          this.createNewCurricula(response.data.data)
          console.log(response.data.data)
        } else {
          console.log(response.data.message)
          this.props.showMessage({type: 'error', content: "Something went wrong!"})
          this.setState({analysis: "settings"})
        }
      })
      .catch((error) => {
        this.props.showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        this.props.showMessage({type: 'error', content: "Something went wrong!"})
        this.setState({analysis: "settings"})
        console.log(error)
      })
  }

  goBackAnalysis = () => {
    let newCourses = JSON.parse(JSON.stringify(this.state.courses))
    Object.keys(newCourses).forEach(courseId => {
      if (newCourses[courseId].hasOwnProperty("priority")) delete newCourses[courseId].priority
    })
    this.setState({analysis: "settings", courses: newCourses})
    this.setSemestersToShow(0)
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedCourse = this.state.courses[start.draggableId].id
    this.setState({
      homeIndex,
      draggedCourse,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }
    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]
    if (start === finish) {
      const newCourseIds = Array.from(start.courseIds)
      newCourseIds.splice(source.index, 1)
      newCourseIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        courseIds: newCourseIds
      }
      const newState = {
        ...this.state,
        homeIndex: null,
        draggedCourse: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }
      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startCourseIds = Array.from(start.courseIds)
    startCourseIds.splice(source.index, 1)
    const newStart = {
      ...start,
      courseIds: startCourseIds
    }

    const finishCourseIds = Array.from(finish.courseIds)
    finishCourseIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      courseIds: finishCourseIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedCourse: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  render(){
    const { columns, error, courses, addModalData, draggedCourse, showAddModal, /*addModalValidated,*/ modalCourseId, sampleCoursesLoaded, semestersToShow, analysis, selectedProfiles } = this.state
    const { rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles, showMessage } = this.props

    return (
      <div className="main">
        <div className="curricula-profiler">
          {error ? (
            <div className="main_loading">
              <Alert variant="danger">{error.message}</Alert>
              <div><Button variant="link" onClick={()=>this.setState({error:null})}>Go back</Button></div>
            </div>
          ) : (
            <Container fluid>
              <Row>
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                  <ListOfCourses 
                    columns={columns} 
                    courses={courses}
                    draggedCourse={draggedCourse}
                    sampleCoursesLoaded={sampleCoursesLoaded}
                    addCustomCourse={this.addCustomCourse}
                    removeCourse={this.removeForeverCourse}
                    loadSamples={this.loadSamples}
                    openAddModal={this.openAddModal}
                    rsSkillsGroup={rsSkillsGroup}
                    importData={this.importData} 
                  />
                  <TimeTable 
                    columns={columns} 
                    courses={courses}
                    draggedCourse={draggedCourse}
                    removeCourses={this.removeCourses}
                    openAddModal={this.openAddModal}
                    rsSkillsGroup={rsSkillsGroup}
                    rsProfiles={rsProfiles}
                    showMessage={showMessage}
                    analyzeCurricula={this.analyzeCurricula}
                    goBackAnalysis={this.goBackAnalysis}
                    analysis={analysis}
                    semestersToShow={semestersToShow}
                    setSemestersToShow={this.setSemestersToShow} 
                  />
                  <Statistics 
                    columns={columns} 
                    courses={courses} 
                    rsSkillsGroup={rsSkillsGroup} 
                    rsProfiles={rsProfiles} 
                    semestersToShow={semestersToShow}
                    selectedProfiles={selectedProfiles}
                  />
                </DragDropContext>
              </Row>
            </Container>
          )}

          <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

            {/* }<Form noValidate validated={addModalValidated} onSubmit={this.submitModal}> */}

              <Modal.Header closeButton className={modalCourseId === null ? "" : " edit"}>
                <Modal.Title>{modalCourseId === null ? "Add new course" : "Edit course"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <CourseForm saveLocally={(newData)=>{this.setState({addModalData: newData}, () => {this.submitModal()})}} formData={addModalData} userDataMerged={null} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} authData={null} hideForm={()=>this.closeAddModal()} showMessage={showMessage} getPublicData={null}></CourseForm>

              </Modal.Body>
              {/* <Modal.Footer>
                {modalCourseId === null ? "" : <Button variant="outline-dark" className="left" onClick={this.duplicateCourse}>Duplicate</Button>}
                <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
                <Button variant="primary" type="submit">Save</Button>
              </Modal.Footer>
            </Form> */}
          </Modal>
        </div>
      </div>
    )
  }

}

export default StudyProfiler
